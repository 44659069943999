import { ApolloError, useReactiveVar } from '@apollo/client';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Button, Grid } from '@mui/material';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import validator from 'validator';
import FormBanner, {
  FormBannerType,
} from '~/base/components/FormBanner/FormBanner';
import FormInput from '~/base/components/FormInput';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import SectionTitle from '~/base/components/SectionTitle/SectionTitle';
import { currentSongtrustUserPersonaVar } from '~/cache';
import {
  LoggedInSongtrustUserPersonaQuery,
  UpdateAccountInformationMutation,
  useUpdateAccountInformationMutation,
} from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';

function AboutMeSection({ t }: Translator) {
  const [wasSaved, setWasSaved] = useState<boolean>(false);
  const [processError, setProcessError] = useState<string>('');

  const loggedInUser = useReactiveVar(currentSongtrustUserPersonaVar);
  const aboutMeForm = useForm();

  const [updateAccountInformation, { loading }] =
    useUpdateAccountInformationMutation({
      fetchPolicy: 'no-cache',
    });

  const handleSubmitComplete = (data: UpdateAccountInformationMutation) => {
    // Update reactive variable to update across site.
    const updatedUserPersona = {
      ...loggedInUser,
      loggedInSongtrustUser: {
        ...loggedInUser?.loggedInSongtrustUser,
        phoneNumber: data.updateAccountInformation?.songtrustUser?.phoneNumber,
        user: {
          ...loggedInUser?.loggedInSongtrustUser?.user,
          firstName:
            data.updateAccountInformation?.songtrustUser?.user?.firstName,
          lastName:
            data.updateAccountInformation?.songtrustUser?.user?.lastName,
          email: data.updateAccountInformation?.songtrustUser?.user?.email,
        },
      },
    };
    currentSongtrustUserPersonaVar(
      updatedUserPersona as LoggedInSongtrustUserPersonaQuery,
    );
    setWasSaved(true);
  };

  const handleFormSubmitError = (error: ApolloError) => {
    setProcessError(error.message);
    setWasSaved(false);
  };

  const handleFormError = async () => {
    setProcessError(t('sections.account-information.about-me.errors.fields'));
    setWasSaved(false);
  };

  const handleFormSubmit = async (values: FieldValues) => {
    setProcessError('');
    if (await aboutMeForm.trigger()) {
      // Full submission variables.
      const submissionVariables = {
        firstName: values.firstname,
        lastName: values.lastname,
        phoneNumber: values.phone,
      };

      // Submit Mutation.
      updateAccountInformation({
        variables: submissionVariables,
        onCompleted: handleSubmitComplete,
        onError: handleFormSubmitError,
      });
    }
  };

  const preLoadFormValues = () => {
    aboutMeForm.setValue(
      'firstname',
      loggedInUser?.loggedInSongtrustUser?.user?.firstName,
    );
    aboutMeForm.setValue(
      'lastname',
      loggedInUser?.loggedInSongtrustUser?.user?.lastName,
    );
    aboutMeForm.setValue(
      'email',
      loggedInUser?.loggedInSongtrustUser?.user?.email,
    );
    aboutMeForm.setValue(
      'phone',
      loggedInUser?.loggedInSongtrustUser?.phoneNumber,
    );
  };

  useEffect(() => {
    preLoadFormValues();
  }, [loggedInUser]);

  return (
    <div data-testid="about-me-section">
      <FormProvider {...aboutMeForm}>
        <form
          id="accountInformationForm"
          onSubmit={aboutMeForm.handleSubmit(handleFormSubmit, handleFormError)}
          onChange={() => {
            setWasSaved(false);
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} data-testid="about-me-title">
              <SectionTitle>
                {t('sections.account-information.about-me.title')}
              </SectionTitle>
            </Grid>
            <Grid item xs={12}>
              {!wasSaved && (
                <FormBanner text={processError} type={FormBannerType.ERROR} />
              )}
              {wasSaved && (
                <FormBanner
                  text={t('sections.account-information.about-me.success')}
                  type={FormBannerType.SUCCESS}
                  time={2000}
                  recall={wasSaved}
                />
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <FormInput
                sx={{
                  width: '100%',
                }}
                id="account-firstname"
                data-testid="account-firstname"
                name="firstname"
                label={t(
                  'sections.account-information.about-me.fields.firstname.label',
                )}
                required={{
                  value: true,
                  message: t(
                    'sections.account-information.about-me.fields.firstname.validation.required',
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormInput
                sx={{
                  width: '100%',
                }}
                id="account-lastname"
                data-testid="account-lastname"
                name="lastname"
                label={t(
                  'sections.account-information.about-me.fields.lastname.label',
                )}
                required={{
                  value: true,
                  message: t(
                    'sections.account-information.about-me.fields.lastname.validation.required',
                  ),
                }}
              />
            </Grid>
            <Grid item xs={0} md={4} />
            <Grid item xs={12} md={4}>
              <FormInput
                sx={{
                  width: '100%',
                }}
                id="account-email"
                data-testid="account-email"
                name="email"
                label={t(
                  'sections.account-information.about-me.fields.email.label',
                )}
                required={{
                  value: true,
                  message: t(
                    'sections.account-information.about-me.fields.email.validation.required',
                  ),
                }}
                tooltip={t(
                  'sections.account-information.about-me.fields.email.tooltip',
                )}
                disabled
                validate={{
                  isEmailValid: (v) =>
                    validator.isEmail(v as string) ||
                    t(
                      'sections.account-information.about-me.fields.email.validation.invalid',
                    ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormInput
                sx={{
                  width: '100%',
                }}
                id="signup-phone"
                data-testid="signup-phone"
                name="phone"
                label={t(
                  'sections.account-information.about-me.fields.phone.label',
                )}
                required={{
                  value: true,
                  message: t(
                    'sections.account-information.about-me.fields.phone.validation.required',
                  ),
                }}
                validate={{
                  isValidPhone: (v) =>
                    isValidPhoneNumber(v as string) ||
                    t(
                      'sections.account-information.about-me.fields.phone.validation.invalid',
                    ),
                }}
                isPhoneNumber
              />
            </Grid>
            <Grid item xs={0} md={4} />
            <Grid item xs={12} md={4}>
              {loading && <LoadingIndicator size={50} />}
              {!loading && (
                <Button
                  data-testid="save-about-me-button"
                  variant="contained"
                  color="secondary"
                  type="submit"
                  sx={{
                    width: '100%',
                    pt: '.5rem',
                    pb: '.5rem',
                    pl: '3rem',
                    pr: '3rem',
                  }}
                >
                  {!wasSaved && (
                    <>
                      {t('sections.account-information.about-me.save-changes')}
                    </>
                  )}
                  {wasSaved && (
                    <>
                      {t('sections.account-information.about-me.saved')}{' '}
                      <CheckCircleIcon />
                    </>
                  )}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </div>
  );
}

export default AboutMeSection;
