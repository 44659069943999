const languageOptions = [
  { choiceId: 'ar', choiceLabel: 'Arabic' },
  { choiceId: 'az', choiceLabel: 'Azerbaijani' },
  { choiceId: 'bg', choiceLabel: 'Bulgarian' },
  { choiceId: 'bn', choiceLabel: 'Bengali' },
  { choiceId: 'bs', choiceLabel: 'Bosnian' },
  { choiceId: 'ca', choiceLabel: 'Catalan' },
  { choiceId: 'cs', choiceLabel: 'Czech' },
  { choiceId: 'cy', choiceLabel: 'Welsh' },
  { choiceId: 'da', choiceLabel: 'Danish' },
  { choiceId: 'de', choiceLabel: 'German' },
  { choiceId: 'el', choiceLabel: 'Greek' },
  { choiceId: 'en', choiceLabel: 'English' },
  { choiceId: 'en-gb', choiceLabel: 'British English' },
  { choiceId: 'eo', choiceLabel: 'Esperanto' },
  { choiceId: 'es', choiceLabel: 'Spanish' },
  { choiceId: 'es-ar', choiceLabel: 'Argentinian Spanish' },
  { choiceId: 'es-mx', choiceLabel: 'Mexican Spanish' },
  { choiceId: 'es-ni', choiceLabel: 'Nicaraguan Spanish' },
  { choiceId: 'et', choiceLabel: 'Estonian' },
  { choiceId: 'eu', choiceLabel: 'Basque' },
  { choiceId: 'fa', choiceLabel: 'Persian' },
  { choiceId: 'fi', choiceLabel: 'Finnish' },
  { choiceId: 'fr', choiceLabel: 'French' },
  { choiceId: 'fy-nl', choiceLabel: 'Frisian' },
  { choiceId: 'ga', choiceLabel: 'Irish' },
  { choiceId: 'gl', choiceLabel: 'Galician' },
  { choiceId: 'he', choiceLabel: 'Hebrew' },
  { choiceId: 'hi', choiceLabel: 'Hindi' },
  { choiceId: 'hr', choiceLabel: 'Croatian' },
  { choiceId: 'hu', choiceLabel: 'Hungarian' },
  { choiceId: 'id', choiceLabel: 'Indonesian' },
  { choiceId: 'is', choiceLabel: 'Icelandic' },
  { choiceId: 'it', choiceLabel: 'Italian' },
  { choiceId: 'ja', choiceLabel: 'Japanese' },
  { choiceId: 'ka', choiceLabel: 'Georgian' },
  { choiceId: 'kk', choiceLabel: 'Kazakh' },
  { choiceId: 'km', choiceLabel: 'Khmer' },
  { choiceId: 'kn', choiceLabel: 'Kannada' },
  { choiceId: 'ko', choiceLabel: 'Korean' },
  { choiceId: 'lt', choiceLabel: 'Lithuanian' },
  { choiceId: 'lv', choiceLabel: 'Latvian' },
  { choiceId: 'mk', choiceLabel: 'Macedonian' },
  { choiceId: 'ml', choiceLabel: 'Malayalam' },
  { choiceId: 'mn', choiceLabel: 'Mongolian' },
  { choiceId: 'nb', choiceLabel: 'Norwegian Bokmal' },
  { choiceId: 'ne', choiceLabel: 'Nepali' },
  { choiceId: 'nl', choiceLabel: 'Dutch' },
  { choiceId: 'nn', choiceLabel: 'Norwegian Nynorsk' },
  { choiceId: 'pa', choiceLabel: 'Punjabi' },
  { choiceId: 'pl', choiceLabel: 'Polish' },
  { choiceId: 'pt', choiceLabel: 'Portuguese' },
  { choiceId: 'pt-br', choiceLabel: 'Brazilian Portuguese' },
  { choiceId: 'ro', choiceLabel: 'Romanian' },
  { choiceId: 'ru', choiceLabel: 'Russian' },
  { choiceId: 'sk', choiceLabel: 'Slovak' },
  { choiceId: 'sl', choiceLabel: 'Slovenian' },
  { choiceId: 'sq', choiceLabel: 'Albanian' },
  { choiceId: 'sr', choiceLabel: 'Serbian' },
  { choiceId: 'sr-latn', choiceLabel: 'Serbian Latin' },
  { choiceId: 'sv', choiceLabel: 'Swedish' },
  { choiceId: 'sw', choiceLabel: 'Swahili' },
  { choiceId: 'tl', choiceLabel: 'Tagalog/Filipino' },
  { choiceId: 'ta', choiceLabel: 'Tamil' },
  { choiceId: 'te', choiceLabel: 'Telugu' },
  { choiceId: 'th', choiceLabel: 'Thai' },
  { choiceId: 'tr', choiceLabel: 'Turkish' },
  { choiceId: 'tt', choiceLabel: 'Tatar' },
  { choiceId: 'uk', choiceLabel: 'Ukrainian' },
  { choiceId: 'ur', choiceLabel: 'Urdu' },
  { choiceId: 'uz', choiceLabel: 'Uzbek' },
  { choiceId: 'vi', choiceLabel: 'Vietnamese' },
  { choiceId: 'zh-cn', choiceLabel: 'Simplified Chinese' },
  { choiceId: 'zh-tw', choiceLabel: 'Traditional Chinese' },
];

export default languageOptions;
