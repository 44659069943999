import FlagNameEnum from '~/base/enums/flagNameEnum';
import FlagTypeEnum from '~/base/enums/flagTypeEnum';
import SwitchNameEnum from '~/base/enums/switchNameEnum';
import useFeatureFlag from '~/base/hooks/useFeatureFlag';
import LoadingIndicator from '../LoadingIndicator';

interface FlagCheckedChildrenProps {
  name: FlagNameEnum | SwitchNameEnum;
  type: FlagTypeEnum;
  children: JSX.Element;
  alternative: JSX.Element;
  hideLoadingIndicator?: boolean;
}

function FlagCheckedChildren({
  name,
  type,
  children,
  alternative,
  hideLoadingIndicator = false,
}: FlagCheckedChildrenProps) {
  const { hasFlag, data, error } = useFeatureFlag(name, type);

  if (data) return hasFlag ? children : alternative;
  if (error) return alternative;

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return !hideLoadingIndicator ? <LoadingIndicator size={50} /> : <></>;
}

export default FlagCheckedChildren;
