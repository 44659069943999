import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';
import { Box, Grid, Link, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface AccessColumnProps {
  crosslinkCount: number;
}

function AccessColumn({ crosslinkCount }: AccessColumnProps) {
  const selfAccessOnly = crosslinkCount === 0;
  return (
    <Box data-testid="access-column">
      <Grid container>
        <Grid item xs={12}>
          <Link
            to="/account/access-management"
            component={RouterLink}
            target="_blank"
          >
            {selfAccessOnly && (
              <Tooltip data-testid="self-tooltip" title="Only You">
                <PersonIcon
                  data-testid="self-icon"
                  fontSize="large"
                  sx={{ color: '#7E7E7E' }}
                />
              </Tooltip>
            )}
            {!selfAccessOnly && (
              <Tooltip data-testid="multi-tooltip" title="Multiple Users">
                <PeopleIcon
                  data-testid="multi-icon"
                  fontSize="large"
                  sx={{ color: '#7E7E7E' }}
                />
              </Tooltip>
            )}
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AccessColumn;
