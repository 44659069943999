import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Grid, Typography } from '@mui/material';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { ACCOUNT_COMPLETION_LINKS } from '~/dashboard/constants/dashboardConstants';

interface DashboardAccountCompletionItemProps {
  /** The title of the completion item */
  title: string;
  /** The label used to find the navigation route */
  label: string;
  /** Flag to indicate if the item is complete */
  complete?: boolean;
}

/**
 * DashboardAccountCompletionItem displays a completion item with an icon indicating its status and a clickable title.
 *
 * @param {DashboardAccountCompletionItemProps} props - The properties for the DashboardAccountCompletionItem component.
 * @returns {JSX.Element} The rendered DashboardAccountCompletionItem component.
 */
function DashboardAccountCompletionItem({
  title,
  label,
  complete = false,
}: DashboardAccountCompletionItemProps): JSX.Element {
  const navigate = useNavigate();

  /**
   * Handles navigation when the completion item is clicked.
   *
   * @param {string} routeLabel - The label used to find the navigation route.
   */
  const handleAccountCompletionItemClick = (routeLabel: string) => {
    const navigateTo = _.get(ACCOUNT_COMPLETION_LINKS, routeLabel);
    if (navigateTo !== undefined) navigate(navigateTo);
  };

  return (
    <div data-testid="dashboard-account-completion-item">
      <Grid item xs={12}>
        {complete ? (
          <CheckCircleIcon
            data-testid="dashboard-account-completion-item-checked"
            width={50}
            height={50}
            sx={{
              margin: '.25rem .75rem .25rem 0',
              fill: '#031C9B',
              float: 'left',
            }}
          />
        ) : (
          <RadioButtonUncheckedIcon
            data-testid="dashboard-account-completion-item-unchecked"
            width={50}
            height={50}
            sx={{
              margin: '.25rem .75rem .25rem 0',
              fill: '#E0E0E0',
              float: 'left',
            }}
          />
        )}

        <Typography
          data-testid={label}
          variant="h4"
          sx={{
            cursor: 'pointer',
            fontSize: '1rem',
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}
          onClick={() => handleAccountCompletionItemClick(label)}
        >
          {title}
        </Typography>
      </Grid>
    </div>
  );
}

export default DashboardAccountCompletionItem;
