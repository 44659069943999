import { useEffect } from 'react';
import RedirectTypeEnum from '~/base/enums/redirectTypeEnum';
import RedirectError from './RedirectError';

/**
 * Props for Redirect component.
 */
interface RedirectProps {
  /**
   * The URL to redirect to.
   */
  url: string | undefined;
  /**
   * The type of redirect.
   */
  redirectType?: RedirectTypeEnum;
}

/**
 * Redirect component redirects the user to a specified URL.
 * @param {RedirectProps} props - Component props.
 * @returns {JSX.Element | null} - Rendered component or null if no URL is provided.
 */
function Redirect({ url, redirectType }: RedirectProps): JSX.Element | null {
  useEffect(() => {
    if (url) {
      let navigateTo;
      switch (redirectType) {
        case RedirectTypeEnum.REDIRECT_LEGACY_BASE:
          navigateTo = `${import.meta.env.VITE_LEGACY_APP_BASE_URL}${url}`;
          break;
        default:
          navigateTo = url;
      }
      window.location.replace(navigateTo);
    }
  }, [url, redirectType]);

  return <RedirectError />;
}

export default Redirect;
