import _ from 'lodash';
import { useFormContext } from 'react-hook-form';
import { Loadable } from '~/types/Loadable';
import { Translator } from '~/types/Translator';
import AddSongwriterBasicInformation from '../AddSongtrustWriterBasicInformation/AddSongwriterBasicInformation';
import AddSongtrustWriterPublishingCompany from '../AddSongtrustWriterPublishingCompany/AddSongtrustWriterPublishingCompany';
import AddSongtrustWriterSignature from '../AddSongtrustWriterSignature/AddSongtrustWriterSignature';
import AddSongtrustWriterUSRepresentation from '../AddSongtrustWriterUSRepresentation';
import AddSongtrustWriterYoutubeClaims from '../AddSongtrustWriterYoutubeClaims/AddSongtrustWriterYoutubeClaims';

/**
 * Props type for AddSongtrustWriter component.
 */
type AddSongtrustWriterProps = Translator & Loadable;

/**
 * AddSongtrustWriter component handles the rendering of songwriter information
 * and related components based on the selected PRO (Performing Rights Organization).
 *
 * @param {AddSongtrustWriterProps} props - Component props including translation function.
 * @returns {JSX.Element} Rendered component.
 */
function AddSongtrustWriter({ t, loading = false }: AddSongtrustWriterProps) {
  const { getValues } = useFormContext(); // Access form context to get form values

  /**
   * Check if the selected PRO is one of the US-based PROs.
   * @returns {boolean} True if the selected PRO is a US PRO, otherwise false.
   */
  const hasUSPRO = () => {
    return _.includes(['BMI', 'ASCAP', 'SESAC', 'GMR'], getValues('pro'));
  };

  return (
    <div data-testid="add-songtrust-writer">
      {/* Basic information of the songwriter */}
      <AddSongwriterBasicInformation t={t} loading={loading} />

      {/* Conditionally render US representation component if no US PRO is selected */}
      {!hasUSPRO() && (
        <AddSongtrustWriterUSRepresentation t={t} loading={loading} />
      )}

      {/* Render publishing company, YouTube claims, and signature components */}
      <AddSongtrustWriterPublishingCompany t={t} loading={loading} />
      <AddSongtrustWriterYoutubeClaims t={t} loading={loading} />
      {!loading && <AddSongtrustWriterSignature t={t} />}
    </div>
  );
}

export default AddSongtrustWriter;
