import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

/**
 * Props for the FormTooltipAlert component
 * @typedef {Object} FormTooltipAlertProps
 * @property {boolean} open - Whether the dialog is open
 * @property {() => void} onClose - Function to call when the dialog is closed
 * @property {React.ReactNode} [content] - Content to display inside the dialog
 */
interface FormTooltipAlertProps {
  open: boolean;
  onClose: () => void;
  content?: React.ReactNode;
}

/**
 * FormTooltipAlert component to display a dialog with customizable content
 * @param {FormTooltipAlertProps} props - Props for the component
 * @returns {JSX.Element} FormTooltipAlert component
 */
function FormTooltipAlert({
  open,
  onClose,
  content = null,
}: FormTooltipAlertProps): JSX.Element {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default FormTooltipAlert;
