/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import { Box, Link, Tooltip } from '@mui/material';
import type { PaginationState } from '@tanstack/react-table';
import { MRT_ColumnDef, MaterialReactTable } from 'material-react-table';
import { useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { UserSongwriterAccess, UserWithEmail } from '~/account/types/types';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import {
  TABLE_BODY_CELL_PROPS,
  TABLE_HEAD_CELL_PROPS,
  TABLE_HEAD_PROPS,
  TABLE_HEAD_ROW_PROPS,
  TABLE_PAPER_PROPS,
  TABLE_PROPS,
} from '~/base/constants/tableConstants';
import { Translator } from '~/types/Translator';
import { useUserSongwriterAccessQuery } from '~/types/generated/graphql';

function AccessManagementUserList({ t }: Translator) {
  const [userAccess, setUserAccess] = useState<Array<UserSongwriterAccess>>([]);

  const [totalCount, setTotalCount] = useState(0);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  // Fetch data using hook.
  const { data, loading, error } = useUserSongwriterAccessQuery({
    variables: {
      offset: pagination.pageIndex * pagination.pageSize,
      first: pagination.pageSize,
    },
    onCompleted: (res) => {
      const items = res.userSongwriterAccess?.edges.map((item) => {
        const user = {
          id: parseInt(item?.node?.user.id as string, 10),
          name: `${item?.node?.user.firstName} ${item?.node?.user.lastName}`,
          email: item?.node?.user.email,
        };

        let songtrustUserId = -1;
        const songwriters = item?.node?.songwriterCrosslinks.edges.map(
          (swItem) => {
            if (swItem?.node?.songtrustuser.id) {
              songtrustUserId = parseInt(swItem?.node?.songtrustuser.id, 10);
            }
            return `${swItem?.node?.songwriter.firstName} ${swItem?.node?.songwriter.lastName}`;
          },
        );

        return {
          songtrustUserId,
          user,
          songwriters,
        } as UserSongwriterAccess;
      });

      const totalItems = res.userSongwriterAccess?.totalCount;

      if (items && totalItems) {
        setUserAccess(items);
        setTotalCount(totalItems);
      }
    },
  });

  const columns = useMemo<MRT_ColumnDef<UserSongwriterAccess>[]>(
    () => [
      {
        accessorKey: 'user',
        header: t('sections.access-management.table.user'),
        Cell: (cellData) => (
          <Box>
            <Box
              component="div"
              sx={{ color: '#D5164B', textDecoration: 'underline' }}
            >
              {cellData.cell.getValue<UserWithEmail>().name}
            </Box>

            <Box component="div" sx={{ fontWeight: 'bold' }}>
              {cellData.cell.getValue<UserWithEmail>().email}
            </Box>
          </Box>
        ),
      },
      {
        accessorKey: 'songwriters',
        header: t('sections.access-management.table.has-access'),
        Cell: (cellData) => (
          <Box component="div">
            <Tooltip
              title={cellData.cell.getValue<string[]>().join(', ')}
              arrow
            >
              <Link
                component={RouterLink}
                data-testid="songwriter-access"
                to={`/account/access-management/${cellData.row.original.songtrustUserId}`}
              >
                {`${cellData.cell.getValue<string[]>().length} ${t(
                  'sections.access-management.table.songwriters',
                )}`}
              </Link>
            </Tooltip>
          </Box>
        ),
      },
    ],
    [],
  );

  return (
    <div data-testid="access-management-user-list">
      {loading && <LoadingIndicator size={50} />}
      {!loading && data && (
        <MaterialReactTable
          columns={columns}
          data={userAccess}
          enableTopToolbar={false}
          enableColumnActions={false}
          enableColumnDragging={false}
          onPaginationChange={setPagination}
          rowCount={totalCount}
          state={{
            isLoading: loading,
            pagination,
            showAlertBanner: error !== undefined,
            showProgressBars: loading,
          }}
          muiTableHeadProps={TABLE_HEAD_PROPS}
          muiTableHeadRowProps={TABLE_HEAD_ROW_PROPS}
          muiTableHeadCellProps={TABLE_HEAD_CELL_PROPS}
          muiTableBodyCellProps={TABLE_BODY_CELL_PROPS}
          muiTableProps={TABLE_PROPS}
          muiTablePaperProps={TABLE_PAPER_PROPS}
          manualPagination
          enableBottomToolbar
          enableSorting={false}
        />
      )}
    </div>
  );
}

export default AccessManagementUserList;
