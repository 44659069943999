import { Box, Drawer, Grid } from '@mui/material';
import Hamburger from 'hamburger-react';
import { useState } from 'react';
import HeaderButtons from '~/base/components/Header/HeaderButtons';
import HeaderLogo from '~/base/components/Header/HeaderLogo';
import HeaderMenuLinks from '~/base/components/Header/HeaderMenuLinks';
import { MenuLinkType } from '~/base/types/MenuLinkType';

interface HeaderSlideDownMenuProps {
  menuLinks: Array<MenuLinkType>;
}

function HeaderSlideDownMenu({ menuLinks }: HeaderSlideDownMenuProps) {
  const [isHamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);

  return (
    <>
      <Box component="span" data-testid="header-slide-down-hamburger">
        <Hamburger
          toggled={isHamburgerMenuOpen}
          toggle={setHamburgerMenuOpen}
          color="#FFF"
        />
      </Box>

      <Box component="span" data-testid="header-slide-down-drawer-menu">
        <Drawer
          sx={{ width: 'auto' }}
          PaperProps={{
            style: {
              height: '85vh',
              backgroundColor: '#000',
              opacity: 1,
              overflowX: 'hidden',
              paddingBottom: '2rem',
            },
          }}
          open={isHamburgerMenuOpen}
          anchor="top"
          onClick={() => setHamburgerMenuOpen(false)}
          onClose={() => {
            setHamburgerMenuOpen(false);
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            sx={{ padding: '1.5rem' }}
            data-testid="no-pad-container2"
          >
            <HeaderLogo size="lg" />
            <Hamburger
              rounded
              toggled={isHamburgerMenuOpen}
              toggle={setHamburgerMenuOpen}
              color="#FFF"
            />
          </Grid>
          <Grid
            container
            spacing={3}
            flexDirection="column"
            alignItems="center"
            flex={1}
            sx={{ paddingTop: '3rem' }}
          >
            <HeaderMenuLinks
              menuLinks={menuLinks}
              setMenuOpen={setHamburgerMenuOpen}
            />
            <HeaderButtons />
          </Grid>
        </Drawer>
      </Box>
    </>
  );
}

export default HeaderSlideDownMenu;
