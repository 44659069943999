import {
  Alert,
  Button,
  Grid,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const enum FormBannerType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
}

interface FormBannerProps {
  title?: string | undefined;
  text: string;
  type: FormBannerType;
  time?: number;
  recall?: boolean;
  dismissButton?: boolean;
  sx?: SxProps<Theme>;
}

/**
 * FormBanner component displays a banner with a message and optional dismiss button.
 *
 * @param {string} title - The title of text to display.
 * @param {string} text - The message text to display.
 * @param {FormBannerType} type - The type of banner (success, error, info, warning).
 * @param {number} [time=undefined] - The time in milliseconds to auto-hide the banner.
 * @param {SxProps<Theme>} [sx={}] - The styling properties.
 * @param {boolean} [recall=false] - Flag indicating whether to restart the timer.
 * @param {boolean} [dismissButton=false] - Flag indicating whether to show a dismiss button.
 * @returns {JSX.Element} The rendered banner component.
 */
function FormBanner({
  title = undefined,
  text,
  type,
  time = undefined,
  recall = false,
  sx = {},
  dismissButton = false,
}: FormBannerProps): JSX.Element {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { t } = useTranslation(['translation']);

  // Merging the passed sx prop with default styles
  const boxStyle = {
    px: { xs: '1rem' },
    py: { xs: '0.375rem' },
    ...sx,
  };

  // Handler for closing the banner
  const handleOnClose = () => {
    setIsVisible(false);
  };

  // Effect to handle visibility and auto-hide based on the time prop
  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    // Set visibility when the banner should be shown
    setIsVisible(true);

    // If a time is provided, set a timeout to hide the banner
    if (time !== undefined) {
      timer = setTimeout(() => {
        setIsVisible(false);
      }, time);
    }

    // Clear the timer if the component unmounts or if recall/time changes
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [time, recall]);

  return (
    <Grid data-testid="form-banner" container>
      {isVisible && text !== '' && (
        <Stack sx={{ width: '100%' }} justifyContent="space-between">
          <Alert
            data-testid="form-banner-text"
            severity={type}
            sx={boxStyle}
            action={
              dismissButton ? (
                <Button
                  variant="outlined"
                  color="inherit"
                  size="small"
                  sx={{
                    pl: '2rem',
                    pr: '2rem',
                  }}
                  onClick={handleOnClose}
                >
                  {t('button.dismiss')}
                </Button>
              ) : undefined
            }
          >
            {title && (
              <Typography fontWeight={700} variant="body2" component="p">
                {title}
              </Typography>
            )}
            {text}
          </Alert>
        </Stack>
      )}
    </Grid>
  );
}

export default FormBanner;
