/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import { Container, Grid, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import PageTitle from '~/base/components/PageTitle/PageTitle';
import usePageTitle from '~/hooks/usePageTitle';
import { Translator } from '~/types/Translator';
import AccountHistoryPageTable from '../AccountHistoryPageTable/AccountHistoryPageTable';

function AccountHistoryPage({ t }: Translator) {
  usePageTitle(t('sections.payment-history.title'));

  return (
    <div data-testid="account-payment-history-page">
      <Container>
        <PageTitle
          currentPage={t('sections.payment-history.title')}
          breadcrumbLinks={[
            {
              id: 'account-history-back-link',
              label: t('sections.account-information.breadcrumb'),
              href: '/account',
            },
            {
              id: 'account-tax-history-back-link',
              label: t('sections.tax-information.title'),
              href: '/account/tax-documentation',
            },
          ]}
          pageTitle={{
            title: t('sections.payment-history.title'),
            id: 'account-payment-history-page-title',
          }}
        />
        <Grid
          container
          spacing={2}
          sx={{
            flexDirection: {
              xs: 'column',
            },
          }}
        >
          <Grid item>
            <Grid item xs={12} md={12} sx={{ pb: '2rem' }}>
              <Typography
                data-testid="account-payment-history-page-content"
                variant="body1"
                component="p"
              >
                {t('sections.payment-history.content')}
                <Link component={RouterLink} to="/account/tax-documentation">
                  {t('sections.payment-history.content-link')}
                </Link>
                {t('sections.payment-history.content-page')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <AccountHistoryPageTable t={t} />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default AccountHistoryPage;
