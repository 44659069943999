import { Container, Grid } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import FormBanner from '~/base/components/FormBanner';
import { FormBannerType } from '~/base/components/FormBanner/FormBanner';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import PageTitle from '~/base/components/PageTitle/PageTitle';
import usePageTitle from '~/hooks/usePageTitle';
import { Translator } from '~/types/Translator';
import {
  SongwriterQuery,
  SongwriterStatusLogType,
  SongwriterType,
  useSongwriterQuery,
} from '~/types/generated/graphql';
import SongwriterDetailInfo from '../SongwriterDetailInfo/SongwriterDetailInfo';
import SongwriterDetailNextSteps from '../SongwriterDetailNextSteps/SongwriterDetailNextSteps';
import SongwriterDetailProgress from '../SongwriterDetailProgress/SongwriterDetailProgress';
import SongwriterDetailStatus from '../SongwriterDetailStatus/SongwriterDetailStatus';

function SongwriterDetailPage({ t }: Translator) {
  usePageTitle(t('page.songwriter-details.title'));

  const { id: songwriterId } = useParams();

  const [currentSongwriter, setCurrentSongwriter] = useState<
    SongwriterType | undefined
  >(undefined);

  const { loading, error } = useSongwriterQuery({
    variables: {
      songwriterId: parseInt(songwriterId as string, 10),
    },
    onCompleted: (res: SongwriterQuery) => {
      setCurrentSongwriter(res.songwriter as SongwriterType);
    },
  });

  return (
    <Container data-testid="songwriter-detail-page">
      <PageTitle
        currentPage={
          loading
            ? '...'
            : `${currentSongwriter?.firstName ?? ''} ${currentSongwriter?.middleName ?? ''} ${currentSongwriter?.lastName ?? ''}`
        }
        breadcrumbLinks={[
          {
            id: 'songwriter-detail-back-link',
            label: t('page.songwriter-details.breadcrumb'),
            href: '/songwriters/manage',
          },
        ]}
        pageTitle={{
          title: t('page.songwriter-details.title'),
          id: 'songwriter-detail-page-title',
        }}
        pageSubtitle={{
          id: 'songwriter-detail-page-subtitle',
          subtitle: t('page.songwriter-details.subtitle'),
        }}
      />
      <Grid container spacing={2} sx={{ pt: '2rem' }}>
        {error && (
          <Grid item xs={12}>
            <FormBanner text={error.message} type={FormBannerType.ERROR} />
          </Grid>
        )}

        {!error && (
          <>
            {loading && (
              <Grid item xs={12}>
                <LoadingIndicator size={100} />
              </Grid>
            )}

            {!loading && (
              <>
                <Grid item xs={12} md={6}>
                  <SongwriterDetailInfo
                    t={t}
                    songwriter={currentSongwriter as SongwriterType}
                  />
                </Grid>
                <Grid item xs={12} md={6} />

                <Grid item xs={12} md={10}>
                  <SongwriterDetailProgress
                    t={t}
                    hasLod={currentSongwriter?.hasLod as boolean}
                    created={currentSongwriter?.created as Date}
                    statusLog={
                      currentSongwriter?.statusLog as SongwriterStatusLogType
                    }
                  />
                </Grid>
                <Grid item xs={12} md={2} />

                <Grid item xs={12} md={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      {currentSongwriter?.statusLog &&
                        currentSongwriter?.statusLog.latest && (
                          <SongwriterDetailStatus
                            t={t}
                            statusLog={
                              currentSongwriter?.statusLog as SongwriterStatusLogType
                            }
                          />
                        )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {currentSongwriter?.statusLog &&
                        currentSongwriter?.statusLog.latest && (
                          <SongwriterDetailNextSteps
                            t={t}
                            statusLog={
                              currentSongwriter?.statusLog as SongwriterStatusLogType
                            }
                          />
                        )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3} />
              </>
            )}
          </>
        )}
      </Grid>
    </Container>
  );
}

export default SongwriterDetailPage;
