const songwriterConstants = {
  tabPaths: {
    client: 'songwriters/manage/client',
    outside: 'songwriters/manage/outside',
  },

  statusLogValues: {
    created: 0,
    processing: 1,
    sent: 2,
    approved: 3,
  } as { [index: string]: number },
};

export const { tabPaths, statusLogValues } = songwriterConstants;
export default songwriterConstants;
