import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormRadio from '~/base/components/FormRadio';
import FormSelect from '~/base/components/FormSelect';
import LoadingIndicator from '~/base/components/LoadingIndicator/LoadingIndicator';
import SectionTitle from '~/base/components/SectionTitle/SectionTitle';
import { Loadable } from '~/types/Loadable';
import { Translator } from '~/types/Translator';

type AddSongtrustWriterUSRepresentationProps = Translator & Loadable;
function AddSongtrustWriterUSRepresentation({
  t,
  loading = false,
}: AddSongtrustWriterUSRepresentationProps) {
  const [hasUSPRO, setHasUSPRO] = useState<boolean>();
  const [hasUsedPublisherToRegister, setHasUsedPublisherToRegister] =
    useState<boolean>();

  const { watch, setValue, getValues } = useFormContext();

  // Watch US rep data for edit songwriter
  const songwriterId = watch('id');

  useEffect(() => {
    const usRepresentationName = getValues('usRepresentation.name');

    if (usRepresentationName) {
      setHasUSPRO(true);
      setValue('us-pro-affiliation', 'yes');
      setValue('pro-representation', usRepresentationName);
    } else if (songwriterId && !usRepresentationName) {
      setHasUSPRO(false);
      setValue('us-pro-affiliation', 'no');
      setValue('us-pro-affiliation-via-publisher', 'no');
    }
  }, [songwriterId]);

  const yesNoChoices = [
    {
      choiceId: 'yes',
      choiceLabel: t('page.add-songwriter.form.songwriter.representation.yes'),
    },
    {
      choiceId: 'no',
      choiceLabel: t('page.add-songwriter.form.songwriter.representation.no'),
    },
  ];

  const usPROChoices = [
    { choiceId: 'ASCAP', choiceLabel: 'ASCAP' },
    { choiceId: 'BMI', choiceLabel: 'BMI' },
  ];

  const handleUSProAffiliationAnswer = (
    event: SyntheticEvent,
    value: string,
  ) => {
    setHasUSPRO(value === 'yes');
    setValue('pro-representation', usPROChoices[0].choiceId);
  };

  const handleUsedPublisherToRegister = (
    event: SyntheticEvent,
    value: string,
  ) => {
    setHasUsedPublisherToRegister(value === 'yes');
    setValue('pro-representation', usPROChoices[0].choiceId);
  };

  const handleProRepresentationSelected = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setValue('pro-representation', value);
  };

  return (
    <Grid
      container
      spacing={2}
      data-testid="add-songtrust-writer-us-representation"
      sx={{ pb: '2rem', pt: '2rem' }}
    >
      <Grid item xs={12}>
        <SectionTitle>
          {t('page.add-songwriter.form.songwriter.representation.title')}
        </SectionTitle>
      </Grid>
      {loading && <LoadingIndicator size={30} />}
      {!loading && (
        <>
          <Grid item xs={12}>
            <Typography variant="body1" component="p">
              {t(
                'page.add-songwriter.form.songwriter.representation.are-you-affiliated',
              )}
            </Typography>
            <FormRadio
              id="us-pro-affiliation"
              name="us-pro-affiliation"
              options={yesNoChoices}
              onOptionSelected={handleUSProAffiliationAnswer}
              columns={2}
              required
            />
          </Grid>

          {hasUSPRO === false && (
            <Grid item xs={12}>
              <Typography variant="body1" component="p">
                {t(
                  'page.add-songwriter.form.songwriter.representation.prior-publisher-registration',
                )}
              </Typography>
              <FormRadio
                id="us-pro-affiliation-via-publisher"
                name="us-pro-affiliation-via-publisher"
                onOptionSelected={handleUsedPublisherToRegister}
                options={yesNoChoices}
                columns={2}
                required
              />
            </Grid>
          )}

          {hasUSPRO === false && hasUsedPublisherToRegister === false && (
            <Grid item xs={12}>
              <Typography
                variant="body1"
                component="p"
                data-testid="ascap-pro-preselected"
              >
                {t(
                  'page.add-songwriter.form.songwriter.representation.ascap-registration',
                )}
              </Typography>
            </Grid>
          )}

          {(hasUSPRO || hasUsedPublisherToRegister) && (
            <Grid item xs={12}>
              <FormSelect
                id="pro-representation"
                name="pro-representation"
                data-testid="us-pro-representation-dropdown"
                required
                options={usPROChoices}
                onOptionSelected={handleProRepresentationSelected}
                value={getValues('pro-representation')}
                sx={{ width: { xs: '100%', lg: 'auto' } }}
                label={t(
                  'page.add-songwriter.form.songwriter.representation.us-representation',
                )}
                noBlur
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default AddSongtrustWriterUSRepresentation;
