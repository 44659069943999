import { Box, Grid, Link, Typography } from '@mui/material';
import dayjs from 'dayjs';
import songtrustLogoBlack from '~/assets/img/songtrust-logo-black.svg';
import { DashboardItemType } from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';

interface DashboardItemProps extends Translator {
  item: DashboardItemType;
}

function DashboardItem({
  t,
  item: {
    title,
    author,
    authorUrl,
    publishDate,
    content,
    date: eventDate,
    location: eventLocation,
    price: eventPrice,
  },
}: DashboardItemProps) {
  const formattedDate = new Date(publishDate);
  return (
    <div data-testid="dashboard-item">
      <Grid
        container
        sx={{
          paddingBottom: '2rem',
          borderBottom: '1px solid #979797',
          marginBottom: '2rem',
        }}
      >
        <Grid item xs={12}>
          <Grid container flexDirection="row">
            <Grid xs={12} item>
              <Box sx={{ display: 'flex', float: 'left', marginRight: '2rem' }}>
                <img
                  data-testid="songtrust-logo-black"
                  src={songtrustLogoBlack}
                  alt="Songtrust"
                />
              </Box>
              <Typography variant="h4" component="h3" sx={{ padding: 0 }}>
                {title}
              </Typography>
              {author && (
                <Typography
                  sx={{
                    fontSize: '.875rem',
                  }}
                >
                  <>
                    {t('page.content.by')}{' '}
                    {authorUrl && (
                      <Link href={authorUrl} target="_blank">
                        {author}
                      </Link>
                    )}{' '}
                    {!authorUrl && <span>{author}</span>} {t('page.content.on')}{' '}
                    <Box component="span">
                      {dayjs(formattedDate).format('MMM D, YYYY')}
                    </Box>
                  </>
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container flexDirection="row">
                  <Grid item xs={12} sx={{ paddingLeft: { md: '6rem' } }}>
                    {content && (
                      <Typography
                        variant="body1"
                        component="div"
                        sx={{ a: { color: '#D5164B' } }}
                        dangerouslySetInnerHTML={{ __html: content as string }}
                      />
                    )}

                    {eventDate && (
                      <div data-testid="event-date">
                        <strong>Date: </strong>
                        <span>{eventDate}</span>
                      </div>
                    )}

                    {eventLocation && (
                      <div data-testid="event-location">
                        <strong>Location: </strong>
                        <span>{eventLocation}</span>
                      </div>
                    )}

                    {eventPrice && (
                      <div data-testid="event-price">
                        <strong>Price: </strong>
                        <span>${eventPrice}</span>
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default DashboardItem;
