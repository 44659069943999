import { useQuery } from '@apollo/client';
import { Grid, Typography } from '@mui/material';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import { dashboardEarningsVar } from '~/cache';
import {
  DashboardEarningsDocument,
  DashboardEarningsQuery,
  EarningPeriod,
} from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';
import DashboardMyEarningsQuarter from '../DashboardMyEarningsQuarter/DashboardMyEarningsQuarter';

interface DashboardMyEarningsProps extends Translator {
  startQuarter: Date | undefined;
  endQuarter: Date | undefined;
}

function DashboardMyEarnings({
  t,
  startQuarter,
  endQuarter,
}: DashboardMyEarningsProps) {
  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  formatter.format(2500); /* $2,500.00 */

  const { loading, data } = useQuery<DashboardEarningsQuery>(
    DashboardEarningsDocument,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        startPeriod: startQuarter,
        endPeriod: endQuarter,
      },
      onCompleted: (res) => {
        dashboardEarningsVar(res);
      },
      onError: () => {
        dashboardEarningsVar(undefined);
      },
    },
  );

  return (
    <div data-testid="dashboard-my-earnings">
      <Grid
        container
        sx={{
          border: '1px solid #000',
          padding: '1rem 1rem 2rem 1rem',
        }}
        rowSpacing={2}
      >
        <Grid item xs={12}>
          <Typography variant="h5" component="h5" align="center">
            {t('page.content.my-earnings')}
          </Typography>
        </Grid>

        {loading && (
          <Grid item xs={12}>
            <LoadingIndicator size={50} />
          </Grid>
        )}
        {!loading && data?.earningsBetweenPeriods && (
          <>
            {(data?.earningsBetweenPeriods as Array<EarningPeriod>).map(
              ({ title, period, total, isEstimate }) => {
                return (
                  <Grid item xs={6} md={12} key={period}>
                    {period && total && (
                      <DashboardMyEarningsQuarter
                        label={`${title} ${t('page.content.earnings')} ${
                          isEstimate ? `(${t('page.content.estimated')})` : ``
                        }`}
                        value={formatter.format(parseFloat(total))}
                      />
                    )}
                  </Grid>
                );
              },
            )}
          </>
        )}

        {!loading && (
          <Grid item xs={6} md={12}>
            <Typography
              variant="body2"
              component="p"
              align="center"
              sx={{ fontWeight: 'bold' }}
            >
              {t('page.content.total-earnings')}
            </Typography>
            <Typography variant="h3" component="h5" align="center">
              {data?.totalEarnings?.total ? (
                <span>
                  {formatter.format(parseFloat(data?.totalEarnings?.total))}
                </span>
              ) : (
                <span>{formatter.format(0)}</span>
              )}
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default DashboardMyEarnings;
