import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { isNull } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { useFormContext } from 'react-hook-form';
import FormRadio from '~/base/components/FormRadio';
import { T } from '~/types/Translator';

export default function OverClaimingModal({
  setOpen,
  open,
  t,
}: {
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  t: T;
}) {
  const { watch, setValue, getValues } = useFormContext();
  const overclaiming = watch('overclaiming');

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Writer Ownership Agreement</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <strong>{t('page.overclaiming.request')}</strong>
        </DialogContentText>
        <DialogContentText>
          <em>{t('page.overclaiming.examples')}</em>
        </DialogContentText>
        <FormRadio
          data-testid="overclaiming-radio"
          id="overclaiming-radio"
          name="overclaiming"
          options={[
            {
              choiceId: 'yes',
              choiceLabel: t('form.labels.overclaiming.yes'),
            },
            {
              choiceId: 'no',
              choiceLabel: t('form.labels.overclaiming.no'),
            },
          ]}
          columns={1}
        />
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="save-overclaiming"
          onClick={() => {
            setValue('overclaimingAgreement', true);
            if (overclaiming === 'yes' && getValues('cowriters').length === 0) {
              setValue('cowriters', [
                {
                  songwriterId: null,
                  percent: null,
                  isOutsideSongwriter: false,
                },
              ]);
            }
            setOpen(false);
          }}
          disabled={isNull(overclaiming)}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
