/* eslint-disable react/require-default-props */
import { ErrorMessage } from '@hookform/error-message';
import {
  Autocomplete,
  createFilterOptions,
  FilterOptionsState,
  FormControl,
  FormHelperText,
  Grid,
  InputBaseComponentProps,
  InputLabel,
  StandardTextFieldProps,
  TextField,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import * as React from 'react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormTooltip from '~/base/components/FormTooltip';

interface FormAutoCompleteOption {
  choiceId: string;
  choiceLabel: string;
  disabled?: boolean;
}

interface FormAutoCompleteProps {
  id: string;
  label: string;
  name: string;
  testid: string;
  tooltip?: string;
  sx?: SxProps<Theme>;
  autocompleteSX?: SxProps<Theme>;
  clearErrorOnFocus?: boolean;
  disabled?: boolean;
  disablePortal?: boolean;
  displayRequired?: boolean;
  required?: boolean;
  children?: React.ReactNode;
  options: FormAutoCompleteOption[];
  defaultOption?: FormAutoCompleteOption;
  DefaultComponent?: JSX.Element;
  placeholder?: string;
  popupIcon?: React.ReactNode;
  forcePopupIcon?: 'auto' | boolean;
  freeSolo?: boolean;
  onControlledOptionSelected?: (
    label: string,
    value?: string,
  ) => void | undefined;
  onOptionSelected?: (value: string, label: string) => void | undefined;
  onInputChange?: (
    evt: React.SyntheticEvent,
    value: string,
  ) => void | undefined;
  inputProps?: InputBaseComponentProps | undefined;
  inputValue?: string | undefined;
  loading?: boolean;
}

function FormAutoComplete({
  id,
  name,
  label,
  testid,
  tooltip = undefined,
  sx = {},
  autocompleteSX = {},
  clearErrorOnFocus = false,
  disabled = false,
  disablePortal = true,
  displayRequired = false,
  required = false,
  children = [],
  options,
  defaultOption = undefined,
  DefaultComponent = undefined,
  placeholder = '',
  popupIcon = undefined,
  forcePopupIcon = false,
  freeSolo = false,
  onControlledOptionSelected = undefined,
  onOptionSelected = undefined,
  onInputChange = () => undefined,
  inputProps,
  inputValue = undefined,
  loading = undefined,
}: FormAutoCompleteProps) {
  const {
    register,
    formState: { errors },
    clearErrors,
    getValues,
    setValue,
    trigger,
    getFieldState,
  } = useFormContext();
  const { onChange, onBlur, ref } = register(name, { required });
  const { error } = getFieldState(name);
  const currentValue = getValues(name);
  const initValue = options.find((item) => item.choiceId === currentValue);
  const [displayValue, setDisplayValue] = useState(
    initValue ?? {
      choiceId: '',
      choiceLabel: '',
    },
  );

  // If default option provided need to patch filterOptions to include it in results
  const originalFilterOptions = createFilterOptions<FormAutoCompleteOption>();
  const patchedFilterOptions = (
    fetchedOptions: FormAutoCompleteOption[],
    state: FilterOptionsState<FormAutoCompleteOption>,
  ) => {
    const results = originalFilterOptions(fetchedOptions, state);
    if (
      defaultOption &&
      !results.includes(defaultOption) &&
      loading === false
    ) {
      results.push(defaultOption);
    }
    return results;
  };

  return (
    <FormControl sx={sx} variant="standard">
      {children}
      <InputLabel htmlFor={id} shrink={false} sx={{ width: '100%', zIndex: 1 }}>
        <Grid container justifyContent="space-between">
          <Grid item>
            {label}{' '}
            {displayRequired ? (
              <span style={{ color: '#ba3310' }}>
                &#65290;
                <span style={{ display: 'none' }}>required</span>
              </span>
            ) : (
              ''
            )}
          </Grid>
          <Grid item>{tooltip && <FormTooltip tooltip={tooltip} />}</Grid>
        </Grid>
      </InputLabel>

      <Autocomplete
        filterOptions={
          defaultOption && DefaultComponent
            ? patchedFilterOptions
            : originalFilterOptions
        }
        disablePortal={disablePortal}
        id={id}
        options={options}
        onBlur={onBlur}
        ref={ref}
        disabled={disabled}
        value={displayValue}
        onFocus={() => {
          if (clearErrorOnFocus) clearErrors([name, id]);
        }}
        sx={{
          width: 300,
          ...autocompleteSX,
        }}
        loading={loading}
        getOptionLabel={(field: string | FormAutoCompleteOption): string => {
          return (typeof field === 'string' ? field : field.choiceLabel) || '';
        }}
        popupIcon={popupIcon}
        forcePopupIcon={forcePopupIcon}
        freeSolo={freeSolo}
        renderOption={(props, option) => {
          const isDefault = defaultOption && option === defaultOption;
          return (
            <li
              {...props}
              key={option.choiceId}
              style={{
                borderTop: isDefault ? '1px solid lightgray' : undefined,
                padding: isDefault ? 0 : undefined,
              }}
            >
              {isDefault ? DefaultComponent : option.choiceLabel}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            data-testid={testid}
            {...(params as StandardTextFieldProps)}
            placeholder={placeholder}
            error={!!error}
            inputProps={{
              ...params.inputProps,
              ...(inputProps || {}),
              ...(inputValue ? { value: inputValue } : {}),
            }}
          />
        )}
        onChange={(event, value) => {
          if (onControlledOptionSelected && value) {
            onControlledOptionSelected(
              (value as FormAutoCompleteOption)?.choiceId,
              (value as FormAutoCompleteOption)?.choiceLabel,
            );
          } else if (onOptionSelected) {
            const storedValue = (value as FormAutoCompleteOption)?.choiceId;
            const storedLabel = (value as FormAutoCompleteOption)?.choiceLabel;

            setValue(name, storedValue);
            onOptionSelected(storedValue, storedLabel);
          }
          setDisplayValue({
            choiceId: (value as FormAutoCompleteOption)?.choiceId,
            choiceLabel: (value as FormAutoCompleteOption)?.choiceLabel,
          });
          trigger(name);
          return onChange(event);
        }}
        onInputChange={onInputChange}
        isOptionEqualToValue={(option, value) =>
          option.choiceId === value.choiceId
        }
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message, messages }) =>
          messages ? (
            Object.entries(messages).map(([type, errorMessage]) => (
              <FormHelperText
                data-testid={`${id}-error-message`}
                sx={{ color: '#F33126' }}
                key={type}
              >
                {errorMessage}
              </FormHelperText>
            ))
          ) : (
            <FormHelperText
              data-testid={`${id}-error-message`}
              sx={{ color: '#F33126' }}
            >
              {message}
            </FormHelperText>
          )
        }
      />
    </FormControl>
  );
}

export default FormAutoComplete;
