import { Button, Grid, Link } from '@mui/material';
import { Translator } from '~/types/Translator';

/**
 * Props for SignupDiscountLink component.
 */
interface SignupDiscountLinkProps extends Translator {
  /**
   * Prefix for translation keys.
   */
  tPrefix?: string;
  /**
   * Click handler for the discount link.
   */
  handleDiscountClick: () => void;
}

/**
 * SignupDiscountLink component displays a link that triggers a discount click handler.
 * @param {SignupDiscountLinkProps} props - Component props.
 * @returns {JSX.Element} - Rendered component.
 */
function SignupDiscountLink({
  t,
  tPrefix = '',
  handleDiscountClick,
}: SignupDiscountLinkProps): JSX.Element {
  return (
    <Grid item xs={12} sx={{ textAlign: 'right' }}>
      <Link
        component={Button}
        href="/#"
        sx={{
          textAlign: 'center',
        }}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.preventDefault();
          handleDiscountClick();
        }}
        data-testid="edit-discount-link"
      >
        {t(`${tPrefix}form.have-discount-code`)}
      </Link>
    </Grid>
  );
}

export default SignupDiscountLink;
