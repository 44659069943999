import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Button, InputAdornment, List, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useState } from 'react';
import { SongOwnershipType } from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';
import SpotifyList from './SpotifyList';

interface AddSpotifyRecordingProps extends Translator {
  songTitle: string;
  ownerships: Maybe<Maybe<SongOwnershipType>[]> | undefined;
  recordingIsrcList: Array<string>;
}

export default function AddSpotifyRecording({
  t,
  songTitle,
  ownerships,
  recordingIsrcList,
}: AddSpotifyRecordingProps) {
  const [searchText, setSearchText] = useState<string>('');
  const [artistName, setArtistName] = useState<string>('');

  // Tooltip content loop for bullets (Recording).
  const addRecordingTips = [] as Array<string>;
  const ADD_RECORDING_TIP_COUNT = 2;
  for (let i = 0; i < ADD_RECORDING_TIP_COUNT; i += 1) {
    addRecordingTips.push(
      t(`page.add-recording.tabs.spotify.copy-content.${i}`),
    );
  }

  const handleSearchArtists = () => {
    setArtistName(searchText);
  };

  return (
    <Grid container spacing={1}>
      {/* Tooltip for Add Spotify recording */}
      <Grid item xs={12} md={12} sx={{ width: '100%' }}>
        <Typography variant="subtitle1">
          {t('page.add-recording.tabs.spotify.copy-title')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <List
          sx={{
            listStyleType: 'decimal',
            pl: '1.5rem',
            pt: '0rem',
          }}
        >
          {addRecordingTips.map((content) => (
            <li key={`ol_${content}`}>
              <Typography variant="body1">{content}</Typography>
            </li>
          ))}
        </List>
      </Grid>

      {/* Search by Artist */}
      <Grid item xs={12}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{ pt: '1.5rem', pb: '2rem' }}
        >
          <Grid item xs={12} md={6}>
            <TextField
              id="song-artist-select"
              data-testid="song-artist-select"
              name="artistName"
              placeholder={t(
                'page.add-recording.tabs.spotify.fields.search-artists.placeholder',
              )}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searchText.length > 0 && (
                      <ClearIcon
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSearchText('');
                          setArtistName('');
                        }}
                      />
                    )}
                    {searchText.length === 0 && (
                      <SearchIcon sx={{ cursor: 'pointer' }} />
                    )}
                  </InputAdornment>
                ),
              }}
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                  handleSearchArtists();
                }
              }}
              sx={{ width: '100%' }}
            />
          </Grid>
          <Grid item xs={12} md="auto" sx={{ textAlign: 'right' }}>
            <Button
              variant="outlined"
              disabled={searchText.length === 0}
              onClick={handleSearchArtists}
            >
              {t(
                'page.add-recording.tabs.spotify.fields.search-artists.button',
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {/* List of songs from Spotify */}
      <Grid item xs={12} sx={{ width: '100%' }}>
        <SpotifyList
          artist={artistName}
          t={t}
          title={songTitle}
          ownerships={ownerships as Array<SongOwnershipType>}
          recordingIsrcList={recordingIsrcList}
        />
      </Grid>
    </Grid>
  );
}
