import SentAndPending from '@mui/icons-material/AccessTime';
import Processing from '@mui/icons-material/HourglassBottom';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import AwaitingRecordingIcon from '@mui/icons-material/PlayDisabled';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Grid, Link } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Link as RouterLink } from 'react-router-dom';

interface SongStatusColumnProps {
  value: Maybe<string> | undefined;
  tooltip: Maybe<string> | undefined;
  label: Maybe<string> | undefined;
  songId: Maybe<string> | undefined;
}

function SongStatusColumn({
  value,
  tooltip,
  label,
  songId,
}: SongStatusColumnProps) {
  let icon;
  let color;
  switch (value) {
    case 'awaiting_recording':
      color = 'grey';
      icon = (
        <AwaitingRecordingIcon
          sx={{ color }}
          data-testid="awaiting-status-status-icon"
        />
      );
      break;
    case 'monitoring':
      color = '#B85D00';
      icon = (
        <MonitorHeartIcon sx={{ color }} data-testid="monitoring-status-icon" />
      );
      break;
    case 'registering':
      color = 'green';
      icon = (
        <VerifiedIcon sx={{ color }} data-testid="registering-status-icon" />
      );
      break;
    case 'sent':
      color = 'blue';
      icon = <SentAndPending sx={{ color }} data-testid="sent-status-icon" />;
      break;
    default:
      color = '#B85D00';
      icon = <Processing sx={{ color }} data-testid="processing-status-icon" />;
  }
  return (
    <Tooltip title={tooltip}>
      <Grid container spacing={1} sx={{ display: 'flex' }}>
        <Grid item xs="auto">
          <Link
            to={`/songs/${songId}/edit`}
            component={RouterLink}
            data-testid="song-status-link"
          >
            {icon}
          </Link>
        </Grid>
        <Grid
          item
          xs="auto"
          sx={{ color }}
          data-testid={`status-${value}-label`}
        >
          {label}
        </Grid>
      </Grid>
    </Tooltip>
  );
}

export default SongStatusColumn;
