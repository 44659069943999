import { Container, Grid, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import workingBackgroundImage from '~/assets/img/songtrust-working-grey.svg';
import { CONTACT_URL, HELP_CENTER_URL } from '~/base/constants/baseConstants';
import ErrorPageEnum from '~/base/enums/errorPageEnums';

interface ErrorPageProps {
  type?: ErrorPageEnum;
}

/**
 * ErrorPage component to display error messages and options
 * @param {ErrorPageProps} props - Props for the component
 * @param {ErrorPageEnum} [props.type=ErrorPageEnum.ERROR_404] - Type of error page to display
 * @returns {JSX.Element} ErrorPage component
 */
function ErrorPage({
  type = ErrorPageEnum.ERROR_404,
}: ErrorPageProps): JSX.Element {
  const { t, i18n } = useTranslation(['translation']);
  const navigate = useNavigate();

  const switchLinkAction = (linkText: string, linkAction: string) => {
    switch (linkAction) {
      case 'refresh':
      case 'back':
        return (
          <Link
            href="/#"
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.preventDefault();
              navigate(-1);
            }}
          >
            {linkText}
          </Link>
        );
      case 'contact':
        return <Link href={CONTACT_URL}>{linkText}</Link>;
      case 'help_center':
        return <Link href={HELP_CENTER_URL}>{linkText}</Link>;
      default:
        return linkText;
    }
  };

  return (
    <div className="error-page" data-testid="error-page">
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h1"
              component="h1"
              sx={{
                textAlign: 'center',
                fontSize: '3.75rem',
                top: { xs: '6.5rem', md: '11rem' },
                mb: '-3.75rem',
                position: 'relative',
              }}
            >
              {type !== ErrorPageEnum.ERROR_FROZEN
                ? t(`errors.${type}.title`)
                : t(`errors.${type}.frozen_title`)}
            </Typography>
            {type !== ErrorPageEnum.SERVER_MAINTENANCE && (
              <Typography
                variant="h2"
                component="h2"
                sx={{
                  color: '#F2F2F2',
                  fontSize: { xs: '12rem', md: '18.75rem' },
                  textAlign: 'center',
                }}
              >
                {type !== ErrorPageEnum.ERROR_FROZEN
                  ? t(`errors.${type}.number`)
                  : `\u2744`}
              </Typography>
            )}
            {type === ErrorPageEnum.SERVER_MAINTENANCE && (
              <Grid container justifyContent="center">
                <Grid
                  item
                  sx={{
                    pt: { xs: 'none', md: '3rem' },
                    height: { xs: '12rem', md: '18.75rem' },
                  }}
                >
                  <img
                    data-testid="songtrust-working-image"
                    src={workingBackgroundImage}
                    alt="Songtrust"
                    height="100%"
                    width="100%"
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="h2" component="h2">
              {t(`errors.${type}.sorry_title`)}
            </Typography>
            <Typography
              variant="body1"
              component="div"
              sx={{
                paddingLeft: { md: '2rem' },
              }}
            >
              {i18n.exists(`errors.${type}.options.content`) && (
                <p>{t(`errors.${type}.options.content`)}</p>
              )}

              {i18n.exists(`errors.${type}.options.bullet1`) && (
                <p>
                  {t(`errors.${type}.options.bullet1.start`)}
                  {switchLinkAction(
                    t(`errors.${type}.options.bullet1.link`),
                    t(`errors.${type}.options.bullet1.linkAction`),
                  )}
                  {t(`errors.${type}.options.bullet1.end`)}
                </p>
              )}

              {i18n.exists(`errors.${type}.options.bullet2`) && (
                <p>
                  {t(`errors.${type}.options.bullet2.start`)}
                  {switchLinkAction(
                    t(`errors.${type}.options.bullet2.link`),
                    t(`errors.${type}.options.bullet2.linkAction`),
                  )}
                  {t(`errors.${type}.options.bullet2.end`)}
                </p>
              )}

              {i18n.exists(`errors.${type}.options.bullet3`) && (
                <p>
                  {t(`errors.${type}.options.bullet3.start`)}
                  {switchLinkAction(
                    t(`errors.${type}.options.bullet3.link`),
                    t(`errors.${type}.options.bullet3.linkAction`),
                  )}
                  {t(`errors.${type}.options.bullet3.end`)}
                </p>
              )}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default ErrorPage;
