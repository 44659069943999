/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled';
import CircleIcon from '@mui/icons-material/Circle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import SyncIcon from '@mui/icons-material/Sync';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import {
  Grid,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { stepConnectorClasses } from '@mui/material/StepConnector';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { statusLogValues } from '~/songwriters/constants/constants';
import { SongwriterStep } from '~/songwriters/types/types';
import { Translator } from '~/types/Translator';
import { SongwriterStatusLogType } from '~/types/generated/graphql';

const CustomConnector = styled(StepConnector)(() => ({
  left: 'calc(-100% + 20px)',
  right: 'calc(90% + 0px)',
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient( 136deg, #606BF8 0%, #031C9B 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#031C9B',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 15,
    border: '1px solid #ccc',
    backgroundColor: '#fff',
    borderRadius: 1,
  },
}));

const CustomStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  backgroundColor: '#fff',
  color: '#fff',
  border: '1px solid #ccc',
  zIndex: 1,
  width: 50,
  height: 50,

  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    border: '3px solid #031C9B',
    color: '#031C9B',
    backgroundColor: '#fff',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    border: '1px solid #031C9B',
    backgroundColor: '#031C9B',
    color: '#031C9B',
  }),
}));

function CustomStepIcon({ icon, active, completed, className }: StepIconProps) {
  const icons: { [index: string]: React.ReactElement } = {
    1: <CircleIcon />,
    2: <CircleIcon />,
    3: <CircleIcon />,
  };

  return (
    <CustomStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(icon)]}
    </CustomStepIconRoot>
  );
}

interface SongwriterDetailProgressProps extends Translator {
  hasLod: boolean;
  created: Date;
  statusLog: SongwriterStatusLogType;
}

function SongwriterDetailProgress({
  t,
  hasLod,
  created,
  statusLog,
}: SongwriterDetailProgressProps) {
  const [steps, setSteps] = useState<Array<SongwriterStep>>([]);
  const [activeStep, setActiveStep] = useState<number>(0);

  useEffect(() => {
    const generatedSteps = [
      {
        title: t('page.songwriter-details.steps.created.title'),
        date: created,
        month: dayjs(created).format('MMM'),
        number: dayjs(created).format('DD'),
      },
      {
        title: t('page.songwriter-details.steps.processing.title'),
        date: statusLog?.sentAt,
        month:
          !hasLod && statusLog?.sentAt !== undefined
            ? dayjs(statusLog?.sentAt).format('MMM')
            : dayjs(created).format('MMM'),
        number:
          !hasLod && statusLog?.sentAt !== undefined
            ? dayjs(statusLog?.sentAt).format('DD')
            : dayjs(created).format('DD'),
      },
      {
        title: t('page.songwriter-details.steps.approved.title'),
        date: statusLog?.approvedAt,
        month: dayjs(statusLog?.approvedAt).format('MMM'),
        number: dayjs(statusLog?.approvedAt).format('DD'),
      },
    ] as Array<SongwriterStep>;

    setSteps(generatedSteps);
    setActiveStep(statusLogValues[`${statusLog?.latest}`] as number);
  }, [statusLog, hasLod, created]);

  return (
    <div data-testid="songwriter-detail-progress">
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<CustomConnector />}
      >
        {steps.map((step, index) => (
          <Step key={step.title}>
            <StepLabel
              StepIconComponent={CustomStepIcon}
              sx={{
                alignItems: 'flex-start',
                '.MuiStepLabel-alternativeLabel': { textAlign: 'left' },
              }}
            >
              <Grid container flexDirection="column">
                <Grid item>
                  {index < activeStep ? (
                    <TaskAltIcon sx={{ color: '#7FC832' }} />
                  ) : index > activeStep ? (
                    <RadioButtonUncheckedIcon />
                  ) : (
                    <SyncIcon sx={{ color: '#B85D00' }} />
                  )}
                </Grid>
                <Grid item>
                  <Typography variant="body1" component="span">
                    {step.month !== 'Invalid Date' ? step.month : ''}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5" component="span">
                    {step.number !== 'Invalid Date' ? step.number : ''}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" component="span">
                    {step.title}
                  </Typography>
                </Grid>
              </Grid>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

export default SongwriterDetailProgress;
