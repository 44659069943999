import { SxProps } from '@mui/material';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import { ReactNode, useEffect, useState } from 'react';
import { Translator } from '~/types/Translator';

/**
 * Props for ErrorAlert component.
 */
interface ErrorAlertProps extends Translator {
  /**
   * Path for translation.
   */
  translatePath?: string;
  /**
   * Error message to display.
   */
  errorMessage?: ReactNode;
  /**
   * Boolean flag indicating if there's an error.
   */
  error: boolean;
  /**
   * Time in milliseconds after which the alert should disappear. Default is 0 (no timeout).
   */
  time?: number;
  /**
   * Custom styles for the Grid container.
   */
  sx?: SxProps;
}

/**
 * ErrorAlert component displays an error message in an Alert component.
 * @param {ErrorAlertProps} props - Component props.
 * @returns {JSX.Element} - Rendered component.
 */
export default function ErrorAlert({
  t,
  translatePath = '',
  errorMessage = '',
  error,
  time = 0,
  sx = {},
}: ErrorAlertProps): JSX.Element {
  const [show, setShow] = useState(error);

  useEffect(() => {
    let timeoutID: NodeJS.Timeout | undefined;
    if (time > 0) {
      timeoutID = setTimeout(() => {
        setShow(false);
      }, time);
    }
    return () => {
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
    };
  }, [time]);

  return (
    <Grid container item sx={sx}>
      {show && (
        <Grow in={show} style={{ transformOrigin: '0 0 0' }} timeout={500}>
          <Grid container item>
            <Alert
              severity="error"
              data-testid="time-error-alert"
              sx={{ width: '100%' }}
            >
              <AlertTitle>Error</AlertTitle>
              {translatePath ? t(translatePath) : errorMessage}
            </Alert>
          </Grid>
        </Grow>
      )}
    </Grid>
  );
}
