import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    if (
      ['prod', 'qual', 'stag'].includes(import.meta.env.VITE_ENV) ||
      import.meta.env.VITE_ENABLE_DEV_TRACKING_SCRIPTS === 'true'
    ) {
      // Google tag manager
      TagManager.initialize({
        gtmId: import.meta.env.VITE_GTM_ID,
      });
    }
  }, [location]);
};

export default usePageTracking;
