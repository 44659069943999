/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from '@mui/material';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import validator from 'validator';
import FormInput from '~/base/components/FormInput';
import { Translator } from '~/types/Translator';

interface AccessManagementInvitePageInputProps extends Translator {
  formMethods: UseFormReturn<FieldValues, any>;
}
function AccessManagementInvitePageInput({
  t,
  formMethods,
}: AccessManagementInvitePageInputProps) {
  return (
    <div data-testid="access-management-invite-page-input">
      <Box
        sx={{
          pt: '2rem',
        }}
      >
        <FormProvider {...formMethods}>
          <FormInput
            id="invite-email"
            name="invite-email"
            label={t('sections.access-management-invite.form.email.label')}
            placeholder={t(
              'sections.access-management-invite.form.email.placeholder',
            )}
            required={{
              value: true,
              message: t(
                'sections.access-management-invite.form.email.validation.required',
              ),
            }}
            validate={{
              isEmailValid: (v) =>
                validator.isEmail(v as string) ||
                t(
                  'sections.access-management-invite.form.email.validation.invalid',
                ),
            }}
          />
        </FormProvider>
      </Box>
    </div>
  );
}

export default AccessManagementInvitePageInput;
