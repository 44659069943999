import { Box, Grid, Typography } from '@mui/material';
import { Translator } from '~/types/Translator';
import { SongwriterStatusLogType } from '~/types/generated/graphql';

interface SongwriterDetailStatusProps extends Translator {
  statusLog: SongwriterStatusLogType;
}
function SongwriterDetailNextSteps({
  t,
  statusLog,
}: SongwriterDetailStatusProps) {
  return (
    <Box data-testid="songwriter-detail-next-steps" sx={{ p: '2rem 1rem' }}>
      <Grid container>
        <Grid item>
          <Typography variant="h3">
            {t(`page.songwriter-details.steps.${statusLog.latest}.next-title`)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            {t(`page.songwriter-details.steps.${statusLog.latest}.next-desc`)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SongwriterDetailNextSteps;
