import { T } from '~/types/Translator';
import {
  InterestedpartySongWriterSongwriterTypeChoices,
  Scalars,
  SongtrustUserType,
  SongType,
  SongwriterType,
} from '~/types/generated/graphql';

function capitalizeFirstLetter(s = '') {
  return s.charAt(0).toLocaleUpperCase() + s.slice(1);
}

export type name = string | null | undefined;

/**
 * This determines whether two songwriters have the same name
 * Using either all three of first, middle, and last names or
 * Using just the first and last name.
 */
export function findSameName(m: [name, name, name], n: [name?, name?, name?]) {
  const response = [false, false, false];
  for (let i = 0; i < 3; i += 1) {
    const r = new RegExp(n.at(i) as string, 'i');
    response[i] = r.test(m.at(i) as string);
  }

  return (
    (response[0] && response[1] && response[2]) || (response[0] && response[2])
  );
}

export function nameArray(parentNode: SongwriterType): [name, name, name] {
  return [
    parentNode?.firstName,
    parentNode?.middleName,
    parentNode?.lastName,
  ].map((n) => (n ? capitalizeFirstLetter(n as string) : null)) as [
    name,
    name,
    name,
  ];
}

/**
 * This determines the detail string to append to a songwriter name
 * Based on the current user and the songwriter's publisher or payee
 */
export function alternateSongwriter(
  a: SongwriterType,
  includePublishing: boolean,
  user: SongtrustUserType,
  t: T,
) {
  if (
    a?.songwriterType === InterestedpartySongWriterSongwriterTypeChoices.Client
  ) {
    if (user?.user?.id === a?.managedBy?.user?.id) {
      return includePublishing ? (a?.publishingCompany?.name ?? '') : '';
    }

    return /you/i.test(a?.payee || '') ? '' : a?.payee;
  }
  return t('form.outside');
}

export function renderLabel(s: string | null | undefined, a?: boolean): string {
  const v = a ? `(${s})` : s;
  return s ? ` ${v}` : '';
}

export function generateSongwriterName(
  songwriter: SongwriterType,
  currentUser: SongtrustUserType,
  t: T,
) {
  return [
    songwriter?.firstName,
    songwriter?.middleName,
    songwriter?.lastName,
    renderLabel(alternateSongwriter(songwriter, true, currentUser, t), true),
  ].reduce((w: string, n) => w.concat(renderLabel(n)), '');
}

export type SongwriterSubmit = {
  percent: Scalars['Decimal']['output'];
  songwriterId: Scalars['Int']['input'];
  inputValue: string;
  label: string;
  isOutsideSongwriter: Scalars['Boolean']['input'];
};

/**
 * Get the songwriters of a song
 */
export function getSongwriters(
  song: SongType | null | undefined,
  currentUser: SongtrustUserType,
  t: T,
) {
  return song?.ownerships
    ? song?.ownerships
        .map((ownership) => {
          const name = generateSongwriterName(
            ownership?.songwriter as SongwriterType,
            currentUser,
            t,
          );
          return {
            percent: ownership?.percent,
            songwriterId: Number(ownership?.songwriter?.id),
            inputValue: name,
            label: name,
            isOutsideSongwriter:
              ownership?.songwriter?.songwriterType ===
              InterestedpartySongWriterSongwriterTypeChoices.Outside,
          };
        })
        .sort((a, b) => {
          // Both are client songwriters
          if (!a.isOutsideSongwriter && !b.isOutsideSongwriter) {
            return parseFloat(b.percent) - parseFloat(a.percent); // Sort by descending percentage
          }
          // Both are outside songwriters
          if (a.isOutsideSongwriter && b.isOutsideSongwriter) {
            return parseFloat(b.percent) - parseFloat(a.percent); // Sort by descending percentage
          }
          // One is a client and the other is an outside songwriter
          return a.isOutsideSongwriter ? 1 : -1; // Client songwriters come first
        })
    : [];
}
