import { gql } from '@apollo/client';

const YOUTUBE_CHANNELS = gql`
  query YoutubeChannels($offset: Int, $first: Int) {
    youtubeChannels(offset: $offset, first: $first) {
      edges {
        node {
          id
          title
          channelId
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export default YOUTUBE_CHANNELS;
