import { styled, Tabs } from '@mui/material';

const SongtrustTabs = styled(Tabs)({
  '& .MuiTabs-indicator': { backgroundColor: '#F33126', height: '.438rem' },
  '& .MuiTab-root': {
    paddingBottom: '0.5rem',
    textTransform: 'none',
  },
});

export default SongtrustTabs;
