import Grid from '@mui/material/Grid';
import FormInput from '~/base/components/FormInput';
import SectionTitle from '~/base/components/SectionTitle/SectionTitle';
import { Translator } from '~/types/Translator';

interface ISWCProps extends Translator {
  isEditable?: boolean;
}

export default function ISWC({ t, isEditable = true }: ISWCProps) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SectionTitle>{t('form.section.iswc.title')}</SectionTitle>
      </Grid>
      <Grid item xs={12}>
        <FormInput
          id="iswc-number"
          label={t(`form.section.iswc.title`)}
          name="iswc"
          placeholder={isEditable ? t(`form.section.iswc.placeholder`) : ''}
          sx={{
            width: '100%',
          }}
          inputProps={{ 'data-testid': 'iswc-input' }}
          pattern={{
            value: /^T-\d{9}-[A-Z0-9]$/i,
            message: t(`form.section.iswc.invalid-iswc`),
          }}
          disabled={!isEditable}
        />
      </Grid>
    </Grid>
  );
}
