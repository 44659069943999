import { Container, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import ModalVideo from 'react-modal-video';
import accountConstants from '~/account/constants/accountConstants';
import FormTooltip from '~/base/components/FormTooltip';
import PageTitle from '~/base/components/PageTitle/PageTitle';
import usePageTitle from '~/hooks/usePageTitle';
import { Translator } from '~/types/Translator';
import PlaidVerification from '../PlaidVerification/PlaidVerification';

function VerifyIdentityPage({ t }: Translator) {
  usePageTitle(t('sections.verify-identity.page.title'));

  const [isVideoOpen, setIsVideoOpen] = useState<boolean>(false);

  const [verificationStatus, setVerificationStatus] = useState<
    string | null | undefined
  >(undefined);

  return (
    <div data-testid="verify-identity-page">
      <Container>
        <Grid
          container
          spacing={2}
          sx={{
            flexDirection: {
              xs: 'column',
            },
          }}
        >
          <ModalVideo
            channel="youtube"
            youtube={{
              autoplay: 1,
              mute: 0,
            }}
            isOpen={isVideoOpen}
            videoId={accountConstants.kycVideoId}
            onClose={() => setIsVideoOpen(false)}
          />
          <Grid item xs={12} md={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <PageTitle
                  pageTitle={{
                    id: 'verify-identity-page-title',
                    title: t('sections.verify-identity.page.title'),
                  }}
                />
              </Grid>

              <Grid item sx={{ mt: { md: '3rem' } }}>
                <Typography
                  variant="body1"
                  component="span"
                  sx={{ pr: '.5rem' }}
                  data-testid="whats-this"
                >
                  {t('sections.verify-identity.form.whats-this')}
                </Typography>
                <FormTooltip
                  tooltip={
                    <>
                      {t('sections.verify-identity.form.whats-this-into')}
                      {t(
                        'sections.verify-identity.form.verification-acknowledgement-watch-this',
                      )}
                      <Link
                        href="https://www.youtube.com/embed/2Sk5HKF84PM?controls=0&autoplay=1"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsVideoOpen(true);
                        }}
                        target="_blank"
                        data-testid="verify-identity-video-link-tooltip"
                      >
                        {t(
                          'sections.verify-identity.form.verification-acknowledgement-quick-tutorial',
                        )}
                      </Link>
                      {t(
                        'sections.verify-identity.form.verification-acknowledgement-best-practices',
                      )}
                    </>
                  }
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <PlaidVerification
                  t={t}
                  verificationStatus={verificationStatus}
                  setVerificationStatus={setVerificationStatus}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default VerifyIdentityPage;
