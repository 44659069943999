import { useReactiveVar } from '@apollo/client';
import { Grid, Link } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { currentSongtrustUserPersonaVar } from '~/cache';

function HeaderAdmin() {
  const loggedInUserPrersona = useReactiveVar(currentSongtrustUserPersonaVar);
  const navigate = useNavigate();

  return (
    <Box
      data-testid="header-admin-container"
      sx={{
        backgroundColor: '#EDEDED',
        height: '3.5rem',
        padding: '.5rem',
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          fontSize: '1rem',
        }}
      >
        <Grid item>
          {`You're logged in as ${loggedInUserPrersona?.loggedInSongtrustUser?.user?.firstName} ${loggedInUserPrersona?.loggedInSongtrustUser?.user?.lastName} (${loggedInUserPrersona?.loggedInSongtrustUser?.user?.email}) - `}
          <Link
            component="a"
            href="/restore-admin"
            data-testid="restore-admin-link"
            onClick={(e: React.MouseEvent<HTMLElement>): void => {
              e.preventDefault();
              navigate('/restore-admin');
            }}
          >
            Return to admin mode
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}

export default HeaderAdmin;
