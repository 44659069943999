import { Grid, Link, Typography } from '@mui/material';
import { Translator } from '~/types/Translator';

function LegacyClientInformation({ t }: Translator) {
  return (
    <Grid>
      <Typography component="p">
        {t('page.increase-songwriters.legacy.content')}
        <br />
        <br />
        {t('page.increase-songwriters.legacy.content-continued')}
        <Link
          href={`mailto:${t('page.increase-songwriters.legacy.content-email')}`}
        >
          {t('page.increase-songwriters.legacy.content-email')}
        </Link>
        {t('page.increase-songwriters.legacy.content-continued2')}
        <br />
        <br />
        {t('page.increase-songwriters.legacy.thank-you')}
      </Typography>
    </Grid>
  );
}

export default LegacyClientInformation;
