import { Grid } from '@mui/material';
import HeaderButtons from '~/base/components/Header/HeaderButtons';
import HeaderLogo from '~/base/components/Header/HeaderLogo';
import HeaderMenuLinks from '~/base/components/Header/HeaderMenuLinks';
import HeaderSlideDownMenu from '~/base/components/Header/HeaderSlideDownMenu';
import { LOGGED_OUT_HEADER_LINKS } from '~/base/constants/headerConstants';

function HeaderExternal() {
  return (
    <Grid
      container
      data-testid="header-external"
      direction="column"
      flexDirection="row"
      alignItems="center"
      sx={{ height: '6em' }}
      justifyContent="space-between"
    >
      <Grid item data-testid="header-external-left">
        <HeaderLogo size="lg" />
      </Grid>

      <Grid item data-testid="header-external-right">
        <Grid
          container
          data-testid="header-external-right-desktop"
          spacing={2}
          direction="column"
          flexDirection="row"
          alignItems="center"
          sx={{ display: { xs: 'none', lg: 'flex' } }}
        >
          <HeaderMenuLinks menuLinks={LOGGED_OUT_HEADER_LINKS} />
          <HeaderButtons />
        </Grid>

        <Grid
          item
          data-testid="header-external-right-mobile"
          sx={{ display: { xs: 'flex', lg: 'none' } }}
        >
          <HeaderSlideDownMenu menuLinks={LOGGED_OUT_HEADER_LINKS} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HeaderExternal;
