const tableConstants = {
  TABLE_HEAD_ROW_PROPS: {
    sx: {
      boxShadow: 'none',
    },
  },
  TABLE_HEAD_PROPS: {
    sx: {
      '& tr th:nth-of-type(3) .Mui-TableHeadCell-Content': {
        justifyContent: 'flex-end',
      },
    },
  },
  TABLE_HEAD_CELL_PROPS: {
    sx: {
      pt: 2,
      pb: 2,
      pr: 1,
      pl: 1,
      margin: 0,
      fontSize: '.75rem',
      color: '#7E7E7E',
    },
  },
  TABLE_BODY_CELL_PROPS: {
    sx: {
      pt: 2,
      pb: 2,
      pr: 1,
      pl: 1,
      margin: 0,
      '.right-aligned': {
        textAlign: 'right',
      },
    },
  },
  TABLE_PROPS: {
    sx: {
      minWidth: 300,
      '& tr td:nth-of-type(2)': {
        fontWeight: 'bold',
      },
    },
  },
  TABLE_PAPER_PROPS: {
    elevation: 0,
    sx: {
      borderRadius: '0',
      border: 'none',
      '& .MuiTablePagination-root': {
        '& .MuiInputLabel-root': {
          top: 0,
        },
      },
    },
  },
  DATEPICKER_STYLES: {
    '.MuiFormLabel-root': {
      width: '100%',
      padding: 0,
      margin: 0,
      left: '-.75rem',
      top: '-.75rem',
      fontSize: '1rem',
    },
    '.MuiInputBase-root': {
      borderRadius: '.5rem',
      borderColor: '#000',
    },
    input: {
      padding: '.825rem',
    },
    fieldset: {
      marginTop: '.25rem',
      legend: { display: 'none' },
      border: '1px solid #7E7E7E',
    },
    padding: 0,
    marginTop: '1rem',
    borderColor: '#000',
  },
};

export const {
  TABLE_HEAD_ROW_PROPS,
  TABLE_HEAD_PROPS,
  TABLE_HEAD_CELL_PROPS,
  TABLE_BODY_CELL_PROPS,
  TABLE_PROPS,
  TABLE_PAPER_PROPS,
  DATEPICKER_STYLES,
} = tableConstants;

export default tableConstants;
