import { Container } from '@mui/material';

import { useReactiveVar } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import {
  currentSessionTokenVar,
  currentSongtrustUserPersonaVar,
} from '~/cache';

import HeaderExternal from '~/base/components/Header/HeaderExternal';
import HeaderInternal from '~/base/components/Header/HeaderInternal';
import HeaderLandingPage from '~/base/components/Header/HeaderLandingPage';
import { LANDING_PAGE_HEADER_PATHS } from '~/base/constants/headerConstants';

import _ from 'lodash';
import FlagNameEnum from '~/base/enums/flagNameEnum';
import { FlagEdge } from '~/types/generated/graphql';
import HeaderAdmin from '../HeaderAdmin';
import HeaderBanners from '../HeaderBanners/HeaderBanners';

function Header() {
  const { pathname } = useLocation();
  const sessionToken = useReactiveVar(currentSessionTokenVar);
  const loggedInUser = useReactiveVar(currentSongtrustUserPersonaVar);

  const useSwapBar = loggedInUser?.loggedInSongtrustUser?.isGhostedAdmin;

  const doesAccountTypeRequireTaxAndPaymentInformation =
    loggedInUser?.loggedInSongtrustUser
      ?.doesAccountTypeRequireTaxAndPaymentInformation;

  const hasPaymentAndTaxInformation =
    loggedInUser?.loggedInSongtrustUser?.isPaymentAndTaxInformationComplete;

  const shouldDisplayTaxBanner = !(
    !loggedInUser?.loggedInSongtrustUser ||
    LANDING_PAGE_HEADER_PATHS.includes(pathname) ||
    // Account type does not require tax and payment information
    !doesAccountTypeRequireTaxAndPaymentInformation ||
    // Account type requires tax and payment information but user has not provided it
    hasPaymentAndTaxInformation
  );

  const filteredFlags = loggedInUser?.featureFlags?.edges.filter(
    (x) => x?.node?.enabled,
  ) as Array<FlagEdge>;

  const hasRateFlag = _.some(filteredFlags, (flag) => {
    return flag?.node?.name === FlagNameEnum.SHOW_RATE_CHANGE_BANNER;
  });

  const userIsFrozen =
    loggedInUser?.loggedInSongtrustUser?.frozenOn !== null &&
    loggedInUser?.loggedInSongtrustUser?.frozenOn !== undefined;

  const userIsCancelled =
    loggedInUser?.loggedInSongtrustUser?.cancelledOn !== null &&
    loggedInUser?.loggedInSongtrustUser?.cancelledOn !== undefined;

  const userIsCancelledForFraud =
    userIsCancelled &&
    loggedInUser?.loggedInSongtrustUser?.cancellationReason === 'fraud';

  const shouldDisplayCancelledUserBanner =
    loggedInUser !== undefined &&
    !hasRateFlag &&
    userIsCancelled &&
    !userIsCancelledForFraud &&
    !userIsFrozen;

  const shouldDisplayFlaggedUserRateChangeBanner =
    loggedInUser !== undefined && hasRateFlag;

  return (
    <>
      {useSwapBar && sessionToken && <HeaderAdmin />}
      <header data-testid="header" style={{ backgroundColor: '#000' }}>
        <Container data-testid="header-container">
          {LANDING_PAGE_HEADER_PATHS.includes(pathname) && (
            <HeaderLandingPage />
          )}
          {!LANDING_PAGE_HEADER_PATHS.includes(pathname) && !sessionToken && (
            <HeaderExternal />
          )}
          {!LANDING_PAGE_HEADER_PATHS.includes(pathname) && sessionToken && (
            <HeaderInternal />
          )}
        </Container>
      </header>

      <HeaderBanners
        shouldDisplayTaxBanner={shouldDisplayTaxBanner}
        shouldDisplayCancelledUserRateChangeBanner={
          shouldDisplayCancelledUserBanner
        }
        shouldDisplayFlaggedUserRateChangeBanner={
          shouldDisplayFlaggedUserRateChangeBanner
        }
      />
    </>
  );
}

export default Header;
