import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';

import { ApolloProvider } from '@apollo/client';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import LoadingIndicator from '~/base/components/LoadingIndicator';

import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/react';
import App from '~/app';
import defaultTheme from '~/themes/DefaultTheme';
import { defaultClient } from './cache';
import './i18n';

const rootElement = document.getElementById('root');

if (
  ['prod', 'qual', 'stag'].includes(import.meta.env.VITE_ENV) ||
  import.meta.env.VITE_ENABLE_DEV_TRACKING_SCRIPTS === 'true'
) {
  Sentry.init({
    environment: import.meta.env.VITE_ENV,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [browserTracingIntegration()],
    tracesSampleRate: 0.01,
  });
}

ReactDOM.createRoot(rootElement as Element).render(
  <React.StrictMode>
    <ApolloProvider client={defaultClient}>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <Suspense fallback={<LoadingIndicator size={30} />}>
          <App />
        </Suspense>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
);
