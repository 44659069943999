import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  GenericScalar: { input: any; output: any; }
  JSONString: { input: any; output: any; }
};

export type AboutInput = {
  accountType: Scalars['String']['input'];
  currentDistributors?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  digitalDistribution?: InputMaybe<Scalars['String']['input']>;
  favoriteDistributor?: InputMaybe<Scalars['Int']['input']>;
  globalPublisher?: InputMaybe<Scalars['Boolean']['input']>;
  location: LocationInput;
};

/** An enumeration. */
export enum AccessType {
  FullAccess = 'full_access',
  ReadOnly = 'read_only'
}

export type AccountCompletion = {
  steps?: Maybe<Array<Maybe<AccountCompletionStep>>>;
};

export type AccountCompletionStep = {
  isCompleted?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type ActiveWorkRegionType = Node & {
  choice?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  songtrustUsers: SongtrustUserTypeConnection;
};


export type ActiveWorkRegionTypeSongtrustUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type ActiveWorkRegionTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ActiveWorkRegionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ActiveWorkRegionType` and its cursor. */
export type ActiveWorkRegionTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ActiveWorkRegionType>;
};

export type AddSongwriterCredits = {
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export type AddYoutubeChannel = {
  channel?: Maybe<YoutubeChannelType>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export type AdministrationInput = {
  catalogRepresentation?: InputMaybe<Scalars['String']['input']>;
  coPublishing?: InputMaybe<Scalars['String']['input']>;
  expectedManagedWriters: Scalars['String']['input'];
  personalPubEntityWithPro?: InputMaybe<Scalars['String']['input']>;
};

export type AlternateTitleType = {
  title: Scalars['String']['output'];
};

export type ArtistInput = {
  name: Scalars['String']['input'];
  spotifyArtistId: Scalars['String']['input'];
};

export type ArtistType = {
  createType: SongsArtistCreateTypeChoices;
  created?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<UserType>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  recordingSet: RecordingTypeConnection;
  spotifyArtistId?: Maybe<Scalars['String']['output']>;
  users: SongtrustUserTypeConnection;
};


export type ArtistTypeRecordingSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type ArtistTypeUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type AvailableSongwritersResults = {
  songtrustUser?: Maybe<SongtrustUserType>;
  songwriters?: Maybe<SongwriterTypeConnection>;
};


export type AvailableSongwritersResultsSongwritersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  songwriterType?: InputMaybe<InterestedpartySongWriterSongwriterTypeChoices>;
};

export type BillingInformationType = {
  expMonth?: Maybe<Scalars['Int']['output']>;
  expYear?: Maybe<Scalars['Int']['output']>;
  last4?: Maybe<Scalars['String']['output']>;
};

export type CatalogInput = {
  genres: Array<InputMaybe<Scalars['String']['input']>>;
  initialCountEstimate: Scalars['Int']['input'];
  regions: Array<InputMaybe<Scalars['Int']['input']>>;
  streamEstimate: Scalars['Int']['input'];
};

export type ChangePasswordMutation = {
  errors?: Maybe<Array<Maybe<FieldError>>>;
  loggedInSongtrustUser?: Maybe<SongtrustUserType>;
};

export type Choice = {
  choice?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

export type ChoiceConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ChoiceEdge>>;
  extra?: Maybe<Scalars['String']['output']>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `Choice` and its cursor. */
export type ChoiceEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<Choice>;
  other?: Maybe<Scalars['String']['output']>;
};

export type ClientSongwriterInput = {
  alternateNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  birthdate: Scalars['Date']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  honorific?: InputMaybe<Scalars['String']['input']>;
  ipi: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  lodTosAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  pro: ProInput;
  publishingCompany?: InputMaybe<PublishingCompanyInput>;
  signature: Scalars['String']['input'];
  songwriterType?: InputMaybe<Scalars['String']['input']>;
  songwriterYoutubeClaims: Scalars['Boolean']['input'];
  usRepresentation?: InputMaybe<UsRepresentationInput>;
};

/** An enumeration. */
export enum ContentDashboardItemCategoryChoices {
  /** Deal */
  Deal = 'DEAL',
  /** Embed */
  Embed = 'EMBED',
  /** Event */
  Event = 'EVENT',
  /** News */
  News = 'NEWS',
  /** Opportunity */
  Opportunity = 'OPPORTUNITY',
  /** Society Event */
  SocietyEvent = 'SOCIETY_EVENT',
  /** Tip */
  Tip = 'TIP',
  /** Tweet */
  Tweet = 'TWEET'
}

export type CountryType = Node & {
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isoCode: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CountryTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CountryTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CountryType` and its cursor. */
export type CountryTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CountryType>;
};

export type CreateAlternateTitle = {
  alternateTitle?: Maybe<AlternateTitleType>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export type CreateAlternateTitleInput = {
  songId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type CreateClientSongwriter = {
  errors?: Maybe<Array<Maybe<FieldError>>>;
  songwriter?: Maybe<SongwriterType>;
};

export type CreateOutsideSongwriter = {
  errors?: Maybe<Array<Maybe<FieldError>>>;
  songwriter?: Maybe<SongwriterType>;
};

export type CreateRecording = {
  errors?: Maybe<Array<Maybe<FieldError>>>;
  recording?: Maybe<RecordingType>;
};

export type CreateSong = {
  errors?: Maybe<Array<Maybe<FieldError>>>;
  song?: Maybe<SongType>;
};

export type CreateSongInput = {
  alternateTitles?: InputMaybe<Array<InputMaybe<SongAlternateTitleInput>>>;
  chorusLyrics?: InputMaybe<Scalars['String']['input']>;
  fullLyrics?: InputMaybe<Scalars['String']['input']>;
  iswc?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  ownerships?: InputMaybe<Array<InputMaybe<SongOwnershipInput>>>;
  publicDomain: Scalars['Boolean']['input'];
  title: Scalars['String']['input'];
};

export type DashboardItemType = Node & {
  /** Enable/Disable */
  active: Scalars['Boolean']['output'];
  /** Author for Blog Post */
  author: Scalars['String']['output'];
  /** Author URL on the Blog/Website etc... */
  authorUrl: Scalars['String']['output'];
  avatar?: Maybe<Scalars['String']['output']>;
  category?: Maybe<ContentDashboardItemCategoryChoices>;
  /** Dashboard Item Content */
  content?: Maybe<Scalars['String']['output']>;
  /** Content URL on the Blog/Website etc... */
  contentUrl: Scalars['String']['output'];
  /** Free form date-field for Event, Opportunity etc... */
  date: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** Date of Dashboard Item post */
  itemDate?: Maybe<Scalars['Date']['output']>;
  /** Free form location field for Event, Opportunity etc... */
  location: Scalars['String']['output'];
  /** Price of Event, Deal etc... */
  price?: Maybe<Scalars['Float']['output']>;
  /** PRO for Society Event */
  pro?: Maybe<PerformingRightsOrganizationType>;
  /** Date to publish item */
  publishDate?: Maybe<Scalars['DateTime']['output']>;
  /** Up priority of the Dashboard Item in the dashboard */
  sticky: Scalars['Boolean']['output'];
  /** Dashboard Item Title */
  title: Scalars['String']['output'];
};

export type DashboardItemTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DashboardItemTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DashboardItemType` and its cursor. */
export type DashboardItemTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DashboardItemType>;
};

export type DeleteAlternateTitle = {
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export type DeleteJsonWebTokenCookie = {
  deleted: Scalars['Boolean']['output'];
};

export type DeleteRecording = {
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export type DeleteRefreshTokenCookie = {
  deleted: Scalars['Boolean']['output'];
};

export type DiscountCodeType = {
  code: Scalars['String']['output'];
  /** A multiplier value, ex: 0.20 for 20% off */
  discount: Scalars['Decimal']['output'];
  isValid?: Maybe<Scalars['Boolean']['output']>;
};

export type DisplayStatusLogType = {
  monitoring?: Maybe<Scalars['DateTime']['output']>;
  processing?: Maybe<Scalars['DateTime']['output']>;
  qualified?: Maybe<Scalars['DateTime']['output']>;
  registering?: Maybe<Scalars['DateTime']['output']>;
  sent?: Maybe<Scalars['DateTime']['output']>;
};

export type DistributorType = Node & {
  choice?: Maybe<Scalars['Int']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  songtrustUsers: SongtrustUserTypeConnection;
};


export type DistributorTypeSongtrustUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type DistributorTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DistributorTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `DistributorType` and its cursor. */
export type DistributorTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<DistributorType>;
};

export type EarningPeriod = {
  isEstimate?: Maybe<Scalars['Boolean']['output']>;
  period?: Maybe<Scalars['Date']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['String']['output']>;
};

export type FieldError = {
  error: Scalars['String']['output'];
  path: Array<Maybe<Scalars['String']['output']>>;
};

export type Flag = {
  enabled?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type FlagConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<FlagEdge>>;
  extra?: Maybe<Scalars['String']['output']>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `Flag` and its cursor. */
export type FlagEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<Flag>;
  other?: Maybe<Scalars['String']['output']>;
};

export type GenerateAuthCodeMutation = {
  created?: Maybe<Scalars['DateTime']['output']>;
};

export type GeneratePlaidIdentityToken = {
  identityId?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type GrapheneNodeWithDjangoModelId = {
  id: Scalars['ID']['output'];
};

/** An enumeration. */
export enum InterestedpartyPerformingRightsOrganizationLanguageChoices {
  /** Afar */
  Aa = 'AA',
  /** Abkhazian */
  Ab = 'AB',
  /** Avestan */
  Ae = 'AE',
  /** Afrikaans */
  Af = 'AF',
  /** Akan */
  Ak = 'AK',
  /** Amharic */
  Am = 'AM',
  /** Aragonese */
  An = 'AN',
  /** Arabic */
  Ar = 'AR',
  /** Assamese */
  As = 'AS',
  /** Avaric */
  Av = 'AV',
  /** Aymara */
  Ay = 'AY',
  /** Azerbaijani */
  Az = 'AZ',
  /** Bashkir */
  Ba = 'BA',
  /** Belarusian */
  Be = 'BE',
  /** Bulgarian */
  Bg = 'BG',
  /** Bihari languages */
  Bh = 'BH',
  /** Bislama */
  Bi = 'BI',
  /** Bambara */
  Bm = 'BM',
  /** Bengali */
  Bn = 'BN',
  /** Tibetan */
  Bo = 'BO',
  /** Breton */
  Br = 'BR',
  /** Bosnian */
  Bs = 'BS',
  /** Catalan; Valencian */
  Ca = 'CA',
  /** Chechen */
  Ce = 'CE',
  /** Chamorro */
  Ch = 'CH',
  /** Corsican */
  Co = 'CO',
  /** Cree */
  Cr = 'CR',
  /** Czech */
  Cs = 'CS',
  /** Church Slavic; Old Slavonic; Chu */
  Cu = 'CU',
  /** Chuvash */
  Cv = 'CV',
  /** Welsh */
  Cy = 'CY',
  /** Danish */
  Da = 'DA',
  /** German */
  De = 'DE',
  /** Divehi; Dhivehi; Maldivian */
  Dv = 'DV',
  /** Dzongkha */
  Dz = 'DZ',
  /** Ewe */
  Ee = 'EE',
  /** Greek, Modern */
  El = 'EL',
  /** English */
  En = 'EN',
  /** English (US) */
  EnUs = 'EN_US',
  /** Esperanto */
  Eo = 'EO',
  /** Spanish */
  Es = 'ES',
  /** Estonian */
  Et = 'ET',
  /** Basque */
  Eu = 'EU',
  /** Persian */
  Fa = 'FA',
  /** Fulah */
  Ff = 'FF',
  /** Finnish */
  Fi = 'FI',
  /** Finnish (FI) */
  FiFi = 'FI_FI',
  /** Fijian */
  Fj = 'FJ',
  /** Faroese */
  Fo = 'FO',
  /** French */
  Fr = 'FR',
  /** Western Frisian */
  Fy = 'FY',
  /** Irish */
  Ga = 'GA',
  /** Gaelic; Scottish Gaelic */
  Gd = 'GD',
  /** Galician */
  Gl = 'GL',
  /** Guarani */
  Gn = 'GN',
  /** Gujarati */
  Gu = 'GU',
  /** Manx */
  Gv = 'GV',
  /** Hausa */
  Ha = 'HA',
  /** Hebrew */
  He = 'HE',
  /** Hindi */
  Hi = 'HI',
  /** Hiri Motu */
  Ho = 'HO',
  /** Croatian */
  Hr = 'HR',
  /** Haitian; Haitian Creole */
  Ht = 'HT',
  /** Hungarian */
  Hu = 'HU',
  /** Armenian */
  Hy = 'HY',
  /** Herero */
  Hz = 'HZ',
  /** Interlingua  */
  Ia = 'IA',
  /** Indonesian */
  Id = 'ID',
  /** Interlingue; Occidental */
  Ie = 'IE',
  /** Igbo */
  Ig = 'IG',
  /** Sichuan Yi; Nuosu */
  Ii = 'II',
  /** Inupiaq */
  Ik = 'IK',
  /** Ido */
  Io = 'IO',
  /** Icelandic */
  Is = 'IS',
  /** Italian */
  It = 'IT',
  /** Italian (IT) */
  ItIt = 'IT_IT',
  /** Inuktitut */
  Iu = 'IU',
  /** Japanese */
  Ja = 'JA',
  /** Japanese (JP) */
  JaJp = 'JA_JP',
  /** Javanese */
  Jv = 'JV',
  /** Georgian */
  Ka = 'KA',
  /** Kongo */
  Kg = 'KG',
  /** Kikuyu; Gikuyu */
  Ki = 'KI',
  /** Kuanyama; Kwanyama */
  Kj = 'KJ',
  /** Kazakh */
  Kk = 'KK',
  /** Kalaallisut; Greenlandic */
  Kl = 'KL',
  /** Central Khmer */
  Km = 'KM',
  /** Kannada */
  Kn = 'KN',
  /** Korean */
  Ko = 'KO',
  /** Kanuri */
  Kr = 'KR',
  /** Kashmiri */
  Ks = 'KS',
  /** Kurdish */
  Ku = 'KU',
  /** Komi */
  Kv = 'KV',
  /** Cornish */
  Kw = 'KW',
  /** Kirghiz; Kyrgyz */
  Ky = 'KY',
  /** Latin */
  La = 'LA',
  /** Luxembourgish; Letzeburgesch */
  Lb = 'LB',
  /** Ganda */
  Lg = 'LG',
  /** Limburgan; Limburger; Limburgish */
  Li = 'LI',
  /** Lingala */
  Ln = 'LN',
  /** Lao */
  Lo = 'LO',
  /** Lithuanian */
  Lt = 'LT',
  /** Luba-Katanga */
  Lu = 'LU',
  /** Latvian */
  Lv = 'LV',
  /** Malagasy */
  Mg = 'MG',
  /** Marshallese */
  Mh = 'MH',
  /** Maori */
  Mi = 'MI',
  /** Macedonian */
  Mk = 'MK',
  /** Malayalam */
  Ml = 'ML',
  /** Mongolian */
  Mn = 'MN',
  /** Marathi */
  Mr = 'MR',
  /** Malay */
  Ms = 'MS',
  /** Maltese */
  Mt = 'MT',
  /** Burmese */
  My = 'MY',
  /** Nauru */
  Na = 'NA',
  /** Ndebele, North; North Ndebele */
  Nd = 'ND',
  /** Nepali */
  Ne = 'NE',
  /** Ndonga */
  Ng = 'NG',
  /** Dutch */
  Nl = 'NL',
  /** Dutch (NL) */
  NlNl = 'NL_NL',
  /** Norwegian */
  No = 'NO',
  /** Norwegian (NO) */
  NoNo = 'NO_NO',
  /** Ndebele, South; South Ndebele */
  Nr = 'NR',
  /** Navajo; Navaho */
  Nv = 'NV',
  /** Chichewa; Chewa; Nyanja */
  Ny = 'NY',
  /** Ojibwa */
  Oj = 'OJ',
  /** Oromo */
  Om = 'OM',
  /** Oriya */
  Or = 'OR',
  /** Ossetian; Ossetic */
  Os = 'OS',
  /** Other */
  Other = 'OTHER',
  /** Panjabi; Punjabi */
  Pa = 'PA',
  /** Pali */
  Pi = 'PI',
  /** Polish */
  Pl = 'PL',
  /** Pushto; Pashto */
  Ps = 'PS',
  /** Portuguese */
  Pt = 'PT',
  /** Quechua */
  Qu = 'QU',
  /** Romansh */
  Rm = 'RM',
  /** Rundi */
  Rn = 'RN',
  /** Romanian; Moldavian; Moldovan */
  Ro = 'RO',
  /** Russian */
  Ru = 'RU',
  /** Kinyarwanda */
  Rw = 'RW',
  /** Sanskrit */
  Sa = 'SA',
  /** Sardinian */
  Sc = 'SC',
  /** Sindhi */
  Sd = 'SD',
  /** Northern Sami */
  Se = 'SE',
  /** Sango */
  Sg = 'SG',
  /** Sinhala; Sinhalese */
  Si = 'SI',
  /** Slovak */
  Sk = 'SK',
  /** Slovenian */
  Sl = 'SL',
  /** Samoan */
  Sm = 'SM',
  /** Shona */
  Sn = 'SN',
  /** Somali */
  So = 'SO',
  /** Albanian */
  Sq = 'SQ',
  /** Serbian */
  Sr = 'SR',
  /** Swati */
  Ss = 'SS',
  /** Sotho, Southern */
  St = 'ST',
  /** Sundanese */
  Su = 'SU',
  /** Swedish */
  Sv = 'SV',
  /** Swahili */
  Sw = 'SW',
  /** Tamil */
  Ta = 'TA',
  /** Telugu */
  Te = 'TE',
  /** Tajik */
  Tg = 'TG',
  /** Thai */
  Th = 'TH',
  /** Tigrinya */
  Ti = 'TI',
  /** Turkmen */
  Tk = 'TK',
  /** Tagalog */
  Tl = 'TL',
  /** Tswana */
  Tn = 'TN',
  /** Tonga (Tonga Islands) */
  To = 'TO',
  /** Turkish */
  Tr = 'TR',
  /** Tsonga */
  Ts = 'TS',
  /** Tatar */
  Tt = 'TT',
  /** Twi */
  Tw = 'TW',
  /** Tahitian */
  Ty = 'TY',
  /** Uighur; Uyghur */
  Ug = 'UG',
  /** Ukrainian */
  Uk = 'UK',
  /** Urdu */
  Ur = 'UR',
  /** Uzbek */
  Uz = 'UZ',
  /** Venda */
  Ve = 'VE',
  /** Vietnamese */
  Vi = 'VI',
  /** Walloon */
  Wa = 'WA',
  /** Wolof */
  Wo = 'WO',
  /** Xhosa */
  Xh = 'XH',
  /** Yiddish */
  Yi = 'YI',
  /** Yoruba */
  Yo = 'YO',
  /** Zhuang; Chuang */
  Za = 'ZA',
  /** Chinese */
  Zh = 'ZH',
  /** Zulu */
  Zu = 'ZU'
}

/** An enumeration. */
export enum InterestedpartySongWriterHonorificChoices {
  /** Mr */
  A_1 = 'A_1',
  /** Mrs */
  A_2 = 'A_2',
  /** Ms */
  A_3 = 'A_3',
  /** Miss */
  A_4 = 'A_4',
  /** Dr */
  A_5 = 'A_5'
}

/** An enumeration. */
export enum InterestedpartySongWriterSongwriterTypeChoices {
  /** Client */
  Client = 'CLIENT',
  /** Outside */
  Outside = 'OUTSIDE'
}

/** An enumeration. */
export enum InterestedpartySongWriterUserCrossLinkAccessTypeChoices {
  /** Full Access */
  FullAccess = 'FULL_ACCESS',
  /** Read Only */
  ReadOnly = 'READ_ONLY'
}

export type JwtAuthenticationMutation = {
  payload: Scalars['GenericScalar']['output'];
  refreshExpiresIn: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
  user?: Maybe<SongtrustUserType>;
};

export type LocationInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  zipcode: Scalars['String']['input'];
};

/** Register mutation classes as base classes for GraphQL */
export type Mutation = {
  addSongwriterCredits?: Maybe<AddSongwriterCredits>;
  addYoutubeChannel?: Maybe<AddYoutubeChannel>;
  changePassword?: Maybe<ChangePasswordMutation>;
  createAlternateTitle?: Maybe<CreateAlternateTitle>;
  createClientSongwriter?: Maybe<CreateClientSongwriter>;
  createOutsideSongwriter?: Maybe<CreateOutsideSongwriter>;
  createRecording?: Maybe<CreateRecording>;
  createSong?: Maybe<CreateSong>;
  deleteAlternateTitle?: Maybe<DeleteAlternateTitle>;
  deleteRecording?: Maybe<DeleteRecording>;
  deleteRefreshTokenCookie?: Maybe<DeleteRefreshTokenCookie>;
  deleteTokenCookie?: Maybe<DeleteJsonWebTokenCookie>;
  generateAuthCode?: Maybe<GenerateAuthCodeMutation>;
  generatePlaidIdentityToken?: Maybe<GeneratePlaidIdentityToken>;
  refreshToken?: Maybe<Refresh>;
  removeYoutubeChannel?: Maybe<RemoveYoutubeChannel>;
  resetPassword?: Maybe<ResetPasswordMutation>;
  /** Allow for RevokeToken to run Silently if there's no token to be revoked */
  revokeToken?: Maybe<RevokeTokenMutation>;
  signupUser?: Maybe<SignupUser>;
  songwriterChangeYoutubeClaimOptOut?: Maybe<SongwriterChangeYoutubeClaimOptOut>;
  songwriterUpdateBiography?: Maybe<SongwriterUpdateBiography>;
  submitOnboardingProfile?: Maybe<SubmitOnboardingProfile>;
  submitSongwriterAccessToAccount?: Maybe<SubmitSongwriterAccessToAccount>;
  tokenAuth?: Maybe<JwtAuthenticationMutation>;
  updateAccountIdentity?: Maybe<UpdateAccountIdentity>;
  updateAccountInformation?: Maybe<UpdateAccountInformation>;
  updateCreditCard?: Maybe<UpdateCreditCard>;
  updateNotifications?: Maybe<UpdateNotificationSetting>;
  updateSong?: Maybe<UpdateSong>;
  updateSongwriter?: Maybe<UpdateSongwriter>;
  verifyToken?: Maybe<Verify>;
};


/** Register mutation classes as base classes for GraphQL */
export type MutationAddSongwriterCreditsArgs = {
  credits?: InputMaybe<Scalars['Int']['input']>;
  discountCode?: InputMaybe<Scalars['String']['input']>;
};


/** Register mutation classes as base classes for GraphQL */
export type MutationAddYoutubeChannelArgs = {
  channelUrl: Scalars['String']['input'];
};


/** Register mutation classes as base classes for GraphQL */
export type MutationChangePasswordArgs = {
  authCode?: InputMaybe<Scalars['String']['input']>;
  newPassword?: InputMaybe<Scalars['String']['input']>;
  oldPassword?: InputMaybe<Scalars['String']['input']>;
};


/** Register mutation classes as base classes for GraphQL */
export type MutationCreateAlternateTitleArgs = {
  input: CreateAlternateTitleInput;
};


/** Register mutation classes as base classes for GraphQL */
export type MutationCreateClientSongwriterArgs = {
  input: ClientSongwriterInput;
};


/** Register mutation classes as base classes for GraphQL */
export type MutationCreateOutsideSongwriterArgs = {
  input: OutsideSongwriterInput;
};


/** Register mutation classes as base classes for GraphQL */
export type MutationCreateRecordingArgs = {
  input: RecordingInput;
};


/** Register mutation classes as base classes for GraphQL */
export type MutationCreateSongArgs = {
  song: CreateSongInput;
};


/** Register mutation classes as base classes for GraphQL */
export type MutationDeleteAlternateTitleArgs = {
  alternateTitleId: Scalars['Int']['input'];
};


/** Register mutation classes as base classes for GraphQL */
export type MutationDeleteRecordingArgs = {
  recordingId: Scalars['Int']['input'];
};


/** Register mutation classes as base classes for GraphQL */
export type MutationRefreshTokenArgs = {
  fp: Scalars['String']['input'];
  refreshToken?: InputMaybe<Scalars['String']['input']>;
};


/** Register mutation classes as base classes for GraphQL */
export type MutationRemoveYoutubeChannelArgs = {
  channelId: Scalars['String']['input'];
};


/** Register mutation classes as base classes for GraphQL */
export type MutationResetPasswordArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
};


/** Register mutation classes as base classes for GraphQL */
export type MutationRevokeTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']['input']>;
};


/** Register mutation classes as base classes for GraphQL */
export type MutationSignupUserArgs = {
  userSignupInput?: InputMaybe<UserSignupInput>;
};


/** Register mutation classes as base classes for GraphQL */
export type MutationSongwriterChangeYoutubeClaimOptOutArgs = {
  optOut?: InputMaybe<Scalars['Boolean']['input']>;
  songwriterId?: InputMaybe<Scalars['Int']['input']>;
};


/** Register mutation classes as base classes for GraphQL */
export type MutationSongwriterUpdateBiographyArgs = {
  bio?: InputMaybe<Scalars['String']['input']>;
  songwriterId?: InputMaybe<Scalars['Int']['input']>;
};


/** Register mutation classes as base classes for GraphQL */
export type MutationSubmitOnboardingProfileArgs = {
  onboardingProfile?: InputMaybe<OnboardingProfileInput>;
};


/** Register mutation classes as base classes for GraphQL */
export type MutationSubmitSongwriterAccessToAccountArgs = {
  songwriterAccesses: SongwriterAccessInput;
  targetAccount: TargetAccountInput;
};


/** Register mutation classes as base classes for GraphQL */
export type MutationTokenAuthArgs = {
  fp: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


/** Register mutation classes as base classes for GraphQL */
export type MutationUpdateAccountIdentityArgs = {
  identityId?: InputMaybe<Scalars['String']['input']>;
};


/** Register mutation classes as base classes for GraphQL */
export type MutationUpdateAccountInformationArgs = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};


/** Register mutation classes as base classes for GraphQL */
export type MutationUpdateCreditCardArgs = {
  stripeToken?: InputMaybe<Scalars['String']['input']>;
};


/** Register mutation classes as base classes for GraphQL */
export type MutationUpdateNotificationsArgs = {
  frequency?: InputMaybe<Scalars['String']['input']>;
  notificationType?: InputMaybe<Scalars['Int']['input']>;
};


/** Register mutation classes as base classes for GraphQL */
export type MutationUpdateSongArgs = {
  input: UpdateSongInput;
};


/** Register mutation classes as base classes for GraphQL */
export type MutationUpdateSongwriterArgs = {
  input: UpdateSongwriterInput;
};


/** Register mutation classes as base classes for GraphQL */
export type MutationVerifyTokenArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID']['output'];
};

export type NotificationSettingType = {
  frequency?: Maybe<NotificationsNotificationSettingsFrequencyChoices>;
  notificationTypeId?: Maybe<Scalars['Int']['output']>;
};

export type NotificationType = GrapheneNodeWithDjangoModelId & {
  allowImmediately: Scalars['Boolean']['output'];
  allowMonthly: Scalars['Boolean']['output'];
  allowQuarterly: Scalars['Boolean']['output'];
  allowWeekly: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type NotificationTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<NotificationTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `NotificationType` and its cursor. */
export type NotificationTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<NotificationType>;
};

/** An enumeration. */
export enum NotificationsNotificationSettingsFrequencyChoices {
  /** Immediately */
  Immediately = 'IMMEDIATELY',
  /** Monthly */
  Monthly = 'MONTHLY',
  /** Never */
  Never = 'NEVER',
  /** Quarterly */
  Quarterly = 'QUARTERLY',
  /** Weekly */
  Weekly = 'WEEKLY'
}

export type OnboardingProfileInput = {
  about: AboutInput;
  administration: AdministrationInput;
  catalog: CatalogInput;
};

export type OutsideSongwriterInput = {
  alternateNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  birthdate?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  honorific?: InputMaybe<Scalars['String']['input']>;
  ipi?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  lodTosAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  pro?: InputMaybe<ProInput>;
  publishingCompany?: InputMaybe<PublishingCompanyInput>;
  signature?: InputMaybe<Scalars['String']['input']>;
  songwriterType?: InputMaybe<Scalars['String']['input']>;
  songwriterYoutubeClaims: Scalars['Boolean']['input'];
  usRepresentation?: InputMaybe<UsRepresentationInput>;
};

export type ProInput = {
  name: Scalars['String']['input'];
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PerformingRightsOrganizationType = GrapheneNodeWithDjangoModelId & {
  /** Alpha, Sigma, or Beta */
  cdbabyBusinessAlias?: Maybe<Scalars['String']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  counterpointCode?: Maybe<Scalars['String']['output']>;
  /** PRO for Society Event */
  dashboarditemSet: DashboardItemTypeConnection;
  id: Scalars['ID']['output'];
  /** If you have one */
  ipiNumber?: Maybe<Scalars['String']['output']>;
  /** Is mechanical society? */
  isMechanical: Scalars['Boolean']['output'];
  language: InterestedpartyPerformingRightsOrganizationLanguageChoices;
  name: Scalars['String']['output'];
  publishingcompanySet: Array<PublishingCompanyType>;
  requiresAgreementNumber?: Maybe<Scalars['Boolean']['output']>;
  requiresLodApproval?: Maybe<Scalars['String']['output']>;
  requiresLodInHomeTerritory?: Maybe<Scalars['Boolean']['output']>;
  requiresLodInNonHomeTerritory?: Maybe<Scalars['String']['output']>;
  /** The PRO will notify us when the LOD for a given songwriter should be sent.re */
  sendLodOnDemand?: Maybe<Scalars['Boolean']['output']>;
  signupPossible: Scalars['Boolean']['output'];
  /** Ave, Blvd, or Street */
  songtrustBusinessAlias?: Maybe<Scalars['String']['output']>;
  songwriterSet: SongwriterTypeConnection;
  url?: Maybe<Scalars['String']['output']>;
  /** The PRO that has this writer's songs in the USA */
  usRepresentation: SongwriterTypeConnection;
  writerLodSignatureRequired?: Maybe<Scalars['Boolean']['output']>;
};


export type PerformingRightsOrganizationTypeDashboarditemSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type PerformingRightsOrganizationTypeSongwriterSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  songwriterType?: InputMaybe<InterestedpartySongWriterSongwriterTypeChoices>;
};


export type PerformingRightsOrganizationTypeUsRepresentationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  songwriterType?: InputMaybe<InterestedpartySongWriterSongwriterTypeChoices>;
};

export type PerformingRightsOrganizationTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PerformingRightsOrganizationTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `PerformingRightsOrganizationType` and its cursor. */
export type PerformingRightsOrganizationTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PerformingRightsOrganizationType>;
};

export type Period = {
  endDate?: Maybe<Scalars['Date']['output']>;
};

export type PeriodConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PeriodEdge>>;
  extra?: Maybe<Scalars['String']['output']>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `Period` and its cursor. */
export type PeriodEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<Period>;
  other?: Maybe<Scalars['String']['output']>;
};

export type PublishingCompanyInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  ipi: Scalars['String']['input'];
  name: Scalars['String']['input'];
  pro?: InputMaybe<ProInput>;
};

export type PublishingCompanyType = {
  CommissionRate?: Maybe<Scalars['Float']['output']>;
  /** The publisher code in Maestro */
  counterpointCode?: Maybe<Scalars['String']['output']>;
  createActionType?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  createdByUser?: Maybe<UserType>;
  deliverableName?: Maybe<Scalars['String']['output']>;
  /** Required */
  email?: Maybe<Scalars['String']['output']>;
  /** Interested Party's legal first name */
  firstName?: Maybe<Scalars['String']['output']>;
  hasIcePublisher?: Maybe<Scalars['Boolean']['output']>;
  hasSacemPublisher?: Maybe<Scalars['Boolean']['output']>;
  hasSgaePublisher?: Maybe<Scalars['Boolean']['output']>;
  iceAgreementNumber?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  ipi?: Maybe<Scalars['String']['output']>;
  /** Interested Party's legal last name */
  lastName?: Maybe<Scalars['String']['output']>;
  managedBy?: Maybe<SongtrustUserType>;
  /** Interested Party's legal middle name */
  middleName?: Maybe<Scalars['String']['output']>;
  modified: Scalars['DateTime']['output'];
  modifiedActionType?: Maybe<Scalars['String']['output']>;
  modifiedByUser?: Maybe<UserType>;
  name: Scalars['String']['output'];
  pro?: Maybe<PerformingRightsOrganizationType>;
  sabamAgreementNumber?: Maybe<Scalars['String']['output']>;
  sacemAgreementNumber?: Maybe<Scalars['String']['output']>;
  sgaeAgreementNumber?: Maybe<Scalars['String']['output']>;
  songownershipSet: Array<SongOwnershipType>;
  songs: SongTypeConnection;
  /** If you have one */
  songwriters: SongwriterTypeConnection;
  supplementalAuditInfo?: Maybe<Scalars['JSONString']['output']>;
};


export type PublishingCompanyTypeSongsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasRecordings?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  songwriterName?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type PublishingCompanyTypeSongwritersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  songwriterType?: InputMaybe<InterestedpartySongWriterSongwriterTypeChoices>;
};

/** Register query classes as base classes for GraphQL */
export type Query = {
  accountCompletion?: Maybe<AccountCompletion>;
  activeWorkRegions?: Maybe<ActiveWorkRegionTypeConnection>;
  artists?: Maybe<Array<Maybe<ArtistType>>>;
  availableSongwritersAccess?: Maybe<AvailableSongwritersResults>;
  catalogRepresentationChoices?: Maybe<ChoiceConnection>;
  checkVerificationStatus?: Maybe<VerificationStatusType>;
  claimedVideos?: Maybe<SongVideoTypeConnection>;
  countries?: Maybe<CountryTypeConnection>;
  digitalDistributionChoices?: Maybe<ChoiceConnection>;
  distributorsChoices?: Maybe<DistributorTypeConnection>;
  earningsBetweenPeriods?: Maybe<Array<Maybe<EarningPeriod>>>;
  expectedManagedWriterChoices?: Maybe<ChoiceConnection>;
  featureFlags?: Maybe<FlagConnection>;
  featureSamples?: Maybe<FlagConnection>;
  featureSwitches?: Maybe<FlagConnection>;
  loggedInSongtrustUser?: Maybe<SongtrustUserType>;
  newsAndEvents?: Maybe<DashboardItemTypeConnection>;
  notificationTypes?: Maybe<NotificationTypeConnection>;
  performingRightsOrganizations?: Maybe<PerformingRightsOrganizationTypeConnection>;
  periods?: Maybe<PeriodConnection>;
  randomTip?: Maybe<DashboardItemType>;
  recordingsBySong?: Maybe<RecordingTypeConnection>;
  song?: Maybe<SongType>;
  /** @deprecated Deprecated in favor of using song(id).) */
  songById?: Maybe<SongType>;
  songs?: Maybe<SongTypeConnection>;
  songwriter?: Maybe<SongwriterType>;
  songwriters?: Maybe<SongwriterTypeConnection>;
  songwritersUsage?: Maybe<SongwritersUsage>;
  states?: Maybe<StateTypeConnection>;
  streamEstimationChoices?: Maybe<ChoiceConnection>;
  totalEarnings?: Maybe<EarningPeriod>;
  tracks?: Maybe<Array<Maybe<SpotifyTrackType>>>;
  transactions?: Maybe<TransactionTypeConnection>;
  trolleyString?: Maybe<Scalars['String']['output']>;
  userCountEstimationChoices?: Maybe<ChoiceConnection>;
  userGenres?: Maybe<UserGenreTypeConnection>;
  userProfileTypes?: Maybe<UserProfileTypeConnection>;
  userSongwriterAccess?: Maybe<SongtrustUserTypeConnection>;
  verifyDiscountCode?: Maybe<DiscountCodeType>;
  yesNoChoices?: Maybe<ChoiceConnection>;
  youtubeChannels?: Maybe<YoutubeChannelTypeConnection>;
};


/** Register query classes as base classes for GraphQL */
export type QueryActiveWorkRegionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryAvailableSongwritersAccessArgs = {
  songtrustUserId?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryCatalogRepresentationChoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryCheckVerificationStatusArgs = {
  identityId?: InputMaybe<Scalars['String']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryClaimedVideosArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<SortByArgumentInput>;
  state?: InputMaybe<Array<InputMaybe<SongVideoState>>>;
};


/** Register query classes as base classes for GraphQL */
export type QueryCountriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryDigitalDistributionChoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryDistributorsChoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryEarningsBetweenPeriodsArgs = {
  startingFrom?: InputMaybe<Scalars['Date']['input']>;
  upTo?: InputMaybe<Scalars['Date']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryExpectedManagedWriterChoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryFeatureFlagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryFeatureSamplesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryFeatureSwitchesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryNewsAndEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryNotificationTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryPerformingRightsOrganizationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryPeriodsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryRecordingsBySongArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  songId?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QuerySongArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QuerySongByIdArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QuerySongsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasRecordings?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  songwriterName?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_Icontains?: InputMaybe<Scalars['String']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QuerySongwriterArgs = {
  songwriterId?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QuerySongwritersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  searchFor?: InputMaybe<Scalars['String']['input']>;
  songwriterType?: InputMaybe<InterestedpartySongWriterSongwriterTypeChoices>;
};


/** Register query classes as base classes for GraphQL */
export type QueryStatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryStreamEstimationChoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryTracksArgs = {
  artist: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


/** Register query classes as base classes for GraphQL */
export type QueryTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  amount?: InputMaybe<Scalars['Decimal']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  clientCode?: InputMaybe<Scalars['Int']['input']>;
  clientName?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  songwriter?: InputMaybe<Scalars['ID']['input']>;
  transactionDate?: InputMaybe<Scalars['DateTime']['input']>;
  transactionType?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryUserCountEstimationChoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryUserGenresArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryUserProfileTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryUserSongwriterAccessArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryVerifyDiscountCodeArgs = {
  discountCode?: InputMaybe<Scalars['String']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryYesNoChoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


/** Register query classes as base classes for GraphQL */
export type QueryYoutubeChannelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type RecordingInput = {
  album: Scalars['String']['input'];
  artists: Array<InputMaybe<ArtistInput>>;
  isrc: Scalars['String']['input'];
  name: Scalars['String']['input'];
  songId: Scalars['Int']['input'];
  spotifyTrackId: Scalars['String']['input'];
};

export type RecordingType = GrapheneNodeWithDjangoModelId & {
  album: Scalars['String']['output'];
  artists: Array<ArtistType>;
  audioFileUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** ISRC Code is your unique 12-digit recording code */
  isrc?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  song: SongType;
  spotifyPreviewUrl?: Maybe<Scalars['String']['output']>;
  spotifyTrackId?: Maybe<Scalars['String']['output']>;
  spotifyUrl?: Maybe<Scalars['String']['output']>;
};

export type RecordingTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<RecordingTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `RecordingType` and its cursor. */
export type RecordingTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<RecordingType>;
};

export type Refresh = {
  payload: Scalars['GenericScalar']['output'];
  refreshExpiresIn: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
  token: Scalars['String']['output'];
  user?: Maybe<SongtrustUserType>;
};

export type RemoveSongwriterAccessInput = {
  crosslinkId: Scalars['Int']['input'];
};

export type RemoveYoutubeChannel = {
  channel?: Maybe<YoutubeChannelType>;
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export type ResetPasswordMutation = {
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

/** Allow for RevokeToken to run Silently if there's no token to be revoked */
export type RevokeTokenMutation = {
  revoked: Scalars['Int']['output'];
};

export type SignupUser = {
  errors?: Maybe<Array<Maybe<FieldError>>>;
  songtrustUser?: Maybe<SongtrustUserType>;
};

export type SongAlternateTitleInput = {
  title: Scalars['String']['input'];
};

export type SongLogType = {
  created: Scalars['DateTime']['output'];
  status?: Maybe<Scalars['String']['output']>;
};

export type SongOwnershipInput = {
  isOutsideSongwriter: Scalars['Boolean']['input'];
  percent: Scalars['Float']['input'];
  songwriterId: Scalars['Int']['input'];
};

export type SongOwnershipType = {
  percent: Scalars['Decimal']['output'];
  songwriter?: Maybe<SongwriterType>;
};

export type SongType = GrapheneNodeWithDjangoModelId & {
  alternateTitle?: Maybe<Scalars['String']['output']>;
  alternateTitles?: Maybe<Array<Maybe<AlternateTitleType>>>;
  alternatetitleSet: Array<AlternateTitleType>;
  cdbabySongId?: Maybe<Scalars['String']['output']>;
  chorusLyrics: Scalars['String']['output'];
  counterpointNumber?: Maybe<Scalars['String']['output']>;
  createActionType?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  createdByUser?: Maybe<UserType>;
  deliverableTitle?: Maybe<Scalars['String']['output']>;
  displayStatus?: Maybe<Scalars['String']['output']>;
  displayStatusLog?: Maybe<DisplayStatusLogType>;
  firstQualifiedAt?: Maybe<Scalars['DateTime']['output']>;
  fullLyrics: Scalars['String']['output'];
  hashOfRelevantSongInfo?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  iswc?: Maybe<Scalars['String']['output']>;
  language: SongsSongLanguageChoices;
  lastSearchedForVideosAt?: Maybe<Scalars['DateTime']['output']>;
  modified: Scalars['DateTime']['output'];
  modifiedActionType?: Maybe<Scalars['String']['output']>;
  modifiedByUser?: Maybe<UserType>;
  origin: SongsSongOriginChoices;
  ownership: Array<SongOwnershipType>;
  ownerships?: Maybe<Array<Maybe<SongOwnershipType>>>;
  proWorkNumber?: Maybe<Scalars['String']['output']>;
  publicDomain: Scalars['Boolean']['output'];
  publishingcompanySet: Array<PublishingCompanyType>;
  recordings: RecordingTypeConnection;
  /** The most recent task id */
  sendToCpTaskId?: Maybe<Scalars['String']['output']>;
  songLog?: Maybe<Array<Maybe<SongLogType>>>;
  songlog?: Maybe<SongLogType>;
  songvideoSet: SongVideoTypeConnection;
  songwriterSet: SongwriterTypeConnection;
  supplementalAuditInfo?: Maybe<Scalars['JSONString']['output']>;
  title: Scalars['String']['output'];
  transferredFromPro: Scalars['Boolean']['output'];
  youtubeAssetId?: Maybe<Scalars['String']['output']>;
  youtubeCompShareId?: Maybe<Scalars['String']['output']>;
};


export type SongTypeRecordingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type SongTypeSongvideoSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type SongTypeSongwriterSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  songwriterType?: InputMaybe<InterestedpartySongWriterSongwriterTypeChoices>;
};

export type SongTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SongTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `SongType` and its cursor. */
export type SongTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SongType>;
};

/** An enumeration. */
export enum SongVideoState {
  ApprovedByClient = 'approved_by_client',
  Claimed = 'claimed',
  ErrorClaiming = 'error_claiming',
  Licensed = 'licensed',
  QueuedForClaiming = 'queued_for_claiming',
  ShouldNotBeClaimed = 'should_not_be_claimed',
  Unreviewed = 'unreviewed'
}

export type SongVideoType = Node & {
  created: Scalars['DateTime']['output'];
  end?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  recording?: Maybe<RecordingType>;
  song: SongType;
  start?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  video: VideoType;
};

export type SongVideoTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SongVideoTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `SongVideoType` and its cursor. */
export type SongVideoTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SongVideoType>;
};

export type SongWriterUserCrossLinkType = GrapheneNodeWithDjangoModelId & {
  /** Level of access provided to the Songwriter record for this user */
  accessType: InterestedpartySongWriterUserCrossLinkAccessTypeChoices;
  created: Scalars['DateTime']['output'];
  createdByUser?: Maybe<UserType>;
  /** Cross-link is only active until this date. If not set, the cross-link covers all time after the start date. */
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  modifiedByUser?: Maybe<UserType>;
  songtrustuser: SongtrustUserType;
  songwriter: SongwriterType;
  /** Cross-link is only active from this date forward. If not set, the cross-link covers all time before the end date. */
  startDate?: Maybe<Scalars['Date']['output']>;
};

export type SongWriterUserCrossLinkTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SongWriterUserCrossLinkTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `SongWriterUserCrossLinkType` and its cursor. */
export type SongWriterUserCrossLinkTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SongWriterUserCrossLinkType>;
};

/** An enumeration. */
export enum SongsArtistCreateTypeChoices {
  /** Legacy */
  Legacy = 'LEGACY',
  /** Spotify */
  Spotify = 'SPOTIFY',
  /** User */
  User = 'USER'
}

/** An enumeration. */
export enum SongsSongLanguageChoices {
  /** Afar */
  Aa = 'AA',
  /** Abkhazian */
  Ab = 'AB',
  /** Avestan */
  Ae = 'AE',
  /** Afrikaans */
  Af = 'AF',
  /** Akan */
  Ak = 'AK',
  /** Amharic */
  Am = 'AM',
  /** Aragonese */
  An = 'AN',
  /** Arabic */
  Ar = 'AR',
  /** Assamese */
  As = 'AS',
  /** Avaric */
  Av = 'AV',
  /** Aymara */
  Ay = 'AY',
  /** Azerbaijani */
  Az = 'AZ',
  /** Bashkir */
  Ba = 'BA',
  /** Belarusian */
  Be = 'BE',
  /** Bulgarian */
  Bg = 'BG',
  /** Bihari languages */
  Bh = 'BH',
  /** Bislama */
  Bi = 'BI',
  /** Bambara */
  Bm = 'BM',
  /** Bengali */
  Bn = 'BN',
  /** Tibetan */
  Bo = 'BO',
  /** Breton */
  Br = 'BR',
  /** Bosnian */
  Bs = 'BS',
  /** Catalan; Valencian */
  Ca = 'CA',
  /** Chechen */
  Ce = 'CE',
  /** Chamorro */
  Ch = 'CH',
  /** Corsican */
  Co = 'CO',
  /** Cree */
  Cr = 'CR',
  /** Czech */
  Cs = 'CS',
  /** Church Slavic; Old Slavonic; Chu */
  Cu = 'CU',
  /** Chuvash */
  Cv = 'CV',
  /** Welsh */
  Cy = 'CY',
  /** Danish */
  Da = 'DA',
  /** German */
  De = 'DE',
  /** Divehi; Dhivehi; Maldivian */
  Dv = 'DV',
  /** Dzongkha */
  Dz = 'DZ',
  /** Ewe */
  Ee = 'EE',
  /** Greek, Modern */
  El = 'EL',
  /** English */
  En = 'EN',
  /** English (US) */
  EnUs = 'EN_US',
  /** Esperanto */
  Eo = 'EO',
  /** Spanish */
  Es = 'ES',
  /** Estonian */
  Et = 'ET',
  /** Basque */
  Eu = 'EU',
  /** Persian */
  Fa = 'FA',
  /** Fulah */
  Ff = 'FF',
  /** Finnish */
  Fi = 'FI',
  /** Finnish (FI) */
  FiFi = 'FI_FI',
  /** Fijian */
  Fj = 'FJ',
  /** Faroese */
  Fo = 'FO',
  /** French */
  Fr = 'FR',
  /** Western Frisian */
  Fy = 'FY',
  /** Irish */
  Ga = 'GA',
  /** Gaelic; Scottish Gaelic */
  Gd = 'GD',
  /** Galician */
  Gl = 'GL',
  /** Guarani */
  Gn = 'GN',
  /** Gujarati */
  Gu = 'GU',
  /** Manx */
  Gv = 'GV',
  /** Hausa */
  Ha = 'HA',
  /** Hebrew */
  He = 'HE',
  /** Hindi */
  Hi = 'HI',
  /** Hiri Motu */
  Ho = 'HO',
  /** Croatian */
  Hr = 'HR',
  /** Haitian; Haitian Creole */
  Ht = 'HT',
  /** Hungarian */
  Hu = 'HU',
  /** Armenian */
  Hy = 'HY',
  /** Herero */
  Hz = 'HZ',
  /** Interlingua  */
  Ia = 'IA',
  /** Indonesian */
  Id = 'ID',
  /** Interlingue; Occidental */
  Ie = 'IE',
  /** Igbo */
  Ig = 'IG',
  /** Sichuan Yi; Nuosu */
  Ii = 'II',
  /** Inupiaq */
  Ik = 'IK',
  /** Ido */
  Io = 'IO',
  /** Icelandic */
  Is = 'IS',
  /** Italian */
  It = 'IT',
  /** Italian (IT) */
  ItIt = 'IT_IT',
  /** Inuktitut */
  Iu = 'IU',
  /** Japanese */
  Ja = 'JA',
  /** Japanese (JP) */
  JaJp = 'JA_JP',
  /** Javanese */
  Jv = 'JV',
  /** Georgian */
  Ka = 'KA',
  /** Kongo */
  Kg = 'KG',
  /** Kikuyu; Gikuyu */
  Ki = 'KI',
  /** Kuanyama; Kwanyama */
  Kj = 'KJ',
  /** Kazakh */
  Kk = 'KK',
  /** Kalaallisut; Greenlandic */
  Kl = 'KL',
  /** Central Khmer */
  Km = 'KM',
  /** Kannada */
  Kn = 'KN',
  /** Korean */
  Ko = 'KO',
  /** Kanuri */
  Kr = 'KR',
  /** Kashmiri */
  Ks = 'KS',
  /** Kurdish */
  Ku = 'KU',
  /** Komi */
  Kv = 'KV',
  /** Cornish */
  Kw = 'KW',
  /** Kirghiz; Kyrgyz */
  Ky = 'KY',
  /** Latin */
  La = 'LA',
  /** Luxembourgish; Letzeburgesch */
  Lb = 'LB',
  /** Ganda */
  Lg = 'LG',
  /** Limburgan; Limburger; Limburgish */
  Li = 'LI',
  /** Lingala */
  Ln = 'LN',
  /** Lao */
  Lo = 'LO',
  /** Lithuanian */
  Lt = 'LT',
  /** Luba-Katanga */
  Lu = 'LU',
  /** Latvian */
  Lv = 'LV',
  /** Malagasy */
  Mg = 'MG',
  /** Marshallese */
  Mh = 'MH',
  /** Maori */
  Mi = 'MI',
  /** Macedonian */
  Mk = 'MK',
  /** Malayalam */
  Ml = 'ML',
  /** Mongolian */
  Mn = 'MN',
  /** Marathi */
  Mr = 'MR',
  /** Malay */
  Ms = 'MS',
  /** Maltese */
  Mt = 'MT',
  /** Burmese */
  My = 'MY',
  /** Nauru */
  Na = 'NA',
  /** Ndebele, North; North Ndebele */
  Nd = 'ND',
  /** Nepali */
  Ne = 'NE',
  /** Ndonga */
  Ng = 'NG',
  /** Dutch */
  Nl = 'NL',
  /** Dutch (NL) */
  NlNl = 'NL_NL',
  /** Norwegian */
  No = 'NO',
  /** Norwegian (NO) */
  NoNo = 'NO_NO',
  /** Ndebele, South; South Ndebele */
  Nr = 'NR',
  /** Navajo; Navaho */
  Nv = 'NV',
  /** Chichewa; Chewa; Nyanja */
  Ny = 'NY',
  /** Ojibwa */
  Oj = 'OJ',
  /** Oromo */
  Om = 'OM',
  /** Oriya */
  Or = 'OR',
  /** Ossetian; Ossetic */
  Os = 'OS',
  /** Other */
  Other = 'OTHER',
  /** Panjabi; Punjabi */
  Pa = 'PA',
  /** Pali */
  Pi = 'PI',
  /** Polish */
  Pl = 'PL',
  /** Pushto; Pashto */
  Ps = 'PS',
  /** Portuguese */
  Pt = 'PT',
  /** Quechua */
  Qu = 'QU',
  /** Romansh */
  Rm = 'RM',
  /** Rundi */
  Rn = 'RN',
  /** Romanian; Moldavian; Moldovan */
  Ro = 'RO',
  /** Russian */
  Ru = 'RU',
  /** Kinyarwanda */
  Rw = 'RW',
  /** Sanskrit */
  Sa = 'SA',
  /** Sardinian */
  Sc = 'SC',
  /** Sindhi */
  Sd = 'SD',
  /** Northern Sami */
  Se = 'SE',
  /** Sango */
  Sg = 'SG',
  /** Sinhala; Sinhalese */
  Si = 'SI',
  /** Slovak */
  Sk = 'SK',
  /** Slovenian */
  Sl = 'SL',
  /** Samoan */
  Sm = 'SM',
  /** Shona */
  Sn = 'SN',
  /** Somali */
  So = 'SO',
  /** Albanian */
  Sq = 'SQ',
  /** Serbian */
  Sr = 'SR',
  /** Swati */
  Ss = 'SS',
  /** Sotho, Southern */
  St = 'ST',
  /** Sundanese */
  Su = 'SU',
  /** Swedish */
  Sv = 'SV',
  /** Swahili */
  Sw = 'SW',
  /** Tamil */
  Ta = 'TA',
  /** Telugu */
  Te = 'TE',
  /** Tajik */
  Tg = 'TG',
  /** Thai */
  Th = 'TH',
  /** Tigrinya */
  Ti = 'TI',
  /** Turkmen */
  Tk = 'TK',
  /** Tagalog */
  Tl = 'TL',
  /** Tswana */
  Tn = 'TN',
  /** Tonga (Tonga Islands) */
  To = 'TO',
  /** Turkish */
  Tr = 'TR',
  /** Tsonga */
  Ts = 'TS',
  /** Tatar */
  Tt = 'TT',
  /** Twi */
  Tw = 'TW',
  /** Tahitian */
  Ty = 'TY',
  /** Uighur; Uyghur */
  Ug = 'UG',
  /** Ukrainian */
  Uk = 'UK',
  /** Urdu */
  Ur = 'UR',
  /** Uzbek */
  Uz = 'UZ',
  /** Venda */
  Ve = 'VE',
  /** Vietnamese */
  Vi = 'VI',
  /** Walloon */
  Wa = 'WA',
  /** Wolof */
  Wo = 'WO',
  /** Xhosa */
  Xh = 'XH',
  /** Yiddish */
  Yi = 'YI',
  /** Yoruba */
  Yo = 'YO',
  /** Zhuang; Chuang */
  Za = 'ZA',
  /** Chinese */
  Zh = 'ZH',
  /** Zulu */
  Zu = 'ZU'
}

/** An enumeration. */
export enum SongsSongOriginChoices {
  /** api */
  Api = 'API',
  /** Bulk Import */
  BulkImport = 'BULK_IMPORT',
  /** counterpoint */
  Counterpoint = 'COUNTERPOINT',
  /** GraphQL API */
  GraphqlApi = 'GRAPHQL_API'
}

export type SongtrustUserType = GrapheneNodeWithDjangoModelId & {
  activeWorkRegions: ActiveWorkRegionTypeConnection;
  billingInformation?: Maybe<BillingInformationType>;
  cancellationReason: Scalars['String']['output'];
  cancelledBy?: Maybe<UserType>;
  cancelledNotes: Scalars['String']['output'];
  cancelledOn?: Maybe<Scalars['DateTime']['output']>;
  catalogRepresentation: Scalars['String']['output'];
  city: Scalars['String']['output'];
  coPublishing: Scalars['String']['output'];
  country?: Maybe<CountryType>;
  currentDistributors?: Maybe<DistributorTypeConnection>;
  digitalDistribution: Scalars['String']['output'];
  doesAccountTypeRequireTaxAndPaymentInformation?: Maybe<Scalars['Boolean']['output']>;
  expectedManagedWriters: Scalars['String']['output'];
  favoriteDistributor?: Maybe<DistributorType>;
  frozenBy?: Maybe<UserType>;
  frozenNotes?: Maybe<Scalars['String']['output']>;
  frozenOn?: Maybe<Scalars['DateTime']['output']>;
  genres: UserGenreTypeConnection;
  hasPastPublishing?: Maybe<Scalars['Boolean']['output']>;
  hasSongs?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** Indicates if a user has been marked to verify/reverify KYC. If True, a user should be forced to verify KYC before regaining full capabilities of the client experience. If not set, is expected to receive the default KYC verification experience for existing users, which at this time is no further verification. If False, a user should not require KYC verification regardless of the default KYC verification experience. */
  isDesignatedForKycAtNextLogin?: Maybe<Scalars['Boolean']['output']>;
  isGhostedAdmin?: Maybe<Scalars['Boolean']['output']>;
  isKycVerificationRequired?: Maybe<Scalars['Boolean']['output']>;
  isPasswordChangeRequired: Scalars['Boolean']['output'];
  isPaymentAndTaxInformationComplete?: Maybe<Scalars['Boolean']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  notificationSettings?: Maybe<Array<Maybe<NotificationSettingType>>>;
  personalPubEntityWithPro: Scalars['String']['output'];
  /** Number must be in format "+[country code] [phone number]". Characters other than digits, "+", and white space are not allowed. */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  profileType: Scalars['String']['output'];
  requiresOnboarding?: Maybe<Scalars['Boolean']['output']>;
  requiresPasswordChange?: Maybe<Scalars['Boolean']['output']>;
  songwriterCountEstimation?: Maybe<Scalars['Int']['output']>;
  songwriterCrosslinks: SongWriterUserCrossLinkTypeConnection;
  state: Scalars['String']['output'];
  streamEstimate?: Maybe<Scalars['Int']['output']>;
  subscriptionPlan?: Maybe<SubscriptionPlanType>;
  user: UserType;
  youtubeOptOut?: Maybe<Scalars['Boolean']['output']>;
  youtubeOptedOutOn?: Maybe<Scalars['DateTime']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};


export type SongtrustUserTypeActiveWorkRegionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type SongtrustUserTypeCurrentDistributorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type SongtrustUserTypeGenresArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type SongtrustUserTypeSongwriterCrosslinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SongtrustUserTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SongtrustUserTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `SongtrustUserType` and its cursor. */
export type SongtrustUserTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SongtrustUserType>;
};

export type SongwriterAccessInput = {
  toRemove?: InputMaybe<Array<InputMaybe<RemoveSongwriterAccessInput>>>;
  toUpsert?: InputMaybe<Array<InputMaybe<UpsertSongwriterAccessInput>>>;
};

export type SongwriterChangeYoutubeClaimOptOut = {
  errors?: Maybe<Array<Maybe<FieldError>>>;
  songwriter?: Maybe<SongwriterType>;
};

export type SongwriterStatusLogType = {
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  latest?: Maybe<Scalars['String']['output']>;
  sentAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SongwriterType = GrapheneNodeWithDjangoModelId & {
  CommissionRate?: Maybe<Scalars['Float']['output']>;
  bio: Scalars['String']['output'];
  birthDate?: Maybe<Scalars['Date']['output']>;
  cancelledInCpDate?: Maybe<Scalars['DateTime']['output']>;
  completedModqueueReviewAt?: Maybe<Scalars['DateTime']['output']>;
  /** Assigned to writers when they're inserted in Counterpoint (starting June '15) */
  counterpointCode?: Maybe<Scalars['String']['output']>;
  cptransactionSet: TransactionTypeConnection;
  createActionType?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  createdByUser?: Maybe<UserType>;
  crosslinkUserCount?: Maybe<Scalars['Int']['output']>;
  currentStatus?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Songwriter name that can be accepted by publishing societies. */
  deliverableFirstName?: Maybe<Scalars['String']['output']>;
  /** Songwriter name that can be accepted by publishing societies. */
  deliverableLastName?: Maybe<Scalars['String']['output']>;
  /** Songwriter name that can be accepted by publishing societies. */
  deliverableMiddleName?: Maybe<Scalars['String']['output']>;
  /** A CAE or IPI number is your membership number from a PRO */
  deprecatedIpi?: Maybe<Scalars['String']['output']>;
  /** Required */
  email?: Maybe<Scalars['String']['output']>;
  enteredModqueueAt?: Maybe<Scalars['DateTime']['output']>;
  /** Deprecated field. Fetch work numbers process is no longer running */
  fetchedWorkNumbersAt?: Maybe<Scalars['Date']['output']>;
  /** Interested Party's legal first name */
  firstName?: Maybe<Scalars['String']['output']>;
  hasApplication?: Maybe<Scalars['Boolean']['output']>;
  hasLod?: Maybe<Scalars['Boolean']['output']>;
  honorific?: Maybe<InterestedpartySongWriterHonorificChoices>;
  id: Scalars['ID']['output'];
  ipi?: Maybe<Scalars['String']['output']>;
  /** Interested Party's legal last name */
  lastName?: Maybe<Scalars['String']['output']>;
  licensingPermitted: Scalars['Boolean']['output'];
  lodTosAccepted?: Maybe<Scalars['Boolean']['output']>;
  manageable?: Maybe<Scalars['Boolean']['output']>;
  managedBy?: Maybe<SongtrustUserType>;
  managingSongtrustusers?: Maybe<SongtrustUserTypeConnection>;
  managingUsers: Array<UserType>;
  /** Interested Party's legal middle name */
  middleName?: Maybe<Scalars['String']['output']>;
  modified: Scalars['DateTime']['output'];
  modifiedActionType?: Maybe<Scalars['String']['output']>;
  modifiedByUser?: Maybe<UserType>;
  payee?: Maybe<Scalars['String']['output']>;
  photoFileUrl: Scalars['String']['output'];
  postTermCollection?: Maybe<Scalars['Boolean']['output']>;
  pro?: Maybe<PerformingRightsOrganizationType>;
  proAccountId?: Maybe<Scalars['String']['output']>;
  /** If you have one */
  publishingCompany?: Maybe<PublishingCompanyType>;
  resetDate?: Maybe<Scalars['DateTime']['output']>;
  resetDescription?: Maybe<Scalars['String']['output']>;
  reviewedSongModqueueAt?: Maybe<Scalars['DateTime']['output']>;
  songownershipSet: Array<SongOwnershipType>;
  songs: SongTypeConnection;
  songsProcessingCount?: Maybe<Scalars['Int']['output']>;
  songsRegisteringCount?: Maybe<Scalars['Int']['output']>;
  songtrustuserCrosslinks: SongWriterUserCrossLinkTypeConnection;
  songtrustuserSet: SongtrustUserTypeConnection;
  songwriterType?: Maybe<InterestedpartySongWriterSongwriterTypeChoices>;
  startMoqueueReviewAt?: Maybe<Scalars['DateTime']['output']>;
  statusLog?: Maybe<SongwriterStatusLogType>;
  supplementalAuditInfo?: Maybe<Scalars['JSONString']['output']>;
  taxWithholdingRate?: Maybe<Scalars['Decimal']['output']>;
  terminationEffectiveDate?: Maybe<Scalars['Date']['output']>;
  /** The PRO that has this writer's songs in the USA */
  usRepresentation?: Maybe<PerformingRightsOrganizationType>;
  viewingSongtrustusers: SongtrustUserTypeConnection;
  viewingUsers: Array<UserType>;
  youtubeOptOut: Scalars['Boolean']['output'];
  youtubeOptedOutOn?: Maybe<Scalars['DateTime']['output']>;
};


export type SongwriterTypeCptransactionSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  transactionType?: InputMaybe<Scalars['String']['input']>;
};


export type SongwriterTypeManagingSongtrustusersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type SongwriterTypeSongsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasRecordings?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  songwriterName?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type SongwriterTypeSongtrustuserCrosslinksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type SongwriterTypeSongtrustuserSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type SongwriterTypeViewingSongtrustusersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type SongwriterTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SongwriterTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `SongwriterType` and its cursor. */
export type SongwriterTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<SongwriterType>;
};

export type SongwriterUpdateBiography = {
  errors?: Maybe<Array<Maybe<FieldError>>>;
  songwriter?: Maybe<SongwriterType>;
};

export type SongwritersUsage = {
  left?: Maybe<Scalars['Int']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  used?: Maybe<Scalars['Int']['output']>;
};

export type SortByArgumentInput = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<SortField>;
};

/** An enumeration. */
export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

/** An enumeration. */
export enum SortField {
  Created = 'created',
  ViewCount = 'view_count'
}

export type SpotifyTrackType = {
  albumTitle: Scalars['String']['output'];
  artistId: Scalars['String']['output'];
  artistName: Scalars['String']['output'];
  artworkUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isrc: Scalars['String']['output'];
  previewUrl?: Maybe<Scalars['String']['output']>;
  songTitle: Scalars['String']['output'];
  spotifyUri?: Maybe<Scalars['String']['output']>;
};

export type StateType = {
  isoCode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type StateTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<StateTypeEdge>>;
  extra?: Maybe<Scalars['String']['output']>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `StateType` and its cursor. */
export type StateTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<StateType>;
  other?: Maybe<Scalars['String']['output']>;
};

export type SubmitOnboardingProfile = {
  songtrustUser?: Maybe<SongtrustUserType>;
};

export type SubmitSongwriterAccessToAccount = {
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export type SubscriptionPlanType = {
  commissionRate?: Maybe<Scalars['Float']['output']>;
  memberCount?: Maybe<Scalars['String']['output']>;
  memberLabel?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  planCode: Scalars['String']['output'];
  planCost?: Maybe<Scalars['Float']['output']>;
  planCurrency?: Maybe<Scalars['String']['output']>;
  planFrequency?: Maybe<Scalars['String']['output']>;
  recurringFee: Scalars['Float']['output'];
  territories?: Maybe<Scalars['Boolean']['output']>;
};

export type TargetAccountInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  songtrustUserId?: InputMaybe<Scalars['Int']['input']>;
};

export type TransactionType = Node & {
  amount: Scalars['Decimal']['output'];
  /** Counterpoint Client Code */
  clientCode?: Maybe<Scalars['Int']['output']>;
  /** Counterpoint Client Name */
  clientName?: Maybe<Scalars['String']['output']>;
  /** Counterpoint Txn Description */
  description?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** Counterpoint Txn Date */
  transactionDate: Scalars['DateTime']['output'];
  /** Counterpoint Txn Type */
  transactionType?: Maybe<Scalars['String']['output']>;
};

export type TransactionTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TransactionTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `TransactionType` and its cursor. */
export type TransactionTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TransactionType>;
};

export type UsRepresentationInput = {
  name: Scalars['String']['input'];
};

export type UpdateAccountIdentity = {
  errors?: Maybe<Array<Maybe<FieldError>>>;
  songtrustUser?: Maybe<SongtrustUserType>;
};

export type UpdateAccountInformation = {
  errors?: Maybe<Array<Maybe<FieldError>>>;
  songtrustUser?: Maybe<SongtrustUserType>;
};

export type UpdateCreditCard = {
  errors?: Maybe<Array<Maybe<FieldError>>>;
};

export type UpdateNotificationSetting = {
  errors?: Maybe<Array<Maybe<FieldError>>>;
  notificationSettings?: Maybe<Array<Maybe<NotificationSettingType>>>;
};

export type UpdateSong = {
  errors?: Maybe<Array<Maybe<FieldError>>>;
  song?: Maybe<SongType>;
};

export type UpdateSongInput = {
  chorusLyrics?: InputMaybe<Scalars['String']['input']>;
  fullLyrics?: InputMaybe<Scalars['String']['input']>;
  iswc?: InputMaybe<Scalars['String']['input']>;
  language: Scalars['String']['input'];
  songId: Scalars['Int']['input'];
  title: Scalars['String']['input'];
};

export type UpdateSongwriter = {
  errors?: Maybe<Array<Maybe<FieldError>>>;
  songwriter?: Maybe<SongwriterType>;
};

export type UpdateSongwriterInput = {
  alternateNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  birthdate: Scalars['Date']['input'];
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  honorific?: InputMaybe<Scalars['String']['input']>;
  ipi: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  lodTosAccepted?: InputMaybe<Scalars['Boolean']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  pro: ProInput;
  publishingCompany?: InputMaybe<PublishingCompanyInput>;
  signature: Scalars['String']['input'];
  songwriterId: Scalars['Int']['input'];
  songwriterType?: InputMaybe<Scalars['String']['input']>;
  songwriterYoutubeClaims: Scalars['Boolean']['input'];
  usRepresentation?: InputMaybe<UsRepresentationInput>;
};

export type UpsertSongwriterAccessInput = {
  accessType: AccessType;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  songwriterId: Scalars['Int']['input'];
};

export type UserGenreType = Node & {
  choice?: Maybe<Scalars['Int']['output']>;
  created: Scalars['DateTime']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  modified: Scalars['DateTime']['output'];
  songtrustuserSet: SongtrustUserTypeConnection;
};


export type UserGenreTypeSongtrustuserSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type UserGenreTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserGenreTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserGenreType` and its cursor. */
export type UserGenreTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<UserGenreType>;
};

export type UserProfileType = {
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type UserProfileTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserProfileTypeEdge>>;
  extra?: Maybe<Scalars['String']['output']>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `UserProfileType` and its cursor. */
export type UserProfileTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<UserProfileType>;
  other?: Maybe<Scalars['String']['output']>;
};

export type UserSignupInput = {
  acceptedTermsOfUse: Scalars['Boolean']['input'];
  currency: Scalars['String']['input'];
  currencyValue: Scalars['String']['input'];
  discountCode?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  identityId?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  numberOfSongwriters: Scalars['Int']['input'];
  password: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  stripeToken: Scalars['String']['input'];
};

export type UserType = {
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean']['output'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean']['output'];
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
};

export type VerificationStatusType = {
  identityId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Verify = {
  payload: Scalars['GenericScalar']['output'];
};

export type VideoType = {
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  viewCount?: Maybe<Scalars['BigInt']['output']>;
  youtubeId?: Maybe<Scalars['String']['output']>;
};

export type YoutubeChannelType = Node & {
  channelId: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** Does this channel belong to the Vevo network */
  isVevo: Scalars['Boolean']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  youtubevideoSet: Array<VideoType>;
};

export type YoutubeChannelTypeConnection = {
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<YoutubeChannelTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `YoutubeChannelType` and its cursor. */
export type YoutubeChannelTypeEdge = {
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<YoutubeChannelType>;
};

export type UpdateAccountPasswordMutationVariables = Exact<{
  oldPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  authCode: Scalars['String']['input'];
}>;


export type UpdateAccountPasswordMutation = { changePassword?: { loggedInSongtrustUser?: { requiresPasswordChange?: boolean | null } | null, errors?: Array<{ path: Array<string | null>, error: string } | null> | null } | null };

export type GeneratePasswordAuthCodeMutationVariables = Exact<{ [key: string]: never; }>;


export type GeneratePasswordAuthCodeMutation = { generateAuthCode?: { created?: any | null } | null };

export type SubmitSongwriterAccessToAccountMutationVariables = Exact<{
  targetAccount: TargetAccountInput;
  access: SongwriterAccessInput;
}>;


export type SubmitSongwriterAccessToAccountMutation = { submitSongwriterAccessToAccount?: { errors?: Array<{ path: Array<string | null>, error: string } | null> | null } | null };

export type UpdateAccountIdentityMutationVariables = Exact<{
  identityId?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateAccountIdentityMutation = { updateAccountIdentity?: { songtrustUser?: { isKycVerificationRequired?: boolean | null } | null, errors?: Array<{ path: Array<string | null>, error: string } | null> | null } | null };

export type UpdateAccountInformationMutationVariables = Exact<{
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateAccountInformationMutation = { updateAccountInformation?: { songtrustUser?: { phoneNumber?: string | null, user: { email: string, firstName: string, lastName: string } } | null, errors?: Array<{ path: Array<string | null>, error: string } | null> | null } | null };

export type UpdateCreditCardMutationVariables = Exact<{
  stripeToken: Scalars['String']['input'];
}>;


export type UpdateCreditCardMutation = { updateCreditCard?: { errors?: Array<{ path: Array<string | null>, error: string } | null> | null } | null };

export type TransactionHistoryQueryVariables = Exact<{
  transactionType: Scalars['String']['input'];
  orderBy: Scalars['String']['input'];
  offset: Scalars['Int']['input'];
  first: Scalars['Int']['input'];
}>;


export type TransactionHistoryQuery = { loggedInSongtrustUser?: { subscriptionPlan?: { planCurrency?: string | null } | null } | null, transactions?: { totalCount?: number | null, edges: Array<{ cursor: string, node?: { amount: any, clientName?: string | null, clientCode?: number | null, description?: string | null, transactionDate: any, transactionType?: string | null } | null } | null> } | null };

export type AvailableSongwritersAccessQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  first: Scalars['Int']['input'];
  songtrustUserId?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AvailableSongwritersAccessQuery = { availableSongwritersAccess?: { songtrustUser?: { user: { firstName: string, lastName: string } } | null, songwriters?: { totalCount?: number | null, edges: Array<{ node?: { id: string, firstName?: string | null, lastName?: string | null, email?: string | null, songtrustuserCrosslinks: { edges: Array<{ node?: { id: string, accessType: InterestedpartySongWriterUserCrossLinkAccessTypeChoices, endDate?: any | null } | null } | null> } } | null } | null> } | null } | null };

export type SubscriptionPlanQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionPlanQuery = { loggedInSongtrustUser?: { billingInformation?: { last4?: string | null, expYear?: number | null, expMonth?: number | null } | null, subscriptionPlan?: { name: string, planCode: string, commissionRate?: number | null, memberCount?: string | null, memberLabel?: string | null, territories?: boolean | null, planCost?: number | null, planFrequency?: string | null, planCurrency?: string | null } | null } | null };

export type TrolleyStringQueryVariables = Exact<{ [key: string]: never; }>;


export type TrolleyStringQuery = { trolleyString?: string | null };

export type UserSongwriterAccessQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  first: Scalars['Int']['input'];
}>;


export type UserSongwriterAccessQuery = { userSongwriterAccess?: { totalCount?: number | null, edges: Array<{ node?: { user: { id: string, firstName: string, lastName: string, email: string }, songwriterCrosslinks: { edges: Array<{ node?: { id: string, songtrustuser: { id: string }, songwriter: { id: string, firstName?: string | null, middleName?: string | null, lastName?: string | null } } | null } | null> } } | null } | null> } | null };

export type FeatureFlagsAndSwitchesQueryVariables = Exact<{ [key: string]: never; }>;


export type FeatureFlagsAndSwitchesQuery = { featureFlags?: { edges: Array<{ node?: { name?: string | null, enabled?: boolean | null } | null } | null> } | null, featureSwitches?: { edges: Array<{ node?: { name?: string | null, enabled?: boolean | null } | null } | null> } | null };

export type LoggedInSongtrustUserPersonaQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInSongtrustUserPersonaQuery = { loggedInSongtrustUser?: { doesAccountTypeRequireTaxAndPaymentInformation?: boolean | null, middleName?: string | null, phoneNumber?: string | null, frozenOn?: any | null, frozenNotes?: string | null, cancelledOn?: any | null, cancelledNotes: string, cancellationReason: string, isGhostedAdmin?: boolean | null, isPaymentAndTaxInformationComplete?: boolean | null, isKycVerificationRequired?: boolean | null, requiresPasswordChange?: boolean | null, requiresOnboarding?: boolean | null, hasSongs?: boolean | null, user: { firstName: string, lastName: string, email: string, isActive: boolean }, frozenBy?: { id: string } | null } | null, featureFlags?: { edges: Array<{ node?: { name?: string | null, enabled?: boolean | null } | null } | null> } | null, featureSwitches?: { edges: Array<{ node?: { name?: string | null, enabled?: boolean | null } | null } | null> } | null };

export type DashboardDataQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardDataQuery = { randomTip?: { id: string, title: string, content?: string | null } | null, newsAndEvents?: { edges: Array<{ node?: { id: string, category?: ContentDashboardItemCategoryChoices | null, title: string, publishDate?: any | null, content?: string | null, contentUrl: string, author: string, authorUrl: string, avatar?: string | null, date: string, location: string, price?: number | null } | null } | null> } | null, accountCompletion?: { steps?: Array<{ isCompleted?: boolean | null, label?: string | null, text?: string | null, order?: number | null } | null> | null } | null, periods?: { edges: Array<{ node?: { endDate?: any | null } | null } | null> } | null };

export type DashboardEarningsQueryVariables = Exact<{
  startPeriod: Scalars['Date']['input'];
  endPeriod: Scalars['Date']['input'];
}>;


export type DashboardEarningsQuery = { earningsBetweenPeriods?: Array<{ title?: string | null, period?: any | null, total?: string | null, isEstimate?: boolean | null } | null> | null, totalEarnings?: { total?: string | null } | null };

export type LoginUserMutationVariables = Exact<{
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
  fp: Scalars['String']['input'];
}>;


export type LoginUserMutation = { revokeToken?: { revoked: number } | null, tokenAuth?: { token: string, payload: any, refreshToken: string, refreshExpiresIn: number } | null };

export type LogoutUserMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutUserMutation = { revokeToken?: { revoked: number } | null, deleteTokenCookie?: { deleted: boolean } | null, deleteRefreshTokenCookie?: { deleted: boolean } | null };

export type RefreshTokenMutationVariables = Exact<{
  fp: Scalars['String']['input'];
}>;


export type RefreshTokenMutation = { refreshToken?: { payload: any, token: string, refreshExpiresIn: number, refreshToken: string } | null };

export type ForgotPasswordMutationVariables = Exact<{
  username: Scalars['String']['input'];
}>;


export type ForgotPasswordMutation = { resetPassword?: { errors?: Array<{ path: Array<string | null>, error: string } | null> | null } | null };

export type SubmitOnboardingProfileMutationVariables = Exact<{
  onboardingProfileInput: OnboardingProfileInput;
}>;


export type SubmitOnboardingProfileMutation = { submitOnboardingProfile?: { songtrustUser?: { profileType: string, songwriterCountEstimation?: number | null, city: string, state: string, hasPastPublishing?: boolean | null, digitalDistribution: string, zipCode?: string | null, requiresOnboarding?: boolean | null, currentDistributors?: { edges: Array<{ node?: { label?: string | null, choice?: number | null } | null } | null> } | null, favoriteDistributor?: { label?: string | null, choice?: number | null } | null, activeWorkRegions: { edges: Array<{ node?: { label?: string | null, choice?: number | null } | null } | null> }, country?: { name: string, isoCode: string } | null, genres: { edges: Array<{ node?: { label?: string | null, choice?: number | null } | null } | null> } } | null } | null };

export type OnboardingFieldsQueryVariables = Exact<{
  countryCursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type OnboardingFieldsQuery = { userProfileTypes?: { edges: Array<{ node?: { id?: string | null, title?: string | null } | null } | null> } | null, userGenres?: { edges: Array<{ node?: { label?: string | null, choice?: number | null } | null } | null> } | null, states?: { edges: Array<{ node?: { name?: string | null, isoCode?: string | null } | null } | null> } | null, countries?: { pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ cursor: string, node?: { id: string, isoCode: string, name: string } | null } | null> } | null, userCountEstimationChoices?: { edges: Array<{ node?: { choice?: string | null, label?: string | null } | null } | null> } | null, streamEstimationChoices?: { edges: Array<{ node?: { choice?: string | null, label?: string | null } | null } | null> } | null, activeWorkRegions?: { edges: Array<{ node?: { choice?: number | null, label?: string | null } | null } | null> } | null, digitalDistributionChoices?: { edges: Array<{ node?: { choice?: string | null, label?: string | null } | null } | null> } | null, yesNoChoices?: { edges: Array<{ node?: { choice?: string | null, label?: string | null } | null } | null> } | null, distributorsChoices?: { edges: Array<{ node?: { choice?: number | null, label?: string | null } | null } | null> } | null, catalogRepresentationChoices?: { edges: Array<{ node?: { choice?: string | null, label?: string | null } | null } | null> } | null, expectedManagedWriterChoices?: { edges: Array<{ node?: { choice?: string | null, label?: string | null } | null } | null> } | null };

export type GeneratePlaidIdentityTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type GeneratePlaidIdentityTokenMutation = { generatePlaidIdentityToken?: { token?: string | null, identityId?: string | null } | null };

export type SignupUserMutationVariables = Exact<{
  userSignupInput: UserSignupInput;
}>;


export type SignupUserMutation = { signupUser?: { songtrustUser?: { profileType: string, songwriterCountEstimation?: number | null, user: { email: string, firstName: string, lastName: string } } | null, errors?: Array<{ path: Array<string | null>, error: string } | null> | null } | null };

export type CheckVerificationStatusQueryVariables = Exact<{
  identityId: Scalars['String']['input'];
}>;


export type CheckVerificationStatusQuery = { checkVerificationStatus?: { status?: string | null, identityId?: string | null } | null };

export type VerifyDiscountCodeQueryVariables = Exact<{
  discountCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type VerifyDiscountCodeQuery = { verifyDiscountCode?: { code: string, discount: any, isValid?: boolean | null } | null };

export type CreateRecordingMutationVariables = Exact<{
  input: RecordingInput;
}>;


export type CreateRecordingMutation = { createRecording?: { recording?: { id: string, isrc?: string | null, song: { id: string } } | null, errors?: Array<{ error: string, path: Array<string | null> } | null> | null } | null };

export type DeleteRecordingMutationVariables = Exact<{
  recordingId: Scalars['Int']['input'];
}>;


export type DeleteRecordingMutation = { deleteRecording?: { errors?: Array<{ path: Array<string | null>, error: string } | null> | null } | null };

export type CreateSongMutationVariables = Exact<{
  song: CreateSongInput;
}>;


export type CreateSongMutation = { createSong?: { song?: { id: string, title: string, alternateTitles?: Array<{ title: string } | null> | null, ownerships?: Array<{ songwriter?: { firstName?: string | null, middleName?: string | null, lastName?: string | null } | null } | null> | null } | null, errors?: Array<{ error: string, path: Array<string | null> } | null> | null } | null };

export type SongUpdateMutationVariables = Exact<{
  song: UpdateSongInput;
}>;


export type SongUpdateMutation = { updateSong?: { song?: { id: string, title: string, iswc?: string | null, language: SongsSongLanguageChoices, chorusLyrics: string, fullLyrics: string } | null, errors?: Array<{ error: string } | null> | null } | null };

export type CopySplitsSongsQueryVariables = Exact<{
  searchTerm: Scalars['String']['input'];
}>;


export type CopySplitsSongsQuery = { songs?: { edges: Array<{ node?: { id: string, title: string, ownerships?: Array<{ percent: any, songwriter?: { id: string, firstName?: string | null, middleName?: string | null, lastName?: string | null, songwriterType?: InterestedpartySongWriterSongwriterTypeChoices | null } | null } | null> | null } | null } | null> } | null };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { loggedInSongtrustUser?: { id: string, user: { id: string, email: string } } | null };

export type OwnershipsByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type OwnershipsByIdQuery = { song?: { ownerships?: Array<{ percent: any, songwriter?: { id: string, firstName?: string | null, middleName?: string | null, lastName?: string | null, songwriterType?: InterestedpartySongWriterSongwriterTypeChoices | null } | null } | null> | null } | null };

export type PerformingRightsOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type PerformingRightsOrganizationsQuery = { performingRightsOrganizations?: { edges: Array<{ node?: { id: string, name: string, counterpointCode?: string | null } | null } | null> } | null };

export type SongByIdQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type SongByIdQuery = { song?: { id: string, title: string, publicDomain: boolean, displayStatus?: string | null, iswc?: string | null, fullLyrics: string, chorusLyrics: string, language: SongsSongLanguageChoices, alternatetitleSet: Array<{ title: string }>, displayStatusLog?: { qualified?: any | null, monitoring?: any | null, processing?: any | null, registering?: any | null, sent?: any | null } | null, ownerships?: Array<{ percent: any, songwriter?: { id: string, firstName?: string | null, middleName?: string | null, lastName?: string | null, songwriterType?: InterestedpartySongWriterSongwriterTypeChoices | null } | null } | null> | null, recordings: { totalCount?: number | null, edges: Array<{ node?: { id: string, name: string, spotifyTrackId?: string | null, spotifyUrl?: string | null, spotifyPreviewUrl?: string | null, isrc?: string | null, artists: Array<{ name?: string | null }> } | null } | null> } } | null };

export type SongsByTitleQueryVariables = Exact<{
  title_Icontains: Scalars['String']['input'];
  offset: Scalars['Int']['input'];
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Scalars['String']['input']>;
}>;


export type SongsByTitleQuery = { songs?: { totalCount?: number | null, edges: Array<{ node?: { id: string, title: string, displayStatus?: string | null, created: any, songwriterSet: { edges: Array<{ node?: { firstName?: string | null, lastName?: string | null } | null } | null> }, recordings: { totalCount?: number | null } } | null } | null> } | null };

export type SongSongwritersQueryVariables = Exact<{
  searchFor?: InputMaybe<Scalars['String']['input']>;
  songwriterType?: InputMaybe<InterestedpartySongWriterSongwriterTypeChoices>;
}>;


export type SongSongwritersQuery = { songwriters?: { edges: Array<{ node?: { id: string, firstName?: string | null, middleName?: string | null, lastName?: string | null, songwriterType?: InterestedpartySongWriterSongwriterTypeChoices | null, payee?: string | null, managedBy?: { user: { email: string, id: string } } | null, publishingCompany?: { name: string } | null } | null } | null> } | null };

export type TracksQueryVariables = Exact<{
  title: Scalars['String']['input'];
  artist: Scalars['String']['input'];
}>;


export type TracksQuery = { tracks?: Array<{ id: string, albumTitle: string, artistName: string, artistId: string, artworkUrl?: string | null, isrc: string, songTitle: string, spotifyUri?: string | null, previewUrl?: string | null } | null> | null };

export type AddSongwriterCreditsMutationVariables = Exact<{
  discountCode?: InputMaybe<Scalars['String']['input']>;
  credits?: InputMaybe<Scalars['Int']['input']>;
}>;


export type AddSongwriterCreditsMutation = { addSongwriterCredits?: { errors?: Array<{ error: string, path: Array<string | null> } | null> | null } | null };

export type CreateClientSongwriterMutationVariables = Exact<{
  input: ClientSongwriterInput;
}>;


export type CreateClientSongwriterMutation = { createClientSongwriter?: { songwriter?: { id: string, firstName?: string | null, middleName?: string | null, lastName?: string | null } | null, errors?: Array<{ path: Array<string | null>, error: string } | null> | null } | null };

export type CreateOutsideSongwriterMutationVariables = Exact<{
  input: OutsideSongwriterInput;
}>;


export type CreateOutsideSongwriterMutation = { createOutsideSongwriter?: { songwriter?: { id: string, firstName?: string | null, middleName?: string | null, lastName?: string | null, email?: string | null } | null, errors?: Array<{ path: Array<string | null>, error: string } | null> | null } | null };

export type SongwriterUpdateBiographyMutationVariables = Exact<{
  songwriterId: Scalars['Int']['input'];
  bio: Scalars['String']['input'];
}>;


export type SongwriterUpdateBiographyMutation = { songwriterUpdateBiography?: { songwriter?: { bio: string } | null, errors?: Array<{ path: Array<string | null>, error: string } | null> | null } | null };

export type SongwriterUpdateMutationVariables = Exact<{
  songwriter: UpdateSongwriterInput;
}>;


export type SongwriterUpdateMutation = { updateSongwriter?: { songwriter?: { id: string, firstName?: string | null, middleName?: string | null, lastName?: string | null, email?: string | null, birthDate?: any | null, youtubeOptOut: boolean, ipi?: string | null, usRepresentation?: { name: string } | null, pro?: { name: string } | null, publishingCompany?: { name: string, ipi?: string | null, pro?: { name: string } | null } | null } | null, errors?: Array<{ error: string } | null> | null } | null };

export type SongwriterChangeYoutubeClaimOutoutMutationVariables = Exact<{
  songwriterId: Scalars['Int']['input'];
  optOut: Scalars['Boolean']['input'];
}>;


export type SongwriterChangeYoutubeClaimOutoutMutation = { songwriterChangeYoutubeClaimOptOut?: { songwriter?: { youtubeOptOut: boolean, youtubeOptedOutOn?: any | null } | null, errors?: Array<{ path: Array<string | null>, error: string } | null> | null } | null };

export type CountriesQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type CountriesQuery = { countries?: { edges: Array<{ node?: { id: string, isoCode: string, name: string } | null } | null>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };

export type SongwriterQueryVariables = Exact<{
  songwriterId: Scalars['Int']['input'];
}>;


export type SongwriterQuery = { songwriter?: { id: string, firstName?: string | null, middleName?: string | null, lastName?: string | null, email?: string | null, birthDate?: any | null, created: any, currentStatus?: Array<string | null> | null, manageable?: boolean | null, hasApplication?: boolean | null, hasLod?: boolean | null, youtubeOptOut: boolean, songsRegisteringCount?: number | null, ipi?: string | null, bio: string, statusLog?: { sentAt?: any | null, approvedAt?: any | null, latest?: string | null } | null, usRepresentation?: { name: string } | null, pro?: { name: string } | null, publishingCompany?: { name: string, ipi?: string | null, pro?: { name: string } | null } | null } | null };

export type SongwritersQueryVariables = Exact<{
  songwriterType: InterestedpartySongWriterSongwriterTypeChoices;
  searchFor: Scalars['String']['input'];
  offset: Scalars['Int']['input'];
  first: Scalars['Int']['input'];
}>;


export type SongwritersQuery = { songwritersUsage?: { limit?: number | null, used?: number | null, left?: number | null } | null, loggedInSongtrustUser?: { youtubeOptOut?: boolean | null, youtubeOptedOutOn?: any | null } | null, songwriters?: { totalCount?: number | null, edges: Array<{ node?: { id: string, firstName?: string | null, middleName?: string | null, lastName?: string | null, payee?: string | null, email?: string | null, created: any, currentStatus?: Array<string | null> | null, youtubeOptOut: boolean, youtubeOptedOutOn?: any | null, manageable?: boolean | null, crosslinkUserCount?: number | null, hasLod?: boolean | null, hasApplication?: boolean | null, pro?: { name: string } | null, statusLog?: { latest?: string | null } | null, publishingCompany?: { name: string } | null } | null } | null> } | null };

export type RemainingSongwritersUsageQueryVariables = Exact<{ [key: string]: never; }>;


export type RemainingSongwritersUsageQuery = { songwritersUsage?: { limit?: number | null, used?: number | null, left?: number | null } | null };

export type AddYoutubeChannelMutationVariables = Exact<{
  channelUrl: Scalars['String']['input'];
}>;


export type AddYoutubeChannelMutation = { addYoutubeChannel?: { channel?: { id: string, title: string, channelId: string } | null, errors?: Array<{ error: string } | null> | null } | null };

export type RemoveYoutubeChannelMutationVariables = Exact<{
  channelId: Scalars['String']['input'];
}>;


export type RemoveYoutubeChannelMutation = { removeYoutubeChannel?: { channel?: { title: string } | null, errors?: Array<{ error: string } | null> | null } | null };

export type ClaimedVideosQueryVariables = Exact<{
  sortBy: SortByArgumentInput;
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ClaimedVideosQuery = { claimedVideos?: { edges: Array<{ node?: { id: string, created: any, start?: string | null, end?: string | null, video: { id: string, title: string, viewCount?: any | null, youtubeId?: string | null }, song: { id: string, title: string } } | null } | null>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };

export type YoutubeChannelsQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
}>;


export type YoutubeChannelsQuery = { youtubeChannels?: { totalCount?: number | null, edges: Array<{ node?: { id: string, title: string, channelId: string } | null } | null>, pageInfo: { hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null } } | null };


export const UpdateAccountPasswordDocument = gql`
    mutation updateAccountPassword($oldPassword: String!, $newPassword: String!, $authCode: String!) {
  changePassword(
    oldPassword: $oldPassword
    newPassword: $newPassword
    authCode: $authCode
  ) {
    loggedInSongtrustUser {
      requiresPasswordChange
    }
    errors {
      path
      error
    }
  }
}
    `;
export type UpdateAccountPasswordMutationFn = Apollo.MutationFunction<UpdateAccountPasswordMutation, UpdateAccountPasswordMutationVariables>;

/**
 * __useUpdateAccountPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateAccountPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountPasswordMutation, { data, loading, error }] = useUpdateAccountPasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *      authCode: // value for 'authCode'
 *   },
 * });
 */
export function useUpdateAccountPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountPasswordMutation, UpdateAccountPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountPasswordMutation, UpdateAccountPasswordMutationVariables>(UpdateAccountPasswordDocument, options);
      }
export type UpdateAccountPasswordMutationHookResult = ReturnType<typeof useUpdateAccountPasswordMutation>;
export type UpdateAccountPasswordMutationResult = Apollo.MutationResult<UpdateAccountPasswordMutation>;
export type UpdateAccountPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateAccountPasswordMutation, UpdateAccountPasswordMutationVariables>;
export const GeneratePasswordAuthCodeDocument = gql`
    mutation generatePasswordAuthCode {
  generateAuthCode {
    created
  }
}
    `;
export type GeneratePasswordAuthCodeMutationFn = Apollo.MutationFunction<GeneratePasswordAuthCodeMutation, GeneratePasswordAuthCodeMutationVariables>;

/**
 * __useGeneratePasswordAuthCodeMutation__
 *
 * To run a mutation, you first call `useGeneratePasswordAuthCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePasswordAuthCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePasswordAuthCodeMutation, { data, loading, error }] = useGeneratePasswordAuthCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useGeneratePasswordAuthCodeMutation(baseOptions?: Apollo.MutationHookOptions<GeneratePasswordAuthCodeMutation, GeneratePasswordAuthCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GeneratePasswordAuthCodeMutation, GeneratePasswordAuthCodeMutationVariables>(GeneratePasswordAuthCodeDocument, options);
      }
export type GeneratePasswordAuthCodeMutationHookResult = ReturnType<typeof useGeneratePasswordAuthCodeMutation>;
export type GeneratePasswordAuthCodeMutationResult = Apollo.MutationResult<GeneratePasswordAuthCodeMutation>;
export type GeneratePasswordAuthCodeMutationOptions = Apollo.BaseMutationOptions<GeneratePasswordAuthCodeMutation, GeneratePasswordAuthCodeMutationVariables>;
export const SubmitSongwriterAccessToAccountDocument = gql`
    mutation submitSongwriterAccessToAccount($targetAccount: TargetAccountInput!, $access: SongwriterAccessInput!) {
  submitSongwriterAccessToAccount(
    targetAccount: $targetAccount
    songwriterAccesses: $access
  ) {
    errors {
      path
      error
    }
  }
}
    `;
export type SubmitSongwriterAccessToAccountMutationFn = Apollo.MutationFunction<SubmitSongwriterAccessToAccountMutation, SubmitSongwriterAccessToAccountMutationVariables>;

/**
 * __useSubmitSongwriterAccessToAccountMutation__
 *
 * To run a mutation, you first call `useSubmitSongwriterAccessToAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitSongwriterAccessToAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitSongwriterAccessToAccountMutation, { data, loading, error }] = useSubmitSongwriterAccessToAccountMutation({
 *   variables: {
 *      targetAccount: // value for 'targetAccount'
 *      access: // value for 'access'
 *   },
 * });
 */
export function useSubmitSongwriterAccessToAccountMutation(baseOptions?: Apollo.MutationHookOptions<SubmitSongwriterAccessToAccountMutation, SubmitSongwriterAccessToAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitSongwriterAccessToAccountMutation, SubmitSongwriterAccessToAccountMutationVariables>(SubmitSongwriterAccessToAccountDocument, options);
      }
export type SubmitSongwriterAccessToAccountMutationHookResult = ReturnType<typeof useSubmitSongwriterAccessToAccountMutation>;
export type SubmitSongwriterAccessToAccountMutationResult = Apollo.MutationResult<SubmitSongwriterAccessToAccountMutation>;
export type SubmitSongwriterAccessToAccountMutationOptions = Apollo.BaseMutationOptions<SubmitSongwriterAccessToAccountMutation, SubmitSongwriterAccessToAccountMutationVariables>;
export const UpdateAccountIdentityDocument = gql`
    mutation UpdateAccountIdentity($identityId: String) {
  updateAccountIdentity(identityId: $identityId) {
    songtrustUser {
      isKycVerificationRequired
    }
    errors {
      path
      error
    }
  }
}
    `;
export type UpdateAccountIdentityMutationFn = Apollo.MutationFunction<UpdateAccountIdentityMutation, UpdateAccountIdentityMutationVariables>;

/**
 * __useUpdateAccountIdentityMutation__
 *
 * To run a mutation, you first call `useUpdateAccountIdentityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountIdentityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountIdentityMutation, { data, loading, error }] = useUpdateAccountIdentityMutation({
 *   variables: {
 *      identityId: // value for 'identityId'
 *   },
 * });
 */
export function useUpdateAccountIdentityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountIdentityMutation, UpdateAccountIdentityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountIdentityMutation, UpdateAccountIdentityMutationVariables>(UpdateAccountIdentityDocument, options);
      }
export type UpdateAccountIdentityMutationHookResult = ReturnType<typeof useUpdateAccountIdentityMutation>;
export type UpdateAccountIdentityMutationResult = Apollo.MutationResult<UpdateAccountIdentityMutation>;
export type UpdateAccountIdentityMutationOptions = Apollo.BaseMutationOptions<UpdateAccountIdentityMutation, UpdateAccountIdentityMutationVariables>;
export const UpdateAccountInformationDocument = gql`
    mutation UpdateAccountInformation($firstName: String, $lastName: String, $phoneNumber: String) {
  updateAccountInformation(
    firstName: $firstName
    lastName: $lastName
    phoneNumber: $phoneNumber
  ) {
    songtrustUser {
      user {
        email
        firstName
        lastName
      }
      phoneNumber
    }
    errors {
      path
      error
    }
  }
}
    `;
export type UpdateAccountInformationMutationFn = Apollo.MutationFunction<UpdateAccountInformationMutation, UpdateAccountInformationMutationVariables>;

/**
 * __useUpdateAccountInformationMutation__
 *
 * To run a mutation, you first call `useUpdateAccountInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountInformationMutation, { data, loading, error }] = useUpdateAccountInformationMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useUpdateAccountInformationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountInformationMutation, UpdateAccountInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountInformationMutation, UpdateAccountInformationMutationVariables>(UpdateAccountInformationDocument, options);
      }
export type UpdateAccountInformationMutationHookResult = ReturnType<typeof useUpdateAccountInformationMutation>;
export type UpdateAccountInformationMutationResult = Apollo.MutationResult<UpdateAccountInformationMutation>;
export type UpdateAccountInformationMutationOptions = Apollo.BaseMutationOptions<UpdateAccountInformationMutation, UpdateAccountInformationMutationVariables>;
export const UpdateCreditCardDocument = gql`
    mutation updateCreditCard($stripeToken: String!) {
  updateCreditCard(stripeToken: $stripeToken) {
    errors {
      path
      error
    }
  }
}
    `;
export type UpdateCreditCardMutationFn = Apollo.MutationFunction<UpdateCreditCardMutation, UpdateCreditCardMutationVariables>;

/**
 * __useUpdateCreditCardMutation__
 *
 * To run a mutation, you first call `useUpdateCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreditCardMutation, { data, loading, error }] = useUpdateCreditCardMutation({
 *   variables: {
 *      stripeToken: // value for 'stripeToken'
 *   },
 * });
 */
export function useUpdateCreditCardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCreditCardMutation, UpdateCreditCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCreditCardMutation, UpdateCreditCardMutationVariables>(UpdateCreditCardDocument, options);
      }
export type UpdateCreditCardMutationHookResult = ReturnType<typeof useUpdateCreditCardMutation>;
export type UpdateCreditCardMutationResult = Apollo.MutationResult<UpdateCreditCardMutation>;
export type UpdateCreditCardMutationOptions = Apollo.BaseMutationOptions<UpdateCreditCardMutation, UpdateCreditCardMutationVariables>;
export const TransactionHistoryDocument = gql`
    query transactionHistory($transactionType: String!, $orderBy: String!, $offset: Int!, $first: Int!) {
  loggedInSongtrustUser {
    subscriptionPlan {
      planCurrency
    }
  }
  transactions(
    transactionType: $transactionType
    orderBy: $orderBy
    offset: $offset
    first: $first
  ) {
    totalCount
    edges {
      cursor
      node {
        amount
        clientName
        clientCode
        description
        transactionDate
        transactionType
      }
    }
  }
}
    `;

/**
 * __useTransactionHistoryQuery__
 *
 * To run a query within a React component, call `useTransactionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionHistoryQuery({
 *   variables: {
 *      transactionType: // value for 'transactionType'
 *      orderBy: // value for 'orderBy'
 *      offset: // value for 'offset'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useTransactionHistoryQuery(baseOptions: Apollo.QueryHookOptions<TransactionHistoryQuery, TransactionHistoryQueryVariables> & ({ variables: TransactionHistoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionHistoryQuery, TransactionHistoryQueryVariables>(TransactionHistoryDocument, options);
      }
export function useTransactionHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionHistoryQuery, TransactionHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionHistoryQuery, TransactionHistoryQueryVariables>(TransactionHistoryDocument, options);
        }
export function useTransactionHistorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TransactionHistoryQuery, TransactionHistoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TransactionHistoryQuery, TransactionHistoryQueryVariables>(TransactionHistoryDocument, options);
        }
export type TransactionHistoryQueryHookResult = ReturnType<typeof useTransactionHistoryQuery>;
export type TransactionHistoryLazyQueryHookResult = ReturnType<typeof useTransactionHistoryLazyQuery>;
export type TransactionHistorySuspenseQueryHookResult = ReturnType<typeof useTransactionHistorySuspenseQuery>;
export type TransactionHistoryQueryResult = Apollo.QueryResult<TransactionHistoryQuery, TransactionHistoryQueryVariables>;
export function refetchTransactionHistoryQuery(variables: TransactionHistoryQueryVariables) {
      return { query: TransactionHistoryDocument, variables: variables }
    }
export const AvailableSongwritersAccessDocument = gql`
    query availableSongwritersAccess($offset: Int!, $first: Int!, $songtrustUserId: Int) {
  availableSongwritersAccess(songtrustUserId: $songtrustUserId) {
    songtrustUser {
      user {
        firstName
        lastName
      }
    }
    songwriters(offset: $offset, first: $first) {
      totalCount
      edges {
        node {
          id
          firstName
          lastName
          email
          songtrustuserCrosslinks {
            edges {
              node {
                id
                accessType
                endDate
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAvailableSongwritersAccessQuery__
 *
 * To run a query within a React component, call `useAvailableSongwritersAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableSongwritersAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableSongwritersAccessQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      first: // value for 'first'
 *      songtrustUserId: // value for 'songtrustUserId'
 *   },
 * });
 */
export function useAvailableSongwritersAccessQuery(baseOptions: Apollo.QueryHookOptions<AvailableSongwritersAccessQuery, AvailableSongwritersAccessQueryVariables> & ({ variables: AvailableSongwritersAccessQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AvailableSongwritersAccessQuery, AvailableSongwritersAccessQueryVariables>(AvailableSongwritersAccessDocument, options);
      }
export function useAvailableSongwritersAccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AvailableSongwritersAccessQuery, AvailableSongwritersAccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AvailableSongwritersAccessQuery, AvailableSongwritersAccessQueryVariables>(AvailableSongwritersAccessDocument, options);
        }
export function useAvailableSongwritersAccessSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AvailableSongwritersAccessQuery, AvailableSongwritersAccessQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AvailableSongwritersAccessQuery, AvailableSongwritersAccessQueryVariables>(AvailableSongwritersAccessDocument, options);
        }
export type AvailableSongwritersAccessQueryHookResult = ReturnType<typeof useAvailableSongwritersAccessQuery>;
export type AvailableSongwritersAccessLazyQueryHookResult = ReturnType<typeof useAvailableSongwritersAccessLazyQuery>;
export type AvailableSongwritersAccessSuspenseQueryHookResult = ReturnType<typeof useAvailableSongwritersAccessSuspenseQuery>;
export type AvailableSongwritersAccessQueryResult = Apollo.QueryResult<AvailableSongwritersAccessQuery, AvailableSongwritersAccessQueryVariables>;
export function refetchAvailableSongwritersAccessQuery(variables: AvailableSongwritersAccessQueryVariables) {
      return { query: AvailableSongwritersAccessDocument, variables: variables }
    }
export const SubscriptionPlanDocument = gql`
    query subscriptionPlan {
  loggedInSongtrustUser {
    billingInformation {
      last4
      expYear
      expMonth
    }
    subscriptionPlan {
      name
      planCode
      commissionRate
      memberCount
      memberLabel
      territories
      planCost
      planFrequency
      planCurrency
    }
  }
}
    `;

/**
 * __useSubscriptionPlanQuery__
 *
 * To run a query within a React component, call `useSubscriptionPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionPlanQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionPlanQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>(SubscriptionPlanDocument, options);
      }
export function useSubscriptionPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>(SubscriptionPlanDocument, options);
        }
export function useSubscriptionPlanSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>(SubscriptionPlanDocument, options);
        }
export type SubscriptionPlanQueryHookResult = ReturnType<typeof useSubscriptionPlanQuery>;
export type SubscriptionPlanLazyQueryHookResult = ReturnType<typeof useSubscriptionPlanLazyQuery>;
export type SubscriptionPlanSuspenseQueryHookResult = ReturnType<typeof useSubscriptionPlanSuspenseQuery>;
export type SubscriptionPlanQueryResult = Apollo.QueryResult<SubscriptionPlanQuery, SubscriptionPlanQueryVariables>;
export function refetchSubscriptionPlanQuery(variables?: SubscriptionPlanQueryVariables) {
      return { query: SubscriptionPlanDocument, variables: variables }
    }
export const TrolleyStringDocument = gql`
    query TrolleyString {
  trolleyString
}
    `;

/**
 * __useTrolleyStringQuery__
 *
 * To run a query within a React component, call `useTrolleyStringQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrolleyStringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrolleyStringQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrolleyStringQuery(baseOptions?: Apollo.QueryHookOptions<TrolleyStringQuery, TrolleyStringQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrolleyStringQuery, TrolleyStringQueryVariables>(TrolleyStringDocument, options);
      }
export function useTrolleyStringLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrolleyStringQuery, TrolleyStringQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrolleyStringQuery, TrolleyStringQueryVariables>(TrolleyStringDocument, options);
        }
export function useTrolleyStringSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TrolleyStringQuery, TrolleyStringQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TrolleyStringQuery, TrolleyStringQueryVariables>(TrolleyStringDocument, options);
        }
export type TrolleyStringQueryHookResult = ReturnType<typeof useTrolleyStringQuery>;
export type TrolleyStringLazyQueryHookResult = ReturnType<typeof useTrolleyStringLazyQuery>;
export type TrolleyStringSuspenseQueryHookResult = ReturnType<typeof useTrolleyStringSuspenseQuery>;
export type TrolleyStringQueryResult = Apollo.QueryResult<TrolleyStringQuery, TrolleyStringQueryVariables>;
export function refetchTrolleyStringQuery(variables?: TrolleyStringQueryVariables) {
      return { query: TrolleyStringDocument, variables: variables }
    }
export const UserSongwriterAccessDocument = gql`
    query userSongwriterAccess($offset: Int!, $first: Int!) {
  userSongwriterAccess(offset: $offset, first: $first) {
    totalCount
    edges {
      node {
        user {
          id
          firstName
          lastName
          email
        }
        songwriterCrosslinks {
          edges {
            node {
              id
              songtrustuser {
                id
              }
              songwriter {
                id
                firstName
                middleName
                lastName
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useUserSongwriterAccessQuery__
 *
 * To run a query within a React component, call `useUserSongwriterAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSongwriterAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSongwriterAccessQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useUserSongwriterAccessQuery(baseOptions: Apollo.QueryHookOptions<UserSongwriterAccessQuery, UserSongwriterAccessQueryVariables> & ({ variables: UserSongwriterAccessQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserSongwriterAccessQuery, UserSongwriterAccessQueryVariables>(UserSongwriterAccessDocument, options);
      }
export function useUserSongwriterAccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSongwriterAccessQuery, UserSongwriterAccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserSongwriterAccessQuery, UserSongwriterAccessQueryVariables>(UserSongwriterAccessDocument, options);
        }
export function useUserSongwriterAccessSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserSongwriterAccessQuery, UserSongwriterAccessQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserSongwriterAccessQuery, UserSongwriterAccessQueryVariables>(UserSongwriterAccessDocument, options);
        }
export type UserSongwriterAccessQueryHookResult = ReturnType<typeof useUserSongwriterAccessQuery>;
export type UserSongwriterAccessLazyQueryHookResult = ReturnType<typeof useUserSongwriterAccessLazyQuery>;
export type UserSongwriterAccessSuspenseQueryHookResult = ReturnType<typeof useUserSongwriterAccessSuspenseQuery>;
export type UserSongwriterAccessQueryResult = Apollo.QueryResult<UserSongwriterAccessQuery, UserSongwriterAccessQueryVariables>;
export function refetchUserSongwriterAccessQuery(variables: UserSongwriterAccessQueryVariables) {
      return { query: UserSongwriterAccessDocument, variables: variables }
    }
export const FeatureFlagsAndSwitchesDocument = gql`
    query featureFlagsAndSwitches {
  featureFlags {
    edges {
      node {
        name
        enabled
      }
    }
  }
  featureSwitches {
    edges {
      node {
        name
        enabled
      }
    }
  }
}
    `;

/**
 * __useFeatureFlagsAndSwitchesQuery__
 *
 * To run a query within a React component, call `useFeatureFlagsAndSwitchesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeatureFlagsAndSwitchesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeatureFlagsAndSwitchesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeatureFlagsAndSwitchesQuery(baseOptions?: Apollo.QueryHookOptions<FeatureFlagsAndSwitchesQuery, FeatureFlagsAndSwitchesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeatureFlagsAndSwitchesQuery, FeatureFlagsAndSwitchesQueryVariables>(FeatureFlagsAndSwitchesDocument, options);
      }
export function useFeatureFlagsAndSwitchesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeatureFlagsAndSwitchesQuery, FeatureFlagsAndSwitchesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeatureFlagsAndSwitchesQuery, FeatureFlagsAndSwitchesQueryVariables>(FeatureFlagsAndSwitchesDocument, options);
        }
export function useFeatureFlagsAndSwitchesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FeatureFlagsAndSwitchesQuery, FeatureFlagsAndSwitchesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FeatureFlagsAndSwitchesQuery, FeatureFlagsAndSwitchesQueryVariables>(FeatureFlagsAndSwitchesDocument, options);
        }
export type FeatureFlagsAndSwitchesQueryHookResult = ReturnType<typeof useFeatureFlagsAndSwitchesQuery>;
export type FeatureFlagsAndSwitchesLazyQueryHookResult = ReturnType<typeof useFeatureFlagsAndSwitchesLazyQuery>;
export type FeatureFlagsAndSwitchesSuspenseQueryHookResult = ReturnType<typeof useFeatureFlagsAndSwitchesSuspenseQuery>;
export type FeatureFlagsAndSwitchesQueryResult = Apollo.QueryResult<FeatureFlagsAndSwitchesQuery, FeatureFlagsAndSwitchesQueryVariables>;
export function refetchFeatureFlagsAndSwitchesQuery(variables?: FeatureFlagsAndSwitchesQueryVariables) {
      return { query: FeatureFlagsAndSwitchesDocument, variables: variables }
    }
export const LoggedInSongtrustUserPersonaDocument = gql`
    query loggedInSongtrustUserPersona {
  loggedInSongtrustUser {
    user {
      firstName
      lastName
      email
      isActive
    }
    doesAccountTypeRequireTaxAndPaymentInformation
    middleName
    phoneNumber
    frozenBy {
      id
    }
    frozenOn
    frozenNotes
    cancelledOn
    cancelledNotes
    cancellationReason
    isGhostedAdmin
    isPaymentAndTaxInformationComplete
    isKycVerificationRequired
    requiresPasswordChange
    requiresOnboarding
    hasSongs
  }
  featureFlags {
    edges {
      node {
        name
        enabled
      }
    }
  }
  featureSwitches {
    edges {
      node {
        name
        enabled
      }
    }
  }
}
    `;

/**
 * __useLoggedInSongtrustUserPersonaQuery__
 *
 * To run a query within a React component, call `useLoggedInSongtrustUserPersonaQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInSongtrustUserPersonaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInSongtrustUserPersonaQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInSongtrustUserPersonaQuery(baseOptions?: Apollo.QueryHookOptions<LoggedInSongtrustUserPersonaQuery, LoggedInSongtrustUserPersonaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoggedInSongtrustUserPersonaQuery, LoggedInSongtrustUserPersonaQueryVariables>(LoggedInSongtrustUserPersonaDocument, options);
      }
export function useLoggedInSongtrustUserPersonaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoggedInSongtrustUserPersonaQuery, LoggedInSongtrustUserPersonaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoggedInSongtrustUserPersonaQuery, LoggedInSongtrustUserPersonaQueryVariables>(LoggedInSongtrustUserPersonaDocument, options);
        }
export function useLoggedInSongtrustUserPersonaSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LoggedInSongtrustUserPersonaQuery, LoggedInSongtrustUserPersonaQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LoggedInSongtrustUserPersonaQuery, LoggedInSongtrustUserPersonaQueryVariables>(LoggedInSongtrustUserPersonaDocument, options);
        }
export type LoggedInSongtrustUserPersonaQueryHookResult = ReturnType<typeof useLoggedInSongtrustUserPersonaQuery>;
export type LoggedInSongtrustUserPersonaLazyQueryHookResult = ReturnType<typeof useLoggedInSongtrustUserPersonaLazyQuery>;
export type LoggedInSongtrustUserPersonaSuspenseQueryHookResult = ReturnType<typeof useLoggedInSongtrustUserPersonaSuspenseQuery>;
export type LoggedInSongtrustUserPersonaQueryResult = Apollo.QueryResult<LoggedInSongtrustUserPersonaQuery, LoggedInSongtrustUserPersonaQueryVariables>;
export function refetchLoggedInSongtrustUserPersonaQuery(variables?: LoggedInSongtrustUserPersonaQueryVariables) {
      return { query: LoggedInSongtrustUserPersonaDocument, variables: variables }
    }
export const DashboardDataDocument = gql`
    query DashboardData {
  randomTip {
    id
    title
    content
  }
  newsAndEvents(first: 5) {
    edges {
      node {
        id
        category
        title
        publishDate
        content
        contentUrl
        author
        authorUrl
        avatar
        date
        location
        price
      }
    }
  }
  accountCompletion {
    steps {
      isCompleted
      label
      text
      order
    }
  }
  periods(last: 3) {
    edges {
      node {
        endDate
      }
    }
  }
}
    `;

/**
 * __useDashboardDataQuery__
 *
 * To run a query within a React component, call `useDashboardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardDataQuery(baseOptions?: Apollo.QueryHookOptions<DashboardDataQuery, DashboardDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardDataQuery, DashboardDataQueryVariables>(DashboardDataDocument, options);
      }
export function useDashboardDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardDataQuery, DashboardDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardDataQuery, DashboardDataQueryVariables>(DashboardDataDocument, options);
        }
export function useDashboardDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DashboardDataQuery, DashboardDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DashboardDataQuery, DashboardDataQueryVariables>(DashboardDataDocument, options);
        }
export type DashboardDataQueryHookResult = ReturnType<typeof useDashboardDataQuery>;
export type DashboardDataLazyQueryHookResult = ReturnType<typeof useDashboardDataLazyQuery>;
export type DashboardDataSuspenseQueryHookResult = ReturnType<typeof useDashboardDataSuspenseQuery>;
export type DashboardDataQueryResult = Apollo.QueryResult<DashboardDataQuery, DashboardDataQueryVariables>;
export function refetchDashboardDataQuery(variables?: DashboardDataQueryVariables) {
      return { query: DashboardDataDocument, variables: variables }
    }
export const DashboardEarningsDocument = gql`
    query DashboardEarnings($startPeriod: Date!, $endPeriod: Date!) {
  earningsBetweenPeriods(startingFrom: $startPeriod, upTo: $endPeriod) {
    title
    period
    total
    isEstimate
  }
  totalEarnings {
    total
  }
}
    `;

/**
 * __useDashboardEarningsQuery__
 *
 * To run a query within a React component, call `useDashboardEarningsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardEarningsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardEarningsQuery({
 *   variables: {
 *      startPeriod: // value for 'startPeriod'
 *      endPeriod: // value for 'endPeriod'
 *   },
 * });
 */
export function useDashboardEarningsQuery(baseOptions: Apollo.QueryHookOptions<DashboardEarningsQuery, DashboardEarningsQueryVariables> & ({ variables: DashboardEarningsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardEarningsQuery, DashboardEarningsQueryVariables>(DashboardEarningsDocument, options);
      }
export function useDashboardEarningsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardEarningsQuery, DashboardEarningsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardEarningsQuery, DashboardEarningsQueryVariables>(DashboardEarningsDocument, options);
        }
export function useDashboardEarningsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DashboardEarningsQuery, DashboardEarningsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DashboardEarningsQuery, DashboardEarningsQueryVariables>(DashboardEarningsDocument, options);
        }
export type DashboardEarningsQueryHookResult = ReturnType<typeof useDashboardEarningsQuery>;
export type DashboardEarningsLazyQueryHookResult = ReturnType<typeof useDashboardEarningsLazyQuery>;
export type DashboardEarningsSuspenseQueryHookResult = ReturnType<typeof useDashboardEarningsSuspenseQuery>;
export type DashboardEarningsQueryResult = Apollo.QueryResult<DashboardEarningsQuery, DashboardEarningsQueryVariables>;
export function refetchDashboardEarningsQuery(variables: DashboardEarningsQueryVariables) {
      return { query: DashboardEarningsDocument, variables: variables }
    }
export const LoginUserDocument = gql`
    mutation loginUser($username: String!, $password: String!, $fp: String!) {
  revokeToken {
    revoked
  }
  tokenAuth(username: $username, password: $password, fp: $fp) {
    token
    payload
    refreshToken
    refreshExpiresIn
  }
}
    `;
export type LoginUserMutationFn = Apollo.MutationFunction<LoginUserMutation, LoginUserMutationVariables>;

/**
 * __useLoginUserMutation__
 *
 * To run a mutation, you first call `useLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginUserMutation, { data, loading, error }] = useLoginUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      fp: // value for 'fp'
 *   },
 * });
 */
export function useLoginUserMutation(baseOptions?: Apollo.MutationHookOptions<LoginUserMutation, LoginUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginUserMutation, LoginUserMutationVariables>(LoginUserDocument, options);
      }
export type LoginUserMutationHookResult = ReturnType<typeof useLoginUserMutation>;
export type LoginUserMutationResult = Apollo.MutationResult<LoginUserMutation>;
export type LoginUserMutationOptions = Apollo.BaseMutationOptions<LoginUserMutation, LoginUserMutationVariables>;
export const LogoutUserDocument = gql`
    mutation logoutUser {
  revokeToken {
    revoked
  }
  deleteTokenCookie {
    deleted
  }
  deleteRefreshTokenCookie {
    deleted
  }
}
    `;
export type LogoutUserMutationFn = Apollo.MutationFunction<LogoutUserMutation, LogoutUserMutationVariables>;

/**
 * __useLogoutUserMutation__
 *
 * To run a mutation, you first call `useLogoutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutUserMutation, { data, loading, error }] = useLogoutUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutUserMutation(baseOptions?: Apollo.MutationHookOptions<LogoutUserMutation, LogoutUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutUserMutation, LogoutUserMutationVariables>(LogoutUserDocument, options);
      }
export type LogoutUserMutationHookResult = ReturnType<typeof useLogoutUserMutation>;
export type LogoutUserMutationResult = Apollo.MutationResult<LogoutUserMutation>;
export type LogoutUserMutationOptions = Apollo.BaseMutationOptions<LogoutUserMutation, LogoutUserMutationVariables>;
export const RefreshTokenDocument = gql`
    mutation refreshToken($fp: String!) {
  refreshToken(fp: $fp) {
    payload
    token
    refreshExpiresIn
    refreshToken
  }
}
    `;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      fp: // value for 'fp'
 *   },
 * });
 */
export function useRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(RefreshTokenDocument, options);
      }
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<RefreshTokenMutation, RefreshTokenMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($username: String!) {
  resetPassword(email: $username) {
    errors {
      path
      error
    }
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const SubmitOnboardingProfileDocument = gql`
    mutation submitOnboardingProfile($onboardingProfileInput: OnboardingProfileInput!) {
  submitOnboardingProfile(onboardingProfile: $onboardingProfileInput) {
    songtrustUser {
      profileType
      songwriterCountEstimation
      city
      state
      hasPastPublishing
      digitalDistribution
      currentDistributors {
        edges {
          node {
            label
            choice
          }
        }
      }
      favoriteDistributor {
        label
        choice
      }
      activeWorkRegions {
        edges {
          node {
            label
            choice
          }
        }
      }
      country {
        name
        isoCode
      }
      zipCode
      genres {
        edges {
          node {
            label
            choice
          }
        }
      }
      requiresOnboarding
    }
  }
}
    `;
export type SubmitOnboardingProfileMutationFn = Apollo.MutationFunction<SubmitOnboardingProfileMutation, SubmitOnboardingProfileMutationVariables>;

/**
 * __useSubmitOnboardingProfileMutation__
 *
 * To run a mutation, you first call `useSubmitOnboardingProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitOnboardingProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitOnboardingProfileMutation, { data, loading, error }] = useSubmitOnboardingProfileMutation({
 *   variables: {
 *      onboardingProfileInput: // value for 'onboardingProfileInput'
 *   },
 * });
 */
export function useSubmitOnboardingProfileMutation(baseOptions?: Apollo.MutationHookOptions<SubmitOnboardingProfileMutation, SubmitOnboardingProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitOnboardingProfileMutation, SubmitOnboardingProfileMutationVariables>(SubmitOnboardingProfileDocument, options);
      }
export type SubmitOnboardingProfileMutationHookResult = ReturnType<typeof useSubmitOnboardingProfileMutation>;
export type SubmitOnboardingProfileMutationResult = Apollo.MutationResult<SubmitOnboardingProfileMutation>;
export type SubmitOnboardingProfileMutationOptions = Apollo.BaseMutationOptions<SubmitOnboardingProfileMutation, SubmitOnboardingProfileMutationVariables>;
export const OnboardingFieldsDocument = gql`
    query OnboardingFields($countryCursor: String) {
  userProfileTypes {
    edges {
      node {
        id
        title
      }
    }
  }
  userGenres {
    edges {
      node {
        label
        choice
      }
    }
  }
  states {
    edges {
      node {
        name
        isoCode
      }
    }
  }
  countries(after: $countryCursor) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        id
        isoCode
        name
      }
    }
  }
  userCountEstimationChoices {
    edges {
      node {
        choice
        label
      }
    }
  }
  streamEstimationChoices {
    edges {
      node {
        choice
        label
      }
    }
  }
  activeWorkRegions {
    edges {
      node {
        choice
        label
      }
    }
  }
  digitalDistributionChoices {
    edges {
      node {
        choice
        label
      }
    }
  }
  yesNoChoices {
    edges {
      node {
        choice
        label
      }
    }
  }
  distributorsChoices {
    edges {
      node {
        choice
        label
      }
    }
  }
  catalogRepresentationChoices {
    edges {
      node {
        choice
        label
      }
    }
  }
  expectedManagedWriterChoices {
    edges {
      node {
        choice
        label
      }
    }
  }
}
    `;

/**
 * __useOnboardingFieldsQuery__
 *
 * To run a query within a React component, call `useOnboardingFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingFieldsQuery({
 *   variables: {
 *      countryCursor: // value for 'countryCursor'
 *   },
 * });
 */
export function useOnboardingFieldsQuery(baseOptions?: Apollo.QueryHookOptions<OnboardingFieldsQuery, OnboardingFieldsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingFieldsQuery, OnboardingFieldsQueryVariables>(OnboardingFieldsDocument, options);
      }
export function useOnboardingFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingFieldsQuery, OnboardingFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingFieldsQuery, OnboardingFieldsQueryVariables>(OnboardingFieldsDocument, options);
        }
export function useOnboardingFieldsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OnboardingFieldsQuery, OnboardingFieldsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OnboardingFieldsQuery, OnboardingFieldsQueryVariables>(OnboardingFieldsDocument, options);
        }
export type OnboardingFieldsQueryHookResult = ReturnType<typeof useOnboardingFieldsQuery>;
export type OnboardingFieldsLazyQueryHookResult = ReturnType<typeof useOnboardingFieldsLazyQuery>;
export type OnboardingFieldsSuspenseQueryHookResult = ReturnType<typeof useOnboardingFieldsSuspenseQuery>;
export type OnboardingFieldsQueryResult = Apollo.QueryResult<OnboardingFieldsQuery, OnboardingFieldsQueryVariables>;
export function refetchOnboardingFieldsQuery(variables?: OnboardingFieldsQueryVariables) {
      return { query: OnboardingFieldsDocument, variables: variables }
    }
export const GeneratePlaidIdentityTokenDocument = gql`
    mutation generatePlaidIdentityToken {
  generatePlaidIdentityToken {
    token
    identityId
  }
}
    `;
export type GeneratePlaidIdentityTokenMutationFn = Apollo.MutationFunction<GeneratePlaidIdentityTokenMutation, GeneratePlaidIdentityTokenMutationVariables>;

/**
 * __useGeneratePlaidIdentityTokenMutation__
 *
 * To run a mutation, you first call `useGeneratePlaidIdentityTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGeneratePlaidIdentityTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generatePlaidIdentityTokenMutation, { data, loading, error }] = useGeneratePlaidIdentityTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useGeneratePlaidIdentityTokenMutation(baseOptions?: Apollo.MutationHookOptions<GeneratePlaidIdentityTokenMutation, GeneratePlaidIdentityTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GeneratePlaidIdentityTokenMutation, GeneratePlaidIdentityTokenMutationVariables>(GeneratePlaidIdentityTokenDocument, options);
      }
export type GeneratePlaidIdentityTokenMutationHookResult = ReturnType<typeof useGeneratePlaidIdentityTokenMutation>;
export type GeneratePlaidIdentityTokenMutationResult = Apollo.MutationResult<GeneratePlaidIdentityTokenMutation>;
export type GeneratePlaidIdentityTokenMutationOptions = Apollo.BaseMutationOptions<GeneratePlaidIdentityTokenMutation, GeneratePlaidIdentityTokenMutationVariables>;
export const SignupUserDocument = gql`
    mutation signupUser($userSignupInput: UserSignupInput!) {
  signupUser(userSignupInput: $userSignupInput) {
    songtrustUser {
      user {
        email
        firstName
        lastName
      }
      profileType
      songwriterCountEstimation
    }
    errors {
      path
      error
    }
  }
}
    `;
export type SignupUserMutationFn = Apollo.MutationFunction<SignupUserMutation, SignupUserMutationVariables>;

/**
 * __useSignupUserMutation__
 *
 * To run a mutation, you first call `useSignupUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupUserMutation, { data, loading, error }] = useSignupUserMutation({
 *   variables: {
 *      userSignupInput: // value for 'userSignupInput'
 *   },
 * });
 */
export function useSignupUserMutation(baseOptions?: Apollo.MutationHookOptions<SignupUserMutation, SignupUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupUserMutation, SignupUserMutationVariables>(SignupUserDocument, options);
      }
export type SignupUserMutationHookResult = ReturnType<typeof useSignupUserMutation>;
export type SignupUserMutationResult = Apollo.MutationResult<SignupUserMutation>;
export type SignupUserMutationOptions = Apollo.BaseMutationOptions<SignupUserMutation, SignupUserMutationVariables>;
export const CheckVerificationStatusDocument = gql`
    query checkVerificationStatus($identityId: String!) {
  checkVerificationStatus(identityId: $identityId) {
    status
    identityId
  }
}
    `;

/**
 * __useCheckVerificationStatusQuery__
 *
 * To run a query within a React component, call `useCheckVerificationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckVerificationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckVerificationStatusQuery({
 *   variables: {
 *      identityId: // value for 'identityId'
 *   },
 * });
 */
export function useCheckVerificationStatusQuery(baseOptions: Apollo.QueryHookOptions<CheckVerificationStatusQuery, CheckVerificationStatusQueryVariables> & ({ variables: CheckVerificationStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckVerificationStatusQuery, CheckVerificationStatusQueryVariables>(CheckVerificationStatusDocument, options);
      }
export function useCheckVerificationStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckVerificationStatusQuery, CheckVerificationStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckVerificationStatusQuery, CheckVerificationStatusQueryVariables>(CheckVerificationStatusDocument, options);
        }
export function useCheckVerificationStatusSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CheckVerificationStatusQuery, CheckVerificationStatusQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckVerificationStatusQuery, CheckVerificationStatusQueryVariables>(CheckVerificationStatusDocument, options);
        }
export type CheckVerificationStatusQueryHookResult = ReturnType<typeof useCheckVerificationStatusQuery>;
export type CheckVerificationStatusLazyQueryHookResult = ReturnType<typeof useCheckVerificationStatusLazyQuery>;
export type CheckVerificationStatusSuspenseQueryHookResult = ReturnType<typeof useCheckVerificationStatusSuspenseQuery>;
export type CheckVerificationStatusQueryResult = Apollo.QueryResult<CheckVerificationStatusQuery, CheckVerificationStatusQueryVariables>;
export function refetchCheckVerificationStatusQuery(variables: CheckVerificationStatusQueryVariables) {
      return { query: CheckVerificationStatusDocument, variables: variables }
    }
export const VerifyDiscountCodeDocument = gql`
    query VerifyDiscountCode($discountCode: String) {
  verifyDiscountCode(discountCode: $discountCode) {
    code
    discount
    isValid
  }
}
    `;

/**
 * __useVerifyDiscountCodeQuery__
 *
 * To run a query within a React component, call `useVerifyDiscountCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyDiscountCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyDiscountCodeQuery({
 *   variables: {
 *      discountCode: // value for 'discountCode'
 *   },
 * });
 */
export function useVerifyDiscountCodeQuery(baseOptions?: Apollo.QueryHookOptions<VerifyDiscountCodeQuery, VerifyDiscountCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyDiscountCodeQuery, VerifyDiscountCodeQueryVariables>(VerifyDiscountCodeDocument, options);
      }
export function useVerifyDiscountCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyDiscountCodeQuery, VerifyDiscountCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyDiscountCodeQuery, VerifyDiscountCodeQueryVariables>(VerifyDiscountCodeDocument, options);
        }
export function useVerifyDiscountCodeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<VerifyDiscountCodeQuery, VerifyDiscountCodeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<VerifyDiscountCodeQuery, VerifyDiscountCodeQueryVariables>(VerifyDiscountCodeDocument, options);
        }
export type VerifyDiscountCodeQueryHookResult = ReturnType<typeof useVerifyDiscountCodeQuery>;
export type VerifyDiscountCodeLazyQueryHookResult = ReturnType<typeof useVerifyDiscountCodeLazyQuery>;
export type VerifyDiscountCodeSuspenseQueryHookResult = ReturnType<typeof useVerifyDiscountCodeSuspenseQuery>;
export type VerifyDiscountCodeQueryResult = Apollo.QueryResult<VerifyDiscountCodeQuery, VerifyDiscountCodeQueryVariables>;
export function refetchVerifyDiscountCodeQuery(variables?: VerifyDiscountCodeQueryVariables) {
      return { query: VerifyDiscountCodeDocument, variables: variables }
    }
export const CreateRecordingDocument = gql`
    mutation createRecording($input: RecordingInput!) {
  createRecording(input: $input) {
    recording {
      id
      song {
        id
      }
      isrc
    }
    errors {
      error
      path
    }
  }
}
    `;
export type CreateRecordingMutationFn = Apollo.MutationFunction<CreateRecordingMutation, CreateRecordingMutationVariables>;

/**
 * __useCreateRecordingMutation__
 *
 * To run a mutation, you first call `useCreateRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecordingMutation, { data, loading, error }] = useCreateRecordingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRecordingMutation(baseOptions?: Apollo.MutationHookOptions<CreateRecordingMutation, CreateRecordingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRecordingMutation, CreateRecordingMutationVariables>(CreateRecordingDocument, options);
      }
export type CreateRecordingMutationHookResult = ReturnType<typeof useCreateRecordingMutation>;
export type CreateRecordingMutationResult = Apollo.MutationResult<CreateRecordingMutation>;
export type CreateRecordingMutationOptions = Apollo.BaseMutationOptions<CreateRecordingMutation, CreateRecordingMutationVariables>;
export const DeleteRecordingDocument = gql`
    mutation deleteRecording($recordingId: Int!) {
  deleteRecording(recordingId: $recordingId) {
    errors {
      path
      error
    }
  }
}
    `;
export type DeleteRecordingMutationFn = Apollo.MutationFunction<DeleteRecordingMutation, DeleteRecordingMutationVariables>;

/**
 * __useDeleteRecordingMutation__
 *
 * To run a mutation, you first call `useDeleteRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecordingMutation, { data, loading, error }] = useDeleteRecordingMutation({
 *   variables: {
 *      recordingId: // value for 'recordingId'
 *   },
 * });
 */
export function useDeleteRecordingMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRecordingMutation, DeleteRecordingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRecordingMutation, DeleteRecordingMutationVariables>(DeleteRecordingDocument, options);
      }
export type DeleteRecordingMutationHookResult = ReturnType<typeof useDeleteRecordingMutation>;
export type DeleteRecordingMutationResult = Apollo.MutationResult<DeleteRecordingMutation>;
export type DeleteRecordingMutationOptions = Apollo.BaseMutationOptions<DeleteRecordingMutation, DeleteRecordingMutationVariables>;
export const CreateSongDocument = gql`
    mutation createSong($song: CreateSongInput!) {
  createSong(song: $song) {
    song {
      id
      title
      alternateTitles {
        title
      }
      ownerships {
        songwriter {
          firstName
          middleName
          lastName
        }
      }
    }
    errors {
      error
      path
    }
  }
}
    `;
export type CreateSongMutationFn = Apollo.MutationFunction<CreateSongMutation, CreateSongMutationVariables>;

/**
 * __useCreateSongMutation__
 *
 * To run a mutation, you first call `useCreateSongMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSongMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSongMutation, { data, loading, error }] = useCreateSongMutation({
 *   variables: {
 *      song: // value for 'song'
 *   },
 * });
 */
export function useCreateSongMutation(baseOptions?: Apollo.MutationHookOptions<CreateSongMutation, CreateSongMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSongMutation, CreateSongMutationVariables>(CreateSongDocument, options);
      }
export type CreateSongMutationHookResult = ReturnType<typeof useCreateSongMutation>;
export type CreateSongMutationResult = Apollo.MutationResult<CreateSongMutation>;
export type CreateSongMutationOptions = Apollo.BaseMutationOptions<CreateSongMutation, CreateSongMutationVariables>;
export const SongUpdateDocument = gql`
    mutation SongUpdate($song: UpdateSongInput!) {
  updateSong(input: $song) {
    song {
      id
      title
      iswc
      language
      chorusLyrics
      fullLyrics
    }
    errors {
      error
    }
  }
}
    `;
export type SongUpdateMutationFn = Apollo.MutationFunction<SongUpdateMutation, SongUpdateMutationVariables>;

/**
 * __useSongUpdateMutation__
 *
 * To run a mutation, you first call `useSongUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSongUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [songUpdateMutation, { data, loading, error }] = useSongUpdateMutation({
 *   variables: {
 *      song: // value for 'song'
 *   },
 * });
 */
export function useSongUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SongUpdateMutation, SongUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SongUpdateMutation, SongUpdateMutationVariables>(SongUpdateDocument, options);
      }
export type SongUpdateMutationHookResult = ReturnType<typeof useSongUpdateMutation>;
export type SongUpdateMutationResult = Apollo.MutationResult<SongUpdateMutation>;
export type SongUpdateMutationOptions = Apollo.BaseMutationOptions<SongUpdateMutation, SongUpdateMutationVariables>;
export const CopySplitsSongsDocument = gql`
    query CopySplitsSongs($searchTerm: String!) {
  songs(title_Icontains: $searchTerm) {
    edges {
      node {
        id
        title
        ownerships {
          songwriter {
            id
            firstName
            middleName
            lastName
            songwriterType
          }
          percent
        }
      }
    }
  }
}
    `;

/**
 * __useCopySplitsSongsQuery__
 *
 * To run a query within a React component, call `useCopySplitsSongsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCopySplitsSongsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCopySplitsSongsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useCopySplitsSongsQuery(baseOptions: Apollo.QueryHookOptions<CopySplitsSongsQuery, CopySplitsSongsQueryVariables> & ({ variables: CopySplitsSongsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CopySplitsSongsQuery, CopySplitsSongsQueryVariables>(CopySplitsSongsDocument, options);
      }
export function useCopySplitsSongsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CopySplitsSongsQuery, CopySplitsSongsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CopySplitsSongsQuery, CopySplitsSongsQueryVariables>(CopySplitsSongsDocument, options);
        }
export function useCopySplitsSongsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CopySplitsSongsQuery, CopySplitsSongsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CopySplitsSongsQuery, CopySplitsSongsQueryVariables>(CopySplitsSongsDocument, options);
        }
export type CopySplitsSongsQueryHookResult = ReturnType<typeof useCopySplitsSongsQuery>;
export type CopySplitsSongsLazyQueryHookResult = ReturnType<typeof useCopySplitsSongsLazyQuery>;
export type CopySplitsSongsSuspenseQueryHookResult = ReturnType<typeof useCopySplitsSongsSuspenseQuery>;
export type CopySplitsSongsQueryResult = Apollo.QueryResult<CopySplitsSongsQuery, CopySplitsSongsQueryVariables>;
export function refetchCopySplitsSongsQuery(variables: CopySplitsSongsQueryVariables) {
      return { query: CopySplitsSongsDocument, variables: variables }
    }
export const CurrentUserDocument = gql`
    query CurrentUser {
  loggedInSongtrustUser {
    id
    user {
      id
      email
    }
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export function useCurrentUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserSuspenseQueryHookResult = ReturnType<typeof useCurrentUserSuspenseQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export function refetchCurrentUserQuery(variables?: CurrentUserQueryVariables) {
      return { query: CurrentUserDocument, variables: variables }
    }
export const OwnershipsByIdDocument = gql`
    query OwnershipsById($id: Int!) {
  song(id: $id) {
    ownerships {
      songwriter {
        id
        firstName
        middleName
        lastName
        songwriterType
      }
      percent
    }
  }
}
    `;

/**
 * __useOwnershipsByIdQuery__
 *
 * To run a query within a React component, call `useOwnershipsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnershipsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnershipsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOwnershipsByIdQuery(baseOptions: Apollo.QueryHookOptions<OwnershipsByIdQuery, OwnershipsByIdQueryVariables> & ({ variables: OwnershipsByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OwnershipsByIdQuery, OwnershipsByIdQueryVariables>(OwnershipsByIdDocument, options);
      }
export function useOwnershipsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OwnershipsByIdQuery, OwnershipsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OwnershipsByIdQuery, OwnershipsByIdQueryVariables>(OwnershipsByIdDocument, options);
        }
export function useOwnershipsByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OwnershipsByIdQuery, OwnershipsByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OwnershipsByIdQuery, OwnershipsByIdQueryVariables>(OwnershipsByIdDocument, options);
        }
export type OwnershipsByIdQueryHookResult = ReturnType<typeof useOwnershipsByIdQuery>;
export type OwnershipsByIdLazyQueryHookResult = ReturnType<typeof useOwnershipsByIdLazyQuery>;
export type OwnershipsByIdSuspenseQueryHookResult = ReturnType<typeof useOwnershipsByIdSuspenseQuery>;
export type OwnershipsByIdQueryResult = Apollo.QueryResult<OwnershipsByIdQuery, OwnershipsByIdQueryVariables>;
export function refetchOwnershipsByIdQuery(variables: OwnershipsByIdQueryVariables) {
      return { query: OwnershipsByIdDocument, variables: variables }
    }
export const PerformingRightsOrganizationsDocument = gql`
    query PerformingRightsOrganizations {
  performingRightsOrganizations {
    edges {
      node {
        id
        name
        counterpointCode
      }
    }
  }
}
    `;

/**
 * __usePerformingRightsOrganizationsQuery__
 *
 * To run a query within a React component, call `usePerformingRightsOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePerformingRightsOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePerformingRightsOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePerformingRightsOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<PerformingRightsOrganizationsQuery, PerformingRightsOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PerformingRightsOrganizationsQuery, PerformingRightsOrganizationsQueryVariables>(PerformingRightsOrganizationsDocument, options);
      }
export function usePerformingRightsOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PerformingRightsOrganizationsQuery, PerformingRightsOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PerformingRightsOrganizationsQuery, PerformingRightsOrganizationsQueryVariables>(PerformingRightsOrganizationsDocument, options);
        }
export function usePerformingRightsOrganizationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PerformingRightsOrganizationsQuery, PerformingRightsOrganizationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PerformingRightsOrganizationsQuery, PerformingRightsOrganizationsQueryVariables>(PerformingRightsOrganizationsDocument, options);
        }
export type PerformingRightsOrganizationsQueryHookResult = ReturnType<typeof usePerformingRightsOrganizationsQuery>;
export type PerformingRightsOrganizationsLazyQueryHookResult = ReturnType<typeof usePerformingRightsOrganizationsLazyQuery>;
export type PerformingRightsOrganizationsSuspenseQueryHookResult = ReturnType<typeof usePerformingRightsOrganizationsSuspenseQuery>;
export type PerformingRightsOrganizationsQueryResult = Apollo.QueryResult<PerformingRightsOrganizationsQuery, PerformingRightsOrganizationsQueryVariables>;
export function refetchPerformingRightsOrganizationsQuery(variables?: PerformingRightsOrganizationsQueryVariables) {
      return { query: PerformingRightsOrganizationsDocument, variables: variables }
    }
export const SongByIdDocument = gql`
    query SongById($id: Int!) {
  song(id: $id) {
    id
    title
    publicDomain
    alternatetitleSet {
      title
    }
    displayStatus
    displayStatusLog {
      qualified
      monitoring
      processing
      registering
      sent
    }
    iswc
    fullLyrics
    chorusLyrics
    language
    ownerships {
      songwriter {
        id
        firstName
        middleName
        lastName
        songwriterType
      }
      percent
    }
    recordings {
      totalCount
      edges {
        node {
          id
          name
          spotifyTrackId
          spotifyUrl
          spotifyPreviewUrl
          artists {
            name
          }
          isrc
        }
      }
    }
  }
}
    `;

/**
 * __useSongByIdQuery__
 *
 * To run a query within a React component, call `useSongByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSongByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSongByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSongByIdQuery(baseOptions: Apollo.QueryHookOptions<SongByIdQuery, SongByIdQueryVariables> & ({ variables: SongByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SongByIdQuery, SongByIdQueryVariables>(SongByIdDocument, options);
      }
export function useSongByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SongByIdQuery, SongByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SongByIdQuery, SongByIdQueryVariables>(SongByIdDocument, options);
        }
export function useSongByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SongByIdQuery, SongByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SongByIdQuery, SongByIdQueryVariables>(SongByIdDocument, options);
        }
export type SongByIdQueryHookResult = ReturnType<typeof useSongByIdQuery>;
export type SongByIdLazyQueryHookResult = ReturnType<typeof useSongByIdLazyQuery>;
export type SongByIdSuspenseQueryHookResult = ReturnType<typeof useSongByIdSuspenseQuery>;
export type SongByIdQueryResult = Apollo.QueryResult<SongByIdQuery, SongByIdQueryVariables>;
export function refetchSongByIdQuery(variables: SongByIdQueryVariables) {
      return { query: SongByIdDocument, variables: variables }
    }
export const SongsByTitleDocument = gql`
    query SongsByTitle($title_Icontains: String!, $offset: Int!, $first: Int!, $orderBy: String) {
  songs(
    title_Icontains: $title_Icontains
    first: $first
    offset: $offset
    orderBy: $orderBy
  ) {
    totalCount
    edges {
      node {
        id
        title
        songwriterSet {
          edges {
            node {
              firstName
              lastName
            }
          }
        }
        recordings {
          totalCount
        }
        displayStatus
        created
      }
    }
  }
}
    `;

/**
 * __useSongsByTitleQuery__
 *
 * To run a query within a React component, call `useSongsByTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useSongsByTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSongsByTitleQuery({
 *   variables: {
 *      title_Icontains: // value for 'title_Icontains'
 *      offset: // value for 'offset'
 *      first: // value for 'first'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useSongsByTitleQuery(baseOptions: Apollo.QueryHookOptions<SongsByTitleQuery, SongsByTitleQueryVariables> & ({ variables: SongsByTitleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SongsByTitleQuery, SongsByTitleQueryVariables>(SongsByTitleDocument, options);
      }
export function useSongsByTitleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SongsByTitleQuery, SongsByTitleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SongsByTitleQuery, SongsByTitleQueryVariables>(SongsByTitleDocument, options);
        }
export function useSongsByTitleSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SongsByTitleQuery, SongsByTitleQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SongsByTitleQuery, SongsByTitleQueryVariables>(SongsByTitleDocument, options);
        }
export type SongsByTitleQueryHookResult = ReturnType<typeof useSongsByTitleQuery>;
export type SongsByTitleLazyQueryHookResult = ReturnType<typeof useSongsByTitleLazyQuery>;
export type SongsByTitleSuspenseQueryHookResult = ReturnType<typeof useSongsByTitleSuspenseQuery>;
export type SongsByTitleQueryResult = Apollo.QueryResult<SongsByTitleQuery, SongsByTitleQueryVariables>;
export function refetchSongsByTitleQuery(variables: SongsByTitleQueryVariables) {
      return { query: SongsByTitleDocument, variables: variables }
    }
export const SongSongwritersDocument = gql`
    query SongSongwriters($searchFor: String, $songwriterType: InterestedpartySongWriterSongwriterTypeChoices) {
  songwriters(searchFor: $searchFor, songwriterType: $songwriterType) {
    edges {
      node {
        id
        firstName
        middleName
        lastName
        songwriterType
        managedBy {
          user {
            email
            id
          }
        }
        payee
        publishingCompany {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useSongSongwritersQuery__
 *
 * To run a query within a React component, call `useSongSongwritersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSongSongwritersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSongSongwritersQuery({
 *   variables: {
 *      searchFor: // value for 'searchFor'
 *      songwriterType: // value for 'songwriterType'
 *   },
 * });
 */
export function useSongSongwritersQuery(baseOptions?: Apollo.QueryHookOptions<SongSongwritersQuery, SongSongwritersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SongSongwritersQuery, SongSongwritersQueryVariables>(SongSongwritersDocument, options);
      }
export function useSongSongwritersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SongSongwritersQuery, SongSongwritersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SongSongwritersQuery, SongSongwritersQueryVariables>(SongSongwritersDocument, options);
        }
export function useSongSongwritersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SongSongwritersQuery, SongSongwritersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SongSongwritersQuery, SongSongwritersQueryVariables>(SongSongwritersDocument, options);
        }
export type SongSongwritersQueryHookResult = ReturnType<typeof useSongSongwritersQuery>;
export type SongSongwritersLazyQueryHookResult = ReturnType<typeof useSongSongwritersLazyQuery>;
export type SongSongwritersSuspenseQueryHookResult = ReturnType<typeof useSongSongwritersSuspenseQuery>;
export type SongSongwritersQueryResult = Apollo.QueryResult<SongSongwritersQuery, SongSongwritersQueryVariables>;
export function refetchSongSongwritersQuery(variables?: SongSongwritersQueryVariables) {
      return { query: SongSongwritersDocument, variables: variables }
    }
export const TracksDocument = gql`
    query Tracks($title: String!, $artist: String!) {
  tracks(title: $title, artist: $artist) {
    id
    albumTitle
    artistName
    artistId
    artworkUrl
    isrc
    songTitle
    spotifyUri
    previewUrl
  }
}
    `;

/**
 * __useTracksQuery__
 *
 * To run a query within a React component, call `useTracksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTracksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTracksQuery({
 *   variables: {
 *      title: // value for 'title'
 *      artist: // value for 'artist'
 *   },
 * });
 */
export function useTracksQuery(baseOptions: Apollo.QueryHookOptions<TracksQuery, TracksQueryVariables> & ({ variables: TracksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TracksQuery, TracksQueryVariables>(TracksDocument, options);
      }
export function useTracksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TracksQuery, TracksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TracksQuery, TracksQueryVariables>(TracksDocument, options);
        }
export function useTracksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TracksQuery, TracksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TracksQuery, TracksQueryVariables>(TracksDocument, options);
        }
export type TracksQueryHookResult = ReturnType<typeof useTracksQuery>;
export type TracksLazyQueryHookResult = ReturnType<typeof useTracksLazyQuery>;
export type TracksSuspenseQueryHookResult = ReturnType<typeof useTracksSuspenseQuery>;
export type TracksQueryResult = Apollo.QueryResult<TracksQuery, TracksQueryVariables>;
export function refetchTracksQuery(variables: TracksQueryVariables) {
      return { query: TracksDocument, variables: variables }
    }
export const AddSongwriterCreditsDocument = gql`
    mutation addSongwriterCredits($discountCode: String, $credits: Int) {
  addSongwriterCredits(discountCode: $discountCode, credits: $credits) {
    errors {
      error
      path
    }
  }
}
    `;
export type AddSongwriterCreditsMutationFn = Apollo.MutationFunction<AddSongwriterCreditsMutation, AddSongwriterCreditsMutationVariables>;

/**
 * __useAddSongwriterCreditsMutation__
 *
 * To run a mutation, you first call `useAddSongwriterCreditsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSongwriterCreditsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSongwriterCreditsMutation, { data, loading, error }] = useAddSongwriterCreditsMutation({
 *   variables: {
 *      discountCode: // value for 'discountCode'
 *      credits: // value for 'credits'
 *   },
 * });
 */
export function useAddSongwriterCreditsMutation(baseOptions?: Apollo.MutationHookOptions<AddSongwriterCreditsMutation, AddSongwriterCreditsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSongwriterCreditsMutation, AddSongwriterCreditsMutationVariables>(AddSongwriterCreditsDocument, options);
      }
export type AddSongwriterCreditsMutationHookResult = ReturnType<typeof useAddSongwriterCreditsMutation>;
export type AddSongwriterCreditsMutationResult = Apollo.MutationResult<AddSongwriterCreditsMutation>;
export type AddSongwriterCreditsMutationOptions = Apollo.BaseMutationOptions<AddSongwriterCreditsMutation, AddSongwriterCreditsMutationVariables>;
export const CreateClientSongwriterDocument = gql`
    mutation createClientSongwriter($input: ClientSongwriterInput!) {
  createClientSongwriter(input: $input) {
    songwriter {
      id
      firstName
      middleName
      lastName
    }
    errors {
      path
      error
    }
  }
}
    `;
export type CreateClientSongwriterMutationFn = Apollo.MutationFunction<CreateClientSongwriterMutation, CreateClientSongwriterMutationVariables>;

/**
 * __useCreateClientSongwriterMutation__
 *
 * To run a mutation, you first call `useCreateClientSongwriterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientSongwriterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientSongwriterMutation, { data, loading, error }] = useCreateClientSongwriterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientSongwriterMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientSongwriterMutation, CreateClientSongwriterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientSongwriterMutation, CreateClientSongwriterMutationVariables>(CreateClientSongwriterDocument, options);
      }
export type CreateClientSongwriterMutationHookResult = ReturnType<typeof useCreateClientSongwriterMutation>;
export type CreateClientSongwriterMutationResult = Apollo.MutationResult<CreateClientSongwriterMutation>;
export type CreateClientSongwriterMutationOptions = Apollo.BaseMutationOptions<CreateClientSongwriterMutation, CreateClientSongwriterMutationVariables>;
export const CreateOutsideSongwriterDocument = gql`
    mutation createOutsideSongwriter($input: OutsideSongwriterInput!) {
  createOutsideSongwriter(input: $input) {
    songwriter {
      id
      firstName
      middleName
      lastName
      email
    }
    errors {
      path
      error
    }
  }
}
    `;
export type CreateOutsideSongwriterMutationFn = Apollo.MutationFunction<CreateOutsideSongwriterMutation, CreateOutsideSongwriterMutationVariables>;

/**
 * __useCreateOutsideSongwriterMutation__
 *
 * To run a mutation, you first call `useCreateOutsideSongwriterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOutsideSongwriterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOutsideSongwriterMutation, { data, loading, error }] = useCreateOutsideSongwriterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOutsideSongwriterMutation(baseOptions?: Apollo.MutationHookOptions<CreateOutsideSongwriterMutation, CreateOutsideSongwriterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOutsideSongwriterMutation, CreateOutsideSongwriterMutationVariables>(CreateOutsideSongwriterDocument, options);
      }
export type CreateOutsideSongwriterMutationHookResult = ReturnType<typeof useCreateOutsideSongwriterMutation>;
export type CreateOutsideSongwriterMutationResult = Apollo.MutationResult<CreateOutsideSongwriterMutation>;
export type CreateOutsideSongwriterMutationOptions = Apollo.BaseMutationOptions<CreateOutsideSongwriterMutation, CreateOutsideSongwriterMutationVariables>;
export const SongwriterUpdateBiographyDocument = gql`
    mutation songwriterUpdateBiography($songwriterId: Int!, $bio: String!) {
  songwriterUpdateBiography(songwriterId: $songwriterId, bio: $bio) {
    songwriter {
      bio
    }
    errors {
      path
      error
    }
  }
}
    `;
export type SongwriterUpdateBiographyMutationFn = Apollo.MutationFunction<SongwriterUpdateBiographyMutation, SongwriterUpdateBiographyMutationVariables>;

/**
 * __useSongwriterUpdateBiographyMutation__
 *
 * To run a mutation, you first call `useSongwriterUpdateBiographyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSongwriterUpdateBiographyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [songwriterUpdateBiographyMutation, { data, loading, error }] = useSongwriterUpdateBiographyMutation({
 *   variables: {
 *      songwriterId: // value for 'songwriterId'
 *      bio: // value for 'bio'
 *   },
 * });
 */
export function useSongwriterUpdateBiographyMutation(baseOptions?: Apollo.MutationHookOptions<SongwriterUpdateBiographyMutation, SongwriterUpdateBiographyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SongwriterUpdateBiographyMutation, SongwriterUpdateBiographyMutationVariables>(SongwriterUpdateBiographyDocument, options);
      }
export type SongwriterUpdateBiographyMutationHookResult = ReturnType<typeof useSongwriterUpdateBiographyMutation>;
export type SongwriterUpdateBiographyMutationResult = Apollo.MutationResult<SongwriterUpdateBiographyMutation>;
export type SongwriterUpdateBiographyMutationOptions = Apollo.BaseMutationOptions<SongwriterUpdateBiographyMutation, SongwriterUpdateBiographyMutationVariables>;
export const SongwriterUpdateDocument = gql`
    mutation SongwriterUpdate($songwriter: UpdateSongwriterInput!) {
  updateSongwriter(input: $songwriter) {
    songwriter {
      id
      firstName
      middleName
      lastName
      email
      birthDate
      usRepresentation {
        name
      }
      pro {
        name
      }
      publishingCompany {
        name
        pro {
          name
        }
        ipi
      }
      youtubeOptOut
      ipi
    }
    errors {
      error
    }
  }
}
    `;
export type SongwriterUpdateMutationFn = Apollo.MutationFunction<SongwriterUpdateMutation, SongwriterUpdateMutationVariables>;

/**
 * __useSongwriterUpdateMutation__
 *
 * To run a mutation, you first call `useSongwriterUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSongwriterUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [songwriterUpdateMutation, { data, loading, error }] = useSongwriterUpdateMutation({
 *   variables: {
 *      songwriter: // value for 'songwriter'
 *   },
 * });
 */
export function useSongwriterUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SongwriterUpdateMutation, SongwriterUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SongwriterUpdateMutation, SongwriterUpdateMutationVariables>(SongwriterUpdateDocument, options);
      }
export type SongwriterUpdateMutationHookResult = ReturnType<typeof useSongwriterUpdateMutation>;
export type SongwriterUpdateMutationResult = Apollo.MutationResult<SongwriterUpdateMutation>;
export type SongwriterUpdateMutationOptions = Apollo.BaseMutationOptions<SongwriterUpdateMutation, SongwriterUpdateMutationVariables>;
export const SongwriterChangeYoutubeClaimOutoutDocument = gql`
    mutation songwriterChangeYoutubeClaimOutout($songwriterId: Int!, $optOut: Boolean!) {
  songwriterChangeYoutubeClaimOptOut(songwriterId: $songwriterId, optOut: $optOut) {
    songwriter {
      youtubeOptOut
      youtubeOptedOutOn
    }
    errors {
      path
      error
    }
  }
}
    `;
export type SongwriterChangeYoutubeClaimOutoutMutationFn = Apollo.MutationFunction<SongwriterChangeYoutubeClaimOutoutMutation, SongwriterChangeYoutubeClaimOutoutMutationVariables>;

/**
 * __useSongwriterChangeYoutubeClaimOutoutMutation__
 *
 * To run a mutation, you first call `useSongwriterChangeYoutubeClaimOutoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSongwriterChangeYoutubeClaimOutoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [songwriterChangeYoutubeClaimOutoutMutation, { data, loading, error }] = useSongwriterChangeYoutubeClaimOutoutMutation({
 *   variables: {
 *      songwriterId: // value for 'songwriterId'
 *      optOut: // value for 'optOut'
 *   },
 * });
 */
export function useSongwriterChangeYoutubeClaimOutoutMutation(baseOptions?: Apollo.MutationHookOptions<SongwriterChangeYoutubeClaimOutoutMutation, SongwriterChangeYoutubeClaimOutoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SongwriterChangeYoutubeClaimOutoutMutation, SongwriterChangeYoutubeClaimOutoutMutationVariables>(SongwriterChangeYoutubeClaimOutoutDocument, options);
      }
export type SongwriterChangeYoutubeClaimOutoutMutationHookResult = ReturnType<typeof useSongwriterChangeYoutubeClaimOutoutMutation>;
export type SongwriterChangeYoutubeClaimOutoutMutationResult = Apollo.MutationResult<SongwriterChangeYoutubeClaimOutoutMutation>;
export type SongwriterChangeYoutubeClaimOutoutMutationOptions = Apollo.BaseMutationOptions<SongwriterChangeYoutubeClaimOutoutMutation, SongwriterChangeYoutubeClaimOutoutMutationVariables>;
export const CountriesDocument = gql`
    query Countries($after: String) {
  countries(after: $after) {
    edges {
      node {
        id
        isoCode
        name
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *      after: // value for 'after'
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
      }
export function useCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
        }
export function useCountriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesSuspenseQueryHookResult = ReturnType<typeof useCountriesSuspenseQuery>;
export type CountriesQueryResult = Apollo.QueryResult<CountriesQuery, CountriesQueryVariables>;
export function refetchCountriesQuery(variables?: CountriesQueryVariables) {
      return { query: CountriesDocument, variables: variables }
    }
export const SongwriterDocument = gql`
    query songwriter($songwriterId: Int!) {
  songwriter(songwriterId: $songwriterId) {
    id
    firstName
    middleName
    lastName
    email
    birthDate
    created
    currentStatus
    statusLog {
      sentAt
      approvedAt
      latest
    }
    manageable
    hasApplication
    hasLod
    usRepresentation {
      name
    }
    pro {
      name
    }
    publishingCompany {
      name
      pro {
        name
      }
      ipi
    }
    youtubeOptOut
    songsRegisteringCount
    ipi
    bio
  }
}
    `;

/**
 * __useSongwriterQuery__
 *
 * To run a query within a React component, call `useSongwriterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSongwriterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSongwriterQuery({
 *   variables: {
 *      songwriterId: // value for 'songwriterId'
 *   },
 * });
 */
export function useSongwriterQuery(baseOptions: Apollo.QueryHookOptions<SongwriterQuery, SongwriterQueryVariables> & ({ variables: SongwriterQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SongwriterQuery, SongwriterQueryVariables>(SongwriterDocument, options);
      }
export function useSongwriterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SongwriterQuery, SongwriterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SongwriterQuery, SongwriterQueryVariables>(SongwriterDocument, options);
        }
export function useSongwriterSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SongwriterQuery, SongwriterQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SongwriterQuery, SongwriterQueryVariables>(SongwriterDocument, options);
        }
export type SongwriterQueryHookResult = ReturnType<typeof useSongwriterQuery>;
export type SongwriterLazyQueryHookResult = ReturnType<typeof useSongwriterLazyQuery>;
export type SongwriterSuspenseQueryHookResult = ReturnType<typeof useSongwriterSuspenseQuery>;
export type SongwriterQueryResult = Apollo.QueryResult<SongwriterQuery, SongwriterQueryVariables>;
export function refetchSongwriterQuery(variables: SongwriterQueryVariables) {
      return { query: SongwriterDocument, variables: variables }
    }
export const SongwritersDocument = gql`
    query songwriters($songwriterType: InterestedpartySongWriterSongwriterTypeChoices!, $searchFor: String!, $offset: Int!, $first: Int!) {
  songwritersUsage {
    limit
    used
    left
  }
  loggedInSongtrustUser {
    youtubeOptOut
    youtubeOptedOutOn
  }
  songwriters(
    songwriterType: $songwriterType
    searchFor: $searchFor
    first: $first
    offset: $offset
  ) {
    totalCount
    edges {
      node {
        id
        firstName
        middleName
        lastName
        payee
        email
        pro {
          name
        }
        created
        currentStatus
        youtubeOptOut
        youtubeOptedOutOn
        manageable
        crosslinkUserCount
        hasLod
        hasApplication
        statusLog {
          latest
        }
        publishingCompany {
          name
        }
        pro {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useSongwritersQuery__
 *
 * To run a query within a React component, call `useSongwritersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSongwritersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSongwritersQuery({
 *   variables: {
 *      songwriterType: // value for 'songwriterType'
 *      searchFor: // value for 'searchFor'
 *      offset: // value for 'offset'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useSongwritersQuery(baseOptions: Apollo.QueryHookOptions<SongwritersQuery, SongwritersQueryVariables> & ({ variables: SongwritersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SongwritersQuery, SongwritersQueryVariables>(SongwritersDocument, options);
      }
export function useSongwritersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SongwritersQuery, SongwritersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SongwritersQuery, SongwritersQueryVariables>(SongwritersDocument, options);
        }
export function useSongwritersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SongwritersQuery, SongwritersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SongwritersQuery, SongwritersQueryVariables>(SongwritersDocument, options);
        }
export type SongwritersQueryHookResult = ReturnType<typeof useSongwritersQuery>;
export type SongwritersLazyQueryHookResult = ReturnType<typeof useSongwritersLazyQuery>;
export type SongwritersSuspenseQueryHookResult = ReturnType<typeof useSongwritersSuspenseQuery>;
export type SongwritersQueryResult = Apollo.QueryResult<SongwritersQuery, SongwritersQueryVariables>;
export function refetchSongwritersQuery(variables: SongwritersQueryVariables) {
      return { query: SongwritersDocument, variables: variables }
    }
export const RemainingSongwritersUsageDocument = gql`
    query remainingSongwritersUsage {
  songwritersUsage {
    limit
    used
    left
  }
}
    `;

/**
 * __useRemainingSongwritersUsageQuery__
 *
 * To run a query within a React component, call `useRemainingSongwritersUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemainingSongwritersUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemainingSongwritersUsageQuery({
 *   variables: {
 *   },
 * });
 */
export function useRemainingSongwritersUsageQuery(baseOptions?: Apollo.QueryHookOptions<RemainingSongwritersUsageQuery, RemainingSongwritersUsageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RemainingSongwritersUsageQuery, RemainingSongwritersUsageQueryVariables>(RemainingSongwritersUsageDocument, options);
      }
export function useRemainingSongwritersUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RemainingSongwritersUsageQuery, RemainingSongwritersUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RemainingSongwritersUsageQuery, RemainingSongwritersUsageQueryVariables>(RemainingSongwritersUsageDocument, options);
        }
export function useRemainingSongwritersUsageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RemainingSongwritersUsageQuery, RemainingSongwritersUsageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RemainingSongwritersUsageQuery, RemainingSongwritersUsageQueryVariables>(RemainingSongwritersUsageDocument, options);
        }
export type RemainingSongwritersUsageQueryHookResult = ReturnType<typeof useRemainingSongwritersUsageQuery>;
export type RemainingSongwritersUsageLazyQueryHookResult = ReturnType<typeof useRemainingSongwritersUsageLazyQuery>;
export type RemainingSongwritersUsageSuspenseQueryHookResult = ReturnType<typeof useRemainingSongwritersUsageSuspenseQuery>;
export type RemainingSongwritersUsageQueryResult = Apollo.QueryResult<RemainingSongwritersUsageQuery, RemainingSongwritersUsageQueryVariables>;
export function refetchRemainingSongwritersUsageQuery(variables?: RemainingSongwritersUsageQueryVariables) {
      return { query: RemainingSongwritersUsageDocument, variables: variables }
    }
export const AddYoutubeChannelDocument = gql`
    mutation AddYoutubeChannel($channelUrl: String!) {
  addYoutubeChannel(channelUrl: $channelUrl) {
    channel {
      id
      title
      channelId
    }
    errors {
      error
    }
  }
}
    `;
export type AddYoutubeChannelMutationFn = Apollo.MutationFunction<AddYoutubeChannelMutation, AddYoutubeChannelMutationVariables>;

/**
 * __useAddYoutubeChannelMutation__
 *
 * To run a mutation, you first call `useAddYoutubeChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddYoutubeChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addYoutubeChannelMutation, { data, loading, error }] = useAddYoutubeChannelMutation({
 *   variables: {
 *      channelUrl: // value for 'channelUrl'
 *   },
 * });
 */
export function useAddYoutubeChannelMutation(baseOptions?: Apollo.MutationHookOptions<AddYoutubeChannelMutation, AddYoutubeChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddYoutubeChannelMutation, AddYoutubeChannelMutationVariables>(AddYoutubeChannelDocument, options);
      }
export type AddYoutubeChannelMutationHookResult = ReturnType<typeof useAddYoutubeChannelMutation>;
export type AddYoutubeChannelMutationResult = Apollo.MutationResult<AddYoutubeChannelMutation>;
export type AddYoutubeChannelMutationOptions = Apollo.BaseMutationOptions<AddYoutubeChannelMutation, AddYoutubeChannelMutationVariables>;
export const RemoveYoutubeChannelDocument = gql`
    mutation RemoveYoutubeChannel($channelId: String!) {
  removeYoutubeChannel(channelId: $channelId) {
    channel {
      title
    }
    errors {
      error
    }
  }
}
    `;
export type RemoveYoutubeChannelMutationFn = Apollo.MutationFunction<RemoveYoutubeChannelMutation, RemoveYoutubeChannelMutationVariables>;

/**
 * __useRemoveYoutubeChannelMutation__
 *
 * To run a mutation, you first call `useRemoveYoutubeChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveYoutubeChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeYoutubeChannelMutation, { data, loading, error }] = useRemoveYoutubeChannelMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useRemoveYoutubeChannelMutation(baseOptions?: Apollo.MutationHookOptions<RemoveYoutubeChannelMutation, RemoveYoutubeChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveYoutubeChannelMutation, RemoveYoutubeChannelMutationVariables>(RemoveYoutubeChannelDocument, options);
      }
export type RemoveYoutubeChannelMutationHookResult = ReturnType<typeof useRemoveYoutubeChannelMutation>;
export type RemoveYoutubeChannelMutationResult = Apollo.MutationResult<RemoveYoutubeChannelMutation>;
export type RemoveYoutubeChannelMutationOptions = Apollo.BaseMutationOptions<RemoveYoutubeChannelMutation, RemoveYoutubeChannelMutationVariables>;
export const ClaimedVideosDocument = gql`
    query ClaimedVideos($sortBy: SortByArgumentInput!, $before: String, $after: String, $first: Int, $last: Int) {
  claimedVideos(
    sortBy: $sortBy
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    edges {
      node {
        id
        created
        video {
          id
          title
          viewCount
          youtubeId
        }
        song {
          id
          title
        }
        start
        end
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useClaimedVideosQuery__
 *
 * To run a query within a React component, call `useClaimedVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimedVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimedVideosQuery({
 *   variables: {
 *      sortBy: // value for 'sortBy'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useClaimedVideosQuery(baseOptions: Apollo.QueryHookOptions<ClaimedVideosQuery, ClaimedVideosQueryVariables> & ({ variables: ClaimedVideosQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClaimedVideosQuery, ClaimedVideosQueryVariables>(ClaimedVideosDocument, options);
      }
export function useClaimedVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClaimedVideosQuery, ClaimedVideosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClaimedVideosQuery, ClaimedVideosQueryVariables>(ClaimedVideosDocument, options);
        }
export function useClaimedVideosSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ClaimedVideosQuery, ClaimedVideosQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClaimedVideosQuery, ClaimedVideosQueryVariables>(ClaimedVideosDocument, options);
        }
export type ClaimedVideosQueryHookResult = ReturnType<typeof useClaimedVideosQuery>;
export type ClaimedVideosLazyQueryHookResult = ReturnType<typeof useClaimedVideosLazyQuery>;
export type ClaimedVideosSuspenseQueryHookResult = ReturnType<typeof useClaimedVideosSuspenseQuery>;
export type ClaimedVideosQueryResult = Apollo.QueryResult<ClaimedVideosQuery, ClaimedVideosQueryVariables>;
export function refetchClaimedVideosQuery(variables: ClaimedVideosQueryVariables) {
      return { query: ClaimedVideosDocument, variables: variables }
    }
export const YoutubeChannelsDocument = gql`
    query YoutubeChannels($offset: Int, $first: Int) {
  youtubeChannels(offset: $offset, first: $first) {
    edges {
      node {
        id
        title
        channelId
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
  }
}
    `;

/**
 * __useYoutubeChannelsQuery__
 *
 * To run a query within a React component, call `useYoutubeChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useYoutubeChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYoutubeChannelsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useYoutubeChannelsQuery(baseOptions?: Apollo.QueryHookOptions<YoutubeChannelsQuery, YoutubeChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<YoutubeChannelsQuery, YoutubeChannelsQueryVariables>(YoutubeChannelsDocument, options);
      }
export function useYoutubeChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<YoutubeChannelsQuery, YoutubeChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<YoutubeChannelsQuery, YoutubeChannelsQueryVariables>(YoutubeChannelsDocument, options);
        }
export function useYoutubeChannelsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<YoutubeChannelsQuery, YoutubeChannelsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<YoutubeChannelsQuery, YoutubeChannelsQueryVariables>(YoutubeChannelsDocument, options);
        }
export type YoutubeChannelsQueryHookResult = ReturnType<typeof useYoutubeChannelsQuery>;
export type YoutubeChannelsLazyQueryHookResult = ReturnType<typeof useYoutubeChannelsLazyQuery>;
export type YoutubeChannelsSuspenseQueryHookResult = ReturnType<typeof useYoutubeChannelsSuspenseQuery>;
export type YoutubeChannelsQueryResult = Apollo.QueryResult<YoutubeChannelsQuery, YoutubeChannelsQueryVariables>;
export function refetchYoutubeChannelsQuery(variables?: YoutubeChannelsQueryVariables) {
      return { query: YoutubeChannelsDocument, variables: variables }
    }