import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  Tab,
  TextField,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { Translator } from '~/types/Translator';

import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import FormBanner from '~/base/components/FormBanner';
import { FormBannerType } from '~/base/components/FormBanner/FormBanner';
import PageTitle from '~/base/components/PageTitle/PageTitle';
import SongtrustTabPanel from '~/base/components/SongtrustTabPanel';
import SongtrustTabs from '~/base/components/SongtrustTabs/SongtrustTabs';
import routeMatchHelper from '~/base/helpers/routeMatch';
import usePageTitle from '~/hooks/usePageTitle';
import { tabPaths } from '~/songwriters/constants/constants';
import { InterestedpartySongWriterSongwriterTypeChoices } from '~/types/generated/graphql';
import SongwritersList from '../SongwritersList/SongwritersList';

export default function ManageSongwritersPage({ t }: Translator) {
  usePageTitle(t('page.songwriters.title'));

  const navigate = useNavigate();
  const formMethods = useForm();

  const [wasSaved, setWasSaved] = useState<boolean>(false);
  const [processError, setProcessError] = useState<string>('');
  const [songwriterTextSearch, setSongwriterTextSearch] = useState<string>('');
  const [searchFor, setSearchFor] = useState<string>('');

  const [requiresUserIncrease, setRequiresUserIncrease] = useState<
    boolean | undefined
  >(undefined);

  const [remainingSongwritersLabel, setRemainingSongwritersLabel] =
    useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const routeMatch = routeMatchHelper(Object.values(tabPaths));
  const currentTab = routeMatch?.pattern?.path;

  const handleAddSongwriterClick = () => {
    if (requiresUserIncrease && currentTab !== tabPaths.outside) {
      navigate('/songwriters/buy');
    } else if (currentTab === tabPaths.outside) {
      navigate('/songwriters/add/outside');
    } else {
      navigate('/songwriters/add');
    }
  };

  const generateAddSongwriterButtonText = () => {
    if (requiresUserIncrease) {
      if (currentTab !== tabPaths.outside) {
        return t('page.songwriters.actions.increase-songwriter');
      }
    }

    if (currentTab !== tabPaths.outside)
      return t('page.songwriters.actions.add-songwriter');
    return t('page.songwriters.actions.add-non-songwriter');
  };

  return (
    <Container data-testid="manage-songwriters-page">
      <Grid
        container
        sx={{
          flexDirection: {
            xs: 'column',
          },
        }}
      >
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <PageTitle
                pageTitle={{
                  id: 'manage-songwriters-title',
                  title: t('page.songwriters.title'),
                }}
              />
            </Grid>
            <Grid item sx={{ mt: '3rem' }}>
              {requiresUserIncrease !== undefined && (
                <Button
                  data-testid="add-songwriter-button"
                  variant="contained"
                  color="secondary"
                  onClick={handleAddSongwriterClick}
                  sx={{
                    backgroundColor: '#7fc832',
                    pl: '2rem',
                    pr: '2rem',
                    '&:hover': {
                      backgroundColor: '#7fc832',
                    },
                  }}
                >
                  {generateAddSongwriterButtonText()}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ borderBottom: { lg: 1, xs: 0 }, borderColor: 'divider' }}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              sx={{
                pt: { md: '1rem' },
              }}
            >
              {!wasSaved && (
                <Grid item xs={12}>
                  <FormBanner text={processError} type={FormBannerType.ERROR} />
                </Grid>
              )}

              <Grid item xs={12} md={12} lg="auto">
                <SongtrustTabs value={currentTab || tabPaths.client}>
                  <Tab
                    data-testid="manage-songwriters-tab-client"
                    label={
                      <Typography variant="h5" component="span">
                        {t('page.songwriters.tabs.client.title')}
                      </Typography>
                    }
                    value={tabPaths.client}
                    to={`/${tabPaths.client}`}
                    component={Link}
                  />
                  <Tab
                    data-testid="manage-songwriters-tab-outside"
                    label={
                      <Typography variant="h5" component="span">
                        {t('page.songwriters.tabs.outside.title')}
                      </Typography>
                    }
                    value={tabPaths.outside}
                    to={`/${tabPaths.outside}`}
                    component={Link}
                  />
                </SongtrustTabs>
              </Grid>

              {!isLoading && (
                <>
                  <Grid item xs={12} md={12} lg="auto">
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                      data-testid="songwriter-remaining-writers"
                    >
                      {remainingSongwritersLabel && (
                        <Typography
                          variant="h5"
                          component="span"
                          sx={{
                            fontSize: '1rem',
                            mr: '1rem',
                            mt: '.25rem',
                          }}
                        >
                          {remainingSongwritersLabel}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={12} lg="auto">
                    <Box display="flex" justifyContent="flex-end">
                      <TextField
                        data-testid="songwriter-search-textbox"
                        placeholder={t('page.songwriters.search')}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {searchFor.length > 0 && (
                                <ClearIcon
                                  sx={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setSearchFor('');
                                    setSongwriterTextSearch('');
                                  }}
                                />
                              )}
                              {searchFor.length === 0 && (
                                <SearchIcon
                                  sx={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setSearchFor(songwriterTextSearch);
                                  }}
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                        value={songwriterTextSearch}
                        onChange={(event) => {
                          setSongwriterTextSearch(event.target.value);
                        }}
                        onKeyDown={(event) => {
                          if (event.key === 'Enter') {
                            event.preventDefault();
                            setSearchFor(songwriterTextSearch);
                          }
                        }}
                      />
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
          <SongtrustTabPanel
            value={currentTab || tabPaths.client}
            id={tabPaths.client}
          >
            <SongwritersList
              t={t}
              formMethods={formMethods}
              songwriterType={
                InterestedpartySongWriterSongwriterTypeChoices.Client
              }
              searchFor={searchFor}
              setRequiresUserIncrease={setRequiresUserIncrease}
              setRemainingSongwritersLabel={setRemainingSongwritersLabel}
              setIsLoading={setIsLoading}
              setProcessError={setProcessError}
              setWasSaved={setWasSaved}
            />
          </SongtrustTabPanel>
          <SongtrustTabPanel value={currentTab} id={tabPaths.outside}>
            <SongwritersList
              t={t}
              formMethods={formMethods}
              songwriterType={
                InterestedpartySongWriterSongwriterTypeChoices.Outside
              }
              searchFor={searchFor}
              setRequiresUserIncrease={setRequiresUserIncrease}
              setRemainingSongwritersLabel={setRemainingSongwritersLabel}
              setIsLoading={setIsLoading}
              setProcessError={setProcessError}
              setWasSaved={setWasSaved}
            />
          </SongtrustTabPanel>
        </Grid>
      </Grid>
    </Container>
  );
}
