import { SvgIcon } from '@mui/material';

function TikTokIcon() {
  return (
    <SvgIcon
      fontSize="huge"
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.9990134,21.8418812 C32.934835,21.8468186 30.921278,21.2030774 29.2427953,20.001598 L29.2427953,28.3810988 C29.2416283,31.5591787 27.2684894,34.4029223 24.2918867,35.5164885 C21.3152839,36.6300546 17.9601423,35.7796547 15.873321,33.3827046 C13.7864996,30.9857544 13.4061176,27.5454835 14.9188843,24.7505361 C16.4316509,21.9555887 19.5199671,20.3927523 22.6679442,20.8291394 L22.6679442,25.0437394 C21.2249643,24.5898541 19.6537069,25.114734 18.7732417,26.3447682 C17.8927766,27.5748023 17.9025339,29.2313812 18.7974274,30.4509584 C19.6923209,31.6705356 21.2696522,32.1768701 22.7071853,31.7060185 C24.1447185,31.2351668 25.1168443,29.8937795 25.1168101,28.3810988 L25.1168101,12 L29.2427953,12 C29.2399293,12.3484301 29.2691218,12.6963851 29.3300036,13.0394669 C29.6197662,14.5873286 30.5339385,15.9476495 31.8576384,16.8006775 C32.7894497,17.4168053 33.8819254,17.7452002 34.9990134,17.7449655 L34.9990134,21.8418812 Z"
        id="Path"
        fill="#000000"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default TikTokIcon;
