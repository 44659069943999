import { ApolloError } from '@apollo/client';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Grid } from '@mui/material';
import { useState } from 'react';
import validator from 'validator';
import FormBanner from '~/base/components/FormBanner';
import { FormBannerType } from '~/base/components/FormBanner/FormBanner';
import FormInput from '~/base/components/FormInput';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import { Translator } from '~/types/Translator';
import {
  GeneratePasswordAuthCodeMutation,
  useGeneratePasswordAuthCodeMutation,
} from '~/types/generated/graphql';

export interface GeneratorPasswordCodeProperties extends Translator {
  callbackOnSave: (wasSaved: boolean) => void;
}

function GeneratePasswordCode({
  t,
  callbackOnSave,
}: GeneratorPasswordCodeProperties) {
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [wasSaved, setWasSaved] = useState<boolean>(false);
  const [processError, setProcessError] = useState<string>('');

  const [generatePasswordCode, { loading }] =
    useGeneratePasswordAuthCodeMutation({
      fetchPolicy: 'no-cache',
    });

  const handleFormSubmitComplete = (data: GeneratePasswordAuthCodeMutation) => {
    if (data.generateAuthCode?.created) {
      setWasSaved(true);
      callbackOnSave(true);
    }
  };

  const handleFormSubmitError = (error: ApolloError) => {
    setProcessError(error.message);
    setWasSaved(false);
  };

  return (
    <div data-testid="change-password-code-section">
      <Grid container>
        <Grid item xs={12}>
          {!buttonDisabled && (
            <FormBanner text={processError} type={FormBannerType.ERROR} />
          )}
          {buttonDisabled && (
            <FormBanner
              text={t('sections.change-password.generate-success')}
              type={FormBannerType.SUCCESS}
            />
          )}
        </Grid>

        <Grid item xs={12} sx={{ pb: '1rem' }}>
          <Grid item xs={6} md={3}>
            {loading && <LoadingIndicator size={50} />}
            {!loading && (
              <Button
                data-testid="generate-password-code-button"
                variant="contained"
                color="secondary"
                type="button"
                disabled={buttonDisabled}
                onClick={() => {
                  setButtonDisabled(true);
                  setTimeout(() => {
                    setButtonDisabled(false);
                  }, 10000);
                  generatePasswordCode({
                    onCompleted: handleFormSubmitComplete,
                    onError: handleFormSubmitError,
                  });
                }}
                sx={{
                  width: '100%',
                  pt: '.5rem',
                  pb: '.5rem',
                  pl: '3rem',
                  pr: '3rem',
                }}
              >
                {!buttonDisabled && (
                  <>{t('sections.change-password.generate-code')}</>
                )}
                {buttonDisabled && (
                  <>
                    {t('sections.change-password.generated')}{' '}
                    <CheckCircleIcon />
                  </>
                )}
              </Button>
            )}
          </Grid>
        </Grid>
        {!wasSaved && (
          <Grid item xs={12}>
            <Box
              sx={{
                border: '1px solid #7E7E7E',
                p: '1rem',
              }}
            >
              {t('sections.change-password.help-text')}
            </Box>
          </Grid>
        )}

        {wasSaved && (
          <>
            <Grid item xs={12} md="auto">
              <FormInput
                sx={{
                  width: { xs: '100%', md: 'none' },
                }}
                label={t('sections.change-password.fields.authcode.label')}
                name="authcode"
                id="authcode"
                data-testid="authcode"
                required={{
                  value: true,
                  message: t(
                    'sections.change-password.fields.authcode.required',
                  ),
                }}
                validate={{
                  isValidCode: (v) =>
                    ((v as string).length === 6 &&
                      validator.isNumeric(v as string)) ||
                    (t(
                      'sections.change-password.fields.authcode.invalid',
                    ) as string),
                }}
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  border: '1px solid #7E7E7E',
                  p: '1rem',
                }}
              >
                {t('sections.change-password.code-generated')}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

export default GeneratePasswordCode;
