/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
import { NotInterested } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckIcon from '@mui/icons-material/Check';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import {
  InputHTMLAttributes,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import spotifyLogo from '~/assets/img/spotify-badge.png';
import ConfirmationDialog from '~/base/components/ConfirmationDialog';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import { Translator } from '~/types/Translator';
import {
  ArtistInput,
  Maybe,
  RecordingInput,
  SongOwnershipType,
  useCreateRecordingMutation,
  useTracksLazyQuery,
} from '~/types/generated/graphql';
import SpotifyPreview from './SpotifyPreview';

interface SpotifyListProps extends Translator {
  artist: string;
  ownerships: Maybe<Maybe<SongOwnershipType>[]> | undefined;
  recordingIsrcList: Array<string>;
  title: string;
}

export default function SpotifyList({
  artist,
  ownerships,
  recordingIsrcList,
  title,
  t,
}: SpotifyListProps) {
  // Get song ID from URL.
  const { id: songId } = useParams();

  const navigate = useNavigate();

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmCloseReminder, setConfirmCloseReminder] = useState(false); // set 'do not remind' for user session
  const [doNotRemind, setDoNotRemind] = useState(false); // checkbox selection in dialog
  const [submittedRecordingId, setSubmittedRecordingId] = useState<
    string | null
  >(null); // recording ID to save to song
  const [hasIsrcSaved, setHasIsrcSaved] = useState(false);
  const [isrcList, setIsrcList] = useState(recordingIsrcList); // list of ISRCs saved to song
  const [returnToSongs, setReturnToSongs] = useState(false); // user selection to navigate back to songs after add recording

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const selectedRecording = useRef<Record<string, unknown> | null>(null);

  const [createRecording, { loading: saveRecording }] =
    useCreateRecordingMutation({
      fetchPolicy: 'no-cache',
    });

  const [getTracks, { data: tracksData, loading, error }] =
    useTracksLazyQuery();

  // Play/Pause Spotify track preview
  const handlePlayPauseClick = (previewUrl: string) => {
    if (audioRef.current && audioRef.current.src === previewUrl) {
      audioRef.current.pause();
      audioRef.current = null;
    } else {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      audioRef.current = new Audio(previewUrl);
      audioRef.current.play();
    }
  };

  const handleAddCompleted = () => {
    // Add new isrc to list
    if (selectedRecording?.current?.isrc) {
      setIsrcList((prev) => [
        ...prev,
        selectedRecording?.current?.isrc as string,
      ]);
    }
    setHasIsrcSaved(false);
  };

  const handleAddError = () => {
    setSubmittedRecordingId(null);
    setHasIsrcSaved(false);
  };

  // Check if song already has ISRC saved
  const doesRecordingExist = (spotifyTrackId: string) => {
    if (isrcList) {
      return isrcList.some((node) => node === spotifyTrackId);
    }
    return false;
  };

  // Add recording to song
  const handleAddRecording = () => {
    setSubmittedRecordingId(selectedRecording?.current?.id as string);

    const artistInput: ArtistInput[] = [
      {
        name: selectedRecording?.current?.artistName as string,
        spotifyArtistId: selectedRecording?.current?.artistId as string,
      },
    ];

    const recordingInput: RecordingInput = {
      album: selectedRecording?.current?.albumTitle as string,
      artists: artistInput,
      isrc: selectedRecording?.current?.isrc as string,
      name: selectedRecording?.current?.songTitle as string,
      songId: parseInt(songId || '0', 10),
      spotifyTrackId: selectedRecording?.current?.id as string,
    };

    // Submit Mutation - only run if ISRC does not already exist in song
    if (doesRecordingExist(selectedRecording?.current?.isrc as string)) {
      setHasIsrcSaved(true);
    } else {
      createRecording({
        variables: { input: recordingInput },
        onCompleted: handleAddCompleted,
        onError: handleAddError,
      });
    }
  };

  const handleAddRecordingClick = () => {
    if (confirmCloseReminder) {
      setReturnToSongs(false); // Option to return back to edit song page only available for open dialog
      handleAddRecording();
    } else {
      setConfirmDialogOpen(true);
    }
  };

  // Manage open dialog
  const handleConfirmDialogClose = (confirmed: string) => {
    setConfirmDialogOpen(false);
    if (confirmed === 'no') {
      selectedRecording.current = null;
    } else {
      if (confirmed === 'yes') {
        setReturnToSongs(true);
      }
      setConfirmCloseReminder(doNotRemind);

      if (selectedRecording.current !== null) handleAddRecording();
    }
  };

  // Search for tracks by artist
  useEffect(() => {
    if (artist) {
      getTracks({
        variables: {
          title,
          artist,
        },
      });
    }
  }, [artist, pagination]);

  // Return to edit song page
  useEffect(() => {
    if (returnToSongs) {
      navigate(`/songs/${songId}/edit`);
    }
  }, [returnToSongs]);

  useEffect(() => {
    setIsrcList(recordingIsrcList);
  }, [recordingIsrcList]);

  const columns = useMemo<MRT_ColumnDef<Record<string, unknown>>[]>(
    () => [
      {
        id: 'artwork-img',
        accessorKey: 'artworkUrl',
        header: t('page.add-recording.tabs.spotify.table.headers.artwork'),
        size: 160,
        // eslint-disable-next-line react/prop-types
        Cell({ cell: { getValue } }) {
          return (
            <img
              src={getValue<string>()}
              width="96"
              height="96"
              alt="album cover"
            />
          );
        },
      },
      {
        id: 'artist-name',
        accessorKey: 'artistName',
        header: t('page.add-recording.tabs.spotify.table.headers.artist'),
      },
      {
        id: 'song-title',
        accessorKey: 'songTitle',
        header: t('page.add-recording.tabs.spotify.table.headers.track'),
      },
      {
        id: 'album-title',
        accessorKey: 'albumTitle',
        header: t('page.add-recording.tabs.spotify.table.headers.album'),
      },
      { id: 'isrc', accessorKey: 'isrc', header: 'ISRC', size: 128 },
      {
        id: 'preview-url',
        accessorKey: 'previewUrl',
        header: t('page.add-recording.tabs.spotify.table.headers.preview'),
        size: 128,
        Cell: ({ row }: { row: { original: Record<string, unknown> } }) => {
          const previewUrl = row.original.previewUrl as string;

          return (
            <Grid container direction="row" alignItems="center">
              <Grid item height="0.875rem" sx={{ color: '#7E7E7E' }}>
                {previewUrl ? (
                  <PlayCircleOutlineIcon
                    sx={{ cursor: 'pointer' }}
                    onClick={() => handlePlayPauseClick(previewUrl)}
                  />
                ) : (
                  <NotInterested />
                )}
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Grid item height="1rem">
                    <img src={spotifyLogo} height="16" alt="spotify logo" />
                  </Grid>
                  <Typography variant="caption" padding={0} lineHeight={1}>
                    {previewUrl ? `00:29:00` : `00:00:00`}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          );
        },
      },
      {
        id: 'add-recording',
        accessorKey: 'id',
        header: t('page.add-recording.tabs.spotify.table.headers.recording'),
        Cell: ({ row }: { row: { original: Record<string, unknown> } }) => {
          const currentId = row.original.id as string;
          return currentId !== selectedRecording?.current?.id ||
            (currentId === selectedRecording?.current?.id &&
              currentId !== submittedRecordingId) ||
            saveRecording ? (
            // Add recording option
            <Button
              variant="text"
              onClick={() => {
                selectedRecording.current = row.original;
                handleAddRecordingClick();
              }}
              sx={{
                color: '#7E7E7E',
                textTransform: 'none',
              }}
              disabled={saveRecording}
            >
              <AddCircleOutlineIcon
                sx={{ height: '1.25rem', fontSize: '2rem' }}
              />
              <Typography
                sx={{
                  fontWeight: '700',
                }}
                variant="caption"
              >
                {t('page.add-recording.tabs.spotify.table.recording.add')}
              </Typography>
            </Button>
          ) : (
            <>
              {/* Added recording */}
              {!hasIsrcSaved && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#7FC832',
                  }}
                >
                  <CheckIcon
                    sx={{
                      height: '1.25rem',
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: '700',
                    }}
                    variant="caption"
                  >
                    {t('page.add-recording.tabs.spotify.table.recording.added')}
                  </Typography>
                </Box>
              )}
              {/* ISRC already exists */}
              {hasIsrcSaved && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#D32F2F',
                  }}
                >
                  <CheckIcon
                    sx={{
                      height: '1.25rem',
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: '700',
                    }}
                    variant="caption"
                  >
                    {t('page.add-recording.tabs.spotify.table.recording.error')}
                  </Typography>
                </Box>
              )}
            </>
          );
        },
      },
    ],
    [hasIsrcSaved, submittedRecordingId, saveRecording],
  );

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  if (xs) {
    return (
      <div>
        {tracksData?.tracks ? (
          tracksData?.tracks?.map((r) => (
            <SpotifyPreview
              artist={r?.artistName}
              isrc={r?.isrc}
              song={r?.songTitle}
              t={t}
            />
          ))
        ) : (
          <LoadingIndicator size={4} />
        )}
      </div>
    );
  }

  const dialogContent = (
    <>
      {t(`page.add-recording.tabs.spotify.confirm-add.content1`)}
      {selectedRecording.current && selectedRecording.current.songTitle}
      {t(`page.add-recording.tabs.spotify.confirm-add.content2`)}
      {title}
      {t(`page.add-recording.tabs.spotify.confirm-add.content3`)}
      <Box sx={{ pb: '0.75rem', pl: '0.5rem' }}>
        {ownerships?.map((ownership) => (
          <li key={ownership?.songwriter?.id}>
            {`${ownership?.songwriter?.firstName} 
            ${ownership?.songwriter?.middleName || ''} 
            ${ownership?.songwriter?.lastName}`}
          </li>
        ))}
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={doNotRemind}
            inputProps={
              {
                'data-testid': 'pre-submission-checkbox',
              } as InputHTMLAttributes<HTMLInputElement>
            }
            onChange={(e) => {
              setDoNotRemind(e.target.checked);
            }}
          />
        }
        label={t('page.add-recording.tabs.spotify.confirm-add.checkbox')}
        sx={{ pl: '0.5rem' }}
      />
    </>
  );

  return (
    <div>
      <ConfirmationDialog
        dialogContent={dialogContent}
        dialogTitle={t(`page.add-recording.tabs.spotify.confirm-add.title`)}
        handleClose={handleConfirmDialogClose}
        noButtonText={t(
          `page.add-recording.tabs.spotify.confirm-add.button.cancel`,
        )}
        open={confirmDialogOpen}
        optionalButtonText={t(
          `page.add-recording.tabs.spotify.confirm-add.button.add-another`,
        )}
        yesButtonText={t(
          `page.add-recording.tabs.spotify.confirm-add.button.back`,
        )}
      />
      <MaterialReactTable
        columns={columns}
        data={(tracksData?.tracks as unknown as Record<string, string>[]) || []}
        enableColumnActions={false}
        enableTopToolbar={false}
        onPaginationChange={setPagination}
        muiTableHeadProps={{
          sx: {
            '& tr': {
              boxShadow: 'none',
            },
            '& th': {
              borderBottom: '1px #A4A4A4 solid',
            },
          },
        }}
        muiTablePaperProps={{
          sx: {
            boxShadow: 'none',
          },
        }}
        state={{
          isLoading: loading,
          pagination,
          showAlertBanner: error !== undefined,
          showProgressBars: loading,
        }}
      />
    </div>
  );
}
