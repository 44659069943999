import { Box, Typography } from '@mui/material';
import { map } from 'lodash';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormRadio from '~/base/components/FormRadio';
import OnboardingFormContext from '~/onboarding/components/OnboardingFormContext';

function OnboardingAdministrationQuestions() {
  const { t } = useTranslation(['onboarding']);
  const { getValues } = useFormContext();

  const isSingleWriterSelected = (value: string): boolean | undefined =>
    value === undefined ? undefined : value === 'single';

  const [isSingleWriter, setSingleWriter] = useState<boolean | undefined>(
    isSingleWriterSelected(getValues('expectedManagedWriters')),
  );

  const handleSingleWriterChange = (event: SyntheticEvent, value: string) => {
    setSingleWriter(isSingleWriterSelected(value));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <OnboardingFormContext.Consumer>
      {({
        expectedManagedWriterChoices,
        catalogRepresentationChoices,
        yesNoChoices,
      }) => (
        <Box
          data-testid="onboarding-administration-questions"
          sx={{
            paddingLeft: '.5rem',
            paddingTop: { md: '2rem' },
            paddingBottom: { md: '2rem' },
          }}
        >
          <Typography
            variant="h4"
            component="h3"
            sx={{ margin: '1.75rem 0 1rem' }}
          >
            {t('form.questions.administration.multiple')}
          </Typography>
          <FormRadio
            name="expectedManagedWriters"
            id="expectedManagedWriters"
            data-testid="expected-managed-writers"
            sx={{ width: '50%' }}
            columns={3}
            onOptionSelected={handleSingleWriterChange}
            required
            autofocus
            options={map(expectedManagedWriterChoices?.edges, (edge) => ({
              choiceId: edge?.node?.choice as string,
              choiceLabel: t(`form.options.${edge?.node?.choice}`, {
                defaultValue: edge?.node?.label as string,
              }),
            }))}
          />

          {isSingleWriter !== undefined && (
            <Box data-testid="single-writer-questions">
              <Typography
                variant="h4"
                component="h3"
                sx={{ margin: '1.75rem 0 1rem' }}
              >
                {t('form.questions.administration.multiple-songtrust')}
              </Typography>
              <FormRadio
                name="catalogRepresentation"
                id="catalogRepresentation"
                data-testid="catalog-representation"
                sx={{ width: '50%' }}
                columns={3}
                required
                autofocus
                options={map(catalogRepresentationChoices?.edges, (edge) => ({
                  choiceId: edge?.node?.choice as string,
                  choiceLabel: t(`form.options.${edge?.node?.choice}`, {
                    defaultValue: edge?.node?.label as string,
                  }),
                }))}
              />
              <Typography
                variant="h4"
                component="h3"
                sx={{ margin: '1.75rem 0 1rem' }}
              >
                {t('form.questions.administration.single-copublished')}
              </Typography>
              <FormRadio
                name="coPublished"
                id="coPublished"
                data-testid="co-published"
                sx={{ width: '50%' }}
                columns={3}
                required
                options={map(yesNoChoices?.edges, (edge) => ({
                  choiceId: edge?.node?.choice as string,
                  choiceLabel: t(`form.options.${edge?.node?.choice}`, {
                    defaultValue: edge?.node?.label,
                  }),
                }))}
              />
              {!isSingleWriter && (
                <>
                  <Typography
                    variant="h4"
                    component="h3"
                    sx={{ margin: '1.75rem 0 1rem' }}
                  >
                    {t('form.questions.administration.single-pro')}
                  </Typography>
                  <FormRadio
                    name="personalPublishingEntity"
                    id="personalPublishingEntity"
                    data-testid="personal-publishing-entity"
                    sx={{ width: '50%' }}
                    columns={3}
                    required
                    options={map(yesNoChoices?.edges, (edge) => ({
                      choiceId: edge?.node?.choice as string,
                      choiceLabel: t(`form.options.${edge?.node?.choice}`, {
                        defaultValue: edge?.node?.label as string,
                      }),
                    }))}
                  />
                </>
              )}
            </Box>
          )}
        </Box>
      )}
    </OnboardingFormContext.Consumer>
  );
}

export default OnboardingAdministrationQuestions;
