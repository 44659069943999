import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Translator } from '~/types/Translator';

interface YoutubeClaimColumnDialogProps extends Translator {
  isOptedOut: boolean;
  isModalOpen: boolean;
  onSuccess: () => void;
  onCancel: () => void;
}

function YoutubeClaimColumnDialog({
  t,
  isOptedOut,
  isModalOpen,
  onSuccess,
  onCancel,
}: YoutubeClaimColumnDialogProps) {
  const langSegment = isOptedOut ? 'opt-in' : 'opt-out';
  return (
    <Dialog
      open={isModalOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t(
          `page.songwriters.tabs.client.table.columns.youtube.modal.${langSegment}.title`,
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t(
            `page.songwriters.tabs.client.table.columns.youtube.modal.${langSegment}.question`,
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>
          {t(
            `page.songwriters.tabs.client.table.columns.youtube.modal.${langSegment}.no`,
          )}
        </Button>
        <Button onClick={onSuccess} autoFocus>
          {t(
            `page.songwriters.tabs.client.table.columns.youtube.modal.${langSegment}.yes`,
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default YoutubeClaimColumnDialog;
