/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from '~/base/components/LoadingIndicator';

import { Translator } from '~/types/Translator';

interface AccessManagementInvitePageActionsProps extends Translator {
  editMode: boolean;
  submitLoading: boolean;
}
function AccessManagementInvitePageActions({
  t,
  submitLoading,
  editMode,
}: AccessManagementInvitePageActionsProps) {
  const navigate = useNavigate();

  return (
    <div data-testid="access-management-invite-page-actions">
      <Grid container justifyContent="space-between" sx={{ pt: '1rem' }}>
        <Grid item>
          <Button
            data-testid="cancel-button"
            variant="outlined"
            color="info"
            type="button"
            onClick={() => {
              navigate('/account/access-management');
            }}
            sx={{
              pl: '2rem',
              pr: '2rem',
            }}
          >
            {t('sections.access-management-invite.cancel')}
          </Button>
        </Grid>
        <Grid item>
          {submitLoading && <LoadingIndicator size={50} />}
          {!submitLoading && (
            <Button
              data-testid="invite-button"
              variant="contained"
              color="secondary"
              type="submit"
              sx={{
                pl: '2rem',
                pr: '2rem',
              }}
            >
              {editMode && t('sections.access-management-invite.save-changes')}
              {!editMode && t('sections.access-management-invite.invite')}
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default AccessManagementInvitePageActions;
