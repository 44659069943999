import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { InputHTMLAttributes } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import FormInput from '~/base/components/FormInput';
import SectionTitle from '~/base/components/SectionTitle/SectionTitle';
import { Translator } from '~/types/Translator';

export default function SongTitle({ t }: Translator) {
  const { control, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: 'alternateTitles',
    control,
  });

  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} gap={3}>
        <SectionTitle>{t('form.titles.information')}</SectionTitle>
        <FormInput
          id="title"
          label={t('form.labels.title')}
          name="title"
          sx={{
            width: '100%',
          }}
          inputProps={{ 'data-testid': 'song-title-input' }}
          inputFullWidth
          displayRequired
          required={{
            value: true,
            message: t('form.errors.songTitleRequired'),
          }}
          clearErrorOnFocus
        />
        <Grid container item xs={12} gap={1}>
          {fields.length !== 0 && (
            <Typography variant="h5">
              {t('form.labels.alternateTitles')}
            </Typography>
          )}
          {fields.map((field, i) => {
            return (
              <Grid
                key={field.id}
                container
                item
                xs={12}
                alignItems="center"
                wrap="nowrap"
              >
                <FormInput
                  key={field.id}
                  id={`alternateTitles-${i}-title`}
                  name={`alternateTitles.${i}.title`}
                  label={t('form.labels.alternateTitle')}
                  sx={{ flexGrow: '1' }}
                  inputProps={{ 'data-testid': `alternate-title-${i}` }}
                  inputFullWidth
                  required
                  displayRequired
                  clearErrorOnFocus
                />
                <IconButton
                  data-testid={`delete-alternate-title-${i}`}
                  onClick={() => remove(i)}
                  sx={{
                    alignSelf: 'flex-start',
                    mt: 2,
                    pt: 1.75,
                    pb: 1.75,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            );
          })}
        </Grid>
        <Button
          data-testid="add-alternate-title"
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={() => append({ title: '' })}
        >
          {t('form.buttons.alternateTitle')}
        </Button>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={
                  {
                    'data-testid': 'public-domain-checkbox',
                  } as InputHTMLAttributes<HTMLInputElement>
                }
                onChange={({ target: { checked } }) =>
                  setValue('publicDomain', checked)
                }
              />
            }
            label={t('form.labels.public')}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
