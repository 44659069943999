import { useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useLogout from '~/login/hooks/useLogout';
import { LogoutUserMutation } from '~/types/generated/graphql';

function Logout() {
  const navigate = useNavigate();
  const client = useApolloClient();

  const logoutUserSuccessCallback = (data: LogoutUserMutation) => {
    client.resetStore();

    if (data) navigate('/login');
  };

  const [logoutUser] = useLogout(logoutUserSuccessCallback);

  useEffect(() => {
    logoutUser();
  }, []);

  return null;
}

export default Logout;
