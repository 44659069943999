import { Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import PageTitle from '~/base/components/PageTitle/PageTitle';
import usePageTitle from '~/hooks/usePageTitle';
import { Translator } from '~/types/Translator';
import ChangePasswordSection from '../ChangePasswordSection/ChangePasswordSection';

function ForceChangePasswordPage({ t }: Translator) {
  usePageTitle(t('sections.change-password.title'));

  return (
    <div data-testid="force-change-password-page">
      <Container>
        <Grid
          container
          spacing={2}
          sx={{
            flexDirection: {
              xs: 'column',
            },
          }}
        >
          <Grid item xs={12} md={12}>
            <PageTitle
              pageTitle={{
                id: 'change-password-page-title',
                title: t('sections.force-change-password.title'),
              }}
            />
            <Typography
              data-testid="change-password-page-subtitle"
              variant="body1"
              component="p"
            >
              {t('sections.force-change-password.content')}{' '}
              <Link
                to={t('sections.force-change-password.support-link')}
                target="_blank"
              >
                {t('sections.force-change-password.support-team')}.
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12} md={12}>
            <ChangePasswordSection t={t} dashboardRedirect />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default ForceChangePasswordPage;
