/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import { useReactiveVar } from '@apollo/client';
import { Box, Grid, SelectChangeEvent } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import type { PaginationState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import _ from 'lodash';
import {
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MaterialReactTable,
} from 'material-react-table';
import { useMemo, useState } from 'react';
import {
  Controller,
  FieldValues,
  FormProvider,
  UseFormReturn,
} from 'react-hook-form';
import validator from 'validator';
import {
  currentUserDisplayNameVar,
  toRemoveVar,
  toUpsertVar,
} from '~/account/state/state';
import FormSelect from '~/base/components/FormSelect';
import { FormSelectOption } from '~/base/components/FormSelect/FormSelect';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import {
  DATEPICKER_STYLES,
  TABLE_BODY_CELL_PROPS,
  TABLE_HEAD_CELL_PROPS,
  TABLE_HEAD_PROPS,
  TABLE_HEAD_ROW_PROPS,
  TABLE_PAPER_PROPS,
  TABLE_PROPS,
} from '~/base/constants/tableConstants';
import { Translator } from '~/types/Translator';
import {
  AccessType,
  RemoveSongwriterAccessInput,
  SongwriterType,
  SongwriterTypeEdge,
  UpsertSongwriterAccessInput,
  useAvailableSongwritersAccessQuery,
} from '~/types/generated/graphql';

interface AccessManagementSongwriterListProps extends Translator {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formMethods: UseFormReturn<FieldValues, any>;
  editSongtrustUserId?: number;
}

function AccessManagementSongwriterList({
  t,
  formMethods,
  editSongtrustUserId = undefined,
}: AccessManagementSongwriterListProps) {
  const [loadedSongwriterAccess, setLoadedSongwriterAccess] = useState<
    SongwriterTypeEdge[]
  >([]);

  const [totalCount, setTotalCount] = useState(0);
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  });

  const toRemove = useReactiveVar(toRemoveVar);
  const toUpsert = useReactiveVar(toUpsertVar);

  /// /////////////////////////
  // Fetch data using hook.  //
  /// /////////////////////////

  const { data, loading, error } = useAvailableSongwritersAccessQuery({
    variables: {
      songtrustUserId: editSongtrustUserId || undefined,
      offset: pagination.pageIndex * pagination.pageSize,
      first: pagination.pageSize,
    },
    onCompleted: (res) => {
      let preRowSelection = {};

      const totalItems =
        res.availableSongwritersAccess?.songwriters?.totalCount;
      const availableSongwritersAccess =
        res.availableSongwritersAccess?.songwriters?.edges;
      const currentSongtrustUser =
        res.availableSongwritersAccess?.songtrustUser?.user;

      _.each(availableSongwritersAccess, (item) => {
        // If we are editing, then checkoff already selected songwriters with crosslinks.
        if (editSongtrustUserId) {
          const hasPermissions =
            item?.node?.songtrustuserCrosslinks?.edges.length;

          const crossLinkData =
            item?.node?.songtrustuserCrosslinks?.edges[0]?.node;

          const permissionTypeFormName = `permission-type-${item?.node?.id}`;
          const endDateFormName = `permission-enddate-${item?.node?.id}`;

          if (crossLinkData) {
            const { id: crossLinkId, endDate, accessType } = crossLinkData;

            if (hasPermissions) {
              // Set form values.
              if (accessType) {
                formMethods.setValue(
                  permissionTypeFormName,
                  accessType.toLowerCase() as AccessType,
                );
              }

              if (endDate) {
                formMethods.setValue(endDateFormName, dayjs(endDate));
              }

              // Set Selection.
              const id = item?.node?.id as string;
              preRowSelection = {
                ...preRowSelection,
                [id]: true,
              };
            }
          } else {
            // Set default field values.
            formMethods.setValue(permissionTypeFormName, AccessType.ReadOnly);
            formMethods.setValue(endDateFormName, undefined);
          }

          // Set the current user name and/or email.
          if (currentSongtrustUser) {
            currentUserDisplayNameVar(
              `${currentSongtrustUser.firstName} ${currentSongtrustUser.lastName}`,
            );
          }
        } else {
          // Set default field values.
          formMethods.setValue(
            `permission-type-${item?.node?.id}`,
            AccessType.ReadOnly,
          );

          formMethods.setValue(`permission-enddate-${item?.node?.id}`, null);
        }
      });

      if (availableSongwritersAccess && totalItems) {
        setLoadedSongwriterAccess(
          res.availableSongwritersAccess?.songwriters
            ?.edges as SongwriterTypeEdge[],
        );
        setRowSelection({ ...rowSelection, ...preRowSelection });
        setTotalCount(totalItems);
      }
    },
  });

  /// ////////////
  // Handlers.  //
  /// ////////////

  const createNewUpsertChange = (songwriterId: number) => {
    const permissionTypeFormName = `permission-type-${songwriterId}`;
    const endDateFormName = `permission-enddate-${songwriterId}`;

    const endDateValue = formMethods.getValues(endDateFormName);

    // Create updated upsert.
    const upcertCrossLink = {
      songwriterId,
      accessType: formMethods.getValues(permissionTypeFormName) as AccessType,
      endDate: endDateValue
        ? dayjs(endDateValue).format('YYYY-MM-DD')
        : undefined,
    } as UpsertSongwriterAccessInput;

    toUpsert.push(upcertCrossLink);

    // Update reactive var.
    toUpsertVar(toUpsert);
  };

  // How to handle change of Permission Type
  const handleSelectChange = (
    event: SelectChangeEvent,
    selectedSongwriter: SongwriterType,
  ) => {
    const songwriterId = parseInt(selectedSongwriter.id, 10);

    const updateToUpsert = toUpsertVar();
    const existingUpdate = _.find(
      updateToUpsert,
      (item) => item.songwriterId === songwriterId,
    );

    const newValue = event.target.value as AccessType;

    // Update react-hook-form values.
    const permissionTypeFormName = `permission-type-${selectedSongwriter.id}`;
    formMethods.setValue(permissionTypeFormName, newValue);

    // Update Upsert, wrapped inside this state update because reasons...
    setRowSelection((prev) => {
      if (existingUpdate) {
        existingUpdate.accessType = newValue;
        toUpsertVar(updateToUpsert);
      } else if (prev[songwriterId]) {
        createNewUpsertChange(songwriterId);
      }
      return prev;
    });
  };

  // How to handle change of Permission End Date
  const handleDateChange = (
    newValue: Date | null,
    selectedSongwriter: SongwriterType,
  ) => {
    const songwriterId = parseInt(selectedSongwriter.id, 10);

    const updateToUpsert = toUpsertVar();
    const existingUpdate = _.find(
      updateToUpsert,
      (item) => item.songwriterId === songwriterId,
    );

    // Update react-hook-form values.
    const endDateFormName = `permission-enddate-${songwriterId}`;
    const endDateValue = newValue !== null ? newValue : undefined;
    const endDateValueDayJs =
      newValue !== null ? dayjs(newValue).format('YYYY-MM-DD') : undefined;

    formMethods.setValue(endDateFormName, endDateValue);

    // Update Upsert, wrapped inside this state update because reasons...
    setRowSelection((prev) => {
      if (existingUpdate) {
        existingUpdate.endDate = endDateValueDayJs;
        toUpsertVar(updateToUpsert);
      } else if (prev[songwriterId]) {
        createNewUpsertChange(songwriterId);
      }
      return prev;
    });
  };

  // How to handle a songwriter row selected.
  const handleRowSelected = (selectedSongwriter: SongwriterType) => {
    const songwriterId = parseInt(selectedSongwriter.id, 10);

    // Does it exist in the toRemove list? Remove it. (edit mode only)
    if (editSongtrustUserId) {
      const existingCrossLink =
        selectedSongwriter.songtrustuserCrosslinks.edges[0]?.node;

      if (existingCrossLink) {
        _.remove(
          toRemove,
          (link) => link.crosslinkId === parseInt(existingCrossLink?.id, 10),
        );
      }
    }

    createNewUpsertChange(songwriterId);
  };

  // How to handle a songwriter row un-selected.
  const handleRowUnSelected = (selectedSongwriter: SongwriterType) => {
    // Does it exist in the toUpsert list? Remove it.
    _.remove(
      toUpsert,
      (link) => link.songwriterId === parseInt(selectedSongwriter.id, 10),
    );

    // Check if we should add this to toRemove list. Add it. (edit mode only)
    if (editSongtrustUserId) {
      const existingCrossLink =
        selectedSongwriter.songtrustuserCrosslinks.edges[0]?.node;

      if (existingCrossLink) {
        const removeCrossLink = {
          crosslinkId: parseInt(existingCrossLink.id, 10),
        } as RemoveSongwriterAccessInput;
        toRemove.push(removeCrossLink);

        // Update reactive var.
        toRemoveVar(toRemove);
      }
    }
  };

  const columns = useMemo<MRT_ColumnDef<SongwriterTypeEdge>[]>(
    () => [
      {
        accessorKey: 'node',
        header: t('sections.access-management-invite.table.songwriter'),
        minSize: 250,
        Cell: (cellData) => (
          <Box>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{ color: '#D5164B', textDecoration: 'underline' }}
              >
                {`${cellData.cell.getValue<SongwriterType>().firstName} ${
                  cellData.cell.getValue<SongwriterType>().lastName
                }`}
              </Grid>
              <Grid item xs={12} sx={{ fontWeight: 'bold' }}>
                {cellData.cell.getValue<SongwriterType>().email}
              </Grid>
            </Grid>
          </Box>
        ),
      },
      {
        accessorKey: 'node.songtrustuserCrosslinks',
        header: t('sections.access-management-invite.table.permissions'),
        minSize: 300,
        Cell: (cellData) => {
          // Songwriter.
          const songwriterId = cellData.row.original.node?.id;

          // React Hook Form Field Names.
          const permissionTypeFormName = `permission-type-${songwriterId}`;
          const endDateFormName = `permission-enddate-${songwriterId}`;

          // Dropdown options.
          const options = [
            {
              choiceId: AccessType.ReadOnly,
              choiceLabel: t(
                'sections.access-management-invite.form.permission-type.options.read-only',
              ),
            },
            {
              choiceId: AccessType.FullAccess,
              choiceLabel: t(
                'sections.access-management-invite.form.permission-type.options.full-access',
              ),
            },
          ] as Array<FormSelectOption>;

          return (
            <Box>
              <FormProvider {...formMethods}>
                <Grid container justifyItems="center" flexDirection="row">
                  <Grid item xs={6} justifyItems="center">
                    <FormSelect
                      inputSx={{
                        width: 'auto',
                        minWidth: '10rem',
                      }}
                      options={options}
                      onOptionSelected={(e) => {
                        handleSelectChange(
                          e,
                          cellData.row.original.node as SongwriterType,
                        );
                      }}
                      value={formMethods.getValues(
                        `permission-type-${songwriterId}`,
                      )}
                      label={t(
                        'sections.access-management-invite.form.permission-type.label',
                      )}
                      id="permission-type"
                      name={`permission-type-${songwriterId}`}
                      data-testid="permission-type"
                      noBlur
                    />
                  </Grid>
                  <Grid item xs={6} justifyItems="center">
                    <Controller
                      control={formMethods.control}
                      name={endDateFormName}
                      rules={{
                        required: false,
                        validate: {
                          isDateOrUndefined: (v) => {
                            return (
                              validator.isAfter(
                                dayjs(v as string).format('MM-DD-YYYY'),
                                dayjs().add(-1, 'day').format('MM-DD-YYYY'),
                              ) ||
                              v === undefined ||
                              v === null ||
                              t(
                                'sections.access-management-invite.form.enddate.invalid',
                              )
                            );
                          },
                        },
                      }}
                      render={({
                        field: { ref, onBlur, name, ...field },
                        fieldState,
                      }) => {
                        const placeholderText =
                          formMethods.getValues(endDateFormName) === undefined
                            ? t(
                                'sections.access-management-invite.form.enddate.placeholder',
                              )
                            : t(
                                'sections.access-management-invite.form.enddate.format',
                              );

                        return (
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              {...field}
                              onChange={(newValue) => {
                                handleDateChange(
                                  newValue,
                                  cellData.row.original.node as SongwriterType,
                                );
                              }}
                              inputRef={ref}
                              label={t(
                                'sections.access-management-invite.form.enddate.label',
                              )}
                              value={
                                formMethods.getValues(endDateFormName) ===
                                undefined
                                  ? undefined
                                  : formMethods.getValues(endDateFormName)
                              }
                              minDate={dayjs(new Date())}
                              slotProps={{
                                textField: {
                                  inputProps: {
                                    'data-testid': 'end-date-picker',
                                  },
                                  InputLabelProps: { shrink: true },
                                  placeholder: placeholderText,
                                  onBlur,
                                  name,
                                  error: !!fieldState.error,
                                  sx: DATEPICKER_STYLES,
                                },
                              }}
                            />
                          </LocalizationProvider>
                        );
                      }}
                    />
                  </Grid>
                </Grid>
              </FormProvider>
            </Box>
          );
        },
      },
    ],
    [],
  );

  return (
    <div data-testid="access-management-songwriter-list">
      {loading && <LoadingIndicator size={50} />}
      {!loading && data && (
        <MaterialReactTable
          columns={columns}
          data={loadedSongwriterAccess}
          enableTopToolbar={false}
          enableColumnActions={false}
          enableColumnDragging={false}
          onPaginationChange={setPagination}
          rowCount={totalCount}
          state={{
            isLoading: loading,
            rowSelection,
            pagination,
            showAlertBanner: error !== undefined,
            showProgressBars: loading,
          }}
          muiTableHeadProps={TABLE_HEAD_PROPS}
          muiTableHeadRowProps={TABLE_HEAD_ROW_PROPS}
          muiTableHeadCellProps={TABLE_HEAD_CELL_PROPS}
          muiTableBodyCellProps={TABLE_BODY_CELL_PROPS}
          muiTableProps={TABLE_PROPS}
          muiTablePaperProps={TABLE_PAPER_PROPS}
          onRowSelectionChange={setRowSelection}
          muiSelectAllCheckboxProps={({ table }) => ({
            onClick: () => {
              const tableRows = table.getRowModel().rows;
              if (table.getIsAllRowsSelected()) {
                // Unselect them all.
                _.each(tableRows, (row) => {
                  handleRowUnSelected(row.original.node as SongwriterType);
                });
              } else {
                // Select them all.
                _.each(tableRows, (row) => {
                  handleRowSelected(row.original.node as SongwriterType);
                });
              }
            },
          })}
          muiSelectCheckboxProps={({ row }) => ({
            onClick: () => {
              // Update table row selection state.
              setRowSelection((prev) => {
                // selecting
                if (prev[row.id] === undefined) {
                  handleRowSelected(row.original.node as SongwriterType);
                }

                // unselecting
                if (prev[row.id] === true) {
                  handleRowUnSelected(row.original.node as SongwriterType);
                }

                const nextRowSelection = {
                  ...prev,
                  [row.id]: true,
                };
                if (prev[row.id] === true) delete nextRowSelection[row.id];
                return nextRowSelection;
              });
            },
          })}
          getRowId={(originalRow) =>
            (originalRow.node?.id as unknown as number).toString()
          }
          manualPagination
          enableBottomToolbar
          enableRowSelection
        />
      )}
    </div>
  );
}

export default AccessManagementSongwriterList;
