import { Typography } from '@mui/material';

interface DashboardMyEarningsQuarterProps {
  label: string;
  value: string;
}

function DashboardMyEarningsQuarter({
  label,
  value,
}: DashboardMyEarningsQuarterProps) {
  return (
    <div data-testid="dashboard-my-earnings-quarter">
      <Typography variant="body2" component="p" align="center">
        {label}
      </Typography>
      <Typography variant="h4" component="h4" align="center">
        {value}
      </Typography>
    </div>
  );
}

export default DashboardMyEarningsQuarter;
