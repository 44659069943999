import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import { Translator } from '~/types/Translator';
import {
  SortDirection,
  SortField,
  useClaimedVideosLazyQuery,
} from '~/types/generated/graphql';
import YoutubeClaimedFilters from './YoutubeClaimedFilters';
import YoutubeClaimedVideo from './YoutubeClaimedVideo';
import { withPending } from './claimedStates';

export default function YoutubeClaimedList({ t }: Translator) {
  // State
  const [pageState, setPageState] = useState(1);
  const [previewInfoOpen, setPreviewInfoOpen] = useState(false);
  const [defaultFilterState] = useState({
    state: withPending,
    sortBy: {
      direction: SortDirection.Desc,
      field: SortField.ViewCount,
    },
    before: undefined,
    after: undefined,
    first: 9,
  });

  const formMethods = useForm({
    defaultValues: defaultFilterState,
  });

  // Hooks
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));

  const [getClaimedVideos, { data, error, loading, refetch }] =
    useClaimedVideosLazyQuery({
      variables: {
        ...defaultFilterState,
      },
    });

  useEffect(() => {
    getClaimedVideos();
  }, []);

  useEffect(() => {
    const subscription = formMethods.watch((value) =>
      refetch({ ...defaultFilterState, sortBy: value.sortBy }),
    );
    return () => subscription.unsubscribe();
  }, [formMethods.watch]);

  // Handlers
  const handleNextPage = () => {
    formMethods.handleSubmit((d) => {
      if (pageState === 2) {
        refetch({
          first: 9,
          before: undefined,
          after: undefined,
        });
      } else {
        refetch({
          ...d,
          first: undefined,
          last: 9,
          before: data?.claimedVideos?.pageInfo?.startCursor,
        });
      }
      setPageState((state) => state - 1);
    })();
  };

  const handlePrevPage = () => {
    formMethods.handleSubmit((d) => {
      refetch({
        ...d,
        first: 9,
        after: data?.claimedVideos?.pageInfo?.endCursor,
      });
      setPageState((state) => state + 1);
    })();
  };

  // Render
  return (
    <>
      {error && (
        <>
          <p>{t('page.claimed.error')}</p>
          <Button variant="contained" onClick={() => getClaimedVideos()}>
            {t('page.claimed.retry')}
          </Button>
        </>
      )}

      {!error && (
        <FormProvider {...formMethods}>
          <Grid container item direction="column" xs={12} sx={{ gap: '2rem' }}>
            {data?.claimedVideos?.edges?.length || loading ? (
              <YoutubeClaimedFilters t={t} />
            ) : (
              <Box component="span" />
            )}
            {loading ? (
              <LoadingIndicator size={100} />
            ) : (
              <Grid
                container
                item
                xs={12}
                sm={3}
                rowGap={6}
                columnGap={10}
                justifyContent="space-between"
              >
                {data?.claimedVideos?.edges?.length ? (
                  <>
                    <Grid container justifyContent="end">
                      <ClickAwayListener
                        onClickAway={() => setPreviewInfoOpen(false)}
                      >
                        <Tooltip
                          open={!xs && previewInfoOpen}
                          title={t('page.claimed.info.copy')}
                          onClose={() => setPreviewInfoOpen(false)}
                          onOpen={() => setPreviewInfoOpen(true)}
                        >
                          <Button
                            variant="text"
                            endIcon={<InfoOutlinedIcon />}
                            color="error"
                            onClick={() => setPreviewInfoOpen(true)}
                            sx={{ textTransform: 'none' }}
                          >
                            {t(
                              `page.claimed.info.${xs ? 'button' : 'buttonLong'}`,
                            )}
                          </Button>
                        </Tooltip>
                      </ClickAwayListener>
                      <Dialog
                        open={xs ? previewInfoOpen : false}
                        onClose={() => setPreviewInfoOpen(false)}
                      >
                        <DialogContent>
                          <DialogContentText>
                            {t('page.claimed.info.copy')}
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => setPreviewInfoOpen(false)}>
                            {t('page.claimed.info.ok')}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Grid>
                    {data?.claimedVideos?.edges.map((edge) => {
                      return (
                        <YoutubeClaimedVideo
                          key={edge?.node?.id}
                          title={edge?.node?.song.title || ''}
                          views={edge?.node?.video.viewCount || 0}
                          youtubeId={edge?.node?.video.youtubeId || ''}
                          start={edge?.node?.start || ''}
                          end={edge?.node?.end || ''}
                          t={t}
                        />
                      );
                    })}
                  </>
                ) : (
                  []
                )}
                {data?.claimedVideos?.pageInfo?.hasNextPage || pageState > 1 ? (
                  <Grid container item sx={{ justifyContent: 'space-between' }}>
                    <Button
                      variant="outlined"
                      disabled={pageState < 2}
                      sx={{ minWidth: '7rem' }}
                      onClick={handleNextPage}
                    >
                      <Typography variant="button">
                        {t('page.claimed.filters.prev')}
                      </Typography>
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      disabled={!data?.claimedVideos?.pageInfo?.hasNextPage}
                      sx={{ minWidth: '7rem' }}
                      onClick={handlePrevPage}
                    >
                      <Typography variant="button" data-testid="next-button">
                        {t('page.claimed.filters.next')}
                      </Typography>
                    </Button>
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
            )}
          </Grid>
        </FormProvider>
      )}
    </>
  );
}
