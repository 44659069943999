const socialMediaConstants = {
  FACEBOOK_LINK_URL: 'https://www.facebook.com/songtrust/',
  TWITTER_LINK_URL: 'https://twitter.com/songtrust',
  TIKTOK_LINK_URL: 'https://www.tiktok.com/@songtrust',
  INSTAGRAM_LINK_URL: 'https://www.instagram.com/songtrust/',
  YOUTUBE_LINK_URL: 'https://www.youtube.com/user/SONGTRUST',
  LINKEDIN_LINK_URL: 'https://www.linkedin.com/company/songtrust/',
};

export const {
  FACEBOOK_LINK_URL,
  TWITTER_LINK_URL,
  TIKTOK_LINK_URL,
  INSTAGRAM_LINK_URL,
  YOUTUBE_LINK_URL,
  LINKEDIN_LINK_URL,
} = socialMediaConstants;

export default socialMediaConstants;
