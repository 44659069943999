import { ApolloError } from '@apollo/client';
import { Box, FormControlLabel, FormGroup, Grid, Switch } from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import { Translator } from '~/types/Translator';
import {
  SongwriterChangeYoutubeClaimOutoutMutation,
  useSongwriterChangeYoutubeClaimOutoutMutation,
} from '~/types/generated/graphql';
import YoutubeClaimsColumnDialog from './YoutubeClaimsColumnDialog';

interface YoutubeClaimColumnProps extends Translator {
  songwriterId: number;
  youtubeClaimFormName: string;
  profileOptedOut: boolean | undefined | null;
  formMethods: UseFormReturn<FieldValues, unknown>;
  setProcessError: Dispatch<SetStateAction<string>>;
  setWasSaved: Dispatch<SetStateAction<boolean>>;
}

function YoutubeClaimColumn({
  t,
  songwriterId,
  youtubeClaimFormName,
  profileOptedOut,
  formMethods,
  setProcessError,
  setWasSaved,
}: YoutubeClaimColumnProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [changeYoutubeClaimOptOut, { loading }] =
    useSongwriterChangeYoutubeClaimOutoutMutation({
      fetchPolicy: 'network-only',
    });

  const handleClaimsChangeComplete = (
    data: SongwriterChangeYoutubeClaimOutoutMutation,
  ) => {
    if (
      data?.songwriterChangeYoutubeClaimOptOut?.songwriter?.youtubeOptOut !==
      null
    ) {
      formMethods.setValue(
        youtubeClaimFormName,
        data?.songwriterChangeYoutubeClaimOptOut?.songwriter?.youtubeOptOut,
      );
      setWasSaved(true);
    }
  };

  const handleClaimsChangeError = (error: ApolloError) => {
    setProcessError(error.message);
    setWasSaved(false);
  };

  const handleClaimsChangeCancel = () => {
    setIsModalOpen(false);
  };

  const handleClaimsChangeSuccess = () => {
    changeYoutubeClaimOptOut({
      variables: {
        songwriterId,
        optOut: !formMethods.getValues(youtubeClaimFormName),
      },
      onCompleted: handleClaimsChangeComplete,
      onError: handleClaimsChangeError,
    });
    setIsModalOpen(false);
  };

  const handleClaimsChange = () => {
    setIsModalOpen(true);
  };

  return (
    <Box data-testid="youtube-claim-column">
      <YoutubeClaimsColumnDialog
        t={t}
        isOptedOut={formMethods.getValues(youtubeClaimFormName)}
        isModalOpen={isModalOpen}
        onSuccess={handleClaimsChangeSuccess}
        onCancel={handleClaimsChangeCancel}
      />
      <Grid container>
        <Grid item xs={12}>
          {loading && <LoadingIndicator size={25} />}
          {!loading && (
            <FormGroup>
              <FormControlLabel
                sx={{
                  '& .MuiFormControlLabel-label': { fontSize: '.75rem' },
                }}
                control={
                  profileOptedOut === undefined || profileOptedOut === null ? (
                    <Switch
                      data-testid="youtube-claim-toggle"
                      checked={!formMethods.getValues(youtubeClaimFormName)}
                      onChange={handleClaimsChange}
                    />
                  ) : (
                    <Switch checked={!profileOptedOut} disabled />
                  )
                }
                label={
                  // eslint-disable-next-line no-nested-ternary
                  profileOptedOut === undefined || profileOptedOut === null
                    ? formMethods.getValues(youtubeClaimFormName)
                      ? t(
                          'page.songwriters.tabs.client.table.columns.youtube.opted-out',
                        )
                      : t(
                          'page.songwriters.tabs.client.table.columns.youtube.opted-in',
                        )
                    : profileOptedOut
                      ? t(
                          'page.songwriters.tabs.client.table.columns.youtube.opted-out',
                        )
                      : t(
                          'page.songwriters.tabs.client.table.columns.youtube.opted-in',
                        )
                }
              />
            </FormGroup>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default YoutubeClaimColumn;
