import { Container, Grid } from '@mui/material';
import PageTitle from '~/base/components/PageTitle/PageTitle';
import SectionTitle from '~/base/components/SectionTitle/SectionTitle';
import usePageTitle from '~/hooks/usePageTitle';
import { Translator } from '~/types/Translator';
import MfaSection from '../../MfaSection/MfaSection';
import ChangePasswordSection from '../ChangePasswordSection/ChangePasswordSection';

function SecurityPage({ t }: Translator) {
  usePageTitle(t('sections.security.title'));

  return (
    <div data-testid="security-page">
      <Container>
        <PageTitle
          currentPage={t('sections.security.title')}
          breadcrumbLinks={[
            {
              id: 'account-password-back-link',
              label: t('sections.account-information.breadcrumb'),
              href: '/account',
            },
          ]}
          pageTitle={{
            title: t('sections.security.title'),
            id: 'security-page-title',
          }}
        />
        <Grid
          container
          spacing={2}
          sx={{
            flexDirection: {
              xs: 'column',
            },
          }}
        >
          <Grid item xs={12} md={12}>
            <SectionTitle>{t('sections.change-password.title')}</SectionTitle>
            <ChangePasswordSection t={t} dashboardRedirect={false} />
          </Grid>
          <Grid item xs={12}>
            <SectionTitle>{t('sections.mfa.title')}</SectionTitle>
            <MfaSection t={t} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default SecurityPage;
