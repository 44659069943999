/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
const pushEvent = (eventId: string, formId: string) => {
  if ((window as any)._mfq) (window as any)._mfq.push([eventId, formId]);
};

const mouseflowHelper = {
  formSubmitSuccess: (formId: string) => {
    pushEvent('formSubmitSuccess', formId);
  },
  formSubmitAttempt: (formId: string) => {
    pushEvent('formSubmitAttempt', formId);
  },
  formSubmitFailure: (formId: string) => {
    pushEvent('formSubmitFailure', formId);
  },
};

export const { formSubmitSuccess, formSubmitAttempt, formSubmitFailure } =
  mouseflowHelper;
export default mouseflowHelper;
