import { Box } from '@mui/material';
import { MouseEventHandler } from 'react';

/**
 * Props for SongwriterIntervalButton component.
 */
interface SongwriterIntervalButtonProps {
  /**
   * The text content of the button.
   */
  text: string;
  /**
   * Background color of the button.
   */
  backgroundColor?: string;
  /**
   * Foreground color (text color) of the button.
   */
  foregroundColor?: string;
  /**
   * Click event handler for the button.
   */
  onClick?: MouseEventHandler<HTMLDivElement>;
}

/**
 * SongwriterIntervalButton component displays a button with specified text and styles.
 * @param {SongwriterIntervalButtonProps} props - Component props.
 * @returns {JSX.Element} - Rendered component.
 */
function SongwriterIntervalButton({
  text,
  backgroundColor = '#E0E0E0',
  foregroundColor = '#7FC832',
  onClick,
}: SongwriterIntervalButtonProps): JSX.Element {
  return (
    <Box
      data-testid="interval-button"
      sx={{
        WebkitUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
        fill: foregroundColor,
        fontSize: '2rem',
        fontWeight: 'bold',
        background: backgroundColor,
        borderRadius: '50%',
        lineHeight: '2.5rem',
        height: '2.5rem',
        width: '2.5rem',
        textAlign: 'center',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      {text}
    </Box>
  );
}

export default SongwriterIntervalButton;
