import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Translator } from '~/types/Translator';
import Cowriter from './Cowriter';

interface CowritersProps extends Translator {
  setOutsideSongwriterOpen: Dispatch<SetStateAction<boolean>>;
  setTotal: Dispatch<SetStateAction<number>>;
  ownershipsLoading: boolean;
}

export default function Cowriters({
  t,
  setOutsideSongwriterOpen,
  setTotal,
  ownershipsLoading,
}: CowritersProps) {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name: 'cowriters',
    control,
  });

  return (
    <>
      {fields.map((field, index) => (
        <Cowriter
          key={field.id}
          fieldId={field.id}
          fields={fields}
          index={index}
          t={t}
          setOutsideSongwriterOpen={setOutsideSongwriterOpen}
          setTotal={setTotal}
          ownershipsLoading={ownershipsLoading}
          remove={remove}
        />
      ))}

      <Button
        data-testid="add-another-cowriter"
        variant="outlined"
        disabled={ownershipsLoading}
        startIcon={<AddIcon />}
        onClick={() =>
          append({
            songwriterId: null,
            percent: null,
            isOutsideSongwriter: false,
          })
        }
      >
        {t('form.buttons.cowriter')}
      </Button>
    </>
  );
}
