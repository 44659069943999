import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { kebabCase } from 'lodash';
import { Controller, Validate, useFormContext } from 'react-hook-form';

/**
 * Option for each checkbox in the FormMultiCheckbox component
 * @typedef {Object} CheckboxOption
 * @property {string} choiceId - The ID of the choice
 * @property {string} choiceLabel - The label for the choice
 * @property {boolean} [disabled] - Whether the checkbox is disabled
 */
interface CheckboxOption {
  choiceId: string;
  choiceLabel: string;
  disabled?: boolean;
}

/**
 * Props for the FormMultiCheckbox component
 * @typedef {Object} FormMultiCheckboxProps
 * @property {string} id - The ID of the component
 * @property {string} [label] - The label for the component
 * @property {string} name - The name of the form field
 * @property {SxProps<Theme>} [sx] - The styling props
 * @property {boolean} [required] - Whether the field is required
 * @property {Validate<unknown, unknown> | Record<string, Validate<unknown, unknown>>} [validate] - Validation rules
 * @property {CheckboxOption[]} options - The options for the checkboxes
 * @property {number} columns - The number of columns for the checkboxes
 * @property {boolean} [autofocus] - Whether to autofocus the first checkbox
 */
interface FormMultiCheckboxProps {
  id: string;
  label?: string;
  name: string;
  sx?: SxProps<Theme>;
  required?: boolean;
  validate?:
    | Validate<unknown, unknown>
    | Record<string, Validate<unknown, unknown>>
    | undefined;
  options: CheckboxOption[];
  columns: number;
  autofocus?: boolean;
}

/**
 * FormMultiCheckbox component to render multiple checkboxes with validation
 * @param {FormMultiCheckboxProps} props - Props for the component
 * @returns {JSX.Element} FormMultiCheckbox component
 */
function FormMultiCheckbox({
  id,
  name,
  label = '',
  sx = {},
  required = false,
  validate = undefined,
  options,
  columns,
  autofocus = false,
}: FormMultiCheckboxProps): JSX.Element {
  const {
    formState: { errors },
    control,
    register,
  } = useFormContext();

  register(name, { required, validate });

  const labelId = `${id}-label`;
  const error = name in errors ? errors[name] : '';

  const maxNumberOfColumns = 12 / columns;
  const labelComponent = label ? (
    <FormLabel data-testid={labelId} id={labelId} sx={{ fontSize: '0.75rem' }}>
      {label}
    </FormLabel>
  ) : null;

  return (
    <FormControl id={id} sx={sx} error={!!error}>
      {labelComponent}
      <FormGroup aria-labelledby={labelId} data-testid={kebabCase(id)}>
        <Controller
          defaultValue={[]}
          rules={{ required }}
          name={name}
          control={control}
          render={({ field }) => (
            <>
              {error && error.message && (
                <FormHelperText
                  data-testid={`${id}-error-message`}
                  sx={{ color: '#F33126' }}
                >
                  {error?.message as unknown as string}
                </FormHelperText>
              )}
              <Grid container columnSpacing={3}>
                {options.map(
                  (checkboxOption: CheckboxOption, index: number) => (
                    <Grid
                      item
                      md={maxNumberOfColumns}
                      sm={maxNumberOfColumns * 2}
                      xs={maxNumberOfColumns * 2}
                      key={checkboxOption.choiceId}
                    >
                      <FormControlLabel
                        label={checkboxOption.choiceLabel}
                        control={
                          <Checkbox
                            name={name}
                            autoFocus={autofocus && index === 0}
                            value={checkboxOption.choiceId}
                            checked={
                              field.value &&
                              field.value.includes(`${checkboxOption.choiceId}`)
                            }
                            onChange={(event) => {
                              if (!field.value.includes(event.target.value)) {
                                field.onChange([
                                  ...field.value,
                                  event.target.value,
                                ]);
                                return;
                              }

                              const newSelected = field.value.filter(
                                (selected: string) =>
                                  selected !== event.target.value,
                              );
                              field.onChange(newSelected);
                            }}
                          />
                        }
                      />
                    </Grid>
                  ),
                )}
              </Grid>
            </>
          )}
        />
      </FormGroup>
    </FormControl>
  );
}

export default FormMultiCheckbox;
