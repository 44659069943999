import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Add from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { T } from '~/types/Translator';

export default function SpotifyPreview({
  artist = '',
  isrc = '',
  song = '',
  t,
}: {
  artist: string | undefined;
  isrc: string | undefined;
  song: string | undefined;
  t: T;
}) {
  return (
    <Grid container direction="row" xs={12} justifyContent="space-between">
      <Card raised={false} sx={{ display: 'flex' }}>
        <CardContent>
          <Typography component="div" fontWeight="bold">
            {song}
          </Typography>
          <Typography>{artist}</Typography>
          <Typography variant="body2">ISRC: {isrc}</Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: 'row-reverse' }}>
          <Button variant="contained" startIcon={<Add />}>
            {t('form.buttons.addRecording')}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}
