import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export interface ConfirmationDialogProps {
  dialogTitle: string;
  dialogContent: JSX.Element;
  yesButtonText: string;
  noButtonText: string;
  optionalButtonText?: string;
  open: boolean;
  handleClose: (confirmed: string) => void;
}

export default function ConfirmationDialog({
  dialogTitle,
  dialogContent,
  yesButtonText = 'Yes',
  noButtonText = 'No',
  optionalButtonText,
  open,
  handleClose,
}: ConfirmationDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" variant="h2">
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant="body1">{dialogContent}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => {
            handleClose('no');
          }}
        >
          {noButtonText}
        </Button>
        {optionalButtonText && (
          <Button
            autoFocus
            onClick={() => {
              handleClose('selectAnother');
            }}
            variant="outlined"
          >
            {optionalButtonText}
          </Button>
        )}
        <Button
          onClick={() => {
            handleClose('yes');
          }}
          autoFocus
          variant="contained"
        >
          {yesButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
