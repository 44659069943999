import SvgIcon from '@mui/material/SvgIcon';

function TwitterIcon() {
  return (
    <SvgIcon
      fontSize="huge"
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.5125037,17.9812282 C35.531261,18.2437157 35.531261,18.5062609 35.531261,18.7687483 C35.531261,26.7749623 29.4375464,36 18.3000094,36 C14.8687418,36 11.6812621,35.0062068 9,33.2812809 C9.48751795,33.3374952 9.95622083,33.3562526 10.4624961,33.3562526 C13.2937016,33.3562526 15.8999921,32.4000317 17.9812499,30.7687772 C15.3187451,30.7125051 13.0874862,28.9687642 12.3187234,26.5687469 C12.693755,26.6249612 13.0687289,26.6624759 13.4625178,26.6624759 C14.00625,26.6624759 14.55004,26.5874465 15.0562576,26.4562605 C12.2812665,25.8937132 10.1999509,23.4562389 10.1999509,20.5124893 L10.1999509,20.4375176 C11.0061707,20.8875208 11.9437496,21.1687656 12.9374274,21.2062226 C11.3061729,20.1187004 10.2374656,18.262473 10.2374656,16.1624579 C10.2374656,15.0374786 10.5374101,14.0062284 11.0624427,13.1062219 C14.043707,16.7812196 18.5249821,19.1811792 23.5499318,19.4437243 C23.4562028,18.9937211 23.3999307,18.5250182 23.3999307,18.0562576 C23.3999307,14.7187191 26.0999502,12 29.4561884,12 C31.1999293,12 32.7749118,12.7312481 33.8811914,13.9124994 C35.2499008,13.6500119 36.5623958,13.1437366 37.7249475,12.4500032 C37.2748866,13.856285 36.3186657,15.0375363 35.0624427,15.787484 C36.2812087,15.656298 37.4624601,15.3187234 38.5499246,14.8500206 C37.7250629,16.0499715 36.693755,17.1186787 35.5125037,17.9812282 Z"
        fill="#000000"
      />
    </SvgIcon>
  );
}

export default TwitterIcon;
