const onboardingConstants = {
  FINISH_ONBOARDING_REDIRECT: `${
    import.meta.env.VITE_LEGACY_APP_BASE_URL
  }redirect?next=/dashboard/`,
  ONBOARDING_PATHS: ['/get-started', '/onboarding'],
};

export const { ONBOARDING_PATHS, FINISH_ONBOARDING_REDIRECT } =
  onboardingConstants;
export default onboardingConstants;
