import { Grid } from '@mui/material';
import SectionTipCard from '~/base/components/SectionTipCard';
import { Translator } from '~/types/Translator';

function AccessManagementInvitePageTips({ t }: Translator) {
  return (
    <div data-testid="access-management-invite-page-tips">
      <Grid container rowSpacing={4}>
        <Grid item xs={12}>
          <SectionTipCard
            title={t(
              'sections.access-management-invite.tips.permission-types.title',
            )}
            color="yellow"
          >
            <div>
              <p>
                {t(
                  'sections.access-management-invite.tips.permission-types.content.set1.title',
                )}
              </p>
              <ul>
                <li>
                  {t(
                    'sections.access-management-invite.tips.permission-types.content.set1.bullet1',
                  )}
                </li>
                <li>
                  {t(
                    'sections.access-management-invite.tips.permission-types.content.set1.bullet2',
                  )}
                </li>
                <li>
                  {t(
                    'sections.access-management-invite.tips.permission-types.content.set1.bullet3',
                  )}
                </li>
              </ul>

              <p>
                {t(
                  'sections.access-management-invite.tips.permission-types.content.set2.title',
                )}
              </p>
              <ul>
                <li>
                  {t(
                    'sections.access-management-invite.tips.permission-types.content.set2.bullet1',
                  )}
                </li>
                <li>
                  {t(
                    'sections.access-management-invite.tips.permission-types.content.set2.bullet2',
                  )}
                </li>
                <li>
                  {t(
                    'sections.access-management-invite.tips.permission-types.content.set2.bullet3',
                  )}
                </li>
                <li>
                  {t(
                    'sections.access-management-invite.tips.permission-types.content.set2.bullet4',
                  )}
                </li>
              </ul>
            </div>
          </SectionTipCard>
        </Grid>
        <Grid item>
          <SectionTipCard
            title={t('sections.access-management-invite.tips.end-date.title')}
            color="blue"
          >
            <div>
              {t('sections.access-management-invite.tips.end-date.content')}
            </div>
          </SectionTipCard>
        </Grid>
      </Grid>
    </div>
  );
}

export default AccessManagementInvitePageTips;
