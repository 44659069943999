/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import type { PaginationState, SortingState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { MRT_ColumnDef, MaterialReactTable } from 'material-react-table';
import { useEffect, useMemo, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import accountConstants from '~/account/constants/accountConstants';
import { PaymentTransaction } from '~/account/types/types';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import {
  TABLE_BODY_CELL_PROPS,
  TABLE_HEAD_CELL_PROPS,
  TABLE_HEAD_PROPS,
  TABLE_PAPER_PROPS,
  TABLE_PROPS,
} from '~/base/constants/tableConstants';
import { Translator } from '~/types/Translator';
import { useTransactionHistoryQuery } from '~/types/generated/graphql';

function AccountHistoryPageTable({ t }: Translator) {
  // Query setup states.
  const [transactionTypeString] = useState<string>('Payment');
  const [orderByString, setOrderByString] = useState<string>(
    'amount,-description',
  );

  // Query return states.
  const [tranasctions, setTranasctions] = useState<PaymentTransaction[]>([]);
  const [transactionTotalCount, setTransactionTotalCount] = useState(0);
  const [defaultCurrencySymbol, setDefaultCurrencySymbol] = useState<string>(
    accountConstants?.currencySymbols.USD,
  );

  // Table states.
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  // Update state of query string if table states updated.
  useEffect(() => {
    if (sorting[0]) {
      const colName = sorting[0].id;
      let dirStr = sorting[0].desc ? '-' : '';

      // Inverse for this column because we actually get negative values from the transactions return.
      if (colName === 'amount') dirStr = sorting[0].desc ? '' : '-';

      setOrderByString(`${dirStr}${colName}`);
    }
  }, [sorting]);

  // Fetch data using hook.
  const { data, loading, error } = useTransactionHistoryQuery({
    variables: {
      transactionType: transactionTypeString,
      orderBy: orderByString,
      offset: pagination.pageIndex * pagination.pageSize,
      first: pagination.pageSize,
    },
    onCompleted: (res) => {
      const defaultCurrency =
        `${res?.loggedInSongtrustUser?.subscriptionPlan?.planCurrency}` as string;

      const items = res.transactions?.edges.map((item) => {
        return {
          transactionDate: item?.node?.transactionDate,
          description: item?.node?.description,
          amount: item?.node?.amount,
        } as PaymentTransaction;
      });

      const totalItems = res.transactions?.totalCount;

      setDefaultCurrencySymbol(
        get(accountConstants?.currencySymbols, defaultCurrency),
      );

      if (items && totalItems) {
        setTranasctions(items as PaymentTransaction[]);
        setTransactionTotalCount(totalItems);
      }
    },
  });

  // Columns definition.
  const columns = useMemo<MRT_ColumnDef<PaymentTransaction>[]>(
    () => [
      {
        accessorKey: 'transactionDate',
        header: t('sections.payment-history.table.transaction-date'),
        Cell: (row) => (
          <div>{dayjs(row.cell.getValue<Date>()).format('MMM D, YYYY')}</div>
        ),
      },
      {
        accessorKey: 'description',
        header: t('sections.payment-history.table.description'),
        Cell: (row) => <div>{row.cell.getValue<string>()}</div>,
      },
      {
        accessorKey: 'amount',
        header: t('sections.payment-history.table.amount'),
        Cell: (row) => (
          <div style={{ textAlign: 'right' }}>
            <CurrencyFormat
              value={row.cell.getValue<number>() * -1}
              displayType="text"
              prefix={defaultCurrencySymbol}
              decimalSeparator="."
              decimalScale={2}
              thousandSeparator
              fixedDecimalScale
            />
          </div>
        ),
      },
    ],
    [],
  );

  // Render
  return (
    <div data-testid="account-payment-history-page-table">
      {loading && <LoadingIndicator size={50} />}
      {!loading && data && (
        <MaterialReactTable
          columns={columns}
          data={tranasctions}
          enableTopToolbar={false}
          enableColumnActions={false}
          enableColumnDragging={false}
          onPaginationChange={setPagination}
          onSortingChange={setSorting}
          rowCount={transactionTotalCount}
          state={{
            isLoading: loading,
            pagination,
            showAlertBanner: error !== undefined,
            showProgressBars: loading,
            sorting,
          }}
          muiTableHeadProps={TABLE_HEAD_PROPS}
          muiTableHeadCellProps={TABLE_HEAD_CELL_PROPS}
          muiTableBodyCellProps={TABLE_BODY_CELL_PROPS}
          muiTableProps={TABLE_PROPS}
          muiTablePaperProps={TABLE_PAPER_PROPS}
          manualPagination
          manualSorting
          enableBottomToolbar
        />
      )}
    </div>
  );
}

export default AccountHistoryPageTable;
