import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InputAdornment from '@mui/material/InputAdornment';

interface EndAdornmentProps {
  id: string;
  endAdornment?: React.ReactNode;
  error?: boolean;
}

/**
 * EndAdornment component renders an adornment or an error icon at the end of an input field.
 *
 * @param {string} id - The identifier for the component.
 * @param {React.ReactNode} [endAdornment=null] - The content to be displayed as the adornment.
 * @param {boolean} [error=false] - Flag indicating whether to show an error icon.
 * @returns {JSX.Element | null} The rendered component or null if no adornment or error.
 */
export default function EndAdornment({
  id = '',
  endAdornment = null,
  error = false,
}: EndAdornmentProps): JSX.Element | null {
  switch (true) {
    case !!endAdornment:
      // Render the provided endAdornment if it exists
      return (
        <InputAdornment data-testid={`${id}-adornment`} position="end">
          {endAdornment}
        </InputAdornment>
      );
    case error:
      // Render the error icon if the error flag is true
      return (
        <InputAdornment data-testid={`${id}-error`} position="end">
          <ErrorOutlineIcon sx={{ color: '#F33126' }} />
        </InputAdornment>
      );
    default:
      // Render nothing if no conditions are met
      return null;
  }
}
