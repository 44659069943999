import { useReactiveVar } from '@apollo/client';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PageTitle from '~/base/components/PageTitle/PageTitle';
import { currentSessionTokenVar } from '~/cache';
import LoginPageForm from '~/login/components/LoginPageForm';
import { Translator } from '~/types/Translator';

function LoginPage({ t }: Translator) {
  const navigate = useNavigate();

  const sessionToken = useReactiveVar(currentSessionTokenVar);

  useEffect(() => {
    document.title = `${t('page.title')} | ${import.meta.env.VITE_APP_TITLE}`;
    if (sessionToken) navigate('/dashboard');
  }, [sessionToken]);

  return (
    <div className="login-page" data-testid="login-page">
      <Container>
        <Grid item xs={12} md={12}>
          <PageTitle
            pageTitle={{
              id: 'login-page-title',
              title: t('page.title'),
            }}
          />
        </Grid>
        <Grid
          sx={{
            flexDirection: {
              xs: 'column-reverse',
              md: 'row',
            },
            paddingTop: { md: '2rem' },
          }}
          container
        >
          <Grid item xs={12} md={6}>
            <Box
              data-testid="signup-content"
              sx={{
                paddingTop: { xs: '2rem' },
              }}
            >
              <Typography variant="h4" component="h4">
                {t('page.content.subtitle')}
              </Typography>
              <Typography
                variant="h3"
                component="h3"
                sx={{
                  marginTop: '2rem',
                }}
              >
                {t('page.content.paid.title')}
              </Typography>
              <Typography variant="body1">
                {t('page.content.paid.description')}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ paddingBottom: { xs: '2rem', md: '0' } }}
          >
            <LoginPageForm t={t} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default LoginPage;
