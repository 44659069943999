/* eslint-disable no-useless-return */
import { Box, Modal } from '@mui/material';

interface FullScreenMessageProps {
  isOpen: boolean;
  allowBackdropClose: boolean;
  allowEscClose: boolean;
  children: JSX.Element;
}

function FullScreenMessage({
  isOpen,
  allowBackdropClose,
  allowEscClose,
  children,
}: FullScreenMessageProps) {
  const handleModalClose = (event: object, reason: string) => {
    if (reason && reason === 'backdropClick' && !allowBackdropClose) return;
    if (reason && reason === 'escapeKeyDown' && !allowEscClose) return;
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '30%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80%',
          height: 'auto',
          bgcolor: '#fff',
          border: '2px solid #000',
          outline: 'none !important',
          p: 4,
          fieldset: {
            border: 'none !important',
            outline: 'none !important',
          },
        }}
      >
        {children}
      </Box>
    </Modal>
  );
}

export default FullScreenMessage;
