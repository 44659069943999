import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { debounce } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import FormAutoComplete from '~/base/components/FormAutoComplete';
import {
  InterestedpartySongWriterSongwriterTypeChoices,
  SongType,
  SongtrustUserType,
  SongwriterType,
  useCopySplitsSongsLazyQuery,
} from '~/types/generated/graphql';
import { T } from '~/types/Translator';
import { generateSongwriterName, getSongwriters } from '../Songwriters/utils';

export default function CopySplits({
  currentUser,
  open,
  setOpen,
  setInputSongwriterValue,
  t,
}: {
  currentUser: SongtrustUserType;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setInputSongwriterValue: Dispatch<SetStateAction<string>>;
  t: T;
}) {
  // Mutations
  const [getSongs, { data, loading }] = useCopySplitsSongsLazyQuery();

  // State
  const [copiedSong, setCopiedSong] = useState<SongType | null>(null);
  const [inputSongValue, setInputSongValue] = useState<string>('');

  // Hooks
  const { setValue, clearErrors, unregister, getValues } = useFormContext();
  const formMethods = useForm();

  const fetchWithDebounce = useMemo(
    () =>
      debounce((input: string) => {
        getSongs({ variables: { searchTerm: input } });
      }, 300),
    [],
  );

  useEffect(() => {
    if (inputSongValue?.length > 1) fetchWithDebounce(inputSongValue);
  }, [inputSongValue, fetch]);

  // Handlers
  const handleClose = () => setOpen(false);

  const handleClick = () => {
    const songwriters = getSongwriters(copiedSong, currentUser, t);
    const copyCowriters = songwriters.slice(1);
    const currentCowriters = getValues('cowriters');

    // In case of attempting multiple subsequent copy splits operations
    // in which subsequent copied song has fewer writers, unregister extra artifact field array elements
    if (currentCowriters.length > copyCowriters.length) {
      for (let i = copyCowriters.length; i < currentCowriters.length; i += 1) {
        unregister([`cowriters.${i}.songwriterId`, `cowriters.${i}.percent`]);
      }
    }

    setInputSongwriterValue(songwriters[0].inputValue);
    setValue('songwriter', songwriters[0]);
    setValue('cowriters', copyCowriters);

    const cowriterFormNames = copyCowriters.map(
      (_, index) => `cowriters.${index}.songwriterId`,
    );
    clearErrors(['songwriter.songwriterId', ...cowriterFormNames]);
    setOpen(false);
  };

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const outsideString = InterestedpartySongWriterSongwriterTypeChoices.Outside;
  const clientString = InterestedpartySongWriterSongwriterTypeChoices.Client;

  // Render
  return (
    <Dialog fullScreen={xs} maxWidth="lg" open={open} onClose={handleClose}>
      <DialogTitle>
        <Typography variant="h3">{t('form.titles.copySplits')}</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (c) => c.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ flexGrow: 0 }}>
        <DialogContentText>
          <Typography variant="body1">{t('form.copy.copySplits1')}</Typography>
          <Typography variant="body1">{t('form.copy.copySplits2')}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogContent sx={{ flexGrow: 0 }}>
        <FormProvider {...formMethods}>
          <FormAutoComplete
            sx={{
              width: '100%',
            }}
            label={t('form.labels.copySplits')}
            placeholder={t('form.placeholders.searchSong')}
            id="copy-song"
            testid="copy-song"
            name="copySong"
            options={
              data?.songs?.edges?.map((edge) => ({
                choiceId: edge?.node?.id ?? '',
                choiceLabel: edge?.node?.title ?? '',
              })) ?? []
            }
            disablePortal={false}
            inputValue={inputSongValue}
            onInputChange={(_: React.SyntheticEvent, value: string) => {
              setInputSongValue(value);
            }}
            onOptionSelected={(value) => {
              let tempData = data?.songs?.edges?.filter(
                (e) => e?.node?.id === value,
              )[0]?.node;
              if (!tempData) return;

              const sortedOwnerships = tempData?.ownerships
                ? [...tempData.ownerships].sort((a, b) => {
                    const aType = a?.songwriter?.songwriterType;
                    const bType = b?.songwriter?.songwriterType;
                    // Both are client songwriters
                    if (aType === clientString && bType === clientString) {
                      return parseFloat(b?.percent) - parseFloat(a?.percent); // Sort by descending percentage
                    }
                    // Both are outside songwriters
                    if (aType === outsideString && bType === outsideString) {
                      return parseFloat(b?.percent) - parseFloat(a?.percent); // Sort by descending percentage
                    }
                    // One is a client and the other is an outside songwriter
                    return aType === outsideString ? 1 : -1; // Client songwriters come first
                  })
                : [];

              tempData = {
                ...tempData,
                ownerships: sortedOwnerships,
                id: tempData.id ?? '',
                title: tempData.title ?? '',
              };
              setCopiedSong(tempData as SongType);
            }}
            popupIcon={<SearchIcon fontSize="small" />}
            forcePopupIcon
            loading={loading}
            autocompleteSX={{
              '.MuiAutocomplete-popupIndicator': {
                transform: 'none',
              },
              width: '100%',
            }}
          />
        </FormProvider>
      </DialogContent>
      <DialogContent sx={{ flexGrow: 0 }}>
        {copiedSong ? (
          <TableContainer>
            <Table>
              {/* Table Header */}
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="h6">
                      {t('form.labels.songwriter')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6">
                      {t('form.labels.ownership')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              {/* Table Body */}
              <TableBody>
                {copiedSong?.ownerships?.map((o, i) => (
                  <TableRow key={o?.songwriter?.id}>
                    {/* Songwriter Column */}
                    <TableCell>
                      <Typography
                        data-testid={`copy-songwriter-${i}`}
                        variant="body1"
                      >
                        {generateSongwriterName(
                          o?.songwriter as SongwriterType,
                          currentUser,
                          t,
                        )}
                      </Typography>
                    </TableCell>

                    {/* Ownership Column */}
                    <TableCell>
                      <Typography variant="body1">{o?.percent}&#37;</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          ''
        )}
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="copy-song-splits"
          color="primary"
          onClick={handleClick}
          disabled={!copiedSong}
        >
          {t('form.buttons.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
