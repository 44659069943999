import { Box, Grid } from '@mui/material';
import dayjs from 'dayjs';

interface JoinDateColumnProps {
  date: Date;
}

function JoinDateColumn({ date }: JoinDateColumnProps) {
  const formattedDate = dayjs(date).format('MM/DD/YYYY');
  return (
    <Box data-testid="join-date-column">
      <Grid container>
        <Grid item xs={12}>
          {formattedDate}
        </Grid>
      </Grid>
    </Box>
  );
}

export default JoinDateColumn;
