import { Link, Stack } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { ResponsiveStyleValue, SxProps } from '@mui/system';
import FacebookIcon from '~/base/components/SocialIcons/FacebookIcon';
import InstagramIcon from '~/base/components/SocialIcons/InstagramIcon';
import LinkedInIcon from '~/base/components/SocialIcons/LinkedInIcon';
import TikTokIcon from '~/base/components/SocialIcons/TikTokIcon';
import TwitterIcon from '~/base/components/SocialIcons/TwitterIcon';
import YouTubeIcon from '~/base/components/SocialIcons/YouTubeIcon';
import {
  FACEBOOK_LINK_URL,
  INSTAGRAM_LINK_URL,
  LINKEDIN_LINK_URL,
  TIKTOK_LINK_URL,
  TWITTER_LINK_URL,
  YOUTUBE_LINK_URL,
} from '~/base/constants/socialMediaConstants';

interface SocialIconGroupProps {
  direction: ResponsiveStyleValue<
    'row' | 'row-reverse' | 'column' | 'column-reverse'
  >;
  spacing: number;
  // eslint-disable-next-line react/require-default-props
  sx?: SxProps<Theme>;
}

function SocialIconGroup({ direction, spacing, sx }: SocialIconGroupProps) {
  return (
    <Stack
      direction={direction}
      spacing={spacing}
      data-testid="social-icon-group"
      sx={sx}
    >
      <Link href={TWITTER_LINK_URL} target="_blank" aria-label="Twitter">
        <TwitterIcon />
      </Link>
      <Link href={FACEBOOK_LINK_URL} target="_blank" aria-label="Facebook">
        <FacebookIcon />
      </Link>
      <Link href={INSTAGRAM_LINK_URL} target="_blank" aria-label="Instagram">
        <InstagramIcon />
      </Link>
      <Link href={YOUTUBE_LINK_URL} target="_blank" aria-label="Youtube">
        <YouTubeIcon />
      </Link>
      <Link href={LINKEDIN_LINK_URL} target="_blank" aria-label="LinkedIn">
        <LinkedInIcon />
      </Link>
      <Link href={TIKTOK_LINK_URL} target="_blank" aria-label="TikTok">
        <TikTokIcon />
      </Link>
    </Stack>
  );
}

export default SocialIconGroup;
