import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import usePageTitle from '~/hooks/usePageTitle';
import TermsPageContent from '../TermsPageContent/TermsPageContent';
import TermsPageTitleButtons from '../TermsPageTitleButtons/TermsPageTitleButtons';

function TermsPage() {
  const { t } = useTranslation(['terms']);
  usePageTitle(t('page.title'));

  return (
    <div data-testid="terms-page">
      <Container>
        <TermsPageTitleButtons t={t} />
        <TermsPageContent />
      </Container>
    </div>
  );
}

export default TermsPage;
