import SyncIcon from '@mui/icons-material/Sync';
import { Button, Grid, Link, Typography } from '@mui/material';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  PlaidLinkError,
  PlaidLinkOnExit,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
  usePlaidLink,
} from 'react-plaid-link';
import FormBanner, {
  FormBannerType,
} from '~/base/components/FormBanner/FormBanner';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import {
  isInvalid,
  isUnsure,
  isValid,
} from '~/signup/constants/signupConstants';
import { Translator } from '~/types/Translator';
import {
  CheckVerificationStatusQuery,
  GeneratePlaidIdentityTokenMutation,
  LoggedInSongtrustUserPersonaQuery,
  UpdateAccountIdentityMutation,
  useCheckVerificationStatusLazyQuery,
  useGeneratePlaidIdentityTokenMutation,
  useUpdateAccountIdentityMutation,
} from '~/types/generated/graphql';

import { useReactiveVar } from '@apollo/client';
import ModalVideo from 'react-modal-video';
import { useNavigate } from 'react-router-dom';
import { currentSongtrustUserPersonaVar } from '~/cache';
import './PlaidVerification.scss';

interface PlaidVerificationProps extends Translator {
  verificationStatus: string | null | undefined;
  setVerificationStatus: Dispatch<SetStateAction<string | null | undefined>>;
}

function PlaidVerification({
  t,
  verificationStatus,
  setVerificationStatus,
}: PlaidVerificationProps) {
  const navigate = useNavigate();

  const loggedInUser = useReactiveVar(currentSongtrustUserPersonaVar);

  const [identityId, setIdentityId] = useState<string | undefined>(undefined);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [isVideoOpen, setIsVideoOpen] = useState<boolean>(false);

  // onComplete fns
  const onUpdateIdentityComplete = (res: UpdateAccountIdentityMutation) => {
    // Only on success, update the logged in user's persona in the reactive var.
    if (res.updateAccountIdentity?.songtrustUser) {
      const updatedUserPersona = {
        ...loggedInUser,
        loggedInSongtrustUser: {
          ...loggedInUser?.loggedInSongtrustUser,
          isKycVerificationRequired:
            res.updateAccountIdentity?.songtrustUser?.isKycVerificationRequired,
        },
      };
      currentSongtrustUserPersonaVar(
        updatedUserPersona as LoggedInSongtrustUserPersonaQuery,
      );

      setTimeout(() => {
        navigate('/dashboard');
      }, 2000);
    }
  };

  const onGenerateTokenComplete = (res: GeneratePlaidIdentityTokenMutation) => {
    const identityIdValue = res.generatePlaidIdentityToken
      ?.identityId as string;
    const tokenValue = res.generatePlaidIdentityToken?.token as string;

    setToken(tokenValue);
    setIdentityId(identityIdValue);
  };

  // Mutations
  const [updateIdentity] = useUpdateAccountIdentityMutation({
    fetchPolicy: 'no-cache',
    onCompleted: onUpdateIdentityComplete,
    onError() {
      return undefined;
    },
  });

  const [generateToken] = useGeneratePlaidIdentityTokenMutation({
    fetchPolicy: 'no-cache',
    onCompleted: onGenerateTokenComplete,
    onError() {
      return undefined;
    },
  });

  // Interval patch for loggedInSongtrustUser
  const [reloadVerificationStatus, { called }] =
    useCheckVerificationStatusLazyQuery();

  // withRefetch
  // Exponential backoff function that polls gql for the VALID identity object by ID.
  // Upon successfully finding the VALID identity, it will call updateIdentity to associate
  // the identity and provide the update the user's persona.
  const withRefetch = (id: string, r = 1000, m = 127000) => {
    return reloadVerificationStatus({
      variables: {
        identityId: id,
      },
      fetchPolicy: 'network-only',
      onCompleted: (res: CheckVerificationStatusQuery) => {
        const resIdentityId = res.checkVerificationStatus?.identityId;
        if (resIdentityId === id) {
          setVerificationStatus(res.checkVerificationStatus?.status);
          if (
            isValid(res.checkVerificationStatus?.status) ||
            isInvalid(res.checkVerificationStatus?.status)
          ) {
            // Update the user's identity object via mutation
            updateIdentity({ variables: { identityId: resIdentityId } });
          } else if (m > 0) {
            // Schedule a retry with exponential backoff.
            setTimeout(() => {
              withRefetch(id, r * 2, m - r);
            }, r);
          }
        }
      },
      onError() {
        // If there's an error, also schedules a retry with exponential backoff.
        if (m > 0) {
          setTimeout(() => {
            withRefetch(id, r * 2, m - r);
          }, r);
        }
      },
    });
  };

  // onSuccess
  // A function that is called when a user successfully links an Item.
  // The function should expect two arguments, the public_token and a metadata object. See onSuccess.
  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
      withRefetch(identityId as string);
    },
    [identityId],
  );

  // onExit
  // A function that is called when a user reaches certain points in the Link flow.
  // The function should expect two arguments, an eventName string and a metadata object. See onEvent.
  const onExit = useCallback<PlaidLinkOnExit>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (error: null | PlaidLinkError, metadata: PlaidLinkOnExitMetadata) => {
      // handle invalid link token
      if (error != null && error.error_code === 'INVALID_LINK_TOKEN') {
        // generate new link token
        generateToken();
      }
    },
    [],
  );

  // PlaidLinkOption object.
  // Token needs to be generated from mutation 'generatePlaidUserToken'
  const config: PlaidLinkOptions = {
    onSuccess,
    onExit,
    token: token as string,
  };
  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (!loggedInUser?.loggedInSongtrustUser?.isKycVerificationRequired)
      navigate('/dashboard');

    generateToken();
  }, [loggedInUser]);

  return (
    <Grid
      container
      columnSpacing={3}
      rowSpacing={3}
      data-testid="identity-verification"
    >
      <ModalVideo
        channel="youtube"
        youtube={{
          autoplay: 1,
          mute: 0,
        }}
        isOpen={isVideoOpen}
        videoId="2Sk5HKF84PM"
        onClose={() => setIsVideoOpen(false)}
      />
      <Grid item xs={12} md={12}>
        {
          // Haven't clicked the button to begin the process, and hasn't returned an invalid result.
        }
        {!called && !isInvalid(verificationStatus) && (
          <>
            <Typography
              variant="body1"
              component="p"
              sx={{ fontSize: '0.875rem', pb: '1.5rem' }}
            >
              {t('sections.verify-identity.form.verification-acknowledgement')}
            </Typography>

            <Typography
              variant="body1"
              component="p"
              sx={{ fontSize: '0.875rem', pb: '1.5rem' }}
            >
              {t(
                'sections.verify-identity.form.verification-acknowledgement-watch-this',
              )}
              <Link
                href="https://www.youtube.com/embed/2Sk5HKF84PM?controls=0&autoplay=1"
                onClick={(e) => {
                  e.preventDefault();
                  setIsVideoOpen(true);
                }}
                target="_blank"
                data-testid="verify-identity-video-link"
              >
                {t(
                  'sections.verify-identity.form.verification-acknowledgement-quick-tutorial',
                )}
              </Link>
              {t(
                'sections.verify-identity.form.verification-acknowledgement-best-practices',
              )}
              <Link
                href="https://www.songtrust.com/contact-support"
                target="_blank"
                data-testid="verify-identity-support-link"
              >
                {t(
                  'sections.verify-identity.form.verification-acknowledgement-support-form',
                )}
              </Link>
              {t(
                'sections.verify-identity.form.verification-acknowledgement-recieve-assistance',
              )}
            </Typography>
          </>
        )}

        {
          // Haven't clicked the button to begin the process, and hasn't returned an invalid result.
        }
        {!called && !isInvalid(verificationStatus) && (
          <>
            {!ready && <LoadingIndicator size={50} />}
            {ready && (
              <Grid container>
                <Grid item xs={12}>
                  <Button
                    id="identity-verification-begin"
                    data-testid="identity-verification-begin"
                    variant="contained"
                    color="success"
                    sx={{ padding: '1rem 4rem' }}
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      if (window.Cypress) {
                        setVerificationStatus('VALID');
                        updateIdentity({
                          variables: { identityId },
                        });
                      } else {
                        open();
                      }
                    }}
                  >
                    {t('sections.verify-identity.form.begin-verification')}
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        )}

        {
          // The verification process has been started (called) and it has not returned a result yet.
        }
        {called && isUnsure(verificationStatus) && (
          <span data-testid="verification-status-verifying">
            <Typography variant="h5" sx={{ display: 'flex' }}>
              <SyncIcon sx={{ color: '#A4A4A4', justifySelf: 'center' }} />
              {t('sections.verify-identity.form.verification-status.verifying')}
            </Typography>
          </span>
        )}

        {
          // The verification process has been started (called) and it has returned a VALID results.
        }
        {called && isValid(verificationStatus) && (
          <span data-testid="verification-status-valid">
            <FormBanner
              text={t(
                'sections.verify-identity.form.verification-status.valid-verification',
              )}
              type={FormBannerType.SUCCESS}
            />
          </span>
        )}

        {
          // The verification process has been started (called) and it has returned an INVALID result.
        }
        {called && isInvalid(verificationStatus) && (
          <span data-testid="verification-status-invalid">
            <FormBanner
              text={t(
                'sections.verify-identity.form.verification-status.invalid-verification',
              )}
              type={FormBannerType.ERROR}
            />
          </span>
        )}
      </Grid>
    </Grid>
  );
}

export default PlaidVerification;
