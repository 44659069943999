import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Translator } from '~/types/Translator';
import { SongVideoState } from '~/types/generated/graphql';
import YoutubeClaimDuration from './YoutubeClaimDuration';

interface ClaimedVideo extends Translator {
  id: number;
  created: string;
  state: SongVideoState;
  video: {
    id: number;
    title: string;
    viewCount: number;
    youtubeId: string;
  };
  song: {
    id: number;
    title: string;
  };
  start: string;
  end: string;
}

export default function YoutubeClaimedVideo({
  title,
  views,
  youtubeId,
  start,
  end,
  t,
}: {
  title: ClaimedVideo['song']['title'];
  views: ClaimedVideo['video']['viewCount'];
  youtubeId: ClaimedVideo['video']['youtubeId'];
  start: ClaimedVideo['start'];
  end: ClaimedVideo['end'];
  t: ClaimedVideo['t'];
}) {
  return (
    <Grid
      container
      item
      xs={12}
      sm={5}
      md={3}
      paddingBottom={1}
      borderRadius="0.416rem"
      boxShadow={1}
      data-testid="youtube-claimed-video"
    >
      <iframe
        src={`https://www.youtube.com/embed/${youtubeId}`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; fullscreen; gyroscope; picture-in-picture; web-share"
        style={{
          border: 'none',
          width: '100%',
          aspectRatio: '16 / 9',
          borderRadius: '0.416rem 0.416rem 0 0',
        }}
      />
      <Typography
        variant="h5"
        component="h4"
        title={title}
        sx={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 2,
          lineHeight: 1.125,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          margin: 'auto 0.5rem',
        }}
      >
        {title || ''}
      </Typography>
      <Grid item xs={12} sx={{ margin: 'auto 0.5rem' }}>
        <Typography data-testid="view-count">
          Views:{' '}
          {Intl.NumberFormat('en-US', {
            compactDisplay: 'short',
            notation: 'compact',
            maximumFractionDigits: 1,
          }).format(views)}
        </Typography>
      </Grid>
      {start && end ? (
        <YoutubeClaimDuration {...{ start, end, t }} />
      ) : (
        <Typography sx={{ margin: 'auto 0.5rem' }}>
          {t('page.claimed.duration.claim')}:{' '}
          {t(`page.claimed.duration.entire`)}
        </Typography>
      )}
    </Grid>
  );
}
