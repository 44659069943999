import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  MenuItem,
  MenuItemProps,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Translator } from '~/types/Translator';

interface DefaultMenuItemProps extends MenuItemProps {
  label: React.ReactNode;
  children?: React.ReactNode;
}

function DefaultMenuItem({ label, children, onClick }: DefaultMenuItemProps) {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLLIElement | null>(null);

  return (
    <MenuItem
      ref={ref}
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      onClick={onClick}
    >
      {label}
      <Popper anchorEl={ref.current} open={open} style={{ zIndex: 1300 }}>
        {children}
      </Popper>
    </MenuItem>
  );
}

interface AutocompleteAddSongWriterProps extends Translator {
  setOutsideSongwriterOpen?: Dispatch<SetStateAction<boolean>>;
  songwriterInput?: boolean;
}

export default function AutocompleteAddSongwriter({
  t,
  setOutsideSongwriterOpen,
  songwriterInput = false,
}: AutocompleteAddSongWriterProps) {
  const navigate = useNavigate();

  const handleDefaultClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSongtrustWriterClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    navigate('/songwriters/add?next=/songs/add');
  };

  const handleOutsideWriterClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (setOutsideSongwriterOpen) setOutsideSongwriterOpen((state) => !state);
  };

  return (
    <MenuList
      onClick={
        songwriterInput ? handleSongtrustWriterClick : handleDefaultClick
      }
      sx={{ width: '100%', padding: 0 }}
    >
      <DefaultMenuItem
        label={
          songwriterInput ? (
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: { xs: '0.75rem', sm: '1rem' },
                }}
              >
                {t('form.section.songwriters.addNewMainSongwriterMenu')}
                <ArrowForwardIcon sx={{ marginLeft: 1 }} />
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography
                variant="subtitle1"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: { xs: '0.75rem', sm: '1rem' },
                }}
              >
                {t('form.section.songwriters.addNewCowriterMenu')}
                <ChevronRightIcon sx={{ marginLeft: 1 }} />
              </Typography>
            </Box>
          )
        }
      >
        {!songwriterInput && (
          <MenuList>
            <Paper elevation={8}>
              <DefaultMenuItem
                label={
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                      fontSize: { xs: '0.75rem', sm: '1rem' },
                    }}
                  >
                    <div>
                      <Typography>
                        {t('form.section.songwriters.addNewSongtrust1')}
                      </Typography>
                      <Typography variant="caption">
                        {t('form.section.songwriters.addNewSongtrust2')}
                      </Typography>
                    </div>
                    <ArrowForwardIcon />
                  </Box>
                }
                onClick={handleSongtrustWriterClick}
              />
              <DefaultMenuItem
                label={
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <div>
                      <Typography>
                        {t('form.section.songwriters.addNewOutside1')}
                      </Typography>
                      <Typography variant="caption">
                        {t('form.section.songwriters.addNewOutside2')}
                      </Typography>
                    </div>
                    <ArrowForwardIcon />
                  </Box>
                }
                onClick={handleOutsideWriterClick}
              />
            </Paper>
          </MenuList>
        )}
      </DefaultMenuItem>
    </MenuList>
  );
}
