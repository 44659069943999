import { Box, Grid, Link, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EXTERNAL_FOOTER_LINKS } from '~/base/constants/footerConstants';
import SocialIconGroup from '../../SocialIconGroup';

function FooterLoggedOut() {
  const { t } = useTranslation(['translation']);
  return (
    <Grid container spacing={2} data-testid="footer-grid-external">
      <Grid item xs={12} md={8} lg={8}>
        {/* General Links */}
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 2, md: 6 }}
          sx={{
            fontWeight: 900,
            fontSize: '1.125rem',
            marginTop: '0.60rem',
            textAlign: { xs: 'center', md: 'left' },
          }}
          className="footer-links"
        >
          {EXTERNAL_FOOTER_LINKS.map(({ text, url, index }) => {
            return (
              <Link
                key={index}
                color="primary"
                className="footer-link-bold"
                href={url}
                underline="none"
                sx={{ color: '#000' }}
              >
                {t(text)}
              </Link>
            );
          })}
        </Stack>

        <Grid
          sx={{
            marginTop: '2.125rem',
            flexDirection: {
              xs: 'column-reverse',
              md: 'column',
            },
          }}
          container
        >
          <Box
            sx={{
              textAlign: { xs: 'center', md: 'left' },
              spacing: { xs: 2, md: 0.1 },
            }}
          >
            {/* Social Icons Mobile */}
            <Box
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <SocialIconGroup
                direction="row"
                spacing={0}
                sx={{ justifyContent: 'center' }}
              />
            </Box>

            {/* Legal Links */}
            <Box marginTop="2rem">
              <Link
                underline="hover"
                color="primary"
                sx={{ color: '#000' }}
                href="/terms-of-service"
              >
                {t('footer.terms-of-service')}
              </Link>
              <span> | </span>
              <Link
                underline="hover"
                color="primary"
                href="https://www.songtrust.com/privacy-policy"
                sx={{ color: '#000' }}
              >
                {t('footer.privacy-policy')}
              </Link>
            </Box>

            {/* Copyright */}
            <Box sx={{ fontSize: '0.875rem' }}>
              {`${t('footer.copyright')} ${new Date().getFullYear()} ${t(
                'footer.all-rights-reserved',
              )}`}
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Social Icons Desktop */}
      <Grid item xs={12} md={4} lg={4}>
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'flex-end',
          }}
        >
          <SocialIconGroup direction="row" spacing={0} />
        </Box>
      </Grid>
    </Grid>
  );
}

export default FooterLoggedOut;
