import { ApolloError } from '@apollo/client';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { currentSessionTokenVar } from '~/cache';
import { SessionToken } from '~/types/SessionToken.model';
import {
  RefreshTokenMutation,
  useRefreshTokenMutation,
} from '~/types/generated/graphql';

function useLoginRefresh(
  callbackError: (error: ApolloError) => void,
  callbackSuccess?: (data: RefreshTokenMutation) => void,
) {
  const [doRefreshToken] = useRefreshTokenMutation({
    fetchPolicy: 'no-cache',
  });

  const handleRefreshTokenComplete = (data: RefreshTokenMutation) => {
    const sessionToken: SessionToken = {
      token: data?.refreshToken?.token as string,
      refreshToken: data?.refreshToken?.refreshToken as string,
      refreshExpiresIn: data?.refreshToken?.refreshExpiresIn as number,
    };

    currentSessionTokenVar(sessionToken);

    // Call the callback function.
    if (callbackSuccess) callbackSuccess(data);
  };

  const handleRefreshTokenError = (error: ApolloError) => {
    currentSessionTokenVar(undefined);
    callbackError(error);
  };

  const refreshToken = async () => {
    const fp = await FingerprintJS.load();
    const fpResult = await fp.get();

    await doRefreshToken({
      variables: { fp: fpResult.visitorId },
      onCompleted: handleRefreshTokenComplete,
      onError: handleRefreshTokenError,
    });
  };

  return [refreshToken];
}

export default useLoginRefresh;
