import SvgIcon from '@mui/material/SvgIcon';

function SongtrustIcon() {
  return (
    <SvgIcon
      width="48"
      height="48"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.762 11.575a4.339 4.339 0 0 1 0-8.678 4.339 4.339 0 0 1 3.741 6.533H26V0H0v14.413h13.238a4.339 4.339 0 0 1 0 8.678 4.339 4.339 0 0 1-3.741-6.533H0V26h26V11.575H12.762z"
        fill="#000"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}

export default SongtrustIcon;
