const footerConstants = {
  EXTERNAL_FOOTER_LINKS: [
    {
      text: 'footer.about-us',
      url: 'https://www.songtrust.com/about-songtrust',
      index: 0,
    },
    {
      text: 'footer.contact-us',
      url: 'https://www.songtrust.com/songtrust-contact-us',
      index: 1,
    },
    {
      text: 'footer.faqs',
      url: 'https://www.songtrust.com/songtrust-faqs',
      index: 2,
    },
    {
      text: 'footer.help-center',
      url: 'https://help.songtrust.com/knowledge',
      index: 3,
    },
    {
      text: 'footer.careers',
      url: 'https://www.songtrust.com/songtrust-careers',
      index: 4,
    },
  ],
};

export const { EXTERNAL_FOOTER_LINKS } = footerConstants;
export default footerConstants;
