import SyncIcon from '@mui/icons-material/Sync';
import { Box, Grid, Typography } from '@mui/material';
import { Translator } from '~/types/Translator';
import { SongwriterStatusLogType } from '~/types/generated/graphql';

interface SongwriterDetailStatusProps extends Translator {
  statusLog: SongwriterStatusLogType;
}

function SongwriterDetailStatus({ t, statusLog }: SongwriterDetailStatusProps) {
  return (
    <Box
      data-testid="songwriter-detail-status"
      sx={{
        boxShadow: '0px 0px 5px 5px rgba(239, 239, 239, 1);',
        p: '2rem 1rem',
      }}
    >
      <Grid container direction="row" spacing={2}>
        <Grid item xs={3}>
          <SyncIcon sx={{ color: '#B85D00', fontSize: 75 }} />
        </Grid>
        <Grid item xs={9}>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h3">
                {t(`page.songwriter-details.steps.${statusLog.latest}.title`)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {t(`page.songwriter-details.steps.${statusLog.latest}.status`)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SongwriterDetailStatus;
