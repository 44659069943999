import { ApolloQueryResult } from '@apollo/client';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import { Exact, SubscriptionPlanQuery } from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';
import BillingInformationSectionForm from '../BillingInformationSectionForm';

interface BillingInformationSectionProps extends Translator {
  refetch: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<SubscriptionPlanQuery>>;
}

function BillingInformationSection({
  refetch,
}: BillingInformationSectionProps) {
  const billingFormMethods = useForm();
  const [stripePromise] = useState(() =>
    loadStripe(import.meta.env.VITE_STRIPE_PK),
  );

  return (
    <div data-testid="billing-information-section">
      <FormProvider {...billingFormMethods}>
        {!stripePromise && <LoadingIndicator size={50} />}
        {stripePromise && (
          <Elements stripe={stripePromise}>
            <BillingInformationSectionForm refetch={refetch} />
          </Elements>
        )}
      </FormProvider>
    </div>
  );
}

export default BillingInformationSection;
