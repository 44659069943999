import { ApolloError } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Grid, TextField, Tooltip } from '@mui/material';
import React from 'react';
import { Translator } from '~/types/Translator';

/**
 * Props for SignupDiscountLinkEntry component.
 */
interface SignupDiscountLinkEntryProps extends Translator {
  /**
   * Prefix for translation keys.
   */
  tPrefix?: string;
  /**
   * Apollo error object if there's an error.
   */
  error: ApolloError | undefined;
  /**
   * Boolean indicating if data is loading.
   */
  loading: boolean;
  /**
   * Boolean indicating if the discount code is invalid.
   */
  invalidCode: boolean;
  /**
   * Currently edited discount code.
   */
  editDiscountCode: string;
  /**
   * Handler for applying the discount code.
   */
  handleDiscountApplyClick: () => void;
  /**
   * Handler for closing the discount code entry.
   */
  handleDiscountCloseClick: () => void;
  /**
   * Handler for changes in the discount code input.
   */
  handleEditDiscountCodeChanges: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => void;
}

/**
 * SignupDiscountLinkEntry component allows users to enter and apply a discount code.
 * @param {SignupDiscountLinkEntryProps} props - Component props.
 * @returns {JSX.Element} - Rendered component.
 */
function SignupDiscountLinkEntry({
  t,
  tPrefix = '',
  loading,
  error,
  invalidCode,
  editDiscountCode,
  handleDiscountApplyClick,
  handleDiscountCloseClick,
  handleEditDiscountCodeChanges,
}: SignupDiscountLinkEntryProps): JSX.Element {
  return (
    <Grid item xs={12}>
      <Grid
        data-testid="discount-code-entry"
        container
        sx={{
          paddingLeft: '.5rem',
          paddingRight: '.5rem',
        }}
      >
        <Grid item xs={9} flexDirection="row">
          <TextField
            InputLabelProps={{
              sx: {
                padding: '0',
                marginLeft: '-.75rem',
                marginTop: '-.75rem',
              },
              shrink: false,
            }}
            label="Discount Code"
            error={error !== undefined || invalidCode}
            InputProps={{
              sx: {
                borderRadius: '.5rem',
                width: '12rem',
                height: '3.125rem',
                fieldset: {
                  borderColor: error || invalidCode ? '#f33126' : 'default',
                },
              },
              placeholder: t(`${tPrefix}form.enter-discount`),
            }}
            name="discount-code"
            data-testid="discount-code"
            value={editDiscountCode}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                handleDiscountApplyClick();
              }
            }}
            onChange={(e) => {
              handleEditDiscountCodeChanges(
                e as React.ChangeEvent<HTMLInputElement>,
              );
            }}
            type="text"
            helperText={
              (error || invalidCode) &&
              t(`${tPrefix}form.errors.invalid-discount-code`)
            }
          />
          <Tooltip
            title={t(`${tPrefix}form.clear-code`)}
            placement="top"
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  background: '#000',
                  left: '25% !important',
                },
                '& .MuiTooltip-arrow': {
                  left: '-25% !important',
                  ':before': {
                    background: '#000',
                  },
                },
              },
            }}
            arrow
          >
            <CloseIcon
              data-testid="close-discount"
              sx={{
                margin: '1rem .5rem',
                height: '1rem',
                width: '1rem',
                borderRadius: '1rem',
                background: '#031C9B',
                border: '1px solid #031C9B',
                color: '#FFF',
                '&:hover': {
                  background: '#FFF',
                  color: '#031C9B',
                },
                fontSize: '.75rem',
                cursor: 'pointer',
              }}
              onClick={handleDiscountCloseClick}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={3} sx={{ textAlign: 'right' }}>
          {loading && <span>...</span>}
          {!loading && (
            <Button
              data-testid="apply-discount-button"
              variant="outlined"
              color="success"
              onClick={handleDiscountApplyClick}
            >
              {t(`${tPrefix}form.apply`)}
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SignupDiscountLinkEntry;
