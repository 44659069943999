import { Grid } from '@mui/material';
import HeaderButtons from '~/base/components/Header/HeaderButtons';
import HeaderLogo from '~/base/components/Header/HeaderLogo';

function HeaderLandingPage() {
  return (
    <Grid
      container
      data-testid="header-landing"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      sx={{ height: '4rem' }}
    >
      <Grid item data-testid="header-landing-left">
        <HeaderLogo size="md" />
      </Grid>

      <Grid item data-testid="header-landing-right">
        <Grid
          container
          data-testid="header-landing-right-buttons"
          spacing={2}
          alignItems="center"
          flexGrow={1}
        >
          <HeaderButtons hidePersonaMenu hideJoinSignIn alwaysShowLogout />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HeaderLandingPage;
