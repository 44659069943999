const translationLocales: string[] = [
  'dashboard',
  'forgotpassword',
  'login',
  'onboarding',
  'signup',
  'terms',
  'translation',
  'youtube',
  'account',
  'songs',
  'songwriters',
];

export default translationLocales;
