import { Avatar, Box, Button, Grid, Menu, MenuItem } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { BasicMenuType, MenuLinkType } from '~/base/types/MenuLinkType';
import './HeaderMenuLinksSubmenuChevron.scss';

function HeaderMenuLinksSubmenuDesktop({
  text,
  reducedText,
  showButtons,
  menuItems,
  handleItemClick,
}: BasicMenuType) {
  const { t } = useTranslation(['translation']);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [goToUrlLink, setGoToUrlLink] = React.useState<string | undefined>(
    undefined,
  );

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!open) {
      setAnchorEl(event.currentTarget);
    }
  };

  React.useEffect(() => {
    if (goToUrlLink) {
      handleItemClick(goToUrlLink);
      setGoToUrlLink(undefined);
    }
  }, [anchorEl, goToUrlLink]);

  return (
    <Box
      component="span"
      sx={{ display: { xs: 'none', md: 'block' } }}
      data-testid="header-submenu-desktop"
    >
      <Button
        sx={{ color: '#FFFFFF', padding: 0 }}
        className="expand-menu"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {reducedText && (
          <Avatar
            variant="circular"
            sx={{
              display: { xs: 'none', md: 'flex', lg: 'none' },
              background: '#3549AF',
              border: '1px solid #606BF8',
              fontFamily: 'inherit',
              fontSize: '1.5rem',
              '&:hover': {
                cursor: 'pointer',
              },
            }}
            onClick={handleClick}
          >
            {reducedText}
          </Avatar>
        )}

        {text && (
          <Box
            component="span"
            sx={{
              display: {
                xs: 'flex',
                md: reducedText ? 'none' : 'flex',
                lg: 'flex',
              },
              textTransform: 'none',
              padding: 0,
              fontFamily: 'inherit',
              fontSize: '1rem',
              '&:hover': {
                textDecoration: 'underline',
                textDecorationThickness: '.125rem',
                textUnderlineOffset: '.45rem',
              },
            }}
          >
            {t(text)}
          </Box>
        )}

        <span className="chevron" />
      </Button>

      <Menu
        sx={{ marginTop: '1rem' }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        MenuListProps={{
          'aria-labelledby': `header-submenu-${text}`,
        }}
      >
        {menuItems &&
          menuItems.map(({ text: itemText, url, index }: MenuLinkType) => {
            return (
              url && (
                <MenuItem
                  className="menu-item"
                  sx={{ fontWeight: 700, fontSize: '1rem' }}
                  data-testid={itemText.replace('header.internal.', '')}
                  key={index}
                  onClick={() => {
                    setGoToUrlLink(url);
                    setAnchorEl(null);
                  }}
                >
                  {t(itemText)}
                </MenuItem>
              )
            );
          })}

        {showButtons && (
          <MenuItem
            className="menu-item"
            component={Box}
            sx={{
              display: { xs: 'flex', lg: 'none' },
              '&:hover': {
                background: 'none',
              },
            }}
          >
            <Grid container justifyContent="center">
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                sx={{
                  minWidth: '6.5rem',
                }}
                onClick={() => {
                  navigate('/logout');
                }}
              >
                {t('logout')}
              </Button>
            </Grid>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
}
export default HeaderMenuLinksSubmenuDesktop;
