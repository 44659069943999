import { Container, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function RedirectError() {
  const { t } = useTranslation(['translation']);
  return (
    <div data-testid="error-page">
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h1"
              component="h1"
              sx={{
                paddingTop: { md: '2rem' },
                paddingBottom: { md: '4rem' },
              }}
            >
              {t('redirect.redirecting')}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default RedirectError;
