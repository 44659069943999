import { Box, Grid, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import SocialIconGroup from '../../SocialIconGroup';

function FooterLoggedIn() {
  const { t } = useTranslation(['translation']);
  return (
    <Grid container spacing={2} data-testid="footer-grid-internal">
      <Grid item xs={12}>
        <Box
          sx={{
            fontSize: '.875rem',
            textAlign: { xs: 'center', md: 'center' },
            spacing: { xs: 2, md: 0.1 },
            paddingBottom: '2rem',
          }}
        >
          {/* Social Icons */}
          <Box>
            <SocialIconGroup
              direction="row"
              spacing={0}
              sx={{ justifyContent: 'center' }}
            />
          </Box>

          {/* Legal Links */}
          <Box>
            <Box
              component="div"
              sx={{ display: { md: 'inline', xs: 'block' } }}
            >
              <Link
                underline="hover"
                color="primary"
                sx={{ color: '#000' }}
                component={RouterLink}
                to="/terms-of-service"
              >
                {t('footer.terms-of-service')}
              </Link>
              <span> | </span>
              <Link
                underline="hover"
                color="primary"
                href="https://www.songtrust.com/privacy-policy"
                sx={{ color: '#000' }}
              >
                {t('footer.privacy-policy')}
              </Link>
              <span> | </span>
              <Link
                underline="hover"
                color="primary"
                href="https://www.songtrust.com/about-songtrust"
                sx={{ color: '#000' }}
              >
                {t('footer.about-songtrust')}
              </Link>
            </Box>
            <Box
              component="span"
              sx={{ display: { md: 'inline', xs: 'none' } }}
            >
              {' | '}
            </Box>
            <Box
              component="div"
              sx={{ display: { md: 'inline', xs: 'block' } }}
            >
              <Link
                underline="hover"
                color="primary"
                href="https://help.songtrust.com/knowledge"
                sx={{ color: '#000' }}
              >
                {t('footer.help-center')}
              </Link>
              <span> | </span>
              <Link
                underline="hover"
                color="primary"
                href="https://www.songtrust.com/songtrust-contact-us"
                sx={{ color: '#000' }}
              >
                {t('footer.contact-us')}
              </Link>
            </Box>
          </Box>

          {/* Copyright */}
          <Box sx={{ fontSize: '0.875rem' }}>
            {`${t('footer.copyright')} ${new Date().getFullYear()} ${t(
              'footer.all-rights-reserved',
            )}`}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default FooterLoggedIn;
