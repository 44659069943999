import { Box, Grid, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { SongwriterType } from '~/types/generated/graphql';

interface SongwriterColumnProps {
  songwriter: SongwriterType;
}
function SongwriterColumn({ songwriter }: SongwriterColumnProps) {
  return (
    <Box data-testid="songwriter-column">
      <Grid container className="songwriter-name">
        {(songwriter.firstName !== null || songwriter.lastName !== null) && (
          <Grid item xs={12}>
            <Link to={`/songwriters/${songwriter.id}`} component={RouterLink}>
              {`${songwriter.firstName !== null ? songwriter.firstName : ''} ${
                songwriter.lastName !== null ? songwriter.lastName : ''
              }`}
            </Link>
          </Grid>
        )}

        {songwriter.publishingCompany?.name !== null && (
          <Grid item xs={12}>
            {songwriter.publishingCompany?.name}
          </Grid>
        )}

        {songwriter.email !== null && (
          <Grid
            item
            xs={12}
            sx={{ fontWeight: 'bold' }}
            className="writer-email"
          >
            {songwriter.email}
          </Grid>
        )}

        {songwriter.pro?.name !== null && (
          <Grid item xs={12}>
            {songwriter.pro?.name}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default SongwriterColumn;
