/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import type { PaginationState } from '@tanstack/react-table';
import { MaterialReactTable, MRT_ColumnDef } from 'material-react-table';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import _ from 'lodash';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import {
  TABLE_BODY_CELL_PROPS,
  TABLE_HEAD_CELL_PROPS,
  TABLE_HEAD_ROW_PROPS,
  TABLE_PAPER_PROPS,
  TABLE_PROPS,
} from '~/base/constants/tableConstants';
import {
  InterestedpartySongWriterSongwriterTypeChoices,
  SongwriterType,
  SongwriterTypeEdge,
  useSongwritersQuery,
} from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';
import AccessColumn from './Columns/AccessColumn';
import ApplicationStatusColumn from './Columns/ApplicationStatusColumn';
import JoinDateColumn from './Columns/JoinDateColumn';
import PayeeColumn from './Columns/PayeeColumn';
import SongwriterColumn from './Columns/SongwriterColumn';
import YoutubeClaimColumn from './Columns/YoutubeClaimColumn';
import NoSongwritersTableMessage from './Message/NoSongwritersTableMessage';

interface SongwritersListProps extends Translator {
  songwriterType: InterestedpartySongWriterSongwriterTypeChoices;
  searchFor: string;
  setRequiresUserIncrease: Dispatch<SetStateAction<boolean | undefined>>;
  setRemainingSongwritersLabel: Dispatch<SetStateAction<string>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  formMethods: UseFormReturn<FieldValues, any>;
  setProcessError: Dispatch<SetStateAction<string>>;
  setWasSaved: Dispatch<SetStateAction<boolean>>;
}

function SongwritersList({
  t,
  formMethods,
  songwriterType,
  searchFor,
  setRequiresUserIncrease,
  setRemainingSongwritersLabel,
  setIsLoading,
  setProcessError,
  setWasSaved,
}: SongwritersListProps) {
  const [profileYoutubeOptOut, setProfileYoutubeOptOut] = useState<
    boolean | undefined | null
  >(undefined);

  const [loadedSongwriters, setLoadedSongwriters] = useState<
    SongwriterTypeEdge[]
  >([]);

  const [totalCount, setTotalCount] = useState(0);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  /// /////////////////////////
  // Fetch data using hook.  //
  /// /////////////////////////

  const { data, loading, error } = useSongwritersQuery({
    variables: {
      songwriterType,
      searchFor,
      offset: pagination.pageIndex * pagination.pageSize,
      first: pagination.pageSize,
    },
    onCompleted: (res) => {
      const totalItems = res.songwriters?.totalCount;
      const songwriters = res.songwriters?.edges;
      const songwriterUsage = res.songwritersUsage;

      if (songwriters && totalItems) {
        // Set react-hook-form-values if set at songwriter level. (Not opted in or out at profile level)
        const profileOptedOut = res.loggedInSongtrustUser?.youtubeOptOut;
        if (profileOptedOut === undefined || profileOptedOut === null) {
          _.each(songwriters, (item) => {
            const youtubeClaimFormName = `youtube-claim-${item?.node?.id}`;
            formMethods.setValue(
              youtubeClaimFormName,
              item?.node?.youtubeOptOut,
            );
          });
        }
        setProfileYoutubeOptOut(profileOptedOut);

        // Set total count of loaded songwriters and the songwriters for the table.
        setLoadedSongwriters(res.songwriters?.edges as SongwriterTypeEdge[]);
        setTotalCount(totalItems);
      }

      // Set the remaining songtrust writers string based on the songwriter usage.
      if (songwriterUsage) {
        let songwriterRemainingAmt = songwriterUsage?.left?.toString();
        if (
          songwriterUsage?.limit === 2147483647 &&
          (songwriterUsage?.left as number) > 0
        ) {
          songwriterRemainingAmt = 'Unlimited';
        }
        setRemainingSongwritersLabel(
          songwriterType ===
            InterestedpartySongWriterSongwriterTypeChoices.Client
            ? `${t(
                'page.songwriters.songtrust-writer-credits',
              )} ${songwriterRemainingAmt}`
            : '',
        );
      }
      setRequiresUserIncrease((songwriterUsage?.left as number) <= 0);
    },
  });

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  /// ////////////
  // Handlers.  //
  /// ////////////
  const columns = useMemo<MRT_ColumnDef<SongwriterTypeEdge>[]>(
    () => [
      {
        id: 'songwriter',
        enableHiding: false,
        header: t(
          'page.songwriters.tabs.client.table.columns.songwriter.title',
        ),
        Cell: (cellData) => {
          return (
            <SongwriterColumn
              songwriter={cellData.row.original.node as SongwriterType}
            />
          );
        },
      },
      {
        id: 'payee',
        enableHiding: true,
        header: t('page.songwriters.tabs.client.table.columns.payee.title'),
        hidden: true,
        Cell: (cellData) => {
          return (
            <PayeeColumn payee={cellData.row.original.node?.payee as string} />
          );
        },
      },
      {
        id: 'created',
        enableHiding: false,
        header:
          songwriterType ===
          InterestedpartySongWriterSongwriterTypeChoices.Client
            ? t('page.songwriters.tabs.client.table.columns.join-date.title')
            : t('page.songwriters.tabs.client.table.columns.added-date.title'),
        Cell: (cellData) => {
          return (
            <JoinDateColumn
              date={cellData.row.original.node?.created as Date}
            />
          );
        },
      },
      {
        id: 'status',
        enableHiding: true,
        header: t(
          'page.songwriters.tabs.client.table.columns.writer-status.title',
        ),
        Cell: (cellData) => {
          return (
            <ApplicationStatusColumn
              t={t}
              songwriter={cellData.row.original.node as SongwriterType}
            />
          );
        },
      },
      {
        id: 'youtubeOptOut',
        enableHiding: true,
        header: t('page.songwriters.tabs.client.table.columns.youtube.title'),
        Cell: (cellData) => {
          const songwriterId = parseInt(
            cellData.row.original.node?.id as string,
            10,
          );
          const youtubeClaimFormName = `youtube-claim-${songwriterId}`;

          return (
            <YoutubeClaimColumn
              t={t}
              songwriterId={songwriterId}
              profileOptedOut={profileYoutubeOptOut}
              youtubeClaimFormName={youtubeClaimFormName}
              formMethods={formMethods}
              setProcessError={setProcessError}
              setWasSaved={setWasSaved}
            />
          );
        },
      },
      {
        id: 'access',
        enableHiding: true,
        header: t('page.songwriters.tabs.client.table.columns.access.title'),
        maxSize: 50,
        Cell: (cellData) => {
          const crosslinkCount = cellData.row.original.node
            ?.crosslinkUserCount as number;

          return <AccessColumn crosslinkCount={crosslinkCount} />;
        },
      },
    ],
    [profileYoutubeOptOut],
  );

  const outsideColumnVisibility = {
    payee: false,
    status: false,
    youtubeOptOut: false,
    access: false,
  };

  return (
    <div data-testid="songwriters-list">
      {loading && <LoadingIndicator size={50} />}
      {!loading && data && totalCount > 0 && (
        <MaterialReactTable
          columns={columns}
          data={loadedSongwriters}
          enableTopToolbar={false}
          enableColumnActions={false}
          enableColumnDragging={false}
          onPaginationChange={setPagination}
          rowCount={totalCount}
          state={{
            isLoading: loading,
            pagination,
            showAlertBanner: error !== undefined,
            showProgressBars: loading,
            columnVisibility:
              songwriterType !==
              InterestedpartySongWriterSongwriterTypeChoices.Client
                ? outsideColumnVisibility
                : {},
          }}
          muiTableHeadRowProps={TABLE_HEAD_ROW_PROPS}
          muiTableHeadCellProps={TABLE_HEAD_CELL_PROPS}
          muiTableBodyCellProps={TABLE_BODY_CELL_PROPS}
          muiTableProps={TABLE_PROPS}
          muiTablePaperProps={TABLE_PAPER_PROPS}
          getRowId={(originalRow) =>
            (originalRow.node?.id as unknown as number).toString()
          }
          manualPagination
          enableBottomToolbar
        />
      )}

      {!loading && data && totalCount === 0 && songwriterType !== undefined && (
        <NoSongwritersTableMessage t={t} songwriterType={songwriterType} />
      )}
    </div>
  );
}

export default SongwritersList;
