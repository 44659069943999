import { Container, Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import PageTitle from '~/base/components/PageTitle/PageTitle';
import usePageTitle from '~/hooks/usePageTitle';
import {
  RemainingSongwritersUsageQuery,
  useRemainingSongwritersUsageQuery,
} from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';
import AddSongwriterStepper from '../AddSongwriterStepper/AddSongwriterStepper';

function AddSongwriterPage({ t }: Translator) {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  usePageTitle(t('page.add-songwriter.title'));

  const { loading: remainingCreditsLoading } =
    useRemainingSongwritersUsageQuery({
      fetchPolicy: 'network-only',
      onCompleted: (res: RemainingSongwritersUsageQuery) => {
        const remainingWriters = res?.songwritersUsage?.left || 0;
        if (remainingWriters <= 0) {
          navigate('/songwriters/buy');
        }
      },
    });

  return (
    <Container data-testid="add-songwriter-page">
      <PageTitle
        currentPage={t('page.add-songwriter.breadcrumb')}
        breadcrumbLinks={[
          {
            id: 'songwriter-add-back-link',
            label: t('page.songwriter-details.breadcrumb'),
            href: '/songwriters/manage',
          },
        ]}
        pageTitle={{
          title: t('page.add-songwriter.title'),
          id: 'songwriter-add-page-title',
        }}
      />
      <Grid
        container
        spacing={2}
        sx={{
          flexDirection: {
            xs: 'column',
          },
        }}
      >
        <Grid item xs={12}>
          {remainingCreditsLoading && <LoadingIndicator size={50} />}
          {!remainingCreditsLoading && (
            <AddSongwriterStepper
              t={t}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default AddSongwriterPage;
