import { Grid, Link, Typography } from '@mui/material';
import { Translator } from '~/types/Translator';

/**
 * AddPRONonMember component displays a message for users who are not members
 * of any Performing Rights Organization (PRO), with links to relevant resources.
 *
 * @param {Object} props - Component props.
 * @param {Function} props.t - Translation function from i18next.
 */
function AddPRONonMember({ t }: Translator) {
  return (
    <Grid
      data-testid="add-songwriter-non-member"
      container
      spacing={2}
      sx={{
        pt: 2, // Top padding
        pb: 2, // Bottom padding
        flexDirection: {
          xs: 'row', // Layout direction for small screens
        },
      }}
    >
      <Grid item xs={12}>
        <Typography variant="body1" fontWeight={700}>
          {/* Display generic message with links */}
          {t('page.add-songwriter.form.pro.generic-message1')}{' '}
          <Link
            href={t('page.add-songwriter.form.pro.directory-link')}
            target="_blank"
          >
            {t('page.add-songwriter.form.pro.directory')}
          </Link>{' '}
          {t('page.add-songwriter.form.pro.generic-message2')}{' '}
          <Link
            href={t('page.add-songwriter.form.pro.ipi-link')}
            target="_blank"
          >
            {t('page.add-songwriter.form.pro.ipi')}
          </Link>
          {t('page.add-songwriter.form.pro.generic-message3')}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default AddPRONonMember;
