import { Avatar, Box, Grid, Typography } from '@mui/material';
import backpackIcon from '~/assets/img/backpack_icon.jpg';
import { Translator } from '~/types/Translator';

function SignupTestimonial({ t }: Translator) {
  const TESTIMONIAL_FONTSIZE = { fontSize: '0.875rem' };

  return (
    <Grid
      container
      columnSpacing={3}
      rowSpacing={3}
      className="signup-testimonial"
      data-testid="signup-testimonial"
    >
      <Grid item xs={12}>
        <Avatar
          alt={t('page.testimonial.artist')}
          src={backpackIcon}
          sx={{ width: 128, height: 128, margin: '0 auto' }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box sx={TESTIMONIAL_FONTSIZE}>
          &quot;{t('page.testimonial.quote')}&quot;
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3" component="h3">
          - {t('page.testimonial.artist')}
        </Typography>
        <Grid item xs={12}>
          <Box sx={TESTIMONIAL_FONTSIZE}>
            {t('page.testimonial.credential')}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={TESTIMONIAL_FONTSIZE}>{t('page.testimonial.since')}</Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SignupTestimonial;
