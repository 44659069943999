import { Box, Grid, Link, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import FormRadio from '~/base/components/FormRadio';
import LoadingIndicator from '~/base/components/LoadingIndicator/LoadingIndicator';
import SectionTipCard from '~/base/components/SectionTipCard';
import SectionTitle from '~/base/components/SectionTitle/SectionTitle';
import { Loadable } from '~/types/Loadable';
import { Translator } from '~/types/Translator';

type AddSongtrustWriterYoutubeClaimsProps = Translator & Loadable;
function AddSongtrustWriterYoutubeClaims({
  t,
  loading = false,
}: AddSongtrustWriterYoutubeClaimsProps) {
  const { setValue, watch, getValues } = useFormContext();

  // Watch for songwriter id to update
  const songwriterId = watch('id');

  useEffect(() => {
    const youtubeOptOut = getValues('youtubeOptOut');
    if (youtubeOptOut) {
      setValue('songwriter-youtube-claims', 'yes');
    } else if (songwriterId && !youtubeOptOut) {
      setValue('songwriter-youtube-claims', 'no');
    }
  }, [songwriterId]);

  return (
    <Grid
      container
      spacing={2}
      data-testid="add-songtrust-writer-youtube-claims"
    >
      <Grid item xs={12}>
        <SectionTitle>
          {t('page.add-songwriter.form.songwriter.youtube.title')}
        </SectionTitle>
      </Grid>
      <Grid item md={9} xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="body1" component="p">
              {t('page.add-songwriter.form.songwriter.youtube.question')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {loading && <LoadingIndicator size={30} />}
            {!loading && (
              <FormRadio
                id="songwriter-youtube-claims"
                name="songwriter-youtube-claims"
                data-testid="songwriter-youtube-claims"
                columns={1}
                required
                options={[
                  {
                    choiceId: 'yes',
                    choiceLabel: t(
                      'page.add-songwriter.form.songwriter.youtube.yes',
                    ),
                  },
                  {
                    choiceId: 'no',
                    choiceLabel: t(
                      'page.add-songwriter.form.songwriter.youtube.no',
                    ),
                  },
                ]}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={3} xs={12}>
        <SectionTipCard
          title={t('page.add-songwriter.form.songwriter.youtube.tip.title')}
          color="blue"
        >
          <Box sx={{ mt: 1 }}>
            <p>
              {t('page.add-songwriter.form.songwriter.youtube.tip.content')}
              {` `}
              <Link
                href={t(
                  'page.add-songwriter.form.songwriter.youtube.tip.blog-link',
                )}
              >
                {t('page.add-songwriter.form.songwriter.youtube.tip.here')}
              </Link>
            </p>
            <p>
              {t(
                'page.add-songwriter.form.songwriter.youtube.tip.content-continued',
              )}
              {` `}
              <Link
                href={t(
                  'page.add-songwriter.form.songwriter.youtube.tip.youtube-link',
                )}
              >
                {t(
                  'page.add-songwriter.form.songwriter.youtube.tip.requirements',
                )}
              </Link>
            </p>
          </Box>
        </SectionTipCard>
      </Grid>
    </Grid>
  );
}

export default AddSongtrustWriterYoutubeClaims;
