import { Button, Grid, Typography } from '@mui/material';
import { Dispatch, useState } from 'react';
import { Translator } from '~/types/Translator';
import AddPRONonMember from '../AddPRONonMember/AddPRONonMember';
import AddPROSelection from '../AddPROSelection/AddPROSelection';

interface AddPROInformationProps extends Translator {
  setDisableContinueButton: Dispatch<React.SetStateAction<boolean>>;
}

/**
 * AddPROInformation component allows users to select whether they are a member
 * of a Performing Rights Organization (PRO) and displays relevant information
 * based on their selection.
 *
 * @param {Object} props - Component props.
 * @param {Function} props.t - Translation function from i18next.
 */
function AddPROInformation({
  t,
  setDisableContinueButton,
}: AddPROInformationProps) {
  // State to control visibility of PRO selection and non-member message
  const [selectPROVisible, setSelectPROVisible] = useState<boolean>(false);
  const [noProMessageVisible, setNoProMessageVisible] =
    useState<boolean>(false);

  // Handler for when the "No PRO" button is clicked
  const handleNoPROClick = () => {
    setNoProMessageVisible(true);
    setDisableContinueButton(true);
    setSelectPROVisible(false);
  };

  // Handler for when the "Yes PRO" button is clicked
  const handlePROClick = () => {
    setNoProMessageVisible(false);
    setSelectPROVisible(true);
  };

  // Styles for the PRO buttons
  const proButtonSx = {
    background: '#7E7E7E',
    color: '#fff',
    border: 'none',
    ':hover, &.selected': {
      border: 'none',
      background: '#B4012D',
    },
    textAlign: 'center',
    p: 1,
    pr: 2,
    pl: 2,
    cursor: 'pointer',
    mr: '2rem',
  };

  return (
    <div data-testid="add-songwriter-pro">
      <Grid
        container
        spacing={2}
        sx={{
          flexDirection: {
            xs: 'row',
          },
        }}
      >
        {/* Title of the PRO information section */}
        <Grid item xs={12}>
          <Typography variant="h3" component="h3">
            {t('page.add-songwriter.form.pro.title')}
          </Typography>
        </Grid>

        {/* Question about PRO membership */}
        <Grid item xs={12}>
          <Typography variant="body1">
            {t('page.add-songwriter.form.pro.question')}
          </Typography>
        </Grid>

        {/* No PRO button */}
        <Grid item xs={12} md={4}>
          <Button
            className={noProMessageVisible ? 'selected' : ''}
            variant="outlined"
            onClick={handleNoPROClick}
            sx={proButtonSx}
          >
            {t('page.add-songwriter.form.pro.no')}
          </Button>
        </Grid>

        {/* Yes PRO button */}
        <Grid item xs={12} md={4}>
          <Button
            variant="outlined"
            className={selectPROVisible ? 'selected' : ''}
            onClick={handlePROClick}
            sx={proButtonSx}
          >
            {t('page.add-songwriter.form.pro.yes')}
          </Button>
        </Grid>

        <Grid item xs={12} md={4} />
      </Grid>

      {/* Conditional rendering based on button clicks */}
      {noProMessageVisible && <AddPRONonMember t={t} />}
      {selectPROVisible && (
        <AddPROSelection
          t={t}
          setDisableContinueButton={setDisableContinueButton}
        />
      )}
    </div>
  );
}

export default AddPROInformation;
