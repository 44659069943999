import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderMenuLinksDefault from '~/base/components/Header/HeaderMenuLinks/HeaderMenuLinksDefault';
import { MenuLinkType } from '~/base/types/MenuLinkType';

interface HeaderMenuProps {
  menuLinks: Array<MenuLinkType>;
  setMenuOpen?: Dispatch<SetStateAction<boolean>>;
}

/**
 * HeaderMenuLinks component renders a list of menu links and handles link clicks.
 *
 * @param {Array<MenuLinkType>} menuLinks - An array of menu link objects.
 * @param {Dispatch<SetStateAction<boolean>>} [setMenuOpen] - A state setter function to close the menu.
 * @returns {JSX.Element} The rendered HeaderMenuLinksDefault component.
 */
function HeaderMenuLinks({
  menuLinks,
  setMenuOpen = undefined,
}: HeaderMenuProps): JSX.Element {
  const navigate = useNavigate();

  /**
   * Handles click events on menu links.
   *
   * @param {string} url - The URL of the menu link.
   */
  const handleMenuLinkClick = (url: string) => {
    // Close the menu if setMenuOpen function is provided
    if (setMenuOpen) setMenuOpen(false);

    // Navigate to the URL, either external or internal
    if (url.indexOf('http') === 0) {
      window.location.href = url;
    } else {
      navigate(url);
    }
  };

  return (
    <HeaderMenuLinksDefault
      menuLinks={menuLinks}
      handleItemClick={handleMenuLinkClick}
    />
  );
}

export default HeaderMenuLinks;
