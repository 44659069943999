import SyncIcon from '@mui/icons-material/Sync';
import { Button, Grid, Link, Typography } from '@mui/material';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import TagManager from 'react-gtm-module';
import { useFormContext } from 'react-hook-form';
import ModalVideo from 'react-modal-video';
import {
  PlaidLinkError,
  PlaidLinkOnExit,
  PlaidLinkOnExitMetadata,
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
  usePlaidLink,
} from 'react-plaid-link';
import FormBanner, {
  FormBannerType,
} from '~/base/components/FormBanner/FormBanner';
import FormTooltip from '~/base/components/FormTooltip';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import {
  isInvalid,
  isUnsure,
  isValid,
} from '~/signup/constants/signupConstants';
import { Translator } from '~/types/Translator';
import {
  CheckVerificationStatusQuery,
  GeneratePlaidIdentityTokenMutation,
  useCheckVerificationStatusLazyQuery,
  useGeneratePlaidIdentityTokenMutation,
} from '~/types/generated/graphql';
import SignupStepNumberCheck from '../SignupStepNumberCheck';

import './SignupPlaidVerification.scss';

interface SignupPlaidVerificationProps extends Translator {
  verificationStatus: string | null | undefined;
  setVerificationStatus: Dispatch<SetStateAction<string | null | undefined>>;
}

function SignupPlaidVerification({
  t,
  verificationStatus,
  setVerificationStatus,
}: SignupPlaidVerificationProps) {
  // Plaid token
  const [identityId, setIdentityId] = useState<string | undefined>(undefined);
  const [token, setToken] = useState<string | undefined>(undefined);

  const [sectionActive, setSectionActive] = useState<boolean>(false);
  const [sectionComplete, setSectionComplete] = useState<boolean>(false);

  const [isVideoOpen, setIsVideoOpen] = useState<boolean>(false);

  const { setValue } = useFormContext();

  const [generateToken] = useGeneratePlaidIdentityTokenMutation({
    fetchPolicy: 'no-cache',
    onCompleted: (res: GeneratePlaidIdentityTokenMutation) => {
      const identityIdValue = res.generatePlaidIdentityToken
        ?.identityId as string;
      const tokenValue = res.generatePlaidIdentityToken?.token as string;

      setToken(tokenValue);
      setIdentityId(identityIdValue);
    },
    onError() {
      return undefined;
    },
  });

  // Interval patch for loggedInSongtrustUser
  const [reloadVerificationStatus, { called }] =
    useCheckVerificationStatusLazyQuery();

  // onSuccess
  // A function that is called when a user successfully links an Item.
  // The function should expect two arguments, the public_token and a metadata object. See onSuccess.
  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
      TagManager.dataLayer({
        dataLayer: {
          event: 'signup_kyc_finished',
          eventCategory: 'user',
          eventAction: 'signup',
          eventLabel: 'kyc_finish',
          eventValue: undefined,
        },
      });

      setSectionComplete(true);
      const withRefetch = (id: string, r = 1000, m = 127000) => {
        return reloadVerificationStatus({
          variables: {
            identityId: id,
          },
          fetchPolicy: 'network-only',
          onCompleted: (res: CheckVerificationStatusQuery) => {
            const resIdentityId = res.checkVerificationStatus?.identityId;
            if (resIdentityId === id) {
              setVerificationStatus(res.checkVerificationStatus?.status);
              if (isValid(res.checkVerificationStatus?.status)) {
                setValue('identityId', resIdentityId);
              } else if (m > 0) {
                // Schedule a retry with exponential backoff.
                setTimeout(() => {
                  withRefetch(id, r * 2, m - r);
                }, r);
              }
            }
          },
          onError() {
            // If there's an error, also schedules a retry with exponential backoff.
            if (m > 0) {
              setTimeout(() => {
                withRefetch(id, r * 2, m - r);
              }, r);
            }
          },
        });
      };
      withRefetch(identityId as string);
    },
    [identityId],
  );

  // onExit
  // A function that is called when a user reaches certain points in the Link flow.
  // The function should expect two arguments, an eventName string and a metadata object. See onEvent.
  const onExit = useCallback<PlaidLinkOnExit>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (error: null | PlaidLinkError, metadata: PlaidLinkOnExitMetadata) => {
      if (error === null) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'signup_kyc_exited',
            eventCategory: 'user',
            eventAction: 'signup',
            eventLabel: 'kyc_exit',
            eventValue: undefined,
          },
        });
      }
      // handle invalid link token
      if (error != null && error.error_code === 'INVALID_LINK_TOKEN') {
        // generate new link token
        generateToken();
      }
    },
    [],
  );

  // PlaidLinkOption object.
  // Token needs to be generated from mutation 'generatePlaidUserToken'
  const config: PlaidLinkOptions = {
    onSuccess,
    onExit,
    token: token as string,
  };
  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    generateToken();
  }, []);

  return (
    <Grid
      container
      columnSpacing={3}
      rowSpacing={3}
      data-testid="signup-plaid-verification"
    >
      <ModalVideo
        channel="youtube"
        youtube={{
          autoplay: 1,
          mute: 0,
        }}
        isOpen={isVideoOpen}
        videoId="2Sk5HKF84PM"
        onClose={() => setIsVideoOpen(false)}
      />
      <Grid item xs={12} md={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Typography variant="h2" component="h2">
              <SignupStepNumberCheck
                text="2"
                active={sectionActive}
                validated={sectionComplete}
              />
              {t('form.verify-my-id')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" component="span" sx={{ pr: '.5rem' }}>
              {t('form.whats-this')}
            </Typography>
            <FormTooltip
              tooltip={
                <>
                  {t('form.whats-this-into')}
                  {t('form.verification-acknowledgement-watch-this')}
                  <Link
                    href="https://www.youtube.com/embed/2Sk5HKF84PM?controls=0&autoplay=1"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsVideoOpen(true);
                    }}
                    target="_blank"
                    data-testid="privacy-policy-plaid"
                  >
                    {t('form.verification-acknowledgement-quick-tutorial')}
                  </Link>
                  {t('form.verification-acknowledgement-best-practices')}
                </>
              }
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        {
          // Haven't clicked the button to begin the process, and hasn't returned an invalid result.
        }
        {!called && !isInvalid(verificationStatus) && (
          <Typography
            variant="body1"
            component="div"
            sx={{ fontSize: '0.875rem', pb: '1.5rem' }}
          >
            {t('form.verification-acknowledgement')}
            {t('form.verification-acknowledgement-watch-this')}
            <Link
              href="https://www.youtube.com/embed/2Sk5HKF84PM?controls=0&autoplay=1"
              onClick={(e) => {
                e.preventDefault();
                setIsVideoOpen(true);
              }}
              target="_blank"
              data-testid="privacy-policy-plaid"
            >
              {t('form.verification-acknowledgement-quick-tutorial')}
            </Link>
            {t('form.verification-acknowledgement-best-practices')}
          </Typography>
        )}

        {
          // Haven't clicked the button to begin the process, and hasn't returned an invalid result.
        }
        {!called && !isInvalid(verificationStatus) && (
          <>
            {!ready && <LoadingIndicator size={50} />}
            {ready && (
              <Grid container>
                <Grid item xs={12}>
                  <Button
                    id="signup-plaid-verification-begin"
                    data-testid="signup-plaid-verification-begin"
                    variant="contained"
                    color="success"
                    sx={{ padding: '1rem 4rem' }}
                    onClick={() => {
                      setSectionActive(true);
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      if (window.Cypress) {
                        setSectionComplete(true);
                        setVerificationStatus('VALID');
                      } else {
                        open();
                      }
                    }}
                  >
                    {t('form.begin-verification')}
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        )}

        {
          // The verification process has been started (called) and it has not returned a result yet.
        }
        {called && isUnsure(verificationStatus) && (
          <span data-testid="verification-status-verifying">
            <Typography variant="h5" sx={{ display: 'flex' }}>
              <SyncIcon sx={{ color: '#A4A4A4', justifySelf: 'center' }} />
              {t('form.verification-status.verifying')}
            </Typography>
          </span>
        )}

        {
          // The verification process has been started (called) and it has returned a VALID results.
        }
        {called && isValid(verificationStatus) && (
          <span data-testid="verification-status-valid">
            <FormBanner
              text={t('form.verification-status.valid-verification')}
              type={FormBannerType.SUCCESS}
            />
          </span>
        )}

        {
          // The verification process has been started (called) and it has returned an INVALID result.
        }
        {called && isInvalid(verificationStatus) && (
          <span data-testid="verification-status-invalid">
            <FormBanner
              text={t('form.verification-status.invalid-verification')}
              type={FormBannerType.ERROR}
            />
          </span>
        )}
      </Grid>
    </Grid>
  );
}

export default SignupPlaidVerification;
