import { currentSongtrustUserPersonaVar } from '~/cache';
import { useLoggedInSongtrustUserPersonaQuery } from '~/types/generated/graphql';

function useLoggedInSongtrustUserPersona() {
  const { loading, data } = useLoggedInSongtrustUserPersonaQuery({
    fetchPolicy: 'network-only',

    onCompleted: (res) => {
      currentSongtrustUserPersonaVar(res);
    },
  });

  return { loading, data };
}

export default useLoggedInSongtrustUserPersona;
