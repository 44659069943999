import { Box, Button, Grid, Link } from '@mui/material';
import { Translator } from '~/types/Translator';

/**
 * Props for SignupDiscountDisplay component.
 */
interface SignupDiscountDisplayProps extends Translator {
  /**
   * Prefix for translation keys.
   */
  tPrefix?: string;
  /**
   * The applied discount code.
   */
  discountCode: string;
  /**
   * Total discount percentage.
   */
  totalDiscountPercent: number;
  /**
   * Click handler for removing the applied discount.
   */
  handleRemoveDiscountClick: () => void;
}

/**
 * SignupDiscountDisplay component displays information about an applied discount.
 * @param {SignupDiscountDisplayProps} props - Component props.
 * @returns {JSX.Element} - Rendered component.
 */
function SignupDiscountDisplay({
  t,
  tPrefix = '',
  discountCode,
  totalDiscountPercent,
  handleRemoveDiscountClick,
}: SignupDiscountDisplayProps): JSX.Element {
  return (
    <Grid item xs={12} data-testid="signup-discount-display">
      <Grid
        container
        rowSpacing={1}
        sx={{
          fontSize: '0.75rem',
          paddingBottom: '1rem',
          paddingLeft: '.5rem',
          paddingRight: '.5rem',
        }}
      >
        <Grid item xs={12}>
          <Box>{t(`${tPrefix}form.discount-code`)}</Box>
        </Grid>
        <Grid item xs={8}>
          <Box sx={{ fontWeight: 'bold' }}>
            <Box component="span" data-testid="applied-discount-code">
              {discountCode}
            </Box>{' '}
            <Box component="span" data-testid="applied-discount-percent">
              ({totalDiscountPercent * 100}% {t(`${tPrefix}form.off`)})
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4} sx={{ textAlign: 'right' }}>
          <Link
            data-testid="remove-applied-discount-code"
            component={Button}
            href="/#"
            sx={{
              textAlign: 'center',
              textTransform: 'uppercase',
            }}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.preventDefault();
              handleRemoveDiscountClick();
            }}
          >
            {t(`${tPrefix}form.remove`)}
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SignupDiscountDisplay;
