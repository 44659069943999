import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Translator } from '~/types/Translator';
import { InterestedpartySongWriterSongwriterTypeChoices } from '~/types/generated/graphql';

interface NoSongwritersTableMessageProps extends Translator {
  songwriterType: InterestedpartySongWriterSongwriterTypeChoices;
}

function NoSongwritersTableMessage({
  t,
  songwriterType,
}: NoSongwritersTableMessageProps) {
  const navigate = useNavigate();
  return (
    <Box data-testid="no-songwriters-message-box">
      <Grid container>
        {songwriterType ===
          InterestedpartySongWriterSongwriterTypeChoices.Client && (
          <Grid item xs={12} textAlign="center">
            <Typography
              data-testid="no-songwriters-message"
              variant="h4"
              component="h4"
              sx={{ p: 3 }}
            >
              {t('page.songwriters.tabs.client.empty.message')}
            </Typography>
            <Button
              data-testid="add-first-songwriter-button"
              variant="contained"
              color="secondary"
              type="submit"
              sx={{
                pt: '.5rem',
                pb: '.5rem',
                pl: '3rem',
                pr: '3rem',
              }}
              onClick={() => {
                navigate('/songwriters/add');
              }}
            >
              {t('page.songwriters.tabs.client.empty.button')}
            </Button>
          </Grid>
        )}

        {songwriterType ===
          InterestedpartySongWriterSongwriterTypeChoices.Outside && (
          <Grid item xs={12} textAlign="center" sx={{ p: 3 }}>
            <Typography
              data-testid="no-songwriters-message"
              variant="h4"
              component="h4"
            >
              {t('page.songwriters.tabs.outside.empty.message')}
            </Typography>
            <Typography
              data-testid="no-songwriters-message-desc"
              variant="body1"
              component="p"
            >
              {t('page.songwriters.tabs.outside.empty.message-desc')}
            </Typography>
            <Typography
              data-testid="no-songwriters-message-desc-cont"
              variant="body1"
              component="p"
            >
              {t('page.songwriters.tabs.outside.empty.message-desc-cont')}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default NoSongwritersTableMessage;
