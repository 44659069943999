import Typography from '@mui/material/Typography';

/**
 * Props for RichTooltipContent component
 * @typedef {Object} RichTooltipContentProps
 * @property {string} [title] - Optional title for the tooltip
 * @property {'default' | 'ul' | 'ol'} [type] - Type of content list: default, unordered list, or ordered list
 * @property {string[]} contents - Array of content strings to display in the tooltip
 */
type RichTooltipContentProps = {
  title?: string;
  type?: 'default' | 'ul' | 'ol';
  contents: string[];
};

/**
 * Component to render rich tooltip content with optional title and list types
 * @param {RichTooltipContentProps} props - Props for the component
 * @returns {JSX.Element} RichTooltipContent component
 */
function RichTooltipContent({
  title,
  type = 'default',
  contents,
}: RichTooltipContentProps) {
  return (
    <>
      {title && <h4>{title}</h4>}
      {type === 'ol' && (
        <ol data-testid="richtooltip-ol">
          {contents.map((content) => (
            <li key={`ol_${content}`}>
              <Typography sx={{ fontSize: '.9rem' }}>{content}</Typography>
            </li>
          ))}
        </ol>
      )}
      {type === 'ul' && (
        <ul data-testid="richtooltip-ul">
          {contents.map((content) => (
            <li key={`ul_${content}`}>
              <Typography>{content}</Typography>
            </li>
          ))}
        </ul>
      )}
      {type === 'default' &&
        contents.map((content) => (
          <Typography
            display="block"
            gutterBottom
            key={`def_${content}`}
            sx={{ fontSize: '.9rem' }}
          >
            {content}
          </Typography>
        ))}
    </>
  );
}

export default RichTooltipContent;
