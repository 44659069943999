import { Container, Grid } from '@mui/material';
import PageTitle from '~/base/components/PageTitle/PageTitle';
import usePageTitle from '~/hooks/usePageTitle';
import { Translator } from '~/types/Translator';
import ChangePasswordSection from '../ChangePasswordSection/ChangePasswordSection';

function ChangePasswordPage({ t }: Translator) {
  usePageTitle(t('sections.change-password.title'));

  return (
    <div data-testid="change-password-page">
      <Container>
        <PageTitle
          currentPage={t('sections.change-password.title')}
          breadcrumbLinks={[
            {
              id: 'account-password-back-link',
              label: t('sections.account-information.breadcrumb'),
              href: '/account',
            },
          ]}
          pageTitle={{
            title: t('sections.change-password.title'),
            id: 'change-password-page-title',
          }}
        />
        <Grid
          container
          spacing={2}
          sx={{
            flexDirection: {
              xs: 'column',
            },
          }}
        >
          <Grid item xs={12} md={12}>
            <ChangePasswordSection t={t} dashboardRedirect={false} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default ChangePasswordPage;
