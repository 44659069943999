import { ApolloError } from '@apollo/client';
import { Box, Button, Container, Grid } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import FormBanner, {
  FormBannerType,
} from '~/base/components/FormBanner/FormBanner';
import PageTitle from '~/base/components/PageTitle/PageTitle';
import SectionTitle from '~/base/components/SectionTitle/SectionTitle';
import usePageTitle from '~/hooks/usePageTitle';
import {
  PublishingCompanyInput,
  SongwriterQuery,
  SongwriterType,
  SongwriterUpdateMutation,
  UpdateSongwriterInput,
  useSongwriterQuery,
  useSongwriterUpdateMutation,
  UsRepresentationInput,
} from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';
import AddPROSelection from '../AddPROSelection/AddPROSelection';
import AddSongtrustWriter from '../AddSongtrustWriter/AddSongtrustWriter';
import { SIGNATURE_FORM_NAME } from '../AddSongtrustWriterSignature/AddSongtrustWriterSignature';

function EditSongwriterPage({ t }: Translator) {
  usePageTitle(t('page.edit-songwriter.title'));

  const navigate = useNavigate();

  const { id: songwriterId } = useParams();
  const [currentSongwriter, setCurrentSongwriter] = useState<
    SongwriterType | undefined
  >(undefined);

  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [disableContinueButton, setDisableContinueButton] =
    useState<boolean>(false);

  const handleCancel = () => {
    navigate('/songwriters/manage');
  };

  // Query hook for songwriter data
  const { loading } = useSongwriterQuery({
    variables: {
      songwriterId: parseInt(songwriterId as string, 10),
    },
    onCompleted: (res: SongwriterQuery) => {
      setCurrentSongwriter(res.songwriter as SongwriterType);
    },
  });

  const formMethods = useForm({
    mode: 'onBlur',
    defaultValues: useMemo(() => {
      return currentSongwriter || {};
    }, [currentSongwriter]),
    shouldUseNativeValidation: true,
  });

  // Watch for changes in form
  const { isDirty } = formMethods.formState;

  // Mutation hook handlers.
  const handleSubmitComplete = async (res: SongwriterUpdateMutation) => {
    if (res.updateSongwriter?.songwriter) {
      navigate(`/songwriters/${songwriterId}`);
    } else {
      // Handle backend validation error messages
      const updateSongwriterErrors = res.updateSongwriter?.errors;
      if (updateSongwriterErrors) {
        const errors = updateSongwriterErrors.map(
          (err) => err?.error as string,
        );
        setErrorMessages(errors);
      }
      window.scrollTo({ top: 0 });
    }
  };

  const handleSubmitError = (error: ApolloError) => {
    setErrorMessages([error.message]);
    window.scrollTo({ top: 0 });
  };

  // Mutation for update songwriter
  const [submitSongwriterUpdate, { loading: isUpdatingSongwriter }] =
    useSongwriterUpdateMutation({
      fetchPolicy: 'network-only',
      onError: handleSubmitError,
      onCompleted: handleSubmitComplete,
    });

  const handleFormSubmit = async (values: FieldValues) => {
    setErrorMessages([]);

    // Explicitly register signature field
    formMethods.register(SIGNATURE_FORM_NAME as never, {
      required: 'Signature is required',
    });

    // Trigger react-hook-form fields for validation.
    if (await formMethods.trigger()) {
      if (currentSongwriter && currentSongwriter.id) {
        // US Representation
        const usProAffiliation = values['us-pro-affiliation'];
        const usProAffiliationViaPublisher =
          values['us-pro-affiliation-via-publisher'];
        const usRepresentationInput: UsRepresentationInput = {
          name: values['pro-representation'] || 'ASCAP',
        };

        // Publishing Company
        const hasPublishingCompany = values['has-publishingcompany'];
        const publishingCompanyInput: PublishingCompanyInput = {
          name: values['publishingcompany-name'],
          ipi: values['publishingcompany-ipi'],
          pro: { name: values['publishingcompany-pro'] },
        };

        // Assign updated values to songwriter input
        const songwriterUpdate = {
          songwriterId: parseInt(currentSongwriter?.id, 10),
          email: values.email,
          firstName: values.firstname,
          lastName: values.lastname,
          middleName: values.middlename,
          ipi: values.ipi,
          birthdate: dayjs(values.birthdate as string).format('YYYY-MM-DD'),
          songwriterYoutubeClaims:
            values['songwriter-youtube-claims'] === 'yes',
          pro: {
            name: values.pro,
          },
          signature: values.signature,
          usRepresentation:
            usProAffiliation === 'yes' || usProAffiliationViaPublisher === 'yes'
              ? usRepresentationInput
              : undefined,
          publishingCompany:
            hasPublishingCompany === 'no' ? undefined : publishingCompanyInput,
        } as UpdateSongwriterInput;

        submitSongwriterUpdate({
          variables: {
            songwriter: songwriterUpdate,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (
      currentSongwriter?.currentStatus &&
      currentSongwriter?.currentStatus[0] !== '01--error'
    ) {
      handleCancel();
    } else {
      // Load songwriter information onto page
      formMethods.reset(currentSongwriter);
    }
  }, [currentSongwriter]);

  return (
    <FormProvider {...formMethods}>
      <form
        data-testid="edit-songwriter-page"
        onSubmit={formMethods.handleSubmit(handleFormSubmit)}
      >
        <Container sx={{ flexGrow: 1 }}>
          <PageTitle
            currentPage={
              loading
                ? '...'
                : `${currentSongwriter?.firstName ?? ''} ${currentSongwriter?.middleName ?? ''} ${currentSongwriter?.lastName ?? ''}`
            }
            breadcrumbLinks={[
              {
                id: 'songwriter-detail-back-link',
                label: t('page.songwriter-details.breadcrumb'),
                href: '/songwriters/manage',
              },
            ]}
            pageTitle={{
              title: t('page.edit-songwriter.title'),
              id: 'songwriter-edit-page-title',
            }}
          />
          <Grid
            container
            spacing={2}
            sx={{
              flexDirection: {
                xs: 'column',
              },
            }}
          >
            <Grid item xs={12}>
              {errorMessages &&
                errorMessages.map((error) => {
                  return (
                    <FormBanner
                      text={error as string}
                      type={FormBannerType.ERROR}
                      sx={{ mb: '0.5rem' }}
                    />
                  );
                })}
            </Grid>
            {/* Home Society Information */}
            <Grid item xs={12}>
              <Grid item xs={12}>
                <SectionTitle>
                  {t('page.add-songwriter.form.pro.title')}
                </SectionTitle>
              </Grid>
              <AddPROSelection
                t={t}
                setDisableContinueButton={setDisableContinueButton}
                loading={loading}
              />
            </Grid>
          </Grid>

          {/* Basic Info, US Rep, Publishing Info, Youtube Claims */}
          <Grid container>
            <Grid item xs={12}>
              <Box sx={{ pr: { md: 3, xs: 0 } }}>
                <AddSongtrustWriter t={t} loading={loading} />
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12} md={8}>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                variant="outlined"
                color="primary"
                data-testid="button-cancel"
                sx={{ mr: 1 }}
                onClick={handleCancel}
                disabled={loading}
              >
                {t('page.edit-songwriter.form.buttons.cancel')}
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button
                variant="contained"
                color="secondary"
                data-testid="button-submit"
                type="submit"
                name="button-submit"
                disabled={
                  loading ||
                  disableContinueButton ||
                  isUpdatingSongwriter ||
                  !isDirty
                }
              >
                {t('page.edit-songwriter.form.buttons.submit')}
              </Button>
            </Box>
          </Grid>
        </Container>
      </form>
    </FormProvider>
  );
}

export default EditSongwriterPage;
