import { useReactiveVar } from '@apollo/client';
import { NavLink } from 'react-router-dom';
import headerLogo from '~/assets/img/songtrust-logo-header.png';
import headerLogoSmall from '~/assets/img/songtrust-logo.svg';
import { currentSessionTokenVar } from '~/cache';

interface HeaderLogoProps {
  size: 'sm' | 'md' | 'lg' | 'xl';
}

function HeaderLogo({ size }: HeaderLogoProps) {
  const sessionToken = useReactiveVar(currentSessionTokenVar);
  const toLink = sessionToken ? '/dashboard' : '/';

  return (
    <NavLink to={toLink} data-testid="header-logo-nav-link" className="logo">
      {size === 'xl' && (
        <img
          data-testid="header-logo-xl"
          src={headerLogo}
          alt="Songtrust"
          style={{ display: 'flex', width: '20rem' }}
        />
      )}
      {size === 'lg' && (
        <img
          data-testid="header-logo-lg"
          src={headerLogo}
          alt="Songtrust"
          style={{ display: 'flex', width: '16rem' }}
        />
      )}
      {size === 'md' && (
        <img
          data-testid="header-logo-md"
          src={headerLogo}
          alt="Songtrust"
          style={{ display: 'flex', width: '10rem' }}
        />
      )}
      {size === 'sm' && (
        <img
          data-testid="header-logo-sm"
          src={headerLogoSmall}
          alt="Songtrust"
          style={{ display: 'flex' }}
        />
      )}
    </NavLink>
  );
}

export default HeaderLogo;
