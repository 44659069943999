import { useReactiveVar } from '@apollo/client';
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import React, { useContext } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import {
  formSubmitAttempt,
  formSubmitFailure,
  formSubmitSuccess,
} from '~/base/helpers/mouseflowHelper';
import { currentSongtrustUserPersonaVar } from '~/cache';
import OnboardingAboutQuestions from '~/onboarding/components/OnboardingAboutQuestions/OnboardingAboutQuestions';
import OnboardingAdministrationQuestions from '~/onboarding/components/OnboardingAdministrationQuestions/OnboardingAdministrationQuestions';
import OnboardingFormContext from '~/onboarding/components/OnboardingFormContext';
import OnboardingSongCatalogQuestions from '~/onboarding/components/OnboardingSongCatalogQuestions/OnboardingSongCatalogQuestions';
import { FINISH_ONBOARDING_REDIRECT } from '~/onboarding/constants/onboardingConstants';

import {
  LoggedInSongtrustUserPersonaQuery,
  OnboardingProfileInput,
  SubmitOnboardingProfileMutation,
  useSubmitOnboardingProfileMutation,
} from '~/types/generated/graphql';

const steps = ['about-you', 'song-catalog', 'administration'];

interface OnboardingStepperProps {
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

export default function OnboardingStepper({
  activeStep,
  setActiveStep,
}: OnboardingStepperProps) {
  const navigate = useNavigate();
  const contextData = useContext(OnboardingFormContext);
  const loggedInUserPersona = useReactiveVar(currentSongtrustUserPersonaVar);

  const { t } = useTranslation(['onboarding']);

  const [submitOnboardingProfileForm, { loading }] =
    useSubmitOnboardingProfileMutation({
      fetchPolicy: 'no-cache',
    });

  const defaultValues = {
    currentDistributors: [],
    genres: [],
    activeWorkRegions: [],
  };
  const onboardingFormMethods = useForm({ defaultValues, mode: 'all' });

  const handleNext = async () => {
    if (await onboardingFormMethods.trigger()) {
      if (activeStep === steps.length - 1) {
        window.location.href = FINISH_ONBOARDING_REDIRECT;
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSubmitComplete = (data: SubmitOnboardingProfileMutation) => {
    // Mouseflow submission fix.
    formSubmitSuccess('#onboardingForm');

    const requiresOnboarding =
      data.submitOnboardingProfile?.songtrustUser?.requiresOnboarding;

    if (!requiresOnboarding) {
      const updatedUserPersona = {
        ...loggedInUserPersona,
        loggedInSongtrustUser: {
          ...loggedInUserPersona?.loggedInSongtrustUser,
          requiresOnboarding,
        },
      };
      currentSongtrustUserPersonaVar(
        updatedUserPersona as LoggedInSongtrustUserPersonaQuery,
      );
      navigate('/dashboard');
    }
  };

  const handleSubmit = async (values: FieldValues) => {
    // Trigger validation.
    if (await onboardingFormMethods.trigger()) {
      // Prep Genres as String array.
      const mappedGenreLabels = values.genres.map((id: string) => {
        const genreNode = contextData?.userGenres?.edges.find((edge) => {
          return `${edge?.node?.choice}` === id;
        });
        return genreNode?.node?.label;
      });

      // Prep/Build OnboardingProfileInput mutation object from values in react-hook-form
      const onboardingInput: OnboardingProfileInput = {
        about: {
          accountType: values.accountType,
          globalPublisher: values.globalPublisher.toLowerCase() === 'yes',
          location: {
            country: values.country,
            city: values.city,
            state: values.state,
            zipcode: values.postalcode,
          },
          digitalDistribution: values?.digitalDistribution,
          currentDistributors: values?.currentDistributors?.map(Number),
          favoriteDistributor: parseInt(values?.favoriteDistributor, 10),
        },
        catalog: {
          genres: mappedGenreLabels,
          initialCountEstimate: parseInt(values.initialCountEstimate, 10),
          streamEstimate: parseInt(values.streamEstimation, 10),
          regions: values.activeWorkRegions.map(Number),
        },
        administration: {
          expectedManagedWriters: values.expectedManagedWriters,
          catalogRepresentation: values.catalogRepresentation,
          coPublishing: values.coPublished,
          personalPubEntityWithPro: values?.personalPublishingEntity
            ? values.personalPublishingEntity
            : 'unsure',
        },
      };

      // Mouseflow submission fix.
      formSubmitAttempt('#onboardingForm');

      // Submit Mutation.
      submitOnboardingProfileForm({
        variables: {
          onboardingProfileInput: onboardingInput,
        },
        onCompleted: handleSubmitComplete,
        onError: () => {
          // Mouseflow submission fix.
          formSubmitFailure('#onboardingForm');
        },
      });
    }
  };

  return (
    <Box sx={{ width: '100%' }} data-testid="onboarding-stepper">
      <FormProvider {...onboardingFormMethods}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel
                  sx={{
                    '.MuiStepLabel-label': {
                      display: {
                        xs: index !== activeStep ? 'none' : 'block',
                        md: 'block',
                      },
                    },
                  }}
                >
                  {t(`form.steps.${label}`)}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          <>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>{t('form.buttons.reset')}</Button>
            </Box>
          </>
        ) : (
          <>
            {loading && <LoadingIndicator size={100} />}
            {!loading && (
              <form
                id="onboardingForm"
                onSubmit={onboardingFormMethods.handleSubmit(handleSubmit)}
              >
                {activeStep === 0 && <OnboardingAboutQuestions />}
                {activeStep === 1 && <OnboardingSongCatalogQuestions />}
                {activeStep === 2 && <OnboardingAdministrationQuestions />}

                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    data-testid="button-back"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    {t('form.buttons.back')}
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {activeStep === steps.length - 1 && (
                    <Button
                      variant="contained"
                      color="secondary"
                      data-testid="button-finish"
                      type="submit"
                      name="button-finish"
                    >
                      {t('form.buttons.finish')}
                    </Button>
                  )}
                  {activeStep !== steps.length - 1 && (
                    <Button
                      data-testid="button-next"
                      variant="contained"
                      color="secondary"
                      onClick={handleNext}
                    >
                      {t('form.buttons.next')}
                    </Button>
                  )}
                </Box>
              </form>
            )}
          </>
        )}
      </FormProvider>
    </Box>
  );
}
