import { Box, Button, Grid, Link, Typography } from '@mui/material';
import _, { startCase } from 'lodash';
import { Dispatch, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import FormAutoComplete from '~/base/components/FormAutoComplete';
import { Translator } from '~/types/Translator';
import { CountriesQuery, useCountriesQuery } from '~/types/generated/graphql';
import AddPRONonMember from '../AddPRONonMember/AddPRONonMember';

interface AddPROLocationFilterProps extends Translator {
  setDisableContinueButton: Dispatch<React.SetStateAction<boolean>>;
}

/**
 * AddPROLocationFilter component allows users to select their country of residence
 * and citizenship to display relevant Performing Rights Organizations (PROs).
 *
 * @param {Object} props - Component props.
 * @param {Function} props.t - Translation function from i18next.
 */
function AddPROLocationFilter({
  t,
  setDisableContinueButton,
}: AddPROLocationFilterProps) {
  // Arrays of PROs for different regions
  const proSelectUS = ['ASCAP', 'BMI', 'SESAC'];
  const proSelectCanada = ['SOCAN'];
  const proSelectIntl: string[] = [];

  const { setValue } = useFormContext(); // Get form context for setting values
  const [selectedPRO, setSelectedPRO] = useState<string>(''); // Selected PRO state

  // Fetch countries data using GraphQL query
  const { data, fetchMore } = useCountriesQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [residenceSelected, setResidenceSelected] = useState<string>(''); // Selected residence
  const [citizenshipSelected, setCitizenshipSelected] = useState<string>(''); // Selected citizenship
  const [displayedPros, setDisplayedPros] = useState<Array<string>>([]); // PROs to display

  // Update query for fetching more countries
  const updateQuery = (
    previousQueryResult: CountriesQuery,
    { fetchMoreResult }: { fetchMoreResult: CountriesQuery },
  ) => {
    if (!fetchMoreResult) return previousQueryResult;

    return {
      countries: {
        pageInfo: fetchMoreResult?.countries?.pageInfo,
        edges: [
          ...(previousQueryResult?.countries?.edges || []),
          ...(fetchMoreResult?.countries?.edges || []),
        ],
      },
    } as CountriesQuery;
  };

  // Effect to fetch more countries if there are more pages
  useEffect(() => {
    if (data?.countries?.pageInfo.hasNextPage) {
      fetchMore({
        updateQuery,
        variables: { after: data.countries.pageInfo.endCursor },
      });
    }
  }, [data, fetchMore]);

  // Effect to update displayed PROs based on selected residence and citizenship
  useEffect(() => {
    let mergedPros: string[] = [];

    if (residenceSelected === 'US' || citizenshipSelected === 'US') {
      mergedPros = _.union(mergedPros, proSelectUS);
    }

    if (residenceSelected === 'CA' || citizenshipSelected === 'CA') {
      mergedPros = _.union(mergedPros, proSelectCanada);
    }

    if (
      (residenceSelected && !['US', 'CA'].includes(residenceSelected)) ||
      (citizenshipSelected && !['US', 'CA'].includes(citizenshipSelected))
    ) {
      mergedPros = _.union(mergedPros, proSelectIntl);
    }

    setDisplayedPros(mergedPros);
  }, [residenceSelected, citizenshipSelected]);

  // Styles for the PRO buttons
  const buttonStyles = {
    backgroundColor: '#FFFFFF',
    borderRadius: '0px',
    border: '1px #FFFFFF solid',
    width: '100%',
    ':hover, &.selected': {
      backgroundColor: '#FFF',
      borderColor: '#031C9B',
    },
  };

  return (
    <Grid
      data-testid="add-songwriter-pro-location-filter"
      container
      spacing={2}
      sx={{
        flexDirection: {
          xs: 'row',
        },
      }}
    >
      {/* Residence selection */}
      <Grid item xs={6}>
        <FormAutoComplete
          id="pro-countries-residence"
          name="pro-countries-residence"
          testid="pro-countries-residence"
          label={t('page.add-songwriter.form.pro.country-residence')}
          required
          placeholder={t('page.add-songwriter.form.pro.country-residence')}
          options={
            data?.countries?.edges
              .map((edge) => ({
                choiceId: edge?.node?.isoCode as string,
                choiceLabel: startCase(
                  edge?.node?.name.toLowerCase(),
                ) as string,
              }))
              .sort((a, b) => a.choiceLabel.localeCompare(b.choiceLabel)) || []
          }
          tooltip={t('form.tooltips.territory')}
          onOptionSelected={setResidenceSelected}
          inputProps={{ 'data-testid': 'pro-countries-residence-input' }}
          autocompleteSX={{ width: '100%' }}
          sx={{ width: '100%' }}
        />
      </Grid>

      {/* Citizenship selection */}
      <Grid item xs={6}>
        <FormAutoComplete
          id="pro-countries-citizenship"
          name="pro-countries-citizenship"
          testid="pro-countries-citizenship"
          label={t('page.add-songwriter.form.pro.country-citizenship')}
          required
          placeholder={t('page.add-songwriter.form.pro.country-citizenship')}
          options={
            data?.countries?.edges
              .map((edge) => ({
                choiceId: edge?.node?.isoCode as string,
                choiceLabel: startCase(
                  edge?.node?.name.toLowerCase(),
                ) as string,
              }))
              .sort((a, b) => a.choiceLabel.localeCompare(b.choiceLabel)) || []
          }
          tooltip={t('form.tooltips.territory')}
          onOptionSelected={setCitizenshipSelected}
          inputProps={{ 'data-testid': 'pro-countries-citizenship-input' }}
          autocompleteSX={{ width: '100%' }}
          sx={{ width: '100%' }}
        />
      </Grid>

      {/* Display selected PROs or non-member message */}
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'grid',
            gap: 1,
            p: 2,
            backgroundColor: '#F2F2F2',
            border: '1px solid #E0E0E0',
          }}
        >
          {displayedPros.length === 0 ? (
            <AddPRONonMember t={t} />
          ) : (
            displayedPros.map(
              (pro) =>
                pro && (
                  <Button
                    key={pro}
                    component={Link}
                    target="_target"
                    sx={buttonStyles}
                    className={pro === selectedPRO ? 'selected' : ''}
                    onClick={() => {
                      setValue('pro', pro);
                      setSelectedPRO(pro);
                      setDisableContinueButton(false);
                    }}
                  >
                    <Typography>{pro}</Typography>
                  </Button>
                ),
            )
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default AddPROLocationFilter;
