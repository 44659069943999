/* eslint-disable react/require-default-props */
import { Box, Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

type BreadcrumbLink = {
  id: string;
  label: string;
  href: string;
};

interface PageTitleType {
  currentPage?: string | undefined;
  breadcrumbLinks?: BreadcrumbLink[];
  pageTitle: { title: string | undefined; id: string };
  pageSubtitle?: { id?: string; subtitle: string };
}

function PageTitle({
  currentPage,
  breadcrumbLinks,
  pageTitle,
  pageSubtitle,
}: PageTitleType) {
  return (
    <Grid
      container
      sx={{
        flexDirection: {
          xs: 'column',
        },
      }}
    >
      <Grid item xs={12} md={12}>
        {/* Breadcrumb */}
        {breadcrumbLinks && (
          <Breadcrumbs
            separator=">"
            aria-label="breadcrumb"
            component="div"
            sx={{ pt: '1rem', color: '#D5164B' }}
          >
            {breadcrumbLinks?.map((link) => (
              <Link
                key={link.id}
                component={RouterLink}
                underline="hover"
                data-testid={link.id}
                to={link.href}
              >
                {link.label}
              </Link>
            ))}
            <Typography component="span">{currentPage}</Typography>
          </Breadcrumbs>
        )}
        {!breadcrumbLinks && <Box sx={{ pt: '2.5rem' }} />}
      </Grid>
      {/* Page Title */}
      <Grid item xs={12} md={12}>
        <Typography
          id={pageTitle.id}
          data-testid={pageTitle.id}
          variant="h1"
          component="h1"
        >
          {pageTitle.title}
        </Typography>
        {pageSubtitle && (
          <Typography
            id={pageSubtitle.id}
            data-testid={pageSubtitle.id}
            variant="body1"
            component="p"
          >
            {pageSubtitle.subtitle}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default PageTitle;
