import { ApolloError, ApolloQueryResult } from '@apollo/client';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Button, Grid } from '@mui/material';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormBanner from '~/base/components/FormBanner';
import { FormBannerType } from '~/base/components/FormBanner/FormBanner';
import FormInput from '~/base/components/FormInput';
import {
  StripeTextFieldCVC,
  StripeTextFieldExpiry,
  StripeTextFieldNumber,
} from '~/base/components/FormStripeFields/CommonTextFields';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import SectionTitle from '~/base/components/SectionTitle/SectionTitle';
import { StripeElementsState } from '~/signup/types/types';
import {
  Exact,
  SubscriptionPlanQuery,
  UpdateCreditCardMutation,
  useUpdateCreditCardMutation,
} from '~/types/generated/graphql';

interface BillingInformationSectionFormProps {
  refetch: (
    variables?:
      | Partial<
          Exact<{
            [key: string]: never;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<SubscriptionPlanQuery>>;
}

function BillingInformationSectionForm({
  refetch,
}: BillingInformationSectionFormProps) {
  const { t } = useTranslation(['account']);

  const billingFormMethods = useFormContext();

  const stripe = useStripe();
  const elements = useElements();

  const [processError, setProcessError] = useState<string>('');
  const [wasSaved, setWasSaved] = useState<boolean>(false);

  const [updateCreditCardForm, { loading: updateCardLoading }] =
    useUpdateCreditCardMutation({
      fetchPolicy: 'no-cache',
    });

  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [stripeState, setStripeState] = useState<StripeElementsState>({
    cardNumberComplete: false,
    expiredComplete: false,
    cvcComplete: false,
    cardNumberError: null,
    expiredError: null,
    cvcError: null,
    stripeCallProcessing: false,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onElementChange = (event: any, field: string, errorField: string) => {
    setStripeState({
      ...stripeState,
      [field]: event?.complete,
      [errorField]: event?.error?.message,
    });
  };

  const {
    cardNumberError,
    expiredError,
    cvcError,
    cardNumberComplete,
    expiredComplete,
    cvcComplete,
  } = stripeState;

  const inputBoxStyle = {
    paddingTop: '1rem',
  };

  const inputStyle = {
    padding: 0,
    borderRadius: '.5rem',
    '& > fieldset': {
      borderColor: '#7E7E7E',
    },
    '&.Mui-focused& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#031C9B',
    },
    '&.Mui-focused': {},
    '.MuiOutlinedInput-input': {
      height: 'auto',
    },
    height: '3.15rem',
  };

  const labelStyle = {
    padding: '0',
    marginLeft: '-.75rem',
    marginTop: '-.75rem',
    backgroundColor: '#000',
  };

  const controlStyle = {
    width: '100%',
  };

  const handleSubmitComplete = (data: UpdateCreditCardMutation) => {
    if (data?.updateCreditCard?.errors?.length === 0) {
      refetch();

      billingFormMethods.resetField('cardholder');
      elements?.getElement('cardNumber')?.clear();
      elements?.getElement('cardExpiry')?.clear();
      elements?.getElement('cardCvc')?.clear();
      setWasSaved(true);
    } else {
      setWasSaved(false);
    }
    setHasSubmitted(false);
  };

  const handleSubmitError = (error: ApolloError) => {
    setProcessError(error.message);
  };

  const handleError = async () => {
    setProcessError(
      t('sections.account-information.billing-information.errors.fields'),
    );
    setWasSaved(false);
  };

  const handleSubmit = async (values: FieldValues) => {
    setProcessError('');
    if (await billingFormMethods.trigger()) {
      const cardNumber = elements?.getElement('cardNumber');
      if (stripe !== null) {
        // Make sure the Stripe fields are in a complete state.
        if (
          cardNumberComplete &&
          expiredComplete &&
          cvcComplete &&
          cardNumber
        ) {
          // Set processing flag for loading indicator.
          setStripeState({ ...stripeState, stripeCallProcessing: true });

          // Create stripe token using the card.
          stripe
            .createToken(cardNumber, { name: values.cardholder })
            .then((result) => {
              // Handle result.error or result.token
              if (result.token) {
                // Success
                const stripeToken = result.token.id;

                // Submit Mutation.
                updateCreditCardForm({
                  variables: {
                    stripeToken,
                  },
                  onCompleted: handleSubmitComplete,
                  onError: handleSubmitError,
                });
              } else if (result.error) {
                setProcessError(
                  t(
                    'sections.account-information.billing-information.errors.stripe',
                  ),
                );
              }
            })
            .finally(() => {
              setStripeState({ ...stripeState, stripeCallProcessing: false });
            });
        } else {
          setProcessError(
            t(
              'sections.account-information.billing-information.errors.creditcard',
            ),
          );
        }
      }
    }
  };

  return (
    <form
      data-testid="billing-information-section-form"
      onSubmit={billingFormMethods.handleSubmit(handleSubmit, handleError)}
      onChange={() => {
        setWasSaved(false);
        setHasSubmitted(false);
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} data-testid="billing-information-title">
          <SectionTitle>
            {t('sections.account-information.billing-information.title')}
          </SectionTitle>
        </Grid>
        <Grid item xs={12}>
          {!wasSaved && (
            <FormBanner text={processError} type={FormBannerType.ERROR} />
          )}
          {wasSaved && (
            <FormBanner
              text={t(
                'sections.account-information.billing-information.success',
              )}
              type={FormBannerType.SUCCESS}
              time={2000}
              recall={wasSaved}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormInput
            sx={{
              width: '100%',
            }}
            id="payment-cardholder"
            data-testid="payment-cardholder"
            name="cardholder"
            label={t(
              'sections.account-information.billing-information.fields.cardholder.label',
            )}
            required={{
              value: true,
              message: t(
                'sections.account-information.billing-information.fields.cardholder.validation.required',
              ),
            }}
            placeholder={t(
              'sections.account-information.billing-information.fields.cardholder.placeholder',
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Box sx={inputBoxStyle}>
            <StripeTextFieldNumber
              id="payment-cardnumber"
              data-testid="payment-cardnumber"
              name="cardnumber"
              sx={controlStyle}
              label={t(
                'sections.account-information.billing-information.fields.cardnumber.label',
              )}
              error={
                hasSubmitted &&
                (Boolean(cardNumberError) || !cardNumberComplete)
              }
              labelErrorMessage={cardNumberError as unknown as string}
              onChange={(e) => {
                onElementChange(e, 'cardNumberComplete', 'cardNumberError');
              }}
              InputLabelProps={{
                sx: labelStyle,
                children: <>test</>,
              }}
              InputProps={{
                sx: inputStyle,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Box sx={inputBoxStyle}>
            <StripeTextFieldExpiry
              id="payment-expiration"
              data-testid="payment-expiration"
              name="expiration"
              label={t(
                'sections.account-information.billing-information.fields.expiration.label',
              )}
              sx={controlStyle}
              error={
                hasSubmitted && (Boolean(expiredError) || !expiredComplete)
              }
              labelErrorMessage={expiredError as unknown as string}
              onChange={(e) => {
                onElementChange(e, 'expiredComplete', 'expiredError');
              }}
              InputLabelProps={{
                sx: labelStyle,
              }}
              InputProps={{
                sx: inputStyle,
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Box sx={inputBoxStyle}>
            <StripeTextFieldCVC
              id="payment-cvc"
              data-testid="payment-cvc"
              name="cvc"
              label={t(
                'sections.account-information.billing-information.fields.cvc.label',
              )}
              tooltip={t(
                'sections.account-information.billing-information.fields.cvc.tooltip',
              )}
              sx={controlStyle}
              error={hasSubmitted && (Boolean(cvcError) || !cvcComplete)}
              labelErrorMessage={cvcError as unknown as string}
              onChange={(e) => {
                onElementChange(e, 'cvcComplete', 'cvcError');
              }}
              InputLabelProps={{
                sx: labelStyle,
              }}
              InputProps={{
                sx: inputStyle,
              }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          {updateCardLoading && <LoadingIndicator size={50} />}
          {!updateCardLoading && (
            <Button
              data-testid="save-billing-information-button"
              variant="contained"
              color="secondary"
              type="submit"
              sx={{
                width: '100%',
                pt: '.5rem',
                pb: '.5rem',
                pl: '3rem',
                pr: '3rem',
              }}
              onClick={() => {
                setHasSubmitted(true);
              }}
            >
              {!wasSaved && (
                <>
                  {t(
                    'sections.account-information.billing-information.update-card',
                  )}
                </>
              )}
              {wasSaved && (
                <>
                  {t(
                    'sections.account-information.billing-information.updated',
                  )}
                  <CheckCircleIcon />
                </>
              )}
            </Button>
          )}
        </Grid>
      </Grid>
    </form>
  );
}

export default BillingInformationSectionForm;
