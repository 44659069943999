import { Box, Grid, Typography } from '@mui/material';
import FormInput from '~/base/components/FormInput';
import {
  StripeTextFieldCVC,
  StripeTextFieldExpiry,
  StripeTextFieldNumber,
} from '~/base/components/FormStripeFields/CommonTextFields';
import { StripeElementsState } from '~/signup/types/types';
import { Translator } from '~/types/Translator';
import SignupStepNumberCheck from '../SignupStepNumberCheck';

interface SignupOrderPaymentDetailsProps extends Translator {
  hasSubmitted: boolean;
  stripeState: StripeElementsState;
  setStripeState: React.Dispatch<React.SetStateAction<StripeElementsState>>;
}

function SignupOrderPaymentDetails({
  t,
  stripeState,
  setStripeState,
  hasSubmitted,
}: SignupOrderPaymentDetailsProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onElementChange = (event: any, field: string, errorField: string) => {
    setStripeState({
      ...stripeState,
      [field]: event?.complete,
      [errorField]: event?.error?.message,
    });
  };

  const {
    cardNumberError,
    expiredError,
    cvcError,
    cardNumberComplete,
    expiredComplete,
    cvcComplete,
  } = stripeState;

  const inputBoxStyle = {
    paddingTop: '1rem',
  };

  const inputStyle = {
    padding: '0',
    borderRadius: '.5rem',
    '& > fieldset': {
      borderColor: '#7E7E7E',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#031C9B',
    },
  };

  const labelStyle = {
    padding: '0',
    marginLeft: '-.75rem',
    marginTop: '-.75rem',
  };

  const controlStyle = {
    width: '100%',
  };

  return (
    <Grid
      container
      columnSpacing={3}
      rowSpacing={3}
      className="signup-payment-details"
      data-testid="signup-payment-details"
    >
      <Grid item xs={12} md={12}>
        <Typography variant="h2" component="h2">
          <SignupStepNumberCheck
            text="3"
            active={
              cardNumberError !== null ||
              expiredError !== null ||
              cvcError !== null ||
              cardNumberComplete ||
              expiredComplete ||
              cvcComplete
            }
            validated={
              cardNumberError !== null &&
              expiredError !== null &&
              cvcError !== null &&
              cardNumberComplete &&
              expiredComplete &&
              cvcComplete
            }
          />
          {t('form.payment-info')}
        </Typography>
      </Grid>

      <Grid item xs={12} md={12}>
        <Box sx={inputBoxStyle}>
          <StripeTextFieldNumber
            id="payment-cardnumber"
            data-testid="payment-cardnumber"
            name="cardnumber"
            sx={controlStyle}
            label={t('form.fields.cardnumber.label')}
            error={
              hasSubmitted && (Boolean(cardNumberError) || !cardNumberComplete)
            }
            labelErrorMessage={cardNumberError as unknown as string}
            onChange={(e) => {
              onElementChange(e, 'cardNumberComplete', 'cardNumberError');
            }}
            InputLabelProps={{
              sx: labelStyle,
              children: <>test</>,
            }}
            InputProps={{
              sx: inputStyle,
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <FormInput
          sx={{
            width: '100%',
          }}
          id="payment-cardholder"
          data-testid="payment-cardholder"
          name="cardholder"
          label={t('form.fields.cardholder.label')}
          required={{
            value: true,
            message: t('form.fields.cardholder.validation.required'),
          }}
          placeholder={t('form.fields.cardholder.placeholder')}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <Box sx={inputBoxStyle}>
          <StripeTextFieldExpiry
            id="payment-expiration"
            data-testid="payment-expiration"
            name="expiration"
            label={t('form.fields.expiration.label')}
            sx={controlStyle}
            error={hasSubmitted && (Boolean(expiredError) || !expiredComplete)}
            labelErrorMessage={expiredError as unknown as string}
            onChange={(e) => {
              onElementChange(e, 'expiredComplete', 'expiredError');
            }}
            InputLabelProps={{
              sx: labelStyle,
            }}
            InputProps={{
              sx: inputStyle,
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={6} md={4}>
        <Box sx={inputBoxStyle}>
          <StripeTextFieldCVC
            id="payment-cvc"
            data-testid="payment-cvc"
            name="cvc"
            label={t('form.fields.cvc.label')}
            tooltip={t('form.fields.cvc.tooltip')}
            sx={controlStyle}
            error={hasSubmitted && (Boolean(cvcError) || !cvcComplete)}
            labelErrorMessage={cvcError as unknown as string}
            onChange={(e) => {
              onElementChange(e, 'cvcComplete', 'cvcError');
            }}
            InputLabelProps={{
              sx: labelStyle,
            }}
            InputProps={{
              sx: inputStyle,
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default SignupOrderPaymentDetails;
