import Grid from '@mui/material/Grid';
import FormInput from '~/base/components/FormInput';
import SectionTitle from '~/base/components/SectionTitle/SectionTitle';
import { T } from '~/types/Translator';

export default function AddManualRecording({ t }: { t: T }) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SectionTitle>
            {t('page.add-recording.tabs.manual.recording.title')}
          </SectionTitle>
        </Grid>
        <Grid container item xs={12} rowGap={2}>
          <FormInput
            id="recording-title"
            label={t(
              `page.add-recording.tabs.manual.recording.fields.recording-title.label`,
            )}
            name="title"
            sx={{
              width: '100%',
            }}
            inputProps={{ 'data-testid': 'recording-title' }}
          />
          <FormInput
            id="isrc-number"
            label={t(
              `page.add-recording.tabs.manual.recording.fields.isrc.label`,
            )}
            name="isrc"
            sx={{
              width: '100%',
            }}
            inputProps={{ 'data-testid': 'isrc-input' }}
          />
          <FormInput
            id="genre"
            label={t(
              `page.add-recording.tabs.manual.recording.fields.genre.label`,
            )}
            name="genre"
            placeholder={t(
              `page.add-recording.tabs.manual.recording.fields.genre.placeholder`,
            )}
            sx={{
              width: '100%',
            }}
            inputProps={{ 'data-testid': 'genre' }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SectionTitle>
            {t('page.add-recording.tabs.manual.artists.title')}
          </SectionTitle>
        </Grid>
        <Grid container item xs={12} rowGap={2}>
          <FormInput
            id="artists"
            label={t(
              `page.add-recording.tabs.manual.artists.fields.search-artists.label`,
            )}
            name="artists"
            placeholder={t(
              `page.add-recording.tabs.manual.artists.fields.search-artists.placeholder`,
            )}
            sx={{
              width: '100%',
            }}
            inputProps={{ 'data-testid': 'artists' }}
          />
        </Grid>
      </Grid>
    </>
  );
}
