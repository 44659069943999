import { CircularProgress, Grid } from '@mui/material';

interface LoadingIndicatorProps {
  size: number;
}
function LoadingIndicator({ size }: LoadingIndicatorProps) {
  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <CircularProgress data-testid="loading" size={size} />
      </Grid>
    </Grid>
  );
}

export default LoadingIndicator;
