import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {
  ClickAwayListener,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import FormTooltipAlert from './FormTooltipAlert';

/**
 * Props for the FormTooltipIcon component.
 */
interface FormTooltipIconProps {
  handleTooltipToggle: () => void;
}

/**
 * FormTooltipIcon renders a question mark icon button.
 *
 * @param {FormTooltipIconProps} props - The props for the component.
 * @param {() => void} props.handleTooltipToggle - Function to handle tooltip toggle.
 */
const FormTooltipIcon = React.forwardRef<
  HTMLButtonElement,
  FormTooltipIconProps
>(({ handleTooltipToggle }, ref) => {
  return (
    <IconButton
      ref={ref}
      data-testid="form-tooltip-icon"
      sx={{
        height: '.75rem',
        width: '.75rem',
        padding: 0,
        borderRadius: '1rem',
        background: '#031C9B',
        border: '1px solid #031C9B',
        color: '#FFF',
        '&:hover': {
          background: '#FFF',
          color: '#031C9B',
        },
      }}
      onClick={handleTooltipToggle}
    >
      <QuestionMarkIcon
        sx={{
          fontSize: '.625rem',
        }}
      />
    </IconButton>
  );
});

FormTooltipIcon.displayName = 'FormTooltipIcon';

/**
 * Props for the FormTooltip component.
 */
interface FormTooltipProps {
  label?: string;
  type?: 'dialog' | 'default';
  tooltip: React.ReactNode;
}

/**
 * FormTooltip is a component that renders a tooltip.
 *
 * @param {FormTooltipProps} props - The props for the component.
 * @param {React.ReactNode} props.tooltip - The content of the tooltip.
 * @param {string} [props.label] - An optional label for the tooltip.
 * @param {'dialog' | 'default'} [props.type='default'] - The type of tooltip, either 'dialog' or 'default'.
 */
function FormTooltip({
  tooltip,
  label = undefined,
  type = 'default',
}: FormTooltipProps) {
  const [open, setOpen] = useState(false);

  /**
   * Handles the tooltip close event.
   */
  const handleTooltipClose = () => {
    setOpen(false);
  };

  /**
   * Handles the tooltip toggle event.
   */
  const handleTooltipToggle = () => {
    setOpen(!open);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <>
        {label && (
          <Typography variant="caption" sx={{ mx: '.5rem' }}>
            {label}
          </Typography>
        )}
        {type === 'dialog' ? (
          <>
            <FormTooltipIcon handleTooltipToggle={handleTooltipToggle} />
            <FormTooltipAlert
              open={open}
              onClose={handleTooltipToggle}
              content={tooltip}
            />
          </>
        ) : (
          <Tooltip
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={tooltip}
            onClose={handleTooltipClose}
            open={open}
          >
            <IconButton
              data-testid="form-tooltip-icon"
              sx={{
                height: '.75rem',
                width: '.75rem',
                padding: 0,
                borderRadius: '1rem',
                background: '#031C9B',
                border: '1px solid #031C9B',
                color: '#FFF',
                '&:hover': {
                  background: '#FFF',
                  color: '#031C9B',
                },
              }}
              onClick={handleTooltipToggle}
            >
              <QuestionMarkIcon
                sx={{
                  fontSize: '.625rem',
                }}
              />
            </IconButton>
          </Tooltip>
        )}
      </>
    </ClickAwayListener>
  );
}

export default FormTooltip;
