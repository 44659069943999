/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
import { FormControl, Grid, InputLabel } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import {
  AuBankAccountElement,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  FpxBankElement,
  IbanElement,
  IdealBankElement,
} from '@stripe/react-stripe-js';
import * as React from 'react';
import FormTooltip from '../FormTooltip';
import StripeInput from './StripeInput';

type StripeElement =
  | typeof AuBankAccountElement
  | typeof CardCvcElement
  | typeof CardExpiryElement
  | typeof CardNumberElement
  | typeof FpxBankElement
  | typeof IbanElement
  | typeof IdealBankElement;

interface StripeTextFieldProps<T extends StripeElement>
  extends Omit<TextFieldProps, 'onChange' | 'inputComponent' | 'inputProps'> {
  inputProps?: React.ComponentProps<T>;
  labelErrorMessage?: string;
  onChange?: React.ComponentProps<T>['onChange'];
  stripeElement?: T;
  tooltip?: string;
}

export function StripeTextField<T extends StripeElement>(
  props: StripeTextFieldProps<T>,
) {
  const {
    helperText,
    InputLabelProps,
    InputProps = {},
    inputProps,
    error,
    labelErrorMessage,
    stripeElement,
    label,
    tooltip,
    sx,
    id,
    ...other
  } = props;

  return (
    <FormControl sx={sx} variant="standard">
      <InputLabel
        htmlFor={id}
        shrink={false}
        sx={{ width: '100%', zIndex: 1, marginTop: '-1rem' }}
      >
        <Grid container justifyContent="space-between">
          <Grid item>{label}</Grid>
          <Grid item>{tooltip && <FormTooltip tooltip={tooltip} />}</Grid>
        </Grid>
      </InputLabel>

      <TextField
        fullWidth
        InputLabelProps={{
          ...InputLabelProps,
          shrink: false,
        }}
        error={error}
        InputProps={{
          ...InputProps,
          inputProps: {
            ...inputProps,
            ...InputProps.inputProps,
            component: stripeElement,
          },
          inputComponent: StripeInput,
        }}
        helperText={error ? labelErrorMessage : helperText}
        {...(other as any)}
      />
    </FormControl>
  );
}

export function StripeTextFieldNumber(
  props: StripeTextFieldProps<typeof CardNumberElement>,
) {
  return <StripeTextField stripeElement={CardNumberElement} {...props} />;
}

export function StripeTextFieldExpiry(
  props: StripeTextFieldProps<typeof CardExpiryElement>,
) {
  return <StripeTextField stripeElement={CardExpiryElement} {...props} />;
}

export function StripeTextFieldCVC(
  props: StripeTextFieldProps<typeof CardCvcElement>,
) {
  return <StripeTextField stripeElement={CardCvcElement} {...props} />;
}
