const signupConstants = {
  PER_SONGWRITER_COST: 100,
  TERMS_OF_USE_LINK: 'https://www.songtrust.com/terms-of-use',
  TERMS_OF_SERVICE_LINK: '/terms-of-service',
  PRIVACY_POLICY_LINK: 'https://www.songtrust.com/privacy-policy',
  isValid: (status: string | null | undefined) => {
    if (
      status === null ||
      status === undefined ||
      status.toUpperCase() !== 'VALID'
    )
      return false;
    return true;
  },
  isInvalid: (status: string | null | undefined) => {
    if (
      status !== null &&
      status !== undefined &&
      status.toUpperCase() === 'INVALID'
    )
      return true;
    return false;
  },
  isUnsure: (status: string | null | undefined) => {
    if (
      status === null ||
      status === undefined ||
      (status as string).toUpperCase() === 'UNSURE'
    )
      return true;
    return false;
  },
};

export const {
  PER_SONGWRITER_COST,
  TERMS_OF_USE_LINK,
  TERMS_OF_SERVICE_LINK,
  PRIVACY_POLICY_LINK,
  isValid,
  isInvalid,
  isUnsure,
} = signupConstants;

export default signupConstants;
