import { Box, Typography } from '@mui/material';
import { map } from 'lodash';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FormMultiCheckbox from '~/base/components/FormMultiCheckbox';
import FormRadio from '~/base/components/FormRadio';
import OnboardingFormContext from '~/onboarding/components/OnboardingFormContext';

function OnboardingSongCatalogQuestions() {
  const { t } = useTranslation(['onboarding']);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <OnboardingFormContext.Consumer>
      {({
        streamEstimationChoices,
        userCountEstimationChoices,
        userGenres,
        activeWorkRegions,
      }) => (
        <Box
          data-testid="onboarding-songcatalog-questions"
          sx={{
            paddingLeft: '.5rem',
            paddingTop: { md: '2rem' },
            paddingBottom: { md: '2rem' },
          }}
        >
          <Typography
            variant="h4"
            component="h3"
            sx={{ margin: '1.75rem 0 1rem' }}
          >
            {t('form.questions.song-catalog.genres')}
          </Typography>
          <FormMultiCheckbox
            name="genres"
            id="genres"
            data-testid="genres"
            columns={4}
            required
            options={map(userGenres?.edges, (edge) => ({
              choiceId: `${edge?.node?.choice}`,
              choiceLabel: t(`form.options.genres.${edge?.node?.choice}`, {
                defaultValue: edge?.node?.label as string,
              }),
            }))}
            autofocus
          />

          <Typography
            variant="h4"
            component="h3"
            sx={{ margin: '1.75rem 0 1rem' }}
          >
            {t('form.questions.song-catalog.number-songs')}
          </Typography>
          <FormRadio
            id="initialCountEstimate"
            name="initialCountEstimate"
            data-testid="initial-count-estimate"
            columns={4}
            chips
            required
            options={map(userCountEstimationChoices?.edges, (edge) => ({
              choiceId: edge?.node?.choice as string,
              choiceLabel: t(
                `form.options.number-songs.${edge?.node?.choice}`,
                {
                  defaultValue: edge?.node?.label as string,
                },
              ),
            }))}
          />

          <Typography
            variant="h4"
            component="h3"
            sx={{ margin: '1.75rem 0 1rem' }}
          >
            {t('form.questions.song-catalog.number-streams')}
          </Typography>
          <FormRadio
            id="streamEstimation"
            name="streamEstimation"
            data-testid="stream-estimation"
            chips
            required
            columns={4}
            options={map(streamEstimationChoices?.edges, (edge) => ({
              choiceId: edge?.node?.choice as string,
              choiceLabel: t(
                `form.options.number-streams.${edge?.node?.choice}`,
                {
                  defaultValue: edge?.node?.label as string,
                },
              ),
            }))}
          />

          <Typography
            variant="h4"
            component="h3"
            sx={{ margin: '1.75rem 0 1rem' }}
          >
            {t('form.questions.song-catalog.top-regions')}
          </Typography>
          <FormMultiCheckbox
            name="activeWorkRegions"
            id="activeWorkRegions"
            data-testid="active-work-regions"
            columns={3}
            required
            validate={{
              validAmount: (value) =>
                ((value as string[]).length > 0 &&
                  (value as string[]).length <= 3) ||
                '',
            }}
            options={map(activeWorkRegions?.edges, (edge) => ({
              choiceId: `${edge?.node?.choice}`,
              choiceLabel: t(`form.options.regions.${edge?.node?.choice}`, {
                defaultValue: edge?.node?.label as string,
              }),
            }))}
          />
        </Box>
      )}
    </OnboardingFormContext.Consumer>
  );
}

export default OnboardingSongCatalogQuestions;
