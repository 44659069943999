import { Container, Grid } from '@mui/material';
import PageTitle from '~/base/components/PageTitle/PageTitle';
import { currentSongtrustUserSubscriptionPlanVar } from '~/cache';
import usePageTitle from '~/hooks/usePageTitle';
import { Translator } from '~/types/Translator';
import { useSubscriptionPlanQuery } from '~/types/generated/graphql';
import AboutMeSection from '../AboutMeSection/AboutMeSection';
import AccountTypeSection from '../AccountTypeSection';
import BillingInformationSection from '../BillingInformationSection';

function AccountInformationPage({ t }: Translator) {
  usePageTitle(t('sections.account-information.page.title'));
  const { loading, refetch } = useSubscriptionPlanQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (res) => {
      currentSongtrustUserSubscriptionPlanVar(res);
    },
  });

  return (
    <div data-testid="account-information-page">
      <Container>
        <Grid
          container
          spacing={2}
          sx={{
            flexDirection: {
              xs: 'column',
            },
          }}
        >
          <Grid item xs={12} md={12}>
            <PageTitle
              pageTitle={{
                id: 'account-information-page-title',
                title: t('sections.account-information.page.title'),
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <AboutMeSection t={t} />
          </Grid>

          <Grid item xs={12} md={12}>
            <BillingInformationSection t={t} refetch={refetch} />
          </Grid>

          <Grid item xs={12} md={12}>
            <AccountTypeSection t={t} loading={loading} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default AccountInformationPage;
