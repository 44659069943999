import { useEffect } from 'react';

const useRawScript = (code: string) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.innerHTML = code;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [code]);
};

export default useRawScript;
