import { useReactiveVar } from '@apollo/client';
import { Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { currentSongtrustUserPersonaVar } from '~/cache';
import { Translator } from '~/types/Translator';

function MfaSection({ t }: Translator): JSX.Element {
  const loggedInUserPersona = useReactiveVar(currentSongtrustUserPersonaVar);
  // will need to extend LoggedInSongtrustUserPersonaQuery after relevant backend migration to retrieve field
  const [mfaEnabled, setMfaEnabled] = useState<boolean>(
    // loggedInUserPersona?.mfa_opt_in
    true,
  );

  const handleToggleMfa = () => {
    setMfaEnabled((prev) => !prev);
  };

  return (
    <Grid container item xs={12} data-testid="mfa-section" direction="column">
      {mfaEnabled ? (
        // MFA enabled content
        <>
          {/* Body */}
          <Typography variant="body1">
            {t('sections.mfa.enabled-copy1')}
          </Typography>
          <br />
          <Typography variant="body1" sx={{ mb: 1 }}>
            {t('sections.mfa.enabled-copy2')}
          </Typography>
          {/* Phone number */}
          <Typography variant="h5" sx={{ pb: 0 }}>
            {t('sections.mfa.phone-number')}
          </Typography>
          <Typography variant="h6" sx={{ pt: 0 }}>
            ***-***-
            {loggedInUserPersona?.loggedInSongtrustUser?.phoneNumber?.slice(-4)}
          </Typography>
          {/* Delivery type */}
          <Typography variant="h5" sx={{ pb: 0 }}>
            {t('sections.mfa.delivery-type1')}
          </Typography>
          <Typography variant="h6" sx={{ pt: 0 }}>
            {t('sections.mfa.delivery-type2')}
          </Typography>
        </>
      ) : (
        // MFA disabled content
        <Typography variant="body1">
          {t('sections.mfa.disabled-copy')}
        </Typography>
      )}

      {/* MFA toggle button */}
      <Grid container justifyContent="flex-end">
        <Button
          data-testid="mfa-button"
          variant="contained"
          color="secondary"
          sx={{ mt: 2 }}
          onClick={handleToggleMfa}
        >
          {mfaEnabled
            ? t('sections.mfa.enabled-button')
            : t('sections.mfa.disabled-button')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default MfaSection;
