import { Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Translator } from '~/types/Translator';

function DashboardMfaItem({ t }: Translator) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/account/security');
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <Grid
      container
      sx={{
        border: '1px solid #000',
        padding: '1rem 1rem 2rem 1rem',
      }}
      rowSpacing={2}
      data-testid="dashboard-mfa-item"
    >
      <Grid item xs={12}>
        <Typography variant="h5" component="h5" align="center">
          {t('page.content.mfa-title')}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body2">{t('page.content.mfa-copy')}</Typography>
      </Grid>

      <Grid item xs={12} display="flex" justifyContent="center">
        <Button
          variant="contained"
          sx={{ color: 'black', backgroundColor: '#7fc832' }}
          onClick={handleClick}
        >
          {t('page.content.mfa-button')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default DashboardMfaItem;
