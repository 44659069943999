const baseConstants = {
  CONTACT_URL: 'https://www.songtrust.com/contact-our-team',
  HELP_CENTER_URL: 'https://help.songtrust.com/knowledge',
  RESET_PASSWORD_FORM_URL:
    'https://share.hsforms.com/1YDmOwwjRQCexrryufnQPHQ1jbfb',
};

export const { CONTACT_URL, HELP_CENTER_URL, RESET_PASSWORD_FORM_URL } =
  baseConstants;

export default baseConstants;
