import { Box, Container, Grid, Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import PageTitle from '~/base/components/PageTitle/PageTitle';
import usePageTitle from '~/hooks/usePageTitle';
import { T } from '~/types/Translator';
import YoutubeAllowlistPrompt from './YoutubeAllowlistPrompt/YoutubeAllowlistPrompt';
import YoutubeClaimedList from './YoutubeClaimedList';

export default function YoutubeClaimsPage({
  allowlisted = false,
  t,
}: {
  allowlisted: boolean;
  t: T;
}) {
  usePageTitle(t('page.title'));

  return (
    <Container>
      <Grid container gap={2} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <Box
            sx={{
              pb: '3rem',
            }}
          >
            <PageTitle
              pageTitle={{
                id: 'onboarding-page-title',
                title: t('page.title'),
              }}
            />
            <Typography variant="h4">
              {t('page.copy')}
              <strong>{t('page.copy-bold')}</strong>
              {t('page.copy-continued')}
              <Link href={t('page.copy-link-url')} target="_blank">
                {t('page.copy-link-text')}
              </Link>
              {t('page.copy-continued-2')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <YoutubeAllowlistPrompt t={t} allowlisted={allowlisted} />
        </Grid>
      </Grid>
      <YoutubeClaimedList t={t} />
    </Container>
  );
}
