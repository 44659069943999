import _ from 'lodash';
import { featureFlagsVar } from '~/cache';
import {
  FlagEdge,
  useFeatureFlagsAndSwitchesQuery,
} from '~/types/generated/graphql';
import FlagTypeEnum from '../enums/flagTypeEnum';

const useFeatureFlag = (featureFlag: string, type: FlagTypeEnum) => {
  const { data, loading, error } = useFeatureFlagsAndSwitchesQuery({
    fetchPolicy: 'cache-first',
    onCompleted: (res) => {
      featureFlagsVar(res);
    },
  });

  let filteredFlags: Array<FlagEdge> = [];

  if (type === FlagTypeEnum.FLAG) {
    filteredFlags = data?.featureFlags?.edges.filter(
      (x) => x?.node?.enabled,
    ) as Array<FlagEdge>;
  } else if (type === FlagTypeEnum.SWITCH) {
    filteredFlags = data?.featureSwitches?.edges.filter(
      (x) => x?.node?.enabled,
    ) as Array<FlagEdge>;
  }

  const hasFlag = _.some(filteredFlags, (flag) => {
    return flag?.node?.name === featureFlag && flag.node.name;
  });

  return { loading, data, error, hasFlag };
};

export default useFeatureFlag;
