import { Box, Grid } from '@mui/material';

interface PayeeColumnProps {
  payee: string;
}

function PayeeColumn({ payee }: PayeeColumnProps) {
  return (
    <Box data-testid="payee-column">
      <Grid container>
        <Grid item xs={12}>
          {payee}
        </Grid>
      </Grid>
    </Box>
  );
}

export default PayeeColumn;
