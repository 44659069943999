import { ApolloError } from '@apollo/client';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { Box, Grid } from '@mui/material';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import TagManager from 'react-gtm-module';
import { FieldErrors, FieldValues, useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FormBanner, {
  FormBannerType,
} from '~/base/components/FormBanner/FormBanner';
import {
  formSubmitAttempt,
  formSubmitFailure,
  formSubmitSuccess,
} from '~/base/helpers/mouseflowHelper';
import { currentSessionTokenVar } from '~/cache';
import { isValid } from '~/signup/constants/signupConstants';
import { StripeElementsState } from '~/signup/types/types';
import { SessionToken } from '~/types/SessionToken.model';
import { Translator } from '~/types/Translator';
import {
  FieldError,
  LoginUserMutation,
  SignupUserMutation,
  UserSignupInput,
  useLoginUserMutation,
  useSignupUserMutation,
} from '~/types/generated/graphql';
import SignupAccountInformation from '../SignupAccountInformation';
import SignupOrderPaymentDetails from '../SignupOrderPaymentDetails';
import SignupOrderSummary from '../SignupOrderSummary';
import SignupPlaidVerification from '../SignupPlaidVerification';
import SignupTestimonial from '../SignupTestimonial';

declare global {
  interface Window {
    _hsq: Array<(string | { email: string })[]> | undefined;
  }
}

function SignupPageForm({ t }: Translator) {
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [stripeState, setStripeState] = useState<StripeElementsState>({
    cardNumberComplete: false,
    expiredComplete: false,
    cvcComplete: false,
    cardNumberError: null,
    expiredError: null,
    cvcError: null,
    stripeCallProcessing: false,
  });

  const [processError, setProcessError] = useState<string>('');
  // Plaid state
  const [verificationStatus, setVerificationStatus] = useState<
    string | null | undefined
  >(undefined);

  // Stripe state.
  const {
    cardNumberComplete,
    expiredComplete,
    cvcComplete,
    stripeCallProcessing,
  } = stripeState;

  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();

  const signupFormMethods = useFormContext();
  const { getValues, setError } = useFormContext();

  const [submitSignupForm, { loading: signupLoading }] = useSignupUserMutation({
    fetchPolicy: 'no-cache',
  });

  const [loginUser, { loading: loginLoading }] = useLoginUserMutation({
    fetchPolicy: 'no-cache',
  });

  const handleLoginComplete = (data: LoginUserMutation) => {
    const sessionToken: SessionToken = {
      token: data?.tokenAuth?.token as string,
      refreshToken: data?.tokenAuth?.refreshToken as string,
      refreshExpiresIn: data?.tokenAuth?.refreshExpiresIn as number,
    };

    currentSessionTokenVar(sessionToken);
    navigate('/get-started');
  };

  const handleSubmitComplete = async (data: SignupUserMutation) => {
    if (data.signupUser) {
      // New user created?
      const newSongtrustUser = data.signupUser.songtrustUser;

      // Success Event Firing.
      TagManager.dataLayer({
        dataLayer: {
          event: 'signup_payment_success',
          eventCategory: 'user',
          eventAction: 'signup',
          eventLabel: 'plaid',
          eventValue: undefined,
        },
      });

      // Handle field errors sent back from account creation mutation, if any.
      const errors = data.signupUser.errors as Array<FieldError>;
      if (!newSongtrustUser && errors.length > 0) {
        formSubmitFailure('#signupForm');
        setProcessError(t('form.errors.fields'));

        errors.forEach((error: FieldError) => {
          const fieldName = error.path[0] as string;
          const errorText = error.error as string;

          setError(fieldName, {
            message: errorText,
          });
        });
      } else {
        // Success, log user into new account and get-started.
        const username = getValues('email');
        const password = getValues('password');

        // eslint-disable-next-line no-underscore-dangle, no-multi-assign
        const _hsq = (window._hsq = window._hsq || []);
        _hsq.push([
          'identify',
          {
            email: username,
          },
        ]);
        // Mark form as successfully completed.
        formSubmitSuccess('#signupForm');

        const fp = await FingerprintJS.load();
        const fpResult = await fp.get();

        loginUser({
          variables: { username, password, fp: fpResult.visitorId },
          onCompleted: handleLoginComplete,
        });
      }
    }
  };

  const handleSubmitError = (error: ApolloError) => {
    formSubmitFailure('#signupForm');
    setProcessError(error.message);
  };

  const handleError = (errors: FieldErrors<UserSignupInput>) => {
    formSubmitFailure('#signupForm');
    if (!errors.identityId) {
      setProcessError(t('form.errors.fields'));
    } else {
      setProcessError(t('form.errors.identity'));
    }
  };

  const handleSubmit = async (values: FieldValues) => {
    setProcessError('');

    // Trigger react-hook-form fields for validation.
    if (await signupFormMethods.trigger()) {
      const cardNumber = elements?.getElement('cardNumber');
      if (stripe !== null) {
        // Make sure the Stripe fields are in a complete state.
        if (
          cardNumberComplete &&
          expiredComplete &&
          cvcComplete &&
          cardNumber
        ) {
          // Set processing flag for loading indicator.
          setStripeState({ ...stripeState, stripeCallProcessing: true });

          // Need to manually add this piece of information to the stripe token.
          const cardHolder = getValues('cardholder');

          // Create stripe token using the card.
          stripe
            .createToken(cardNumber, { name: cardHolder })
            .then((result) => {
              // Handle result.error or result.token
              if (result.token) {
                // Success
                const stripeToken = result.token.id;

                // USD Only.
                const currency = 'USD';
                const currencyValue = '1';

                // Prep/Build mutation object from values in react-hook-form
                const userSignupInput: UserSignupInput = {
                  numberOfSongwriters: values.numberOfSongwriters,
                  email: values.email,
                  firstName: values.firstname,
                  lastName: values.lastname,
                  password: values.password,
                  phoneNumber: values.phone,
                  acceptedTermsOfUse: values.acceptedTermsOfUse,
                  identityId: values.identityId,
                  currency,
                  currencyValue,
                  stripeToken,
                };

                // Only include the discount code if it is valid.
                if (!signupFormMethods.getFieldState('discountCode').invalid)
                  userSignupInput.discountCode = values.discountCode;

                // Mouseflow submission fix.
                formSubmitAttempt('#signupForm');

                // Submit Mutation.
                submitSignupForm({
                  variables: {
                    userSignupInput,
                  },
                  onCompleted: handleSubmitComplete,
                  onError: handleSubmitError,
                });
              } else if (result.error) {
                formSubmitFailure('#signupForm');
                setProcessError(t('form.errors.stripe'));
              }
            })
            .finally(() => {
              setStripeState({ ...stripeState, stripeCallProcessing: false });
            });
        } else {
          setProcessError(t('form.errors.creditcard'));
        }
      }
    } else {
      setProcessError(t('form.errors.fields'));
    }
  };

  return (
    <form
      data-testid="signup-form"
      id="signupForm"
      onSubmit={signupFormMethods.handleSubmit(handleSubmit, handleError)}
      style={{ display: 'inherit' }}
    >
      <Grid container>
        <Grid item xs={12}>
          <FormBanner
            text={processError}
            type={FormBannerType.ERROR}
            sx={{ marginLeft: '2rem' }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              paddingRight: { md: '4rem' },
            }}
          >
            <SignupAccountInformation t={t} />
          </Box>

          <Box
            sx={{
              paddingTop: '2rem',
              paddingRight: { md: '4rem' },
            }}
          >
            <SignupPlaidVerification
              t={t}
              verificationStatus={verificationStatus}
              setVerificationStatus={setVerificationStatus}
            />
          </Box>

          <Box
            sx={{
              paddingTop: '2rem',
              paddingRight: { md: '4rem' },
            }}
          >
            <SignupOrderPaymentDetails
              t={t}
              hasSubmitted={hasSubmitted}
              stripeState={stripeState}
              setStripeState={setStripeState}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              paddingTop: { xs: '2rem', md: '0' },
              paddingBottom: { xs: '2.5rem' },
            }}
          >
            <SignupOrderSummary
              t={t}
              setHasSubmitted={setHasSubmitted}
              idVerified={isValid(verificationStatus)}
              isLoading={signupLoading || loginLoading || stripeCallProcessing}
            />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <SignupTestimonial t={t} />
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}

export default SignupPageForm;
