import { useEffect } from 'react';

import { generatePath, useParams } from 'react-router-dom';
import useRequireAuth from '~/base/hooks/useRequireAuth';
import RedirectError from './RedirectError';

interface RedirectAuthProps {
  url: string | undefined;
}

function RedirectAuth({ url }: RedirectAuthProps) {
  const params = useParams();
  const { requireAuthComplete } = useRequireAuth();

  useEffect(() => {
    if (url) {
      const replaceParams = generatePath(url, params);
      const navigateTo = `${import.meta.env.VITE_LEGACY_APP_AUTH_REDIRECT}${replaceParams}`;
      if (requireAuthComplete) window.location.replace(navigateTo);
    }
  }, [url, requireAuthComplete]);

  return <RedirectError />;
}

export default RedirectAuth;
