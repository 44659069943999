const enum FlagNameEnum {
  // GENERAL
  SHOW_NOTICE_BANNER = 'show_notice_banner',
  SHOW_RATE_CHANGE_BANNER = 'PAT-4309/rate_change_banner',

  // SONGS
  ADD_SONG_REDIRECT = 'PAT-3326/add_song_redirect',
  EDIT_SONG_REDIRECT = 'PAT-3675/edit_song_redirect',

  // RECORDINGS
  ADD_RECORDING_REDIRECT = 'PAT-3351/add_recording_redirect',
  EDIT_RECORDING_REDIRECT = 'PAT-4146/edit_recording_redirect',

  // SONGWRITERS
  ADD_SONGWRITER_REDIRECT = 'PAT-3365/add_songwriter_redirect',
  EDIT_SONGWRITERS_REDIRECT = 'PAT-3365/edit_songwriter_redirect',
}

export default FlagNameEnum;
