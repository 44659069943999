import { SvgIcon } from '@mui/material';

function YouTubeIcon() {
  return (
    <SvgIcon
      fontSize="huge"
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.6769064,17.222225 C37.3400592,15.9538858 36.3475905,14.9549816 35.0874567,14.6159891 C32.8033737,14 23.6444635,14 23.6444635,14 C23.6444635,14 14.485607,14 12.2014703,14.6159891 C10.9413365,14.9550352 9.94886783,15.9538858 9.61202056,17.222225 C9,19.5211634 9,24.3176841 9,24.3176841 C9,24.3176841 9,29.1142048 9.61202056,31.4131432 C9.94886783,32.6814824 10.9413365,33.6387701 12.2014703,33.9777625 C14.485607,34.5937517 23.6444635,34.5937517 23.6444635,34.5937517 C23.6444635,34.5937517 32.80332,34.5937517 35.0874567,33.9777625 C36.3475905,33.6387701 37.3400592,32.6814824 37.6769064,31.4131432 C38.288927,29.1142048 38.288927,24.3176841 38.288927,24.3176841 C38.288927,24.3176841 38.288927,19.5211634 37.6769064,17.222225 Z M20.6489843,28.6725654 L20.6489843,19.9628028 L28.3040143,24.3177914 L20.6489843,28.6725654 Z"
        id="Shape"
        fill="#000000"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default YouTubeIcon;
