import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Translator } from '~/types/Translator';

interface ClaimDurationProps extends Translator {
  start: string;
  end: string;
}

export default function YoutubeClaimDuration({
  start = '',
  end = '',
  t,
}: ClaimDurationProps) {
  return (
    <Grid item sx={{ margin: 'auto 0.5rem' }}>
      <Typography>{t('page.claimed.duration.claim')}: </Typography>
      <Typography>
        {t('page.claimed.duration.start')}: {start ?? ''}{' '}
      </Typography>
      <Typography>
        {t('page.claimed.duration.end')}: {end ?? ''}
      </Typography>
    </Grid>
  );
}
