import CheckIcon from '@mui/icons-material/Check';
import { Avatar } from '@mui/material';

interface SignupStepNumberCheckProps {
  text: string;
  active: boolean;
  validated: boolean;
}

function SignupStepNumberCheck({
  text,
  active,
  validated,
}: SignupStepNumberCheckProps) {
  return (
    <span data-testid="signup-step-number">
      {!validated && (
        <Avatar
          data-testid="step-no-icon"
          className={active ? 'active' : ''}
          sx={{
            float: 'left',
            mr: '.5rem',
            background: '#A4A4A4',
            '&.active': { background: '#031C9B' },
          }}
        >
          {text}
        </Avatar>
      )}
      {validated && (
        <Avatar
          data-testid="step-check-icon"
          sx={{
            float: 'left',
            mr: '.5rem',
            background: '#031C9B',
          }}
        >
          <CheckIcon
            sx={{
              fill: '#fff',
            }}
          />
        </Avatar>
      )}
    </span>
  );
}

export default SignupStepNumberCheck;
