import { Grid, LinearProgress, Typography } from '@mui/material';
import _ from 'lodash';
import { AccountCompletionStep } from '~/types/generated/graphql';
import { Translator } from '~/types/Translator';

import DashboardAccountCompletionItem from '../DashboardAccountCompletionItem';

interface DashboardAccountCompletionProps extends Translator {
  items: Array<AccountCompletionStep>;
}

function DashboardAccountCompletion({
  t,
  items,
}: DashboardAccountCompletionProps) {
  const orderedItems = _.orderBy(items, ['order'], ['asc']);
  const completedItems = _.filter(items, (item) => {
    return item.isCompleted;
  }) as Array<AccountCompletionStep>;

  const percentComplete = completedItems
    ? Math.round((completedItems.length / orderedItems.length) * 100)
    : 0;

  return (
    <div data-testid="dashboard-account-completion">
      <Grid
        container
        sx={{
          border: '1px solid #F2F2F2',
          padding: '1rem 1rem 2rem 1rem',
          width: '100%',
        }}
        rowSpacing={3}
      >
        <Grid item xs={12}>
          <Typography variant="h5" component="h5" align="center">
            {t('page.content.account-completion')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {t('page.content.my-account-is')} {percentComplete}
          {t('page.content.percent-completed')}
        </Grid>
        <Grid item xs={12}>
          <LinearProgress
            variant="determinate"
            value={percentComplete}
            sx={{
              borderRadius: '.5rem',
              height: '.75rem',
              backgroundColor: '#E0E0E0',
              '& .MuiLinearProgress-barColorPrimary': {
                background: 'linear-gradient(#606BF8, #031C9B);',
              },
            }}
          />
        </Grid>

        {orderedItems.map(({ isCompleted, label, text }) => {
          return (
            <Grid item xs={6} md={12} key={label}>
              <DashboardAccountCompletionItem
                title={text as string}
                label={label as string}
                complete={isCompleted as boolean}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default DashboardAccountCompletion;
