import { Box, Tab } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingIndicator from '~/base/components/LoadingIndicator';
import PageTitle from '~/base/components/PageTitle/PageTitle';
import SongtrustTabPanel from '~/base/components/SongtrustTabPanel';
import SongtrustTabs from '~/base/components/SongtrustTabs/SongtrustTabs';
import routeMatchHelper from '~/base/helpers/routeMatch';
import usePageTitle from '~/hooks/usePageTitle';
import { tabPaths } from '~/songs/constants/constants';
import { T } from '~/types/Translator';
import { SongOwnershipType, useSongByIdQuery } from '~/types/generated/graphql';
import AddManualRecording from '../AddManualRecording/AddManualRecording';
import AddSpotifyRecording from '../AddSpotifyRecording/AddSpotifyRecording';

export default function AddRecordingsPage({ t }: { t: T }) {
  usePageTitle(t('page.titles.addRecordings'));
  const navigate = useNavigate();
  const formMethods = useForm();

  const [recordingIsrcList, setRecordingIsrcList] = useState<Array<string>>([]);
  const params = useParams();
  const {
    data: songData,
    loading,
    error,
  } = useSongByIdQuery({
    variables: { id: Number(params.id || '') },
  });
  const routeMatch = routeMatchHelper(Object.values(tabPaths));
  const currentTab = routeMatch?.pattern?.path;

  useEffect(() => {
    if (songData?.song?.recordings.totalCount) {
      setRecordingIsrcList(
        songData.song.recordings.edges.map(
          (recording) => recording?.node?.isrc,
        ) as [],
      );
    }
  }, [songData]);

  return (
    <FormProvider {...formMethods}>
      <form>
        <Container>
          <Grid
            container
            sx={{
              flexDirection: {
                xs: 'column',
              },
            }}
          >
            <Grid item>
              <PageTitle
                currentPage={t('page.add-recording.title')}
                breadcrumbLinks={[
                  {
                    id: 'songs-back-link',
                    label: t('page.titles.breadcrumb'),
                    href: '/songs',
                  },
                  {
                    id: 'song-edit-back-link',
                    label: songData?.song?.title || '',
                    href: `/songs/${songData?.song?.id}/edit`,
                  },
                ]}
                pageTitle={{
                  title: loading ? '...' : songData?.song?.title,
                  id: 'recording-song-page-title',
                }}
              />
            </Grid>
            <Grid item sx={{ pt: '1.5rem', pb: '1rem' }}>
              <Typography variant="h2">
                {t('page.add-recording.title')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Box
                sx={{ borderBottom: { lg: 1, xs: 0 }, borderColor: 'divider' }}
              >
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <SongtrustTabs value={currentTab || tabPaths.spotify}>
                      <Tab
                        data-testid="song-recordings-tab-spotify"
                        label={
                          <Typography variant="h3" component="span">
                            {t('page.add-recording.tabs.spotify.title')}
                          </Typography>
                        }
                        value={tabPaths.spotify}
                        to={`/songs/${songData?.song?.id}/recordings/spotify`}
                        component={Link}
                      />
                      <Tab
                        data-testid="song-recordings-tab-manual"
                        label={
                          <Typography variant="h3" component="span">
                            {t('page.add-recording.tabs.manual.title')}
                          </Typography>
                        }
                        value={tabPaths.manual}
                        to={`/songs/${songData?.song?.id}/recordings/manual`}
                        component={Link}
                      />
                    </SongtrustTabs>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid container item xs={12} md={12}>
              <Box width="100%">
                {loading || error || !songData?.song ? (
                  <LoadingIndicator size={50} />
                ) : (
                  <SongtrustTabPanel
                    value={currentTab || tabPaths.spotify}
                    id={tabPaths.spotify}
                  >
                    <AddSpotifyRecording
                      t={t}
                      songTitle={songData.song?.title || ''}
                      ownerships={
                        songData.song?.ownerships as Array<SongOwnershipType>
                      }
                      recordingIsrcList={recordingIsrcList}
                    />
                  </SongtrustTabPanel>
                )}
                <SongtrustTabPanel value={currentTab} id={tabPaths.manual}>
                  <AddManualRecording t={t} />
                </SongtrustTabPanel>
              </Box>
            </Grid>

            <Grid container item>
              <Button
                variant="outlined"
                onClick={() => navigate(`/songs/${songData?.song?.id}/edit`)}
              >
                {t('form.buttons.backToSong')}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </form>
    </FormProvider>
  );
}
