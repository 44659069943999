import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      queryStringParams: {
        version: import.meta.env.VITE_BUILD_TIMESTAMP,
      },
      loadPath: `${import.meta.env.VITE_CDN_URL}locales/{{lng}}/{{ns}}.json`,
    },
    react: {
      // useSuspense: false,
    },
    returnNull: false,
  })
  .then(() => {
    return true;
  });

export default i18n;
