import { useReactiveVar } from '@apollo/client';
import {
  Avatar,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SongtrustIcon from '~/base/components/SocialIcons/SongtrustIcon';
import { HELP_CENTER_URL } from '~/base/constants/baseConstants';
import { currentSongtrustUserPersonaVar } from '~/cache';

function OnboardingGetStartedPage() {
  const navigate = useNavigate();
  const { t } = useTranslation(['onboarding']);
  const userInformation = useReactiveVar(currentSongtrustUserPersonaVar);

  const avatarSx = {
    marginRight: '1rem',
    backgroundColor: '#031c9b',
    color: '#fff',
    fontSize: '1.375rem',
    fontFamily: 'National2Bold,Arial,sans-serif',
  };

  return (
    <div
      className="onboarding-getstarted-page"
      data-testid="onboarding-getstarted-page"
    >
      <Container sx={{ maxWidth: '960px' }} maxWidth={false}>
        <Grid
          container
          sx={{
            paddingTop: '2rem',
            paddingBottom: '2rem',
          }}
        >
          <Typography
            variant="h1"
            component="h1"
            sx={{
              color: '#6a003a',
              paddingBottom: '1.5rem',
            }}
          >
            {t('get-started-qualified.title', {
              name: userInformation?.loggedInSongtrustUser?.user.firstName,
            })}
            <Typography
              variant="h1"
              component="span"
              sx={{
                color: '#000',
              }}
            >
              {' '}
              <SongtrustIcon /> Songtrust
            </Typography>
          </Typography>
          <Typography variant="h4" component="h4">
            {t('get-started-qualified.subtitle')}
          </Typography>
        </Grid>

        <Grid container direction="column" spacing={3}>
          <Grid item container flexDirection="row">
            <Grid item xs={2} sm={1}>
              <Avatar sx={avatarSx}>1</Avatar>
            </Grid>
            <Grid item container xs={10} sm={11} alignContent="center">
              <Typography variant="body1" component="p">
                {t(`get-started-qualified.bullet-1`)}{' '}
                <strong>{t(`get-started-qualified.bullet-1-bold`)}</strong>
              </Typography>
            </Grid>
          </Grid>
          <Grid item container flexDirection="row">
            <Grid item xs={2} sm={1}>
              <Avatar sx={avatarSx}>2</Avatar>
            </Grid>
            <Grid item container xs={10} sm={11} alignContent="center">
              <Typography variant="body1" component="p">
                {t(`get-started-qualified.bullet-2`)}{' '}
                <strong>{t(`get-started-qualified.bullet-2-bold`)} </strong>
                {t(`get-started-qualified.bullet-2-more`)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container flexDirection="row">
            <Grid item xs={2} sm={1}>
              <Avatar sx={avatarSx}>3</Avatar>
            </Grid>
            <Grid item container xs={10} sm={11} alignContent="center">
              <Typography variant="body1" component="p">
                {t(`get-started-qualified.bullet-3`)}{' '}
                {t(`get-started-qualified.bullet-3-must`)}{' '}
                {t(`get-started-qualified.bullet-3-more`)}{' '}
                <strong>{t(`get-started-qualified.bullet-3-bold`)}</strong>
              </Typography>
            </Grid>
          </Grid>
          <Grid item container flexDirection="row">
            <Grid item xs={2} sm={1}>
              <Avatar sx={avatarSx}>4</Avatar>
            </Grid>
            <Grid item container xs={10} sm={11} alignContent="center">
              <Typography variant="body1" component="p">
                {t(`get-started-qualified.bullet-4`)}{' '}
                <strong>{t(`get-started-qualified.bullet-4-bold`)} </strong>
                {t(`get-started-qualified.bullet-4-more`)}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container flexDirection="row">
            <Grid item xs={2} sm={1}>
              <Avatar sx={avatarSx}>5</Avatar>
            </Grid>
            <Grid item container xs={10} sm={11} alignContent="center">
              <Typography variant="body1" component="p">
                <strong>
                  {t(`get-started-qualified.bullet-5-bold`)}{' '}
                  <Link href={HELP_CENTER_URL}>
                    {t(`get-started-qualified.bullet-5-help-center`)}
                  </Link>{' '}
                  {t(`get-started-qualified.bullet-5-bold-more`)}{' '}
                </strong>
                {t(`get-started-qualified.bullet-5`)}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container justifyContent="flex-end">
            <Button
              data-testid="get-started-button"
              variant="contained"
              color="secondary"
              type="button"
              onClick={() => {
                navigate('/onboarding');
              }}
            >
              {t('get-started-qualified.button')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default OnboardingGetStartedPage;
