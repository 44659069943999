import { SvgIcon } from '@mui/material';

function FacebookIcon() {
  return (
    <SvgIcon
      fontSize="huge"
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.0117188,25.5 L30.6782812,21.1565625 L26.510625,21.1565625 L26.510625,18.3379688 C26.510625,17.1496875 27.0928125,15.9914063 28.959375,15.9914063 L30.8540625,15.9914063 L30.8540625,12.2934375 C30.8540625,12.2934375 29.1346875,12 27.4907812,12 C24.0585938,12 21.8151563,14.0803125 21.8151563,17.84625 L21.8151563,21.1565625 L18,21.1565625 L18,25.5 L21.8151563,25.5 L21.8151563,36 L26.510625,36 L26.510625,25.5 L30.0117188,25.5 Z"
        id="Path"
        fill="#000000"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default FacebookIcon;
