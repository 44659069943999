const dashboardConstants = {
  DASHBOARD_PATHS: ['/dashboard'],
  ACCOUNT_COMPLETION_LINKS: {
    'add-songwriter': '/songwriters/add',
    'register-song': '/songs/add',
    'tax-done': '/account/tax-documentation',
  },
  FROZEN_PATHS: ['/frozen'],
  PASSWORD_RESET_PATHS: ['/account/reset-requested'],
};

export const {
  DASHBOARD_PATHS,
  ACCOUNT_COMPLETION_LINKS,
  FROZEN_PATHS,
  PASSWORD_RESET_PATHS,
} = dashboardConstants;

export default dashboardConstants;
