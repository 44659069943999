import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { Controller, useFormContext } from 'react-hook-form';
import { Translator } from '~/types/Translator';
import { SortDirection, SortField } from '~/types/generated/graphql';

export default function YoutubeClaimedFilters({ t }: Translator) {
  const { control } = useFormContext();

  return (
    <Grid
      container
      item
      direction={{ xs: 'column', sm: 'row' }}
      alignItems={{ sm: 'center', xs: 'normal' }}
      rowGap={{ xs: 2, sm: 1 }}
    >
      <Grid item xs={12}>
        <Typography variant="h5" component="h3">
          {t('page.claimed.filters.title')}
        </Typography>
      </Grid>
      <Grid item>
        <Controller
          name="sortBy.field"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              data-testid="sort-by"
              sx={{
                marginRight: '1em',
              }}
            >
              <MenuItem value={SortField.Created} data-testid="sort-by-created">
                {t(`page.claimed.filters.created`)}
              </MenuItem>
              <MenuItem value={SortField.ViewCount} data-testid="sort-by-views">
                {t(`page.claimed.filters.views`)}
              </MenuItem>
            </Select>
          )}
        />
        <Controller
          name="sortBy.direction"
          control={control}
          render={({ field }) => (
            <Select {...field} data-testid="sort-direction">
              <MenuItem value={SortDirection.Asc} data-testid="ascending">
                {t(`page.claimed.filters.asc`)}
              </MenuItem>
              <MenuItem value={SortDirection.Desc} data-testid="descending">
                {t(`page.claimed.filters.desc`)}
              </MenuItem>
            </Select>
          )}
        />
      </Grid>
    </Grid>
  );
}
