import { Box, Grid, Link, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Translator } from '~/types/Translator';
import { SongwriterType } from '~/types/generated/graphql';

interface SongwriterDetailInfoProps extends Translator {
  songwriter: SongwriterType;
}
function SongwriterDetailInfo({ t, songwriter }: SongwriterDetailInfoProps) {
  const [lodVerified, setLodVerified] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const { id: songwriterId } = useParams();

  useEffect(() => {
    if (songwriter && songwriter.currentStatus && songwriter.currentStatus[0]) {
      // Error?
      setHasError(
        (songwriter?.currentStatus[0].indexOf('error') > 0) as boolean,
      );

      // LOD Verified?
      setLodVerified(
        (songwriter?.currentStatus[0].indexOf('lod_verified') > 0) as boolean,
      );
    }
  }, [songwriter]);

  return (
    songwriter && (
      <div data-testid="songwriter-detail-info">
        <Grid container sx={{ backgroundColor: '#E0E0E0', p: '1rem' }}>
          <Grid item xs={6}>
            <Typography variant="h3" component="h3">
              {`${songwriter.firstName} ${songwriter.middleName ?? ''} ${songwriter.lastName}`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            {songwriter.ipi && (
              <Box>
                {t('page.songwriter-details.ipi')}: <b>{songwriter.ipi}</b>
              </Box>
            )}
            {songwriter.pro && (
              <Box>
                {t('page.songwriter-details.pro-affiliation')}:{' '}
                <b>{songwriter.pro?.name}</b>
              </Box>
            )}
          </Grid>
        </Grid>

        <Grid
          container
          data-testid="songwriter-details-affiliation"
          sx={{ backgroundColor: '#F2F2F2', p: '1rem' }}
        >
          {songwriter.publishingCompany && (
            <Grid item xs={6}>
              {songwriter.publishingCompany?.name && (
                <Box>
                  {t('page.songwriter-details.publisher.name')}:
                  <Typography variant="h3" component="h3" sx={{ pt: '0' }}>
                    {songwriter.publishingCompany?.name}
                  </Typography>
                </Box>
              )}
              {songwriter.publishingCompany?.ipi && (
                <Box>
                  {t('page.songwriter-details.publisher.ipi')}:{' '}
                  <b>{songwriter.publishingCompany?.ipi}</b>
                </Box>
              )}
              {songwriter.publishingCompany?.pro && (
                <Box>
                  {t('page.songwriter-details.publisher.pro-name')}:{' '}
                  <b>{songwriter.publishingCompany?.pro?.name}</b>
                </Box>
              )}
            </Grid>
          )}
          <Grid item xs={6}>
            {!hasError && lodVerified && (
              <Box>
                <Link
                  href={`${
                    import.meta.env.VITE_LEGACY_APP_AUTH_REDIRECT
                  }/pdfs/letter-of-direction/${songwriter.id}`}
                  target="_blank"
                >
                  {t('page.songwriter-details.letter-of-direction')}
                </Link>
              </Box>
            )}

            {!hasError && !songwriter.hasApplication && !songwriter.hasLod && (
              <Box>
                <Typography variant="body1" component="div">
                  {t('page.songwriter-details.registration-status')}
                </Typography>
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                  }}
                >
                  {songwriter?.currentStatus && songwriter?.currentStatus[1]}
                </Typography>
              </Box>
            )}

            {hasError && (
              <Box>
                <Typography variant="body1" component="div">
                  {t('page.songwriter-details.error')}:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                    color: '#D5164B',
                  }}
                >
                  {!songwriter.hasApplication && !songwriter.hasLod && (
                    <Link href={`/songwriters/${songwriterId}/edit`}>
                      {t('page.songwriter-details.lod-error')}
                    </Link>
                  )}
                  {(songwriter.hasApplication || songwriter.hasLod) && (
                    <>{t('page.songwriter-details.pro-error')}</>
                  )}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
    )
  );
}

export default SongwriterDetailInfo;
