import { SvgIcon } from '@mui/material';

function LinkedInIcon() {
  return (
    <SvgIcon
      fontSize="huge"
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3722628,36 L12.3964374,36 L12.3964374,19.976428 L17.3722628,19.976428 L17.3722628,36 Z M14.8816715,17.7906649 C13.2905645,17.7906649 12,16.4727784 12,14.8816714 C12,13.2901682 13.2901683,12 14.8816715,12 C16.4731747,12 17.7633429,13.2901682 17.7633429,14.8816714 C17.7633429,16.4727784 16.4722427,17.7906649 14.8816715,17.7906649 Z M35.9951785,36 L31.0300677,36 L31.0300677,28.1998259 C31.0300677,26.3408558 30.9925668,23.956874 28.4430456,23.956874 C25.8560236,23.956874 25.4595862,25.976562 25.4595862,28.0658943 L25.4595862,36 L20.4891181,36 L20.4891181,19.976428 L25.2613675,19.976428 L25.2613675,22.1621911 L25.3310119,22.1621911 C25.9953124,20.9032344 27.6180272,19.5746333 30.0389741,19.5746333 C35.0748008,19.5746333 36.0005358,22.8907788 36.0005358,27.1980178 L36.0005358,36 L35.9951785,36 Z"
        id="Shape"
        fill="#000000"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default LinkedInIcon;
